import { useQuery, useMutation } from 'react-query';

import settingApi from '../services/setting';

import {
  GET_ACCOUNT_SETTING_QUERY,
  GET_CARD_SETTING_QUERY,
  GET_CUSTOMIZATION_SETTING_QUERY,
  GET_GENERAL_SETTING_QUERY,
  GET_SECURITY_SETTING_QUERY,
  GET_TRANSACTION_SETTING_QUERY,
  UPDATE_ACCOUNT_SETTING_MUTATION,
  UPDATE_CARD_SETTING_MUTATION,
  UPDATE_PASSWORD_SETTING_MUTATION,
  UPDATE_SECURITY_SETTING_MUTATION,
  UPDATE_TRANSACTION_SETTING_MUTATION,
} from './keys';

export const useGetGeneralSetting = (body) => {
  const generalInfo = useQuery([GET_GENERAL_SETTING_QUERY, body], () => settingApi.getGeneralSetting(body));
  return generalInfo;
};

export const useGetAccountSetting = (body) => {
  const accountSetting = useQuery([GET_ACCOUNT_SETTING_QUERY, body], () => settingApi.getAccountSetting(body));
  return accountSetting;
};

export const useGetCardSetting = (body) => {
  const cardSetting = useQuery([GET_CARD_SETTING_QUERY, body], () => settingApi.getCardSetting(body));
  return cardSetting;
};

export const useGetTransactionSetting = (body) => {
  const transactionSetting = useQuery([GET_TRANSACTION_SETTING_QUERY, body], () =>
    settingApi.getTransactionSetting(body)
  );
  return transactionSetting;
};

export const useGetSecuritySetting = (body) => {
  const securitySetting = useQuery([GET_SECURITY_SETTING_QUERY, body], () => settingApi.getSecuritySetting(body));
  return securitySetting;
};

export const useEnableGoogle2FA = () => {
  const response = useMutation(settingApi.enableGoogle2FA, {
    mutationKey: 'enableGoogle2FA',
    onError: (error) => {
      console.log('Enable Google Authenticator error', error);
    },
  });

  return response;
};

export const useConfirmGoogle2FA = () => {
  const response = useMutation(settingApi.confirmGoogle2FA, {
    mutationKey: 'confirmGoogle2FA',
    onError: (error) => {
      console.log('Enable Google Authenticator error', error);
    },
  })

  return response;
}

export const useDisableGoogle2FA = () => {
  const response = useMutation(settingApi.disableGoogle2FA, {
    mutationKey: 'disableGoogle2FA',
    onError: (error) => {
      console.log('Disable Google Authenticator error', error);
    },
  });

  return response;
};

export const useConfirmDisableGoogle2FA = () => {
  const response = useMutation(settingApi.confirmDisableGoogle2FA, {
    mutationKey: 'confirmDisableGoogle2FA',
    onError: (error) => {
      console.log('Enable Google Authenticator error', error);
    },
  })

  return response;
}

export const useEnableSMS2FA = () => {
  const response = useMutation(settingApi.enableSMS2FA, {
    mutationKey: 'enableSMS2FA',
    onError: (error) => {
      console.log('Get SMS Authenticator OTP error', error);
    },
  });

  return response;
};

export const useConfirmSMS2FA = () => {
  const response = useMutation(settingApi.confirmSMS2FA, {
    mutationKey: 'confirmSMS2FA',
    onError: (error) => {
      console.log('Enable SMS Authenticator error', error);
    },
  });

  return response;
};

export const useDisableSMS2FA = () => {
  const response = useMutation(settingApi.disableSMS2FA, {
    mutationKey: 'disableSMS2FA',
    onError: (error) => {
      console.log('Disable SMS Authenticator error', error);
    },
  });

  return response;
};

export const useConfirmDisableSMS2FA = () => {
  const response = useMutation(settingApi.confirmDisableSMS2FA, {
    mutationKey: 'confirmDisableSMS2FA',
    onError: (error) => {
      console.log('Confirm Disable SMS Authenticator error', error);
    },
  })

  return response;
}

export const useEnableEmail2FA = () => {
  const response = useMutation(settingApi.enableEmail2FA, {
    mutationKey: 'enableEmail2FA',
    onError: (error) => {
      console.log('Get Email Authenticator OTP error', error);
    },
  });

  return response;
};

export const useConfirmEmail2FA = () => {
  const response = useMutation(settingApi.confirmEmail2FA, {
    mutationKey: 'confirmEmail2FA',
    onError: (error) => {
      console.log('Enable Email Authenticator error', error);
    },
  });

  return response;
};

export const useDisableEmail2FA = () => {
  const response = useMutation(settingApi.disableEmail2FA, {
    mutationKey: 'disableEmail2FA',
    onError: (error) => {
      console.log('Disable Email Authenticator error', error);
    },
  });

  return response;
};

export const useConfirmDisableEmail2FA = () => {
  const response = useMutation(settingApi.confirmDisableEmail2FA, {
    mutationKey: 'confirmDisableEmail2FA',
    onError: (error) => {
      console.log('Confirm Disable Email Authenticator error', error);
    },
  })

  return response;
}

export const useUpdateAccountSettingMutation = () => {
  const response = useMutation(settingApi.updateAccountSetting, {
    mutationKey: UPDATE_ACCOUNT_SETTING_MUTATION,
    onSuccess: (data) => {
      console.log('Account setting updated successfully.', data);
    },
    onError: (error) => {
      console.log('Account setting update error', error);
    },
  });

  return response;
};

export const useUpdateCardSettingMutation = () => {
  const response = useMutation(settingApi.updateCardSetting, {
    mutationKey: UPDATE_CARD_SETTING_MUTATION,
    onSuccess: (data) => {
      console.log('Card setting updated successfully.', data);
    },
    onError: (error) => {
      console.log('Card setting update error', error);
    },
  });

  return response;
};

export const useUpdateTransactionSettingMutation = () => {
  const response = useMutation(settingApi.updateTransactionSetting, {
    mutationKey: UPDATE_TRANSACTION_SETTING_MUTATION,
    onSuccess: (data) => {
      console.log('Transaction setting updated successfully.', data);
    },
    onError: (error) => {
      console.log('Transaction setting update error', error);
    },
  });

  return response;
};

export const useUpdateSecuritySettingMutation = () => {
  const response = useMutation(settingApi.updateSecuritySetting, {
    mutationKey: UPDATE_SECURITY_SETTING_MUTATION,
    onSuccess: (data) => {
      console.log('Security setting updated successfully.', data);
    },
    onError: (error) => {
      console.log('Security setting update error', error);
    },
  });

  return response;
};

export const useUpdatePasswordSettingMutation = () => {
  const response = useMutation(settingApi.updatePasswordSetting, {
    mutationKey: UPDATE_PASSWORD_SETTING_MUTATION,
    onSuccess: (data) => {
      console.log('Password updated successfully.', data);
    },
    onError: (error) => {
      console.log('Password update error', error);
    },
  });

  return response;
};

export const useGetCustomization = (body) => {
  const customization = useQuery([GET_CUSTOMIZATION_SETTING_QUERY, body], () => settingApi.getCustomization(body));
  return customization;
};

export const useUpdateCustomization = () => {
  const response = useMutation(settingApi.updateCustomization, {
    mutationKey: 'updateCustomization',
    onSuccess: (data) => {
      console.log('Customization setting updated successfully.', data);
    },
    onError: (error) => {
      console.log('Customization setting update error', error);
    },
  });

  return response;
};

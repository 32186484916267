import React from 'react';
import { Row, Col } from 'antd';
import { Checkbox, FormItem, Input, Radio } from 'formik-antd';

import './LinkFunding.less';

const BankLinkFunding = () => {
  return (
    <>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <FormItem label="Account Type" name="account_type">
            <Radio.Group name="account_type">
              <Radio value="checking" name="account_type">
                Checking
              </Radio>
              <Radio value="savings" name="account_type">
                Savings
              </Radio>
            </Radio.Group>
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={12}>
          <FormItem label="First Name" name="first_name">
            <Input name="first_name" placeholder="John" />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label="Last Name" name="last_name">
            <Input name="last_name" placeholder="Doe" />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <FormItem label="Routing Number" name="routing_number">
            <Input name="routing_number" placeholder="Routing Number" />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <FormItem label="Bank Name" name="bank_name">
            <Input name="bank_name" placeholder="Bank Name" />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <FormItem label="Account Number" name="account_number">
            <Input name="account_number" placeholder="Account number" />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <FormItem label="Re-enter Account Number" name="re_account_number">
            <Input name="re_account_number" placeholder="Account number" />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <FormItem name="primary_source_account">
            <Checkbox name="primary_source_account" />{' '}
            <span className="checkbox_label">Make my primary source account</span>
          </FormItem>
        </Col>
      </Row>
    </>
  );
};

export default BankLinkFunding;

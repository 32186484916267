import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { IoArrowBackCircle } from 'react-icons/io5';

import LayoutWarpper from 'components/Layout';
import CreateProductSteps from './CreateProductSteps';
import CardProductForm from './CardProductForm';
import WalletProductForm from './WalletProductForm';
import AccountProductForm from './AccountProductForm';
import CardAccountProductForm from './CardAccountProductForm';
import { useGetUserNameEmail } from 'rc-query/userHooks';
import { getAdmin, getBackoffice } from 'helpers/localStorage';

import './Products.less';

const ConfigureProduct = (props) => {
  const productType = props.match.params.productType;
  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const { data } = useGetUserNameEmail({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
  });
  const entities = data || [0];

  const [stepNumber, setStepNumber] = useState(1);
  return (
    <LayoutWarpper>
      <h1 className="page-title">Products - Creation</h1>
      <Row style={{ width: '100%' }} gutter={[16, 16]}>
        <Col span={24}>
          <div className="backBtn">
            <Link to={`/select_product_type`} style={{ display: 'flex', alignContent: 'center' }}>
              <IoArrowBackCircle size={22} style={{ marginRight: '13px' }} /> <span>Back</span>
            </Link>
          </div>
          <Row gutter={[16, 8]} style={{ marginTop: '40px' }}>
            <Col span={6}>
              <CreateProductSteps stepNumber={stepNumber} />
            </Col>
            <Col span={18}>
              {productType === 'card' ? (
                <CardProductForm stepNumber={stepNumber} setStepNumber={setStepNumber} entities={entities} />
              ) : productType === 'wallet' ? (
                <WalletProductForm stepNumber={stepNumber} setStepNumber={setStepNumber} entities={entities} />
              ) : productType === 'account' ? (
                <AccountProductForm stepNumber={stepNumber} setStepNumber={setStepNumber} entities={entities} />
              ) : productType === 'card_account' ? (
                <CardAccountProductForm stepNumber={stepNumber} setStepNumber={setStepNumber} entities={entities} />
              ) : null}
            </Col>
          </Row>
        </Col>
      </Row>
    </LayoutWarpper>
  );
};

export default ConfigureProduct;

import { useMutation, useQuery } from 'react-query';

import messageApi from 'services/message';

import {
  GET_ALL_MESSAGES_QUERY,
  MESSAGE_MUTATION,
  SAVE_MESSAGE_MUTATION,
  RESEND_MESSAGE_MUTATION,
  GET_SINGLE_MESSAGE_QUERY,
  UPDATE_MESSAGE_MUTATION,
} from './keys';

export const useGetAllMessages = (body) => {
  const messages = useQuery([GET_ALL_MESSAGES_QUERY, body], () => messageApi.getAllMessages(body));
  return messages;
};

export const useGetSingleMessage = (body) => {
  const messages = useQuery([GET_SINGLE_MESSAGE_QUERY, body], () => messageApi.getMessage(body));
  return messages;
};

export const useSendMessageMutation = () => {
  const response = useMutation(messageApi.sendMessage, {
    mutationKey: MESSAGE_MUTATION,
    onSuccess: (data) => {
      console.log('Message Send Success', data);
    },
    onError: (error) => {
      console.log('Message Send Error', error);
    },
  });
  return response;
};

export const useUpdateMessageMutation = () => {
  const response = useMutation(messageApi.updateMessage, {
    mutationKey: UPDATE_MESSAGE_MUTATION,
    onSuccess: (data) => {
      console.log('Message Update Success', data);
    },
    onError: (error) => {
      console.log('Message Update Error', error);
    },
  });

  return response;
};

export const useSaveMessageMutation = () => {
  const response = useMutation(messageApi.saveMessage, {
    mutationKey: SAVE_MESSAGE_MUTATION,
    onSuccess: (data) => {
      console.log('Message Save Success', data);
    },
    onError: (error) => {
      console.log('Message Save Error', error);
    },
  });

  return response;
};

export const useDeleteMessageMutation = () => {
  const response = useMutation(messageApi.deleteMessage, {
    mutationKey: SAVE_MESSAGE_MUTATION,
    onSuccess: (data) => {
      console.log('Message Delete Success', data);
    },
    onError: (error) => {
      console.log('Message Delete Error', error);
    },
  });

  return response;
};

export const useArchiveMessageMutation = () => {
  const response = useMutation(messageApi.archiveMessage, {
    mutationKey: SAVE_MESSAGE_MUTATION,
    onSuccess: (data) => {
      console.log('Message Archive Success', data);
    },
    onError: (error) => {
      console.log('Message Archive Error', error);
    },
  });

  return response;
};

export const useResendMessageMutation = () => {
  const response = useMutation(messageApi.resendMessage, {
    mutationKey: RESEND_MESSAGE_MUTATION,
    onSuccess: (data) => {
      console.log('Message Resend Success', data);
    },
    onError: (error) => {
      console.log('Message Resend Error', error);
    },
  });

  return response;
};

import { useMutation, useQuery } from 'react-query';

import productsApi from '../services/products';

import { GET_PRODUCT, GET_ALL_PRODUCTS, SAVE_PRODUCT_MUTATION } from './keys';

export const useGetAllProducts = (body) => {
  const products = useQuery([GET_ALL_PRODUCTS, body], () => productsApi.getProducts(body), {
    select: ({ data }) => data,
  });

  return products;
};

export const useGetUser = (body) => {
  const product = useQuery([GET_PRODUCT, body], () => productsApi.getProduct(body), {});

  return product;
};

export const useSaveProductMutation = () => {
  const response = useMutation(productsApi.saveProduct, {
    mutationKey: SAVE_PRODUCT_MUTATION,
    onSuccess: (data) => {
      console.log('Product Save Success', data);
    },
    onError: (error) => {
      console.log('Product Save Error', error);
    },
  });

  return response;
};

export const useUploadProductLogoMutation = () => {
  const response = useMutation(productsApi.uploadProductLogo, {
    mutationKey: 'UPLOAD_PRODUCT_LOGO',
    onSuccess: (data) => {
      console.log('Product Logo Upload Success', data);
    },
    onError: (error) => {
      console.log('Product Logo Upload Error', error);
    },
  });

  return response;
};

import React from 'react';
import { Steps } from 'antd';

import './LinkFunding.less';

const { Step } = Steps;

const LinkFundingSteps = ({ stepNumber }) => {
  return (
    <Steps progressDot current={stepNumber} direction="vertical">
      <Step title="Select source" />
      <Step title="Add source" />
    </Steps>
  );
};

export default LinkFundingSteps;

import { useMutation } from 'react-query';

import { LOGIN_MUTATION, VERIFY_MUTATION, VERIFY_BACKOFFICE_MUTATION, RESET_MUTATION } from './keys';

import authApi from 'services/auth';
import verifyApi from 'services/verify';
import backOfficeApi from 'services/verifyBackoffice';
import { setAdmin, setBackOffice } from 'helpers/localStorage';

export const useBackOfficeVerifyMutation = (history) => {
  const response = useMutation(backOfficeApi.verify, {
    mutationKey: VERIFY_BACKOFFICE_MUTATION,
    onSuccess: (data) => {
      if (data?.status !== 'error') {
        setBackOffice(data);
        history.push('/login');
      }
    },
    onError: (error) => {
      console.log('backOfficeVerifyMut ~ error', error);
    },
  });
  return response;
};

export const useLoginMutation = () => {
  const response = useMutation(authApi.login, {
    mutationKey: LOGIN_MUTATION,
    // onSuccess: (data) => {},
    onError: (error) => {
      console.log('useLoginMutation ~ error', error);
    },
  });
  return response;
};

export const useVerify2FAMutation = () => {
  const response = useMutation(authApi.verify2FA, {
    mutationKey: 'verify2FA',
    onError: (error) => {
      console.log('useVerify2FAMutation ~ error', error);
    },
  });
  return response;
};

export const useVerifyMut = (history) => {
  const response = useMutation(VERIFY_MUTATION, verifyApi.verify, {
    onSuccess: (data) => {
      console.log('useVerifyMut ~ data', data);
      setAdmin(data.user);

      history.push('/dashboard');
    },
  });
  return response;
};

export const useResetMutation = () => {
  const response = useMutation(authApi.reset, {
    mutationKey: RESET_MUTATION,
    // onSuccess: (data) => {},
    onError: (error) => {
      console.log('useResetMutation ~ error', error);
    },
  });
  return response;
};

export const useVerifyPasscodeMutation = () => {
  const response = useMutation(authApi.verifyPasscode, {
    mutationKey: RESET_MUTATION,
    // onSuccess: (data) => {},
    onError: (error) => {
      console.log('useVerifyPasscodeMutation ~ error', error);
    },
  });
  return response;
};

export const useChangePasswordMutation = () => {
  const response = useMutation(authApi.changePassword, {
    mutationKey: RESET_MUTATION,
    // onSuccess: (data) => {},
    onError: (error) => {
      console.log('useChangePasswordMutation ~ error', error);
    },
  });
  return response;
};

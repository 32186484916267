import axios from 'axios';

import { API_BASE_URL } from 'constants/config';
import { apiWrapper } from './interceptors';
// const host = window.location.origin;

const request = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30 * 1000,
});

const payoutsApi = {
  getPayouts: (body) => {
    const path = body?.entityType === 'business' ? '/admin/payouts/business' : '/admin/payouts';
    return new Promise((resolve, reject) => {
      request
        .post(path, { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getBeneficiaries: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/payouts/entity-bank-details', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  reversePayout: (body) => {
    const { id } = body;
    body.id = undefined;
    return new Promise((resolve, reject) => {
      request
        .post(`/admin/payouts/reverse-payout/${id}`, { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  statusPayout: (body) => {
    const { id } = body;
    body.id = undefined;
    return new Promise((resolve, reject) => {
      request
        .post(`/admin/payouts/approve-reject/${id}`, { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  sendMoney: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/payouts/create', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  exportFile: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/file-export', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },
};

export default apiWrapper(payoutsApi, ['payoutsApi']);

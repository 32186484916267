import React, { useState } from 'react';
import { Card, Spin, DatePicker } from 'antd';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import moment from 'moment';
import { CHART_DATE_ONLY } from 'constants/config';
import { moneyFormat } from 'helpers/utils';

const { RangePicker } = DatePicker;

const FlowChart = ({ isLoading, chartData, setFilters }) => {
  // const currentTime = new Date();
  const [activeTabKey, setActiveTabKey] = useState('year');
  const [dates, setDates] = useState([]);

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'start',
        labels: {
          usePointStyle: true,
          pointStyle: 'line',
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
            }
            return label;
          },
        },
      },
    },
    scales: {
      y: {
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value) {
            return moneyFormat(value, 2);
          },
        },
      },
    },
  };

  let labels = [];
  let inFlowValues = [];
  let outFlowValues = [];
  if (chartData) {
    labels = chartData.axes[0];
    inFlowValues = chartData.axes[1];
    outFlowValues = chartData.axes[2];
  }

  const charDataSet = {
    labels,
    datasets: [
      {
        label: 'Inflow',
        data: inFlowValues,
        borderColor: 'rgb(93, 218, 180)',
        fill: true,
        tension: 0.1,
        backgroundColor: 'rgba(93, 218, 180, 0.1)',
        lineTension: 0.4,
        borderDashOffset: 0.0,
        pointBorderWidth: 2,
        pointHoverRadius: 10,
      },
      {
        label: 'Outflow',
        data: outFlowValues,
        borderColor: 'rgb(255, 99, 132)',
        fill: true,
        tension: 0.1,
        backgroundColor: 'rgba(255, 99, 132, 0.1)',
        lineTension: 0.4,
        borderDashOffset: 0.0,
        pointBorderWidth: 2,
        pointHoverRadius: 10,
      },
    ],
  };

  const chartTabList = [
    {
      key: 'week',
      tab: 'Week',
    },
    {
      key: 'month',
      tab: 'Month',
    },
    {
      key: 'year',
      tab: 'Year',
    },
    {
      key: 'all_time',
      tab: 'All Time',
    },
  ];

  const contentList = {
    week: <Line type="line" options={options} data={charDataSet} height={400} width={990} />,
    month: <Line type="line" options={options} data={charDataSet} height={400} width={990} />,
    year: <Line type="line" options={options} data={charDataSet} height={400} width={990} />,
    all_time: <Line type="line" options={options} data={charDataSet} height={400} width={990} />,
  };

  const handleDateRange = (selectedDates) => {
    setDates(selectedDates);
    if (selectedDates.length > 0) {
      setFilters({
        type: 'custom',
        start_date: `${moment(selectedDates[0]).format(CHART_DATE_ONLY)}`,
        end_date: `${moment(selectedDates[1]).format(CHART_DATE_ONLY)}`,
      });
    }
  };

  return (
    <Card
      tabList={chartTabList}
      activeTabKey={activeTabKey}
      onTabChange={(key) => {
        setActiveTabKey(key);
        if (key === 'year') {
          setFilters({ type: 'year' });
        } else if (key === 'month') {
          setFilters({ type: 'month' });
        } else if (key === 'week') {
          setFilters({ type: 'week' });
        } else if (key === 'all_time') {
          if (dates.length > 0) {
            setFilters({
              type: 'custom',
              start_date: `${moment(dates[0]).format(CHART_DATE_ONLY)}`,
              end_date: `${moment(dates[1]).format(CHART_DATE_ONLY)}`,
            });
          } else {
            setFilters({
              type: 'custom',
              start_date: `${moment().startOf('year').format(CHART_DATE_ONLY)}`,
              end_date: `${moment().endOf('year').format(CHART_DATE_ONLY)}`,
            });
          }
        }
      }}
      style={{ width: '100%', height: '500px' }}
      tabBarExtraContent={
        activeTabKey === 'all_time' ? (
          <RangePicker
            allowClear={false}
            ranges={{
              'Last Year': [moment().subtract(1, 'years'), moment().startOf('day')],
              'Last 9 months': [moment().subtract(9, 'months'), moment().startOf('day')],
              'Last 6 months': [moment().subtract(6, 'months'), moment().startOf('day')],
              'Last 3 months': [moment().subtract(3, 'months'), moment().startOf('day')],
              'This Year': [moment().startOf('year'), moment().endOf('year')],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
            }}
            onCalendarChange={handleDateRange}
          />
        ) : null
      }
    >
      <Spin spinning={isLoading}>{contentList[activeTabKey]}</Spin>
    </Card>
  );
};

export default FlowChart;

import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Row, Spin, Space, Upload, notification } from 'antd';
import { IoArrowBackCircle } from 'react-icons/io5';
import { Link, useHistory } from 'react-router-dom';
import { InfoCircleFilled, CloudUploadOutlined, ArrowRightOutlined } from '@ant-design/icons';

import { getAdmin, getBackoffice, removeBulkUploadedKysync, setBulkUploadedKysync } from 'helpers/localStorage';

import LayoutWarpper from 'components/Layout';
import { API_BASE_URL } from 'constants/config';

import './KySync.less';

const KySync_KYB = () => {
  const { Dragger } = Upload;

  const [isLoading, setIsLoading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);

  useEffect(() => {
    removeBulkUploadedKysync();
  }, []);

  const history = useHistory();
  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const props = {
    name: 'file',
    multiple: false,
    maxCount: '1',
    accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
    action: `${API_BASE_URL}/admin/kysync/add-businesses-kyb`,
    data: {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
    },

    onChange(info) {
      const { status, response } = info.file;
      setIsLoading(true);
      // console.log('🚀 ~ file: KySync_KYC.jsx ~ line 39 ~ onChange ~ info', info);

      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }

      if (status === 'done') {
        setBulkUploadedKysync(response?.data || []);
        setIsUploaded(true);
        setIsLoading(false);
        notification.success({ message: `${info.file.name} file uploaded successfully.` });
        history.push('/kysync_bulk_upload_preview/kyb');
      } else if (status === 'error') {
        setIsLoading(false);
        notification.error({ message: `${info.file.name} file upload failed.` });
      }
    },

    // onDrop(e) {
    //   console.log('Dropped files', e.dataTransfer.files);
    // },
  };

  const handleUploadAndPreview = () => {
    setIsLoading(false);
    history.push('/kysync_bulk_upload_preview/kyb');

    // const body = {
    //   api_key: backOffice?.data?.name,
    //   token: adminUser?.token,
    //   // file: csvFilePath,
    // };

    // bulkUploadKYB
    //   .mutateAsync(body)
    //   .then((data) => {
    //     if (data.status === 'success') {
    //       notification.success({
    //         message: data.message,
    //       });
    //       // queryClient.refetchQueries([GET_ALL_PRODUCTS]);
    //       history.push('/kysync_bulk_upload_preview/kyb');
    //     } else {
    //       notification.error({
    //         message: data.message,
    //       });
    //     }
    //   })
    //   .finally(() => {
    //     setIsLoading(false);
    //   });
  };

  return (
    <LayoutWarpper>
      <div className="KySyncPage">
        <h1 className="page-title">KySync - KYB</h1>
        <div className="backBtn">
          <Link to="/kysync" style={{ display: 'flex', alignContent: 'center', marginBottom: '20px' }}>
            <IoArrowBackCircle size={22} style={{ marginRight: '13px' }} /> <span>Back</span>
          </Link>
        </div>
        <Row style={{ width: '100%' }} gutter={[16, 16]}>
          <Col span={16}>
            <Spin spinning={isLoading}>
              <div>
                <Card>
                  <p className="kyc-ver-title">KYB identity verification</p>
                  <p className="kyc-ver-description">
                    Know Your Business: Identity verification for businesses. Upload a bulk list or try adding
                    <br />
                    businesses to get started.
                  </p>
                  <Divider />
                  <div className="sampleFileText">
                    <p>
                      <InfoCircleFilled style={{ color: '#3895D3' }} /> To ensure you’re using the correct formatting,
                      <a href="https://api.deposits.dev/csv/add-businesses-to-kyb.csv" target="_blank">
                        download a template file here
                      </a>{' '}
                      and edit.
                    </p>
                  </div>
                  <Dragger {...props} style={{ marginTop: '16px', marginBottom: '16px' }}>
                    <div className="dragDropDesc">
                      <CloudUploadOutlined style={{ fontSize: '30px', color: '#8895A7' }} />
                      <p>
                        Drag & Drop to upload or browse to choose files
                        <br />
                        (Supported file types: .csv Max upload size: 100MB)
                      </p>
                    </div>
                  </Dragger>
                  <Link to="/add_contacts_manually_kyb">+ Add contacts manually</Link>
                  <br />
                  <div className="kyc-btn">
                    <Space>
                      <Link to="/kysync">
                        <Button size="large">Cancel</Button>
                      </Link>
                      <Button type="primary" size="large" onClick={handleUploadAndPreview} disabled={!isUploaded}>
                        Upload & preview
                      </Button>
                    </Space>
                  </div>
                </Card>
                <div className="link">
                  <Link to={`/kysync_kyb_results`}>
                    Verification results <ArrowRightOutlined />
                  </Link>
                </div>
              </div>
            </Spin>
          </Col>
        </Row>
      </div>
    </LayoutWarpper>
  );
};

export default KySync_KYB;

import React from 'react';
import { Row, Col, Card, Tag } from 'antd';
import moment from 'moment';
import { capitalize } from 'lodash';

import { ShowIfEmpty, renderUserStatus } from 'helpers/utils';

import './EntityDetail.less';

const BusinessInformation = ({ businessInfo }) => {
  return (
    <Card className="general-box">
      <div className="box-header">
        <p className="header-text">GENERAL INFORMATION</p>
        {renderUserStatus(businessInfo?.status, Tag)}
      </div>
      <Row>
        <Col span={8}>
          <div className="user-data">
            <span className="label">ID</span>
            <span className="value">{businessInfo?.id}</span>
          </div>
        </Col>
        <Col span={8}>
          <div className="user-data">
            <span className="label">KYB Status</span>
            <span className="value">{capitalize(businessInfo?.kyb_status)}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <div className="user-data">
            <span className="label">Business Name</span>
            <span className="value">{ShowIfEmpty(businessInfo?.business_name, '-')}</span>
          </div>
          <div className="user-data">
            <span className="label">Address</span>
            <span className="value">
              {ShowIfEmpty(businessInfo?.business_address1, '')} {ShowIfEmpty(businessInfo?.city, '')}
            </span>
          </div>
        </Col>
        <Col span={8}>
          <div className="user-data">
            <span className="label">Email Address</span>
            <span className="value ellipsis" title={ShowIfEmpty(businessInfo?.business_email, '-')}>
              {ShowIfEmpty(businessInfo?.business_email, '-')}
            </span>
          </div>
          <div className="user-data">
            <span className="label">Verification Date</span>
            <span className="value">{moment(businessInfo?.email_confirm).format('MMM DD, YYYY')}</span>
          </div>
        </Col>
        <Col span={8}>
          <div className="user-data">
            <span className="label">Phone Number</span>
            <span className="value">{ShowIfEmpty(businessInfo?.phone_number, '-')}</span>
          </div>
          <div className="user-data">
            <span className="label">IP</span>
            <span className="value">{ShowIfEmpty(businessInfo?.ip, '-')}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="user-data">
            <span className="label">KYB Result</span>
            <span className="value">{ShowIfEmpty(businessInfo?.kyb_response, 'N/A')}</span>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default BusinessInformation;

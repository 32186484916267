import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Tabs, Pagination, Spin, Tag, Modal, Row, Col, Button, notification, Dropdown, Menu, Input, Radio, Select } from 'antd';
import moment from 'moment';
import { IoArrowBackCircle } from 'react-icons/io5';
import { queryClient } from 'rc-query';
import { Formik } from 'formik';
import { Form, FormItem, InputNumber, SubmitButton } from 'formik-antd';
import { EllipsisOutlined } from '@ant-design/icons';

import { DEFAULT_PAGE_SIZE } from 'constants/config';
import ExportFile from 'components/ExportFile';
import { useAllBankAccounts, useBlockAccountMutation, useAccountLimitMutation } from 'rc-query/bankAccountHooks';
import { getAdmin, getBackoffice } from 'helpers/localStorage';
import { GET_ALL_BANK_ACCOUNTS_QY } from 'rc-query/keys';

import './BankAccountTable.less';

const { TabPane } = Tabs;
const { Search } = Input;

const BankAccountsTable = ({ userId, entityType, issued_bank_account = false }) => {
  const [filters, setFilters] = useState({
    page: 1,
    per_page: DEFAULT_PAGE_SIZE,
  });
  const [accountDetail, setAccountDetail] = useState({
    bank_name: '',
    account_number: '',
    account_holder_name: '',
    status: '',
  });
  const [selectedAccount, setSelectedAccount] = useState({});
  const [statusToChange, setStatusToChange] = useState('');
  const [showBlockAccountBox, setShowBlockAccountBox] = useState(false);
  const [showAccountLimitBox, setShowAccountLimitBox] = useState(false);
  const [accountType, setAccountType] = useState('individual');

  const adminUser = getAdmin();
  const backOffice = getBackoffice();
  const apiKey = backOffice?.data?.name;
  const adminToken = adminUser?.token;

  const blockAccountMutation = useBlockAccountMutation();
  const accountLimitMutation = useAccountLimitMutation();

  const payloadProps = {
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
    issued_bank_account,
    accountType,
    ...filters,
  };

  if (userId) {
    payloadProps.user_id = userId;
  }

  const { isLoading, data } = useAllBankAccounts(payloadProps);

  const allBankAccounts = data?.accounts?.data || [];
  const totalRecords = data?.accounts?.total || 0;
  const currentPage = data?.accounts?.current_page || 0;

  const stats = data?.stats || {};
  const { total, active, blocked, blocked_transfer, blocked_deposit, pending } = stats;

  const renderBankAccountStatus = (status) => {
    switch (status.toLowerCase()) {
      case 'active':
      case 'completed':
        return <Tag color="green">Active</Tag>;
      case 'pending':
        return <Tag color="orange">Pending</Tag>;
      case 'blocked':
        return <Tag color="red">Blocked</Tag>;
      case 'closed':
        return <Tag color="gray">Closed</Tag>;
      case 'blocked_deposit':
        return <Tag color="red">Blocked Deposits</Tag>;
      case 'blocked_transfer':
        return <Tag color="red">Blocked Transfer</Tag>;
      default:
        return null;
    }
  };

  const handleShowBlockAccountBox = (record, statusToChange) => {
    setAccountDetail(record);
    setStatusToChange(statusToChange);
    setShowBlockAccountBox(true);
  };

  const handleAccountStatus = (account_id, status) => {
    blockAccountMutation
      .mutateAsync({
        api_key: apiKey,
        token: adminToken,
        issued_bank_account,
        account_id: `${account_id}`,
        status,
      })
      .then((response) => {
        if (response?.status === 'error') {
          notification.error({
            message: response.message,
          });
        } else if (response?.status === 'success') {
          queryClient.refetchQueries(['allBankAccounts']);
          setShowBlockAccountBox(false);

          notification.success({
            message: response.message,
          });
        }
      });
  };

  const columns = [
    {
      title: 'Account Number',
      dataIndex: 'account_number',
      key: 'account_number',
    },
    {
      title: 'Routing Number',
      dataIndex: 'routing_number',
      key: 'routing_number',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text, record) => <span>{renderBankAccountStatus(record.status)}</span>,
    },
    {
      title: 'Entity',
      dataIndex: issued_bank_account ? 'account_holder_name' : 'owner_name',
      key: issued_bank_account ? 'account_holder_name' : 'owner_name',
    },
    {
      title: 'Date Created',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (text, record) => moment(record.created_at).format('MMM DD, YYYY'),
    },
  ];

  if (issued_bank_account) {
    columns.push({
      title: 'Action',
      key: 'id',
      dataIndex: 'id',
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu key={`menu-${record.id}`}>
              <Menu.Item key={`set-limit-${record.id}`} onClick={() => handleShowAccountLimitBox(record)}>
                Set limits
              </Menu.Item>
              {/* {record?.status === 'blocked_transfer' ? (
                <Menu.Item
                  key={`active-transfer-${record.id}`}
                  onClick={() => handleAccountStatus(record.id, 'active')}
                >
                  <span className="green">Activate Transfer</span>
                </Menu.Item>
              ) : (
                <Menu.Item
                  key={`block-transfer-${record.id}`}
                  onClick={() => handleShowBlockAccountBox(record, 'blocked_transfer')}
                >
                  <span className="red">Block Transfer</span>
                </Menu.Item>
              )} */}
              {/* {record?.status === 'blocked_deposit' ? (
                <Menu.Item key={`active-deposit-${record.id}`} onClick={() => handleAccountStatus(record.id, 'active')}>
                  <span className="green">Activate Deposits</span>
                </Menu.Item>
              ) : (
                <Menu.Item
                  key={`block-deposit-${record.id}`}
                  onClick={() => handleShowBlockAccountBox(record, 'blocked_deposit')}
                >
                  <span className="red">Block Deposits</span>
                </Menu.Item>
              )} */}
              {record?.status === 'blocked' ||
              record?.status === 'blocked_deposit' ||
              record?.status === 'blocked_transfer' ? (
                <Menu.Item key={`active-${record.id}`} onClick={() => handleAccountStatus(record.id, 'active')}>
                  <span className="green">Activate</span>
                </Menu.Item>
              ) : (
                <Menu.Item key={`block-${record.id}`} onClick={() => handleShowBlockAccountBox(record, 'blocked')}>
                  <span className="red">Block</span>
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <a className="ant-dropdown-link">
            <EllipsisOutlined />
          </a>
        </Dropdown>
      ),
    });
  }

  const handlePageChange = (page) => {
    setFilters({ ...filters, page });
  };

  const handleTabs = (key) => {
    setFilters(key !== 'all' ? { ...filters, status: key } : {});
  };

  const handleTableChange = (pagination, tableFilters, sorter) => {
    const account_number = tableFilters?.account_number?.[0]?.toString() || undefined;
    const owner_name = tableFilters?.owner_name?.[0]?.toString() || undefined;

    setFilters({
      ...filters,
      account_number,
      owner_name,
      page: 1,
      sort_by_column: sorter.field,
      sort_by_type: sorter.order === 'ascend' ? 'ASC' : 'DESC',
    });
  };

  const onSearch = (value) => {
    const keyword = value?.toString() || undefined;

    setFilters({
      ...filters,
      search: keyword,
      page: 1,
      per_page: DEFAULT_PAGE_SIZE,
    });
  };

  const handleCancelBox = () => {
    setShowBlockAccountBox(false);
    setStatusToChange('block');
    setAccountDetail({});
  };

  const handleShowAccountLimitBox = (record) => {
    setSelectedAccount(record);
    setShowAccountLimitBox(true);
  };

  const handleHideAccountLimitBox = () => {
    setSelectedAccount({});
    setShowAccountLimitBox(false);
  };

  const handleAccountLimit = (values, formikHelpers) => {
    formikHelpers.validateForm();

    accountLimitMutation
      .mutateAsync({
        api_key: apiKey,
        token: adminToken,
        account_id: `${selectedAccount?.id}`,
        daily_limit: values.daily_limit,
        monthly_limit: values.monthly_limit,
        issued_bank_account,
      })
      .then((data) => {
        if (data.status === 'success') {
          notification.success({
            message: data.message,
          });
          queryClient.refetchQueries([GET_ALL_BANK_ACCOUNTS_QY]);
          setShowAccountLimitBox(false);
        } else {
          notification.error({
            message: data.message,
          });
        }
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  const handleAccountTypeChange = (value) => {
    setAccountType(value);
  };

  return (
    <Spin spinning={isLoading}>
    <Row>
      <Col span={20}>
        
      {userId ? (
        <div className="backBtn">
          <Link
            to={`/entity/${entityType}/${userId}`}
            style={{ display: 'flex', alignContent: 'center', marginBottom: '20px' }}
          >
            <IoArrowBackCircle size={22} style={{ marginRight: '13px' }} /> <span>Back</span>
          </Link>
        </div>
      ) : null}

      {!userId && (
        <Tabs defaultActiveKey="0" left="true" size="large" onChange={handleTabs}>
          <TabPane onChange={() => handleTabs('all')} tab={`All (${total || 0})`} key="all" />
          <TabPane onChange={() => handleTabs('active')} tab={`Active (${active || 0})`} key="active" />
          <TabPane onChange={() => handleTabs('blocked')} tab={`Blocked (${blocked || 0})`} key="blocked" />
          <TabPane
            onChange={() => handleTabs('blocked_deposit')}
            tab={`Blocked Deposit (${blocked_deposit || 0})`}
            key="blocked_deposit"
          />
          <TabPane
            onChange={() => handleTabs('blocked_transfer')}
            tab={`Blocked Transfer (${blocked_transfer || 0})`}
            key="blocked_transfer"
          />
          <TabPane onChange={() => handleTabs('pending')} tab={`Pending (${pending || 0})`} key="pending" />
        </Tabs>
      )}
      </Col>

      <Col span={4}>
        <Radio.Group>
          <Select defaultValue="individual" onChange={handleAccountTypeChange} style={{ width: 200 }}>
            <Option value="individual">Individual</Option>
            <Option value="business">Business</Option>
          </Select>
        </Radio.Group>
      </Col>
    </Row>

      <div className="bank_accounts">
        <div className="searchBox">
          <Search placeholder="Search" onSearch={onSearch} allowClear style={{ width: 300 }} />
          <ExportFile data_type={issued_bank_account ? 'issued_bank_accounts' : 'saved_bank_accounts'} />
        </div>
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={allBankAccounts}
          onChange={handleTableChange}
          pagination={false}
        />
        {totalRecords > 0 ? (
          <div className="pagination">
            <Pagination
              showLessItems
              defaultPageSize={DEFAULT_PAGE_SIZE}
              total={totalRecords}
              showSizeChanger={false}
              current={currentPage}
              onChange={handlePageChange}
            />
          </div>
        ) : null}
      </div>
      <Modal
        width={525}
        title="Account Limits"
        visible={showAccountLimitBox}
        onCancel={handleHideAccountLimitBox}
        footer={false}
        className="cardModal"
        key={`account-limit-modal-${selectedAccount?.id}`}
      >
        <Formik
          enableReinitialize
          key={`account-limit-form-${selectedAccount?.id}`}
          onSubmit={handleAccountLimit}
          layout="vertical"
          initialValues={{
            daily_limit: +selectedAccount?.daily_limit || 0,
            monthly_limit: +selectedAccount?.monthly_limit || 0,
          }}
        >
          {(formProps) => {
            const { isSubmitting, handleSubmit } = formProps;
            return (
              <Form
                initialValues={{
                  daily_limit: +selectedAccount?.daily_limit || 0,
                  monthly_limit: +selectedAccount?.monthly_limit || 0,
                }}
              >
                <Row>
                  <Col span={24}>
                    <p className="descriptionText">Set entity spending and withdrawals account limits</p>
                  </Col>
                </Row>
                <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
                  <Col span={12}>
                    <FormItem name="daily_limit" label="Daily limit">
                      <InputNumber
                        size="large"
                        prefix="$"
                        precision={2}
                        name="daily_limit"
                        placeholder="0.00"
                        min={0}
                        style={{ width: '100%' }}
                      />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem name="monthly_limit" label="Monthly limit">
                      <InputNumber
                        size="large"
                        prefix="$"
                        precision={2}
                        name="monthly_limit"
                        placeholder="0.00"
                        min={0}
                        style={{ width: '100%' }}
                      />
                    </FormItem>
                  </Col>
                </Row>
                <Row style={{ marginTop: '22px' }}>
                  <Col span={24}>
                    <SubmitButton
                      loading={isSubmitting}
                      type="primary"
                      htmlType="submit"
                      onClick={handleSubmit}
                      size="large"
                      style={{ width: '100%' }}
                    >
                      Set Limit
                    </SubmitButton>
                  </Col>
                </Row>
                {/* <pre
                style={{
                  background: '#f6f8fa',
                  fontSize: '.65rem',
                  padding: '.5rem',
                }}
              >
                <FormikDebug />
              </pre> */}
              </Form>
            );
          }}
        </Formik>
      </Modal>
      <Modal
        width={450}
        title={
          statusToChange === 'blocked_deposit'
            ? 'Block Deposits'
            : statusToChange === 'blocked_transfer'
            ? 'Block Transfers'
            : 'Block Account'
        }
        visible={showBlockAccountBox}
        onCancel={handleCancelBox}
        footer={false}
        className="blockAccountModal"
      >
        <Row style={{ marginTop: '20px' }}>
          <Col span={24}>
            <p className="descriptionText">
              {statusToChange === 'blocked_deposit'
                ? 'You are about to block deposits from this account.'
                : statusToChange === 'blocked_transfer'
                ? 'You are about to block transfers from this account.'
                : 'You are about to block this account.'}{' '}
              Are you sure about this?
            </p>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
          <Col span={12}>
            <label>Account Number</label>
            <p>{accountDetail?.account_number}</p>
          </Col>
          <Col span={12}>
            <label>Account Name</label>
            <p>{accountDetail?.bank_name}</p>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
          <Col span={24}>
            <label>Owner Name</label>
            <p>{accountDetail?.account_holder_name}</p>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
          <Col span={24}>
            <Button
              className="actionBtn"
              type="danger"
              size="large"
              style={{ width: '100%' }}
              onClick={() => handleAccountStatus(accountDetail?.id, statusToChange)}
            >
              {statusToChange === 'blocked_deposit'
                ? 'Block Deposits'
                : statusToChange === 'blocked_transfer'
                ? 'Block Transfers'
                : 'Block Account'}
            </Button>
          </Col>
        </Row>
      </Modal>
    </Spin>
  );
};

export default BankAccountsTable;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, notification, Row, Space, Upload } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Radio, SubmitButton } from 'formik-antd';
import { UploadOutlined, InfoCircleFilled } from '@ant-design/icons';

import { API_BASE_URL } from 'constants/config';
import { getAdmin, getBackoffice, setBackOffice } from 'helpers/localStorage';
import { queryClient } from 'rc-query';
import { GET_CUSTOMIZATION_SETTING_QUERY } from 'rc-query/keys';
import { useGetCustomization, useUpdateCustomization } from 'rc-query/settingHooks';
import Loading from 'components/Loading';

import './Settings.less';

const Customisations = () => {
  const history = useHistory();
  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const [productLogo, setProductLogo] = useState('');
  const { isLoading, data } = useGetCustomization({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
  });

  const updateCustomization = useUpdateCustomization();

  useEffect(() => {
    setProductLogo(data?.data?.logo);
  }, [data]);

  const { company_brand_color } = data?.data || {};

  const uploaderProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    action: `${API_BASE_URL}/files/upload`,
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info?.file?.response?.status === 'success') {
        setProductLogo(info?.file?.response?.data?.file_name);
        notification.success({
          message: `${info.file.name} file uploaded successfully`,
        });
      } else if (info?.file?.response?.status === 'error') {
        notification.error({ message: `${info.file.name} file upload failed.` });
        notification.error({ message: info?.file?.response?.message });
      }
    },
  };

  const handleSubmit = (values, formikHelpers) => {
    formikHelpers.validateForm();
    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      ...values,
      logo: productLogo,
    };

    updateCustomization
      .mutateAsync(body)
      .then((data) => {
        if (data.status === 'error') {
          notification.error({
            message: data.message,
          });
        } else if (data.status === 'success') {
          setBackOffice({
            ...backOffice,
            data: { logo: productLogo, name: backOffice?.data?.name, company_brand_color: values.company_brand_color },
          });
          queryClient.refetchQueries([GET_CUSTOMIZATION_SETTING_QUERY]);
          notification.success({
            message: data.message,
          });
          history.push('/settings#customisations');
        }
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  return (
    <div className="custumizationTab">
      <Row>
        <Col span={14}>
          {isLoading ? (
            <Loading fullScreen loading={isLoading} />
          ) : (
            <Formik
              initialValues={{
                company_brand_color: company_brand_color,
              }}
              onSubmit={handleSubmit}
            >
              {(formProps) => {
                const { isSubmitting, handleSubmit } = formProps;
                return (
                  <Form
                    layout="vertical"
                    initialValues={{
                      company_brand_color: company_brand_color,
                    }}
                  >
                    <h5 className="settings-description" style={{ marginTop: '32px' }}>
                      Customise your KySync application
                    </h5>

                    <Row style={{ marginTop: '32px' }}>
                      <Col span={24}>
                        <FormItem label="Logo" name="file">
                          <Upload {...uploaderProps}>
                            <Button icon={<UploadOutlined />}>Choose File</Button>
                          </Upload>
                          {productLogo && (
                            <div style={{ marginTop: '20px' }}>
                              <img src={productLogo} alt="logo preview" width={150} />
                            </div>
                          )}
                        </FormItem>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: '32px' }}>
                      <Col span={24}>
                        <FormItem label="Color" name="company_brand_color">
                          <Radio.Group defaultValue="white" size="large" name="company_brand_color">
                            <Row gutter={[8, 8]}>
                              <Col span={6}>
                                <Radio.Button value="#0CB9E9" name="company_brand_color">
                                  <div className="alignCenter">
                                    <span className="teal-Dot"></span>
                                    <span>Teal</span>
                                  </div>
                                </Radio.Button>
                              </Col>

                              <Col span={6}>
                                <Radio.Button value="#1A8B5A" name="company_brand_color">
                                  <div className="alignCenter">
                                    <span className="green-Dot"></span>
                                    <span>Green</span>
                                  </div>
                                </Radio.Button>
                              </Col>

                              <Col span={6}>
                                <Radio.Button value="#525964" name="company_brand_color">
                                  <div className="alignCenter">
                                    <span className="black-Dot"></span>
                                    <span>Black</span>
                                  </div>
                                </Radio.Button>
                              </Col>

                              <Col span={6}>
                                <Radio.Button value="#971E31" name="company_brand_color">
                                  <div className="alignCenter">
                                    <span className="red-Dot"></span>
                                    <span>Red</span>
                                  </div>
                                </Radio.Button>
                              </Col>
                            </Row>

                            <Row gutter={[8, 8]} style={{ marginTop: '16px' }}>
                              <Col span={6}>
                                <Radio.Button value="#F6F6F8" name="company_brand_color">
                                  <div className="alignCenter">
                                    <span className="white-Dot"></span>
                                    <span>White</span>
                                  </div>
                                </Radio.Button>
                              </Col>
                              <Col span={6}>
                                <Radio.Button value="#E06104" name="company_brand_color">
                                  <div className="alignCenter">
                                    <span className="orange-Dot"></span>
                                    <span>Orange</span>
                                  </div>
                                </Radio.Button>
                              </Col>
                              <Col span={6}>
                                <Radio.Button value="#0059AC" name="company_brand_color">
                                  <div className="alignCenter">
                                    <span className="blue-Dot"></span>
                                    <span>Blue</span>
                                  </div>
                                </Radio.Button>
                              </Col>
                              <Col span={6}>
                                <Radio.Button value="#4D36C0" name="company_brand_color">
                                  <div className="alignCenter">
                                    <span className="purple-Dot"></span>
                                    <span>Purple</span>
                                  </div>
                                </Radio.Button>
                              </Col>
                            </Row>
                          </Radio.Group>
                        </FormItem>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: '32px' }}>
                      <Col>
                        <div className="color-info">
                          <p>
                            <InfoCircleFilled style={{ color: '#3895D3' }} /> You can pick a color closely related to
                            your brand color
                          </p>
                        </div>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: '32px' }}>
                      <Space>
                        <Button size="large">Cancel</Button>
                        <SubmitButton
                          loading={isSubmitting}
                          type="primary"
                          htmlType="submit"
                          onClick={handleSubmit}
                          size="large"
                          style={{ marginLeft: '16px' }}
                        >
                          Save customisations
                        </SubmitButton>
                      </Space>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          )}
        </Col>
        <Col span={10}>
          <div className="preview">
            <div className="title">Mobile Preview</div>
            <div>
              <img src={`/images/mobilePreview.svg`} alt="Mobile Preview" />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Customisations;

import React from 'react';
import { Row, Col } from 'antd';

import LayoutWarpper from 'components/Layout';
import AudiencesTable from 'components/Audeinces/AudiencesTable';

import './AudiencesList.less';

const AudiencesList = () => {
  return (
    <LayoutWarpper>
      <h1 className="page-title">Entities - Distressed driver car loan offer</h1>
      <Row style={{ width: '100%' }} gutter={[16, 16]}>
        <Col span={24}>
          <AudiencesTable />
        </Col>
      </Row>
    </LayoutWarpper>
  );
};

export default AudiencesList;

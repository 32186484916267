import axios from 'axios';

import { API_BASE_URL } from '@Constants/config';
import { apiWrapper } from './interceptors';
// const host = window.location.origin;

const request = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30 * 1000,
});

const messageApi = {
  getAllMessages: (body) => {
    const post_request = '/admin/messages';

    return new Promise((resolve, reject) => {
      request
        .post(post_request, { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getMessage: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/messages/get_single', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  sendMessage: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/messages/send', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  saveMessage: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/messages/save', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  updateMessage: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/messages/update', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  deleteMessage: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/messages/delete', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  archiveMessage: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/messages/archive', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  resendMessage: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/messages/resend', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },
};

export default apiWrapper(messageApi, ['sendEmail', 'saveMessage', 'deleteMessage', 'archiveMessage']);

import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Row, Col, Alert } from 'antd';
import { ArrowLeftOutlined, KeyOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import { useVerifyPasscodeMutation } from 'rc-query/authHooks';

import { getAdmin, getBackoffice, getVerifyEmail, setStageToken } from 'helpers/localStorage';

import './VerifyPasscode.less';

const VerifyPasscode = () => {
  const history = useHistory();
  const verifyPasscodeMutation = useVerifyPasscodeMutation();

  const adminUser = getAdmin();
  const backOffice = getBackoffice();
  const verifyEmail = getVerifyEmail();

  if (adminUser?.status === 'active') {
    history.push('/dashboard');
  }

  const onFinish = async (values) => {
    verifyPasscodeMutation
      .mutateAsync({ ...values, email: verifyEmail, api_key: backOffice?.data?.name })
      .then((data) => {
        setStageToken(data?.data?.stage_token);
        history.push('/change_password');
      });
  };

  return (
    <>
      <div className="main-container">
        <Row justify="center" align="middle">
          <Col>
            <Row
              type="flex"
              align="middle"
              justify="center"
              className="px-3 bg-white mh-page"
              style={{ minHeight: '100vh' }}
            >
              <div
                style={{
                  maxWidth: '500px',
                  zIndex: 2,
                  minWidth: '300px',
                  backgroundColor: 'white',
                  padding: '2rem',
                  boxShadow: '2px 3px 5px 0px rgba(38,7,4,0.39)',
                }}
              >
                <div className="mb-5">
                  <Row>
                    <Col>
                      <img
                        className="gnp_logo"
                        src={backOffice?.data?.logo ? backOffice.data.logo : 'https://assets.deposits.inc/img/logo/deposits/png/logo_main.png'}
                        alt="Backoffice"
                      />
                    </Col>
                  </Row>

                  <h2 className="mb-0 mt-3">Verify Passcode</h2>
                </div>
                {verifyPasscodeMutation?.data?.status === 'warning' ? (
                  <Alert type="error" message={verifyPasscodeMutation?.data?.message} showIcon closable />
                ) : null}

                <Form layout="vertical" onFinish={onFinish}>
                  <Form.Item
                    label="Enter Passcode"
                    name="code"
                    rules={[
                      {
                        required: true,
                        message: 'Please input passcode!',
                      },
                    ]}
                  >
                    <Input prefix={<KeyOutlined style={{ fontSize: '16px' }} />} type="text" placeholder="Passcode" />
                  </Form.Item>

                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    style={{ marginTop: '1rem' }}
                    loading={verifyPasscodeMutation.isLoading}
                  >
                    Reset
                  </Button>
                </Form>
                <div className="mt-15">
                  <Link to="/reset_password">
                    <ArrowLeftOutlined /> {'  '}Back
                  </Link>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default VerifyPasscode;

import { useMutation, useQuery } from 'react-query';

import bankAccountsApi from 'services/bankAccounts';

import { BLOCK_ACCOUNT_MUTATION, GET_ALL_BANK_ACCOUNTS_QY } from './keys';

export const useAllBankAccounts = (body) => {
  const data = useQuery([GET_ALL_BANK_ACCOUNTS_QY, body], () => bankAccountsApi.getAllBankAccounts(body), {
    select: ({ data }) => data,
  });

  return data;
};

export const useBlockAccountMutation = () => {
  const response = useMutation(bankAccountsApi.blockAccount, {
    mutationKey: BLOCK_ACCOUNT_MUTATION,
    onSuccess: (data) => {
      console.log('Bank Account Blocked Success', data);
    },
    onError: (error) => {
      console.log('Bank Account Blocked Error', error);
    },
  });

  return response;
};

export const useAccountLimitMutation = () => {
  const response = useMutation(bankAccountsApi.accountLimit, {
    mutationKey: 'AccountLimitMutation',
    onSuccess: (data) => {
      console.log('Account Limit Set Success', data);
    },
    onError: (error) => {
      console.log('Account Limit Set Error', error);
    },
  });

  return response;
};

import React, { useState } from 'react';
import { Row, Col } from 'antd';

import LayoutWarpper from 'components/Layout';
import StatisticsCard from 'components/StatisticsCard';
import UserTable from 'components/UserTable';
import FlowChart from './partials/FlowChart';

import { getAdmin, getBackoffice } from 'helpers/localStorage';
import { useGetDashboardStatistics } from 'rc-query/statisticsHooks';

const Dashboard = () => {
  const [filters, setFilters] = useState({});

  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const { isLoading, data } = useGetDashboardStatistics({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
    ...filters,
  });

  return (
    <LayoutWarpper>
      <h1 className="page-title">Dashboard</h1>
      <Row style={{ width: '100%' }}>
        <Col span={24}>
          <StatisticsCard isLoading={isLoading} data={data} />
        </Col>
      </Row>
      <Row style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Col span={24}>
          <FlowChart isLoading={isLoading} chartData={data?.data?.chart_data} setFilters={setFilters} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <h3>Recent Registered Entities</h3>
          <UserTable pageSize={7} hideTabs={true} hideActivityLogBtn={true} />
        </Col>
      </Row>
    </LayoutWarpper>
  );
};

export default Dashboard;

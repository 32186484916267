import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Row, Col, Alert, notification } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import OtpInput from 'react-otp-input';

import { useLoginMutation, useVerify2FAMutation } from 'rc-query/authHooks';
import { getAdmin, getBackoffice, setAdmin, setEmail, setVerifyToken, getFingerprint } from 'helpers/localStorage';

import './Login.less';
import { assignFingerprint } from 'helpers/fingerprintjs';

const Login = (props) => {
  const [showVerify2FA, setShowVerify2FA] = useState(false);
  const [showEmailVerify2FA, setShowEmailVerify2FA] = useState(false);
  const [showSmsVerify2FA, setShowSmsVerify2FA] = useState(false);
  const [OTPCode, setOTPCode] = useState('');
  const [loginFormValues, setLoginFormValues] = useState({ email: '', password: '' });

  const history = useHistory();
  const loginMutation = useLoginMutation(props.history);
  const verify2FAMutation = useVerify2FAMutation(props.history);
  // const emailVerify2FAMutation = useEmailVerify2FAMutation(props.history);

  const adminUser = getAdmin();
  const backOffice = getBackoffice();
  const boName = backOffice?.data?.name;
  const boLogo = backOffice?.data?.logo;
  document.title = boName + ' Backoffice';
  let fingerprint = getFingerprint()
  if(!fingerprint){
    assignFingerprint()
    fingerprint = getFingerprint()
  }
  console.log('fingerprint',fingerprint)

var link = document.querySelector("link[rel~='icon']");
if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.head.appendChild(link);
}
link.href = boLogo;

  if (adminUser?.status === 'active') {
    history.push('/dashboard');
  }

  const handleOTPChange = (otp) => {
    setOTPCode(otp);
  };

  const onFinish = async (values) => {
    loginMutation.mutateAsync({ ...values, api_key: backOffice?.data?.name, fingerprint }).then((response) => {
      setVerifyToken(response);
      if (response?.data?.data?.status === 'active') {
        notification.success({ message: 'Redirecting, please wait...' });
        setAdmin(response.data?.data);
        history.push('/dashboard');
      } else if (response?.message === 'Enter code from your 2FA App') {
        setLoginFormValues(values);
        setShowVerify2FA(true);
      } else if (response?.data?.['2fa'] === 'email') {
        setLoginFormValues(values);
        setShowEmailVerify2FA(true);
      } else if (response?.data?.['2fa'] === 'sms') {
        setLoginFormValues(values);
        setShowSmsVerify2FA(true);
      } else {
        notification.error({ message: response?.message });
      }
    });

    setEmail(values.email);
  };

  const handleVerify2FASubmit = async () => {
    verify2FAMutation
      .mutateAsync({ ...loginFormValues, code: OTPCode, api_key: backOffice?.data?.name, fingerprint })
      .then((response) => {
        setVerifyToken(response);
        if (response?.data?.data?.status === 'active') {
          notification.success({ message: 'Redirecting, please wait...' });
          setAdmin(response.data?.data);
          history.push('/dashboard');
        } else {
          notification.error({ message: response?.message });
        }
      });

    setEmail(loginFormValues.email);
  };

  // const handleEmailVerify2FASubmit = async () => {
  //   verify2FAMutation
  //     .mutateAsync({ ...loginFormValues, code: OTPCode, api_key: backOffice?.data?.name })
  //     .then((response) => {
  //       setVerifyToken(response);
  //       if (response?.data?.data?.status === 'active') {
  //         notification.success({ message: 'Redirecting, please wait...' });
  //         setAdmin(response.data?.data);
  //         history.push('/dashboard');
  //       } else {
  //         notification.error({ message: response?.message });
  //       }
  //     });

  //   setEmail(loginFormValues.email);
  // };

  const backToLogin = () => {
    setLoginFormValues({ email: '', password: '' });
    setEmail('');
    setShowVerify2FA(false);
    setShowEmailVerify2FA(false);
    setShowSmsVerify2FA(false);
  };

  return (
    <>
      <div className="main-container">
        <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
          <Col>
            <div className="loginContainer">
              <div style={{ marginBottom: '24px' }}>
                <Row>
                  <Col>
                    <img
                      className="gnp_logo"
                      src={backOffice?.data?.logo ? backOffice.data.logo : 'https://assets.deposits.inc/img/logo/deposits/png/logo_main.png'}
                      alt="Backoffice"
                    />
                  </Col>
                </Row>
              </div>
              <div className="boxContainer">
                {!showVerify2FA && !showEmailVerify2FA && !showSmsVerify2FA ? (
                  <>
                    <div className="mb-5 heading">
                      <h2 className="mb-0 mt-3">Login to Dashboard</h2>
                      <p className="text-muted description">
                        Welcome back, provide your login details below to access your dashboard.
                      </p>
                    </div>
                    {loginMutation?.data?.status === 'warning' ? (
                      <Alert type="error" message={loginMutation?.data?.message} showIcon closable />
                    ) : null}

                    <Form layout="vertical" onFinish={onFinish} size="large">
                      <Form.Item
                        label="Email Address"
                        name="email"
                        rules={[
                          {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                          },
                          {
                            required: true,
                            message: 'Please input your E-mail!',
                          },
                        ]}
                      >
                        <Input type="email" placeholder="Email" />
                      </Form.Item>

                      <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your Password!',
                          },
                        ]}
                      >
                        <Input.Password type="password" placeholder="Password" />
                      </Form.Item>

                      <Form.Item name="remember" valuePropName="checked" initialValue={true}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          block
                          size="large"
                          style={{ marginTop: '1rem', height: '50px' }}
                          loading={loginMutation.isLoading}
                        >
                          Login
                        </Button>
                      </Form.Item>
                    </Form>
                    <Link className="resetPassword" to="/reset_password">
                      Reset Password
                    </Link>
                  </>
                ) : null}

                {showVerify2FA && (
                  <>
                    <div className="mb-5">
                      <h2 className="mb-0 mt-3 securityTitle">Enter Security code</h2>
                      <p className="text-2fa">
                        2-FA is enabled. You’ll need to enter your security codes below to continue.
                      </p>
                    </div>
                    <div className="authTitle">Google Authenticator code</div>
                    <div className="otpCode">
                      <OtpInput
                        value={OTPCode}
                        onChange={handleOTPChange}
                        numInputs={6}
                        placeholder="------"
                        inputStyle="code-digit"
                        containerStyle="code-container"
                        isInputNum
                      />
                    </div>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      size="large"
                      style={{ marginTop: '1rem', height: '50px' }}
                      loading={verify2FAMutation.isLoading}
                      onClick={handleVerify2FASubmit}
                    >
                      Continue to dashboard
                    </Button>
                    <div className="mt-15">
                      <Button type="secondary" size="large" block onClick={backToLogin}>
                        <ArrowLeftOutlined /> {'  '}Back
                      </Button>
                    </div>
                  </>
                )}

                {showEmailVerify2FA && (
                  <div>
                    <div className="mb-5">
                      <h2 className="mb-0 mt-3 securityTitle">Enter Security code</h2>
                      <p className="text-2fa">
                        2-FA is enabled. You’ll need to enter your security codes below to continue.
                      </p>
                    </div>
                    <div className="authTitle" style={{ textAlign: 'center' }}>
                      Email Verification code
                    </div>
                    <div className="otpCode" style={{ width: '250px', marginLeft: 'auto', marginRight: 'auto' }}>
                      <OtpInput
                        value={OTPCode}
                        onChange={handleOTPChange}
                        numInputs={4}
                        placeholder="----"
                        inputStyle="code-digit"
                        containerStyle="code-container"
                        // isInputNum
                      />
                    </div>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      size="large"
                      style={{ marginTop: '1rem', height: '50px' }}
                      loading={verify2FAMutation.isLoading}
                      onClick={handleVerify2FASubmit}
                    >
                      Continue to dashboard
                    </Button>
                    <div className="mt-15">
                      <Button type="secondary" size="large" block onClick={backToLogin}>
                        <ArrowLeftOutlined /> {'  '}Back
                      </Button>
                    </div>
                  </div>
                )}

                {showSmsVerify2FA && (
                  <div>
                    <div className="mb-5">
                      <h2 className="mb-0 mt-3 securityTitle">Enter Security code</h2>
                      <p className="text-2fa">
                        2-FA is enabled. You’ll need to enter your security codes below to continue.
                      </p>
                    </div>
                    <div className="authTitle" style={{ textAlign: 'center' }}>
                      Sms Verification code
                    </div>
                    <div className="otpCode" style={{ width: '250px', marginLeft: 'auto', marginRight: 'auto' }}>
                      <OtpInput
                        value={OTPCode}
                        onChange={handleOTPChange}
                        numInputs={4}
                        placeholder="----"
                        inputStyle="code-digit"
                        containerStyle="code-container"
                        // isInputNum
                      />
                    </div>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      size="large"
                      style={{ marginTop: '1rem', height: '50px' }}
                      loading={verify2FAMutation.isLoading}
                      onClick={handleVerify2FASubmit}
                    >
                      Continue to dashboard
                    </Button>
                    <div className="mt-15">
                      <Button type="secondary" size="large" block onClick={backToLogin}>
                        <ArrowLeftOutlined /> {'  '}Back
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Login;

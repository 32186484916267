import React from 'react';
import { Row, Col } from 'antd';

import MessageTableNew from 'components/MessageTableNew';
import LayoutWarpper from 'components/Layout';

import './MessageList.less';

const MessageListNew = () => {
  return (
    <LayoutWarpper>
      <h1 className="page-title">All Messages</h1>
      <Row style={{ width: '100%' }} gutter={[16, 16]}>
        <Col span={24}>
          <MessageTableNew />
        </Col>
      </Row>
    </LayoutWarpper>
  );
};

export default MessageListNew;

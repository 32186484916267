import axios from 'axios';

import { API_BASE_URL } from '@Constants/config';
import { apiWrapper } from './interceptors';

const request = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30 * 1000,
});

const backOfficeApi = {
  verify: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/verify-backoffice', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },
};

export default apiWrapper(backOfficeApi, ['verify']);

import React from 'react';
import { Row, Col } from 'antd';
import { DatePicker, FormItem, Input, InputNumber } from 'formik-antd';

// import ExpiryDate from 'components/ExpiryDate';

import './LinkFunding.less';

const CardLinkFunding = () => {
  return (
    <>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <FormItem label="Card Number" name="card_number">
            <InputNumber name="card_number" placeholder="Card Number" controls={false} style={{ width: '100%' }} />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <FormItem label="Name on Card" name="card_name">
            <Input name="card_name" placeholder="Card holder name" />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={12}>
          <FormItem label="Card Expiry" name="card_expiry">
            <DatePicker
              name="card_expiry"
              placeholder="MM/YY"
              picker="month"
              format="MM/YY"
              style={{ width: '100%' }}
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label="CVV Code" name="cvv">
            <InputNumber name="cvv" placeholder="CVV" maxLength={3} controls={false} style={{ width: '100%' }} />
          </FormItem>
        </Col>
      </Row>
    </>
  );
};

export default CardLinkFunding;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Pagination, Spin, Tag, Button, Modal, Row, Col, notification } from 'antd';
import moment from 'moment';
import { Formik } from 'formik';
import { Form, FormItem, Input, Select, SubmitButton } from 'formik-antd';
import * as yup from 'yup';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import Search from 'antd/lib/input/Search';

import { GET_ALL_TEAM_MEMBERS_QUERY } from 'rc-query/keys';
import { queryClient } from 'rc-query';

import {
  useGetAllTeamMembers,
  useDeleteTeamMemberMutation,
  useCreateTeamMemberMutation,
  useUpdateTeamMemberMutation,
  useRoles,
} from 'rc-query/userHooks';

import { DEFAULT_PAGE_SIZE } from 'constants/config';
import ExportFile from 'components/ExportFile';
import { getAdmin, getBackoffice } from 'helpers/localStorage';

const Teams = () => {
  const [filters, setFilters] = useState({
    page: 1,
    per_page: DEFAULT_PAGE_SIZE,
  });

  const [showDeleteTeamMemberBox, setShowDeleteTeamMemberBox] = useState(false);
  const [showTeamMemberBox, setShowTeamMemberBox] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState({});

  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const deleteTeamMemberMutation = useDeleteTeamMemberMutation();
  const createTeamMemberMutation = useCreateTeamMemberMutation();
  const updateTeamMemberMutation = useUpdateTeamMemberMutation();

  const { isLoading, data } = useGetAllTeamMembers({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
    ...filters,
  });

  const userRoles = useRoles({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
  });
  const roles = userRoles?.data?.map((d) => ({ label: `${d.name}`, value: d.id }));

  const usersData = data?.data || [];
  const totalTeamMembers = data?.total || 0;
  const currentPage = data?.current_page || 0;

  // const renderTeamRole = (status) => {
  //   switch (status) {
  //     case 'owner':
  //       return <Tag color="gray">Owner</Tag>;
  //     case 'member':
  //       return <Tag color="gray">Member</Tag>;
  //     case 'auditor':
  //       return <Tag color="gray">Auditor</Tag>;
  //     default:
  //       return null;
  //   }
  // };

  // const renderEnvironment = (status) => {
  //   switch (status) {
  //     case 'owner':
  //       return <Tag color="gray">Owner</Tag>;
  //     case 'member':
  //       return <Tag color="gray">Member</Tag>;
  //     case 'auditor':
  //       return <Tag color="gray">Auditor</Tag>;
  //     default:
  //       return null;
  //   }
  // };

  const handleDeleteTeamMember = (teamMemberId) => {
    deleteTeamMemberMutation
      .mutateAsync({
        api_key: backOffice?.data?.name,
        token: adminUser?.token,
        team_member_id: teamMemberId,
      })
      .then(() => {
        queryClient.refetchQueries(['allTeamMembers']);
        notification.success({ message: 'Team member deleted successfully.' });
        setShowDeleteTeamMemberBox(false);
      });
  };

  const handleSaveTeamMember = (values, formikHelpers) => {
    formikHelpers.validateForm();

    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      ...values,
    };

    if (selectedTeamMember?.id) {
      body.firstName = undefined;
      body.lastName = undefined;
      body.email = undefined;
      body.id = selectedTeamMember?.id;

      updateTeamMemberMutation
        .mutateAsync(body)
        .then((data) => {
          if (data.status === 'success') {
            notification.success({
              message: data.message,
            });
            // reload listing
            queryClient.refetchQueries([GET_ALL_TEAM_MEMBERS_QUERY]);
            handleShowTeamMemberBox({ show: false });
          } else {
            notification.error({
              message: data.message,
            });
          }
        })
        .finally(() => {
          formikHelpers.setSubmitting(false);
        });
    } else {
      createTeamMemberMutation
        .mutateAsync(body)
        .then((data) => {
          if (data.status === 'success') {
            notification.success({
              message: data.message,
            });
            // reload listing
            queryClient.refetchQueries([GET_ALL_TEAM_MEMBERS_QUERY]);
            handleShowTeamMemberBox({ show: false });
          } else {
            notification.error({
              message: data.message,
            });
          }
        })
        .finally(() => {
          formikHelpers.setSubmitting(false);
        });
    }
  };

  const handleEditTeamMember = (teamMember) => {
    const fullName = teamMember?.name.split(' ');
    const firstName = fullName[0];
    const lastName = fullName[fullName.length - 1];
    teamMember.first_name = firstName;
    teamMember.last_name = lastName;
    setSelectedTeamMember(teamMember);
    handleShowTeamMemberBox({ show: true, createNew: false });
  };

  const handleShowDeleteBox = (teamMember) => {
    setSelectedTeamMember(teamMember);
    setShowDeleteTeamMemberBox(true);
  };

  const handleCancelBox = () => {
    setSelectedTeamMember({});
    setShowDeleteTeamMemberBox(false);
  };

  const handleShowTeamMemberBox = ({ show, createNew }) => {
    if (!show || !!createNew) {
      setSelectedTeamMember({});
    }
    setShowTeamMemberBox(show);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Environment',
      dataIndex: 'environment',
      key: 'environment',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (text, record) => <Tag color="gray">{record?.role || ''}</Tag>,
    },
    {
      title: 'Created Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text, record) => moment(record.created_at).format('MMM DD, YYYY'),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'team_id',
      render: (text, record) => (
        <>
          <EditOutlined onClick={() => handleEditTeamMember(record)} />
          <DeleteOutlined onClick={() => handleShowDeleteBox(record)} style={{ marginLeft: '16px' }} />
        </>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setFilters({ ...filters, page });
  };

  const handleTableChange = (pagination, tableFilters, sorter) => {
    const name = tableFilters?.name?.[0]?.toString() || undefined;
    const email = tableFilters?.email?.[0]?.toString() || undefined;
    const phone_number = tableFilters?.phone_number?.[0]?.toString() || undefined;
    const address = tableFilters?.address?.[0]?.toString() || undefined;

    setFilters({
      search: name,
      email,
      phone_number,
      address,
      page: 1,
      sort_by_column: sorter?.field,
      sort_by_type: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
    });
  };

  const onSearch = (value) => {
    const search = value?.toString() || undefined;

    setFilters({
      search: search,
      page: 1,
      per_page: DEFAULT_PAGE_SIZE,
    });
  };

  return (
    <>
      <Spin spinning={isLoading}>
        <div className="teamsPage">
          <div className="topButtons">
            <div>
              <Search placeholder="Search team members" onSearch={onSearch} allowClear style={{ width: 300 }} />
            </div>
            <div>
              <Button type="primary" size="large">
                <Link to="activity_log">Activity Log</Link>
              </Button>
              <Button
                type="primary"
                size="large"
                style={{ marginLeft: '20px', marginRight: '20px' }}
                onClick={() => handleShowTeamMemberBox({ show: true, createNew: true })}
              >
                <PlusOutlined /> Invite team member
              </Button>
              <ExportFile data_type="team_members" />
            </div>
          </div>
          <div className="teams">
            <Table
              rowKey={(record) => record.id}
              columns={columns}
              pagination={false}
              dataSource={usersData}
              onChange={handleTableChange}
            />
            {totalTeamMembers > 0 ? (
              <div className="pagination">
                <Pagination
                  showLessItems
                  defaultPageSize={DEFAULT_PAGE_SIZE}
                  total={totalTeamMembers}
                  showSizeChanger={false}
                  current={currentPage}
                  onChange={handlePageChange}
                />
              </div>
            ) : null}
          </div>
        </div>
      </Spin>

      <Modal
        width={450}
        title="Delete Team Member"
        visible={showDeleteTeamMemberBox}
        onCancel={handleCancelBox}
        footer={false}
        className="cardModal"
      >
        <Row style={{ marginTop: '20px' }}>
          <Col span={24}>
            <p className="descriptionText">
              You are about to delete [{selectedTeamMember?.name}]. Are you sure about this?
            </p>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
          <Col span={24}>
            <Button
              className="actionBtn"
              type="danger"
              size="large"
              style={{ width: '100%' }}
              onClick={() => handleDeleteTeamMember(selectedTeamMember?.id)}
            >
              Delete Team Member
            </Button>
          </Col>
          <Col span={24}>
            <Button
              className="actionBtn"
              type="default"
              size="large"
              style={{ width: '100%' }}
              onClick={handleCancelBox}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal>

      <Modal
        width={450}
        visible={showTeamMemberBox}
        title={selectedTeamMember?.id ? 'Edit team member' : 'Invite team member'}
        onCancel={() => handleShowTeamMemberBox({ show: false })}
        footer={false}
        className="cardModal"
        key={`modal-team-member-${selectedTeamMember?.id}`}
      >
        <Formik
          enableReinitialize
          initialValues={{
            first_name: selectedTeamMember?.first_name || null,
            last_name: selectedTeamMember?.last_name || null,
            email: selectedTeamMember?.email || null,
            role: selectedTeamMember?.role?.role_access?.id || 1,
          }}
          validationSchema={yup.object().shape({
            first_name: yup.string().nullable(true).required('First Name is required'),
            last_name: yup.string().nullable(true).required('Last Name is required'),
            email: yup.string().email().nullable(true).required('Email is required'),
          })}
          onSubmit={handleSaveTeamMember}
        >
          {(formProps) => {
            const { isSubmitting, handleSubmit, values } = formProps;
            return (
              <Spin spinning={isSubmitting}>
                <Form
                  layout="vertical"
                  initialValues={{
                    first_name: selectedTeamMember?.first_name || '',
                    last_name: selectedTeamMember?.last_name || '',
                    email: selectedTeamMember?.email || '',
                    role: selectedTeamMember?.role?.role_access?.id || 1,
                  }}
                >
                  <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
                    <Col span={24}>
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <FormItem name="first_name" label="First Name" required>
                            <Input name="first_name" placeholder="Blackmon" disabled={selectedTeamMember?.id} />
                          </FormItem>
                        </Col>
                        <Col span={12}>
                          <FormItem name="last_name" label="Last Name" required>
                            <Input name="last_name" placeholder="London" disabled={selectedTeamMember?.id} />
                          </FormItem>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <FormItem name="email" label="Email Address" required>
                        <Input name="email" disabled={selectedTeamMember?.id} />
                      </FormItem>
                    </Col>
                    <Col span={24}>
                      <FormItem name="role" label="Role" placeholder="Select Role" required>
                        <Select name="role" style={{ width: '100%' }} options={roles} selected={values.role} />
                      </FormItem>
                    </Col>
                    <Col span={24}>
                      <SubmitButton
                        loading={isSubmitting}
                        type="primary"
                        htmlType="submit"
                        onClick={handleSubmit}
                        size="large"
                      >
                        Save Changes
                      </SubmitButton>
                    </Col>
                  </Row>
                  {/* <pre
                    style={{
                      background: '#f6f8fa',
                      fontSize: '.65rem',
                      padding: '.5rem',
                    }}
                  >
                    <FormikDebug />
                  </pre> */}
                </Form>
              </Spin>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default Teams;

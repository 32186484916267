import React from 'react';
import { Card, Descriptions, notification } from 'antd';
import { Formik, Form } from 'formik';
import { FormItem, SubmitButton, Input } from 'formik-antd';
import { EyeTwoTone } from '@ant-design/icons';

import { getAdmin, getBackoffice } from 'helpers/localStorage';
import { useUpdatePasswordSettingMutation } from '../../rc-query/settingHooks';

import './Settings.less';

const UpdatePasswordSetting = () => {
  const adminUser = getAdmin();
  const backOffice = getBackoffice();
  const updatePasswordSettingMutation = useUpdatePasswordSettingMutation();

  const updatePasswordSetting = (values) => {
    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      ...values,
    };

    updatePasswordSettingMutation.mutateAsync(body).then((data) => {
      if (data.status === 'error' || data.status === 'warning') {
        notification.error({
          message: data.message,
        });
      } else if (data.status === 'success') {
        notification.success({
          message: data.message,
        });
      }
    });
  };

  return (
    <Formik
      onSubmit={updatePasswordSetting}
      layout="vertical"
      initialValues={{
        current_password: '',
        new_password: '',
        new_password_confirmation: '',
      }}
      key="updatePasswordForm"
      id="updatePasswordForm"
    >
      {() => (
        <Form>
          <Card title="Change Password">
            <h2 className="cardSubHeading">Set / choose a new password.</h2>
            <Descriptions layout="vertical" column={1}>
              <Descriptions.Item label="Current Password">
                <FormItem
                  name="current_password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input current Password!',
                    },
                  ]}
                >
                  <Input.Password
                    id="current_password"
                    name="current_password"
                    prefix={<EyeTwoTone style={{ fontSize: '16px' }} />}
                    type="password"
                    placeholder="Enter current password"
                    style={{ width: '175%' }}
                  />
                </FormItem>
              </Descriptions.Item>
              <Descriptions.Item label="New Password">
                <FormItem
                  name="new_password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input new Password!',
                    },
                  ]}
                >
                  <Input.Password
                    id="new_password"
                    name="new_password"
                    prefix={<EyeTwoTone style={{ fontSize: '16px' }} />}
                    type="password"
                    placeholder="Enter new password"
                    style={{ width: '175%' }}
                  />
                </FormItem>
              </Descriptions.Item>
              <Descriptions.Item label="Confirm New Password">
                <FormItem
                  name="new_password_confirmation"
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm new Password!',
                    },
                  ]}
                >
                  <Input.Password
                    id="new_password_confirmation"
                    name="new_password_confirmation"
                    prefix={<EyeTwoTone style={{ fontSize: '16px' }} />}
                    type="password"
                    placeholder="Confirm new password"
                    style={{ width: '175%' }}
                  />
                </FormItem>
              </Descriptions.Item>
              <Descriptions.Item>
                <SubmitButton type="primary" htmlType="submit" loading={false}>
                  Save
                </SubmitButton>
              </Descriptions.Item>
            </Descriptions>
          </Card>
          {/* <pre
            style={{
              background: '#f6f8fa',
              fontSize: '.65rem',
              padding: '.5rem',
            }}
          >
            <strong>props</strong> = {JSON.stringify(formProps, null, 2)}
          </pre> */}
        </Form>
      )}
    </Formik>
  );
};

export default UpdatePasswordSetting;

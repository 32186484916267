import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  // Pagination,
  Spin,
} from 'antd';
import { IoArrowBackCircle } from 'react-icons/io5';

import { DEFAULT_PAGE_SIZE } from 'constants/config';
import ExportFile from 'components/ExportFile';
// import { getAdmin, getBackoffice } from 'helpers/localStorage';
import { renderTags } from 'helpers/utils';

import './Audiences.less';

const AudiencesTable = () => {
  const [filters, setFilters] = useState({
    page: 1,
    per_page: DEFAULT_PAGE_SIZE,
  });

  // const adminUser = getAdmin();
  // const backOffice = getBackoffice();
  // const apiKey = backOffice?.data?.name;
  // const adminToken = adminUser?.token;

  // const payloadProps = {
  //   api_key: backOffice?.data?.name,
  //   token: adminUser?.token,
  //   ...filters,
  // };

  // if (audienceId) {
  //   payloadProps.user_id = audienceId;
  // }

  // const { isLoading, data } = useGetAllMessages(payloadProps);
  const data = {
    data: [
      {
        name: 'Ingrid Potteridge',
        tags: 'No car loan',
        audiences: 'Distressed driver',
      },
      {
        name: 'Leela Godridge',
        tags: 'No car loan',
        audiences: 'Distressed driver',
      },
      {
        name: 'Francklin Vannacci',
        tags: 'Multiple car repair',
        audiences: 'Distressed driver',
      },
      {
        name: 'Olivia Jarratt',
        tags: 'Eco Friendly',
        audiences: 'Distressed driver',
      },
      {
        name: 'Tamma Purser',
        tags: 'No car loan',
        audiences: 'Distressed driver',
      },
      {
        name: 'Caddric Maggiore',
        tags: 'Multiple car repair',
        audiences: 'Distressed driver',
      },
      {
        name: 'Peggie McIlwrath',
        tags: 'Robo investor',
        audiences: 'Distressed driver',
      },
      {
        name: 'Zacharia Bulgen',
        tags: 'Salary Increase',
        audiences: 'Distressed driver',
      },
      {
        name: 'Marga Gavriel',
        tags: 'Salary Increase',
        audiences: 'Distressed driver',
      },
      {
        name: 'Brannon Spurr',
        tags: 'Eco Friendly',
        audiences: 'Distressed driver',
      },
      {
        name: 'Leena Junkison',
        tags: 'Eco Friendly',
        audiences: 'Distressed driver',
      },
      {
        name: 'Vanda Marrington',
        tags: 'No car loan',
        audiences: 'Distressed driver',
      },
      {
        name: 'Lynelle Dmitrovic',
        tags: 'Robo investor',
        audiences: 'Distressed driver',
      },
      {
        name: 'Joann Stallybrass',
        tags: 'Robo investor',
        audiences: 'Distressed driver',
      },
      {
        name: 'Igor Dalston',
        tags: 'Eco Friendly',
        audiences: 'Distressed driver',
      },
    ],
    total: 20,
    current_page: 0,
  };

  const audiencesData = data?.data || [];
  // const totalRecords = data?.total || 0;
  // const currentPage = data?.current_page || 0;

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      // width: 150,
    },
    {
      title: 'Persona Tags',
      dataIndex: 'persona_tags',
      key: 'persona_tags',
      render: () => renderTags(['Eco Friendly', 'No car loan', 'Robo investor']),
    },
    {
      title: 'Audiences',
      dataIndex: 'audiences',
      key: 'audiences',
      render: () => <span style={{ color: '#0D7FE9' }}>Weekend warrior heloc</span>,
    },
  ];

  const handleTableChange = (pagination, tableFilters, sorter) => {
    const type = tableFilters?.type?.[0]?.toString() || undefined;
    const recipients = tableFilters?.recipients?.[0]?.toString() || undefined;
    const status = tableFilters?.status?.[0]?.toString() || undefined;

    setFilters({
      ...filters,
      type,
      recipients,
      status,
      page: 1,
      per_page: DEFAULT_PAGE_SIZE,
      sort_by_column: sorter.field,
      sort_by_type: sorter.order === 'ascend' ? 'ASC' : 'DESC',
    });
  };

  // const handlePageChange = (page) => {
  //   setFilters({ ...filters, page });
  // };

  return (
    <Spin spinning={false}>
      <div className="backBtn">
        <Link to="/message_list_new" style={{ display: 'flex', alignContent: 'center', marginBottom: '20px' }}>
          <IoArrowBackCircle size={22} style={{ marginRight: '13px' }} /> <span>Back</span>
        </Link>
      </div>
      <div>
        <div style={{ margin: '15px 0px', display: 'flex', justifyContent: 'flex-end' }}>
          <div>
            <ExportFile data_type="audiences" status="all" />
          </div>
        </div>
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          pagination={true}
          dataSource={audiencesData}
          onChange={handleTableChange}
        />
        {/* {totalRecords > 0 ? (
          <div className="pagination">
            <Pagination
              showLessItems
              defaultPageSize={DEFAULT_PAGE_SIZE}
              total={totalRecords}
              showSizeChanger={false}
              current={currentPage}
              onChange={handlePageChange}
            />
          </div>
        ) : null} */}
      </div>
      <div>
        <Link to="/audience_history">Audience version: March 9, 2023</Link>
      </div>
    </Spin>
  );
};

export default AudiencesTable;

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { capitalize } from 'lodash';

import { getAdmin, getBackoffice } from 'helpers/localStorage';

export default function PrivateRoute({ component: Component, ...rest }) {
  const backOffice = getBackoffice();
  document.title = `${capitalize(backOffice?.data?.name)} BackOffice`;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (getAdmin()) {
          return <Component {...props} />;
        }
        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
}

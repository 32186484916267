import React from 'react';
import { Row, Col, Card, Tag } from 'antd';
import moment from 'moment';
import { capitalize } from 'lodash';

import { ShowIfEmpty, renderUserStatus } from 'helpers/utils';

import './EntityDetail.less';

const UserGeneralInformation = ({ userInfo }) => {
  return (
    <Card className="general-box">
      <div className="box-header">
        <p className="header-text">GENERAL INFORMATION</p>
        {renderUserStatus(userInfo?.status, Tag)}
      </div>
      <Row>
        <Col span={8}>
          <div className="user-data">
            <span className="label">ID</span>
            <span className="value">{userInfo?.id}</span>
          </div>
        </Col>
        <Col span={8}>
          <div className="user-data">
            <span className="label">KYC Status</span>
            <span className="value">{capitalize(userInfo?.kyc_status)}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <div className="user-data">
            <span className="label">First Name</span>
            <span className="value">{ShowIfEmpty(userInfo?.first_name, '-')}</span>
          </div>
          <div className="user-data">
            <span className="label">Date Of Birth</span>
            <span className="value">
              {userInfo?.dob
                ? moment(userInfo?.dob, [
                    'DD-MM-YYYY',
                    'DD/MM/YYYY',
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'YYYY-MM-DD',
                    'YYYY/MM/DD',
                    'YYYY/DD/MM',
                    'YYYY-DD-MM',
                    'MMM DD, YYYY',
                  ]).format('MMM DD, YYYY')
                : '-'}
            </span>
          </div>
          <div className="user-data">
            <span className="label">Verification Date</span>
            <span className="value">
              {userInfo?.verification_date ? moment(userInfo?.verification_date, [
                    'DD-MM-YYYY',
                    'DD/MM/YYYY',
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'YYYY-MM-DD',
                    'YYYY/MM/DD',
                    'YYYY/DD/MM',
                    'YYYY-DD-MM',
                    'MMM DD, YYYY',
                  ]).format('MMM DD, YYYY') : '-'}
            </span>
          </div>
        </Col>
        <Col span={8}>
          <div className="user-data">
            <span className="label">Middle Name</span>
            <span className="value">{ShowIfEmpty(userInfo?.middle_name, '-')}</span>
          </div>
          <div className="user-data">
            <span className="label">Phone Number</span>
            <span className="value">{ShowIfEmpty(userInfo?.phone_number, '-')}</span>
          </div>
          <div className="user-data">
            <span className="label">Address</span>
            <span className="value">{ShowIfEmpty(userInfo?.address, '')}</span>
            <span className="value">
              {ShowIfEmpty(userInfo?.city, '')} {ShowIfEmpty(userInfo?.state, '')} {ShowIfEmpty(userInfo?.country, '')}
            </span>
          </div>
        </Col>
        <Col span={8}>
          <div className="user-data">
            <span className="label">Last Name</span>
            <span className="value">{ShowIfEmpty(userInfo?.last_name, '-')}</span>
          </div>
          <div className="user-data">
            <span className="label">Email</span>
            <span className="value ellipsis" title={ShowIfEmpty(userInfo?.email, '-')}>
              {ShowIfEmpty(userInfo?.email, '-')}
            </span>
          </div>
          <div className="user-data">
            <span className="label">IP</span>
            <span className="value">{ShowIfEmpty(userInfo?.ip?.replace('::ffff:', ''), '-')}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="user-data">
            <span className="label">KYC Result</span>
            <span className="value">{ShowIfEmpty(userInfo?.kyc_result, 'N/A')}</span>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default UserGeneralInformation;

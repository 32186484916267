import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  //  Font
} from '@react-pdf/renderer';

// // Register font
// Font.register({ family: 'Circular Std', src: source });

// Create styles
export const styles = StyleSheet.create({
  pageHeaderBlueLine: {
    width: 595,
    height: 2.32,
    left: 0,
    top: 0,
    backgroundColor: '#0CB9E9',
  },
  pageHeaderBar: {
    width: 595,
    height: 36,
    backgroundColor: '#F7FBFF',
  },
  logo: {
    width: 88,
    marginTop: 10,
    marginLeft: 40,
  },
  pageContent: {
    padding: 30,
  },
  pageTitle: {
    fontSize: 18,
    color: '#8895A7',
    marginBottom: 16,
  },
  card: {
    marginBottom: 15,
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',

    paddingTop: 4,
    paddingBottom: 4,
    paddingRight: 10,
    paddingLeft: 10,

    width: 515,
    height: 19,
    backgroundColor: '#F4F7F9',
  },
  tableHeaderText: {
    fontSize: 10,
    fontWeight: 500,
    color: '#4F627D',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  rowLabel: {
    paddingTop: 4,
    paddingRight: 0,
    paddingBottom: 4,
    paddingLeft: 10,

    width: 164,
    height: 22,

    borderWidth: 0.5,
    borderStyle: 'solid',
    borderColor: '#F1F5F9',

    fontSize: 10,
    fontWeight: 300,
    color: '#5F6B7A',
  },
  rowValue: {
    paddingTop: 4,
    paddingRight: 0,
    paddingBottom: 4,
    paddingLeft: 10,

    width: 351,
    height: 22,

    borderWidth: 0.5,
    borderStyle: 'solid',
    borderColor: '#F1F5F9',

    fontSize: 10,
    fontWeight: 300,
    color: '#212934',
  },
});

// Create Document Component
const WireTransferPDF = (data) => {
  return (
    <Document>
      <Page size="A4">
        <View>
          <View style={styles.pageHeaderBlueLine}></View>
          <View style={styles.pageHeaderBar}>
            <Image style={styles.logo} src="images/text-logo.svg" alt="Despsits" />
          </View>
          <View style={styles.pageContent}>
            <View>
              <Text style={styles.pageTitle}>Wire Transfer Detail Report</Text>
            </View>
            <View style={styles.card}>
              <View style={styles.tableHeader}>
                <Text style={styles.tableHeaderText}>BENEFICIARY DETAIL</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>Beneficiary Name</Text>
                <Text style={styles.rowValue}>{data?.data?.account_name}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>Account Number</Text>
                <Text style={styles.rowValue}>{data?.data?.account_number}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>Account Type</Text>
                <Text style={styles.rowValue}>{data?.data?.account_type}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>Beneficiary Address</Text>
                <Text style={styles.rowValue}>{data?.data?.beneficiary_branch}</Text>
              </View>
            </View>
            <View style={styles.card}>
              <View style={styles.tableHeader}>
                <Text style={styles.tableHeaderText}>RECEVING BANKING DETAILS</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>Routing Number</Text>
                <Text style={styles.rowValue}>{data?.data?.routing_number}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>Bank Name</Text>
                <Text style={styles.rowValue}>{data?.data?.bank_name}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>Bank Address</Text>
                <Text style={styles.rowValue}>{data?.data?.bank_branch}</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default WireTransferPDF;

import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, Button, notification } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { IoArrowBackCircle } from 'react-icons/io5';
import { Formik } from 'formik';
import { Form, FormItem, Radio, SubmitButton } from 'formik-antd';
import { format } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import { InfoCircleFilled } from '@ant-design/icons';
import { isEmpty } from 'lodash';

import { useSaveBankAccount, useSaveTokenizeCard } from 'rc-query/masterWalletHooks';
import { getAdmin, getBackoffice, host } from 'helpers/localStorage';
import localStorageService from 'helpers/localStorageService';
import { LinkMasterWalletValidation } from 'schemas/link_master_wallet_schema';

import { queryClient } from 'rc-query';
import {
  GET_ALL_WALLET_BANK_ACCOUNTS_QY,
  GET_ALL_WALLET_TOKENIZED_CARDS,
  GET_WALLET_TRANSACTIONS,
} from 'rc-query/keys';
import LayoutWarpper from 'components/Layout';
import LinkFundingSteps from './LinkFundingSteps';
import CardLinkFunding from './CardLinkFunding';
import BankLinkFunding from './BankLinkFunding';

import './LinkFunding.less';

const LinkFunding = () => {
  const history = useHistory();
  const fundAccountReferral = localStorageService.get(`${host}_fund_account_referral`);
  const {
    step,
    linkFundingType,
    values: fundAccountReferralValues,
  } = fundAccountReferral ?? { step: 0, linkFundingType: 'card_link_funding', values: {} };

  const initialStep = step ? step + 1 : 0;
  const [stepNumber, setStepNumber] = useState(step ?? 0);

  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const saveBankAccount = useSaveBankAccount();
  const saveTokenizeCard = useSaveTokenizeCard();

  const handleSubmit = (values, formikHelpers) => {
    if (values.linkFundingType === 'bank_link_funding') {
      handleSaveBankAccount(values, formikHelpers);
    } else if (values.linkFundingType === 'card_link_funding') {
      handleSaveTokenizeCard(values, formikHelpers);
    }
  };

  const handleSaveTokenizeCard = (values, formikHelpers) => {
    formikHelpers.validateForm();

    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      card_name: values.card_name,
      card_number: values.card_number,
      cvv: `${values.cvv}`,
      expiry_month: format(parseISO(values.card_expiry), 'MM'),
      expiry_year: format(parseISO(values.card_expiry), 'yy'),
    };

    saveTokenizeCard
      .mutateAsync(body)
      .then((data) => {
        if (data.status === 'success') {
          notification.success({
            message: data.message,
          });
          queryClient.refetchQueries([GET_WALLET_TRANSACTIONS]);
          history.push('/settings#master_wallet');
        } else {
          notification.error({
            message: data.message,
          });
        }
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  const handleSaveBankAccount = (values, formikHelpers) => {
    formikHelpers.validateForm();

    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      routing_number: values.routing_number,
      account_number: values.account_number,
      account_name: `${values.first_name} ${values.last_name}`,
      bank_name: values.bank_name,
      account_type: values.account_type,
    };

    saveBankAccount
      .mutateAsync(body)
      .then((data) => {
        if (data.status === 'success') {
          notification.success({
            message: data.message,
          });
          queryClient.refetchQueries([GET_WALLET_TRANSACTIONS]);
          queryClient.refetchQueries([GET_ALL_WALLET_BANK_ACCOUNTS_QY]);
          queryClient.refetchQueries([GET_ALL_WALLET_TOKENIZED_CARDS]);
          history.push('/linked_accounts');
        } else {
          notification.error({
            message: data.message,
          });
        }
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  // reset fund account referral on switching page
  useEffect(() => {
    return () => {
      if (!isEmpty(fundAccountReferralValues)) {
        localStorageService.set(`${host}_fund_account_referral`, {
          values: { ...fundAccountReferralValues },
        });
      } else {
        localStorageService.remove(`${host}_fund_account_referral`);
      }
    };
  }, []);

  return (
    <LayoutWarpper>
      <h1 className="page-title">Master Wallet</h1>
      <Row style={{ width: '100%' }} gutter={[16, 16]} className="linkFundingPage">
        <Col span={24}>
          <Formik
            initialValues={{
              linkFundingType: linkFundingType ?? 'card_link_funding',
              bankFundingType: 'bank_detail',
              account_type: 'checking',
            }}
            validationSchema={LinkMasterWalletValidation}
            onSubmit={handleSubmit}
          >
            {(formProps) => {
              const { isSubmitting, handleSubmit, values } = formProps;
              return (
                <Spin spinning={isSubmitting}>
                  <Form
                    size="large"
                    layout="vertical"
                    initialValues={{
                      linkFundingType: linkFundingType ?? 'card_link_funding',
                      bankFundingType: 'bank_detail',
                      account_type: 'checking',
                    }}
                  >
                    <div className="backBtn">
                      <Link to="/settings#master_wallet" style={{ display: 'flex', alignContent: 'center' }}>
                        <IoArrowBackCircle size={22} style={{ marginRight: '13px' }} /> <span>Back</span>
                      </Link>
                    </div>
                    <Row gutter={[16, 8]} style={{ marginTop: '40px' }}>
                      <Col span={6}>
                        <LinkFundingSteps stepNumber={step ? initialStep : stepNumber} />
                      </Col>
                      {stepNumber == 0 ? (
                        <Col span={10}>
                          <Row gutter={[16, 8]}>
                            <Col span={24}>
                              <p>Select a funding source for your wallet</p>
                              <FormItem name="linkFundingType" style={{ marginBottom: '0px' }}>
                                <Radio.Group name="linkFundingType">
                                  <label className="cursor-pointer">
                                    <div className="linkFundingType">
                                      <div className="icon">
                                        <img src="images/icons/card.svg" alt="Card" />
                                      </div>
                                      <div className="optionText">
                                        <p className="title">Card</p>
                                        <p className="desc">Add your debit / credit card information</p>
                                      </div>
                                      <div className="option">
                                        <Radio value="card_link_funding" name="linkFundingType" />
                                      </div>
                                    </div>
                                  </label>

                                  <label className="cursor-pointer">
                                    <div
                                      className={`linkFundingType ${
                                        values.linkFundingType === 'bank_link_funding' &&
                                        (values.bankFundingType === 'bank_login' ||
                                          values.bankFundingType === 'bank_detail') &&
                                        'bankLinkDetail'
                                      }`}
                                      style={{ marginBottom: '0px' }}
                                    >
                                      <div className="icon">
                                        <img src="images/icons/bank_transfer.svg" alt="Bank" />
                                      </div>
                                      <div className="optionText">
                                        <p className="title">Bank</p>
                                        <p className="desc">Login to your bank or enter bank details</p>
                                      </div>
                                      <div className="option">
                                        <Radio value="bank_link_funding" name="linkFundingType" />
                                      </div>
                                    </div>
                                  </label>
                                </Radio.Group>
                              </FormItem>
                              <FormItem name="bankFundingType">
                                <Radio.Group name="bankFundingType">
                                  {values.linkFundingType === 'bank_link_funding' ? (
                                    <label className="cursor-pointer">
                                      <div className="optionDetail">
                                        {/* <div className="option">
                                        <div className="radio">
                                          <Radio value="bank_login" name="bankFundingType" />
                                        </div>
                                        <div className="optionText">
                                          <p className="title">Use bank login</p>
                                          <p className="desc">Automatically connect with your bank to authorize</p>
                                        </div>
                                      </div> */}

                                        <div className="option">
                                          <div className="radio">
                                            <Radio value="bank_detail" name="bankFundingType" />
                                          </div>
                                          <div className="optionText">
                                            <p className="title">Enter bank details</p>
                                            <p className="desc">Manually enter your bank details and verify</p>
                                          </div>
                                        </div>
                                      </div>
                                    </label>
                                  ) : null}
                                </Radio.Group>
                              </FormItem>
                              <div style={{ marginTop: '16px' }}>
                                <Button type="link">
                                  <Link to="/linked_accounts">Manage linked accounts</Link>
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          <Row gutter={[16, 8]} style={{ marginTop: '20px' }}>
                            <Col span={24}>
                              <Button type="secondary" size="large">
                                <Link to="/settings#master_wallet">Cancel</Link>
                              </Button>
                              <Button
                                type="primary"
                                size="large"
                                style={{ marginLeft: '16px' }}
                                onClick={() => setStepNumber(1)}
                              >
                                Continue
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      ) : stepNumber == 1 && values.linkFundingType === 'card_link_funding' ? (
                        <Col span={10}>
                          <Row gutter={[16, 8]}>
                            <Col span={24}>
                              <p>Enter card details and save</p>
                              <CardLinkFunding stepNumber={stepNumber} setStepNumber={setStepNumber} />
                            </Col>
                          </Row>
                          <Row gutter={[16, 8]} style={{ marginTop: '16px' }}>
                            <Col span={24}>
                              <Button type="secondary" size="large" onClick={() => setStepNumber(0)}>
                                Go Back
                              </Button>
                              <SubmitButton
                                loading={isSubmitting}
                                type="primary"
                                htmlType="submit"
                                onClick={handleSubmit}
                                size="large"
                                style={{ marginLeft: '16px' }}
                              >
                                Save card
                              </SubmitButton>
                            </Col>
                          </Row>
                        </Col>
                      ) : stepNumber == 1 && values.linkFundingType === 'bank_link_funding' ? (
                        <Col span={10}>
                          <Row gutter={[16, 8]}>
                            <Col span={24}>
                              <p>Enter bank details and save</p>
                              <div className="bank_link_funding_info">
                                <div className="icon">
                                  <InfoCircleFilled
                                    style={{ fontSize: '24px', color: '#3895D3', marginRight: '16px' }}
                                  />
                                </div>
                                <div className="text">
                                  <p>
                                    To verify your bank account, you’ll see two deposits and two withdrawals on your
                                    bank statement over the next three days, each $1 or less.
                                  </p>
                                  <p>
                                    To avoid any overdraft or other bank fees, make sure you have at least $2 in your
                                    bank account.
                                  </p>
                                </div>
                              </div>
                              <BankLinkFunding stepNumber={stepNumber} setStepNumber={setStepNumber} />
                            </Col>
                          </Row>
                          <Row gutter={[16, 8]} style={{ marginTop: '16px' }}>
                            <Col span={24}>
                              <Button type="secondary" size="large" onClick={() => setStepNumber(0)}>
                                Go Back
                              </Button>
                              <SubmitButton
                                loading={isSubmitting}
                                type="primary"
                                htmlType="submit"
                                onClick={handleSubmit}
                                size="large"
                                style={{ marginLeft: '16px' }}
                              >
                                Save bank
                              </SubmitButton>
                            </Col>
                          </Row>
                        </Col>
                      ) : null}
                    </Row>
                    {/* <pre
                      style={{
                        background: '#f6f8fa',
                        fontSize: '.65rem',
                        padding: '.5rem',
                      }}
                    >
                      <FormikDebug />
                    </pre> */}
                  </Form>
                </Spin>
              );
            }}
          </Formik>
        </Col>
      </Row>
    </LayoutWarpper>
  );
};

export default LinkFunding;

import React from 'react';
import { Steps } from 'antd';

import './WithdrawFunds.less';

const { Step } = Steps;

const WithdrawFundsSteps = ({ stepNumber }) => {
  return (
    <Steps progressDot current={stepNumber} direction="vertical">
      <Step title="Amount & Source" />
      <Step title="Preview and confirm" />
    </Steps>
  );
};

export default WithdrawFundsSteps;

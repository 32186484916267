import React, {
  // useEffect,
  useState,
} from 'react';
import { Row, Col, notification, Spin, Button, Alert, Select } from 'antd';
import { Formik } from 'formik';
import {
  Form,
  Input,
  FormItem,
  AutoComplete,
  SubmitButton,
  // FormikDebug
} from 'formik-antd';
import * as yup from 'yup';
import { find, isEmpty } from 'lodash';
import { EditOutlined } from '@ant-design/icons';

import { getAdmin, getBackoffice } from 'helpers/localStorage';
import { queryClient } from 'rc-query';
import { useGetUserNameEmail } from 'rc-query/userHooks';
import { GET_ALL_MESSAGES_QUERY } from 'rc-query/keys';
import { useSendMessageMutation, useSaveMessageMutation, useUpdateMessageMutation } from 'rc-query/messageHooks';

import Draft from './Editor';

const { Option } = Select;

import './MessagingForm.less';

const MessagingForm = ({ message, setViewModal, parentFormKey }) => {
  const [composeMessage, setComposeMessage] = useState(false);
  const [enableModifyAudience, setEnableModifyAudience] = useState(false);
  const [selectedPersonas, setSelectedPersonas] = useState([]);

  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const sendMessageMutation = useSendMessageMutation();
  const saveMessageMutation = useSaveMessageMutation();
  const updateMessageMutation = useUpdateMessageMutation();

  const { data } = useGetUserNameEmail({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
  });
  const allUserNameEmail = data || [0];

  const audiences = [
    { value: '', label: '+ Create "New audience group name typed into the search input goes here"' },
    { value: 'Distressed driver car loan offer', label: 'Distressed driver car loan offer' },
    { value: 'Downing Street', label: 'Downing Street' },
    { value: 'Weekend warrior heloc', label: 'Weekend warrior heloc' },
    { value: 'Eco friendly driver auto loan', label: 'Eco friendly driver auto loan' },
  ];

  const personas = [
    { label: 'Salary increase', value: 'salary_increase' },
    { label: 'Eco friendly', value: 'eco_friendly' },
    { label: 'High income earner', value: 'high_income_earner' },
    { label: 'Has mortgaged loan', value: 'has_mortgaged_loan' },
    { label: 'Robo investor', value: 'robo_investor' },
  ];

  const handleSaveEmail = (values) => {
    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      ...values,
      selected_recipients: values.recipients === 'selected' ? values.selected_recipients : undefined,
    };

    if (message?.id) {
      body.id = `${message.id}`;
      updateMessageMutation.mutateAsync(body).then((data) => {
        if (data?.status === 'error') {
          notification.error({
            message: data.message,
          });
        } else if (data?.status === 'success') {
          notification.success({
            message: data.message,
          });
        }
        queryClient.refetchQueries([GET_ALL_MESSAGES_QUERY]);
        setViewModal(false);
      });
    } else {
      saveMessageMutation
        .mutateAsync(body)
        .then((data) => {
          if (data?.status === 'error') {
            notification.error({
              message: data.message,
            });
          } else if (data?.status === 'success') {
            notification.success({
              message: data.message,
            });
          }
        })
        .finally(() => {
          queryClient.refetchQueries([GET_ALL_MESSAGES_QUERY]);
          saveMessageMutation.isLoading = false;
          setViewModal(false);
        });
    }
  };

  const handleSaveSendEmail = (values) => {
    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      ...values,
      selected_recipients: values.recipients === 'selected' ? values.selected_recipients : undefined,
    };

    if (message?.id) {
      body.id = message.id;
    }

    saveMessageMutation.mutateAsync(body).then((data) => {
      if (data?.status === 'error') {
        notification.error({
          message: data.message,
        });
      } else if (data?.status === 'success') {
        notification.success({
          message: data.message,
        });
        sendMessageMutation
          .mutateAsync({
            api_key: backOffice?.data?.name,
            token: adminUser?.token,
            id: data?.data?.id?.toString(),
          })
          .then((data) => {
            if (data?.status === 'error') {
              notification.error({
                message: data.message,
              });
            } else if (data?.status === 'success') {
              notification.success({
                message: data.message,
              });
            }
            queryClient.refetchQueries([GET_ALL_MESSAGES_QUERY]);
          })
          .finally(() => {
            sendMessageMutation.isLoading = false;
            setViewModal(false);
          });
      }
      // saveMessageMutation.isLoading = false;
    });
  };

  // const defaultValue = (options, value) => {
  //   console.log('value here', value);
  //   return options ? options.find((option) => option.value === value) : '';
  // };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setSelectedPersonas([{ label: 'Salary increase', value: 'salary_increase', key: 'salary_increase' }]);
  //   }, 5000);
  // }, []);

  return (
    <Spin spinning={saveMessageMutation.isLoading || sendMessageMutation.isLoading}>
      <Formik
        key={`innter-form-${parentFormKey}`}
        initialValues={{
          type: message?.type || 'email',
          recipients: message?.recipients || 'all',
          subject: message?.subject || '',
          message: message?.message || '',
          audience: '',
          personas: [],
          // personas: selectedPersonas,
          // [
          //   // { label: 'Salary increase', value: 'salary_increase', key: 'salary_increase' },
          //   // { label: 'Eco friendly', value: 'eco_friendly', key: 'eco_friendly' },
          // ],
        }}
        validationSchema={yup.object().shape({
          type: yup.string().nullable(true).required('Message Type is required'),
          recipients: yup.string().nullable(true).required('Recipient is required'),
          selected_recipients: yup.array().when('recipients', {
            is: (recipients) => recipients === 'selected',
            then: yup.array().min(1, 'Select at least one recipient').required('Select at least one recipient'),
          }),
          subject: yup.string().nullable(true).required('Subject is required'),
          message: yup.string().nullable(true).required('Message is required'),
        })}
        onSubmit={handleSaveEmail}
      >
        {(formProps) => {
          const { isSubmitting, values, validateForm, setFieldValue } = formProps;
          const existingAudience = find(audiences, function (a) {
            return a.value === values.audience;
          });

          return (
            <Spin spinning={isSubmitting}>
              <Form
                layout="vertical"
                initialValues={{
                  type: message?.type || 'email',
                  recipients: message?.recipients || 'all',
                  subject: message?.subject,
                  message: message?.message,
                  audience: '',
                  personas: [],
                  // personas: selectedPersonas,
                  // [
                  //   // { label: 'Salary increase', value: 'salary_increase', key: 'salary_increase' },
                  //   // { label: 'Eco friendly', value: 'eco_friendly', key: 'eco_friendly' },
                  // ],
                }}
              >
                {!composeMessage ? (
                  <Row gutter={[20, 16]}>
                    <Col span={24}>
                      <FormItem label="Audience" name="audience">
                        <AutoComplete
                          name="audience"
                          size="large"
                          options={audiences}
                          placeholder="Start typing to create new audience or select..."
                          filterOption={(inputValue, option) =>
                            option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                          }
                          allowClear
                          onChange={(option) => {
                            setEnableModifyAudience(false);

                            let isExistingAudience = find(audiences, function (a) {
                              return a.value === option;
                            });

                            if (option === '' || !isExistingAudience) {
                              setSelectedPersonas([]);
                            } else {
                              setSelectedPersonas(
                                option
                                  ? [
                                      { label: 'Salary increase', value: 'salary_increase', key: 'salary_increase' },
                                      { label: 'Eco friendly', value: 'eco_friendly', key: 'eco_friendly' },
                                    ]
                                  : []
                              );
                              setFieldValue(
                                'personas',
                                option
                                  ? [
                                      { label: 'Salary increase', value: 'salary_increase', key: 'salary_increase' },
                                      { label: 'Eco friendly', value: 'eco_friendly', key: 'eco_friendly' },
                                    ]
                                  : []
                              );
                            }
                          }}
                        />
                      </FormItem>
                      {values.audience ? (
                        <Alert showIcon message="232 total accounts" type="info" className="mt-15" />
                      ) : null}
                    </Col>
                    <Col span={24}>
                      <label style={{ marginBottom: '10px' }}>Personas</label>
                      <Select
                        name="personas"
                        mode="multiple"
                        size="large"
                        label="Personas"
                        placeholder="- select -"
                        style={{ width: '100%', marginTop: '10px' }}
                        options={personas}
                        disabled={values.audience !== '' && existingAudience && !enableModifyAudience}
                        labelInValue
                        value={selectedPersonas}
                        // defaultValue={[
                        //   { label: 'Salary increase', value: 'salary_increase' },
                        //   { label: 'Eco friendly', value: 'eco_friendly' },
                        // ]}
                        // value={values.personas}
                        // value={defaultValue(personas, values.personas)}
                        // value={['salary_increase', 'eco_friendly']}
                        // value={[
                        //   { label: 'Salary increase', value: 'salary_increase', key: 'salary_increase' },
                        //   { label: 'Eco friendly', value: 'eco_friendly', key: 'eco_friendly' },
                        // ]}
                        // defaultValue={defaultValue(personas, values.personas)}
                        // defaultValue={selectedPersonas}
                        onChange={(option) => {
                          setSelectedPersonas([...option]);
                        }}
                      />
                      {/* <FormItem label="Personas" name="personas"> */}
                      {/* <Select
                          mode="multiple"
                          name="personas"
                          placeholder="- select -"
                          size="large"
                          disabled={existingAudience && !enableModifyAudience}
                          value={['salary_increase', 'eco_friendly', 'robo_investor']}
                        >
                          {personas?.map((persona) => (
                            <Option key={persona.value} value={persona.value} label={persona.label}>
                              {persona.label}
                            </Option>
                          ))}
                        </Select> */}
                      {/* </FormItem> */}
                    </Col>

                    <Col className="w-full" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      {!enableModifyAudience ? (
                        <>
                          {values.audience !== '' ? (
                            <Button
                              type="default"
                              htmlType="submit"
                              size="large"
                              style={{ marginRight: '16px' }}
                              onClick={() => setEnableModifyAudience(true)}
                            >
                              Modify audience
                            </Button>
                          ) : null}
                          <Button type="primary" htmlType="submit" size="large" onClick={() => setComposeMessage(true)}>
                            Compose message
                          </Button>
                        </>
                      ) : (
                        <Button
                          type="primary"
                          htmlType="submit"
                          size="large"
                          style={{ marginRight: '16px' }}
                          onClick={() => setEnableModifyAudience(false)}
                        >
                          Save changes
                        </Button>
                      )}
                    </Col>
                  </Row>
                ) : (
                  <Row gutter={[20, 16]}>
                    <Col span={24}>
                      <div style={{ marginBottom: '10px' }}>
                        <span>Audience</span>
                      </div>
                      <div className="selected-audience-box">
                        <span className="audience-text">New audience group 2</span>
                        <div onClick={() => setComposeMessage(false)} className="edit-audience-link cursor-pointer">
                          <EditOutlined style={{ fontSize: '16px', marginRight: '10px' }} /> <span>Edit / Change</span>
                        </div>
                      </div>
                    </Col>
                    <Col span={24} md={12}>
                      <FormItem label="Message Type" name="type" required>
                        <Select name="type" placeholder="Email">
                          <Option value="email">Email</Option>
                          <Option value="phone" disabled>
                            SMS
                          </Option>
                          <Option value="push" disabled>
                            Push Notification
                          </Option>
                        </Select>
                      </FormItem>
                    </Col>
                    <Col span={24} md={12}>
                      <FormItem label="Select Recipients" name="recipients" required>
                        <Select name="recipients" placeholder="Select Recipients">
                          <Option key="all" value="all">
                            All
                          </Option>
                          <Option key="active" value="active">
                            Active
                          </Option>
                          <Option key="Inactive" value="inactive">
                            Inactive
                          </Option>
                          <Option key="selected" value="selected">
                            Select Recipients
                          </Option>
                        </Select>
                      </FormItem>
                    </Col>
                    {values.recipients === 'selected' ? (
                      <Col span={24}>
                        <FormItem label="Add / Select Recipients" name="selected_recipients" required>
                          <Select name="selected_recipients" placeholder="Add / Select Recipients" mode="multiple">
                            {allUserNameEmail?.map((user) => (
                              <Option key={user.email} value={user.email} label={user.name}>
                                <div className="select-option-label-item">
                                  <span role="img" aria-label={user.name} style={{ marginRight: '10px' }}>
                                    {user.name}
                                  </span>
                                  ({user.email})
                                </div>
                              </Option>
                            ))}
                          </Select>
                        </FormItem>
                      </Col>
                    ) : null}
                    <Col span={24}>
                      <FormItem label="Subject" name="subject" required>
                        <Input name="subject" placeholder="Subject" />
                      </FormItem>
                    </Col>
                    <Col span={24}>
                      <FormItem label="Message" name="message">
                        <Draft alreadySaveTemplate={message ? message.message : ''} />
                      </FormItem>
                    </Col>
                    {saveMessageMutation.isLoading || sendMessageMutation.isLoading ? (
                      ''
                    ) : (
                      <>
                        <Col>
                          <SubmitButton type="primary" htmlType="submit" loading={saveMessageMutation.isLoading}>
                            Save Email
                          </SubmitButton>
                        </Col>
                        <Col>
                          <SubmitButton
                            type="primary"
                            htmlType="submit"
                            onClick={() =>
                              validateForm().then((values) => {
                                if (isEmpty(values)) {
                                  handleSaveSendEmail(formProps.values);
                                }
                              })
                            }
                            loading={saveMessageMutation.isLoading}
                          >
                            Save and Send Email
                          </SubmitButton>
                        </Col>
                      </>
                    )}
                  </Row>
                )}

                {/* <pre
                  style={{
                    background: '#f6f8fa',
                    fontSize: '.65rem',
                    padding: '.5rem',
                  }}
                >
                  <FormikDebug />
                </pre> */}
              </Form>
            </Spin>
          );
        }}
      </Formik>
    </Spin>
  );
};
export default MessagingForm;

import React from 'react';
import { Steps } from 'antd';

import './FundAccounts.less';

const { Step } = Steps;

const BankTransferSteps = ({ stepNumber }) => {
  return (
    <Steps progressDot current={stepNumber} direction="vertical">
      <Step title="Funding method" />
      <Step title="Account to fund" />
      <Step title="Amount & Source" />
      <Step title="Preview and confirm" />
    </Steps>
  );
};

export default BankTransferSteps;

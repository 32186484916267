import axios from 'axios';

import { API_BASE_URL } from '@Constants/config';
import { apiWrapper } from './interceptors';
// const host = window.location.origin;

const request = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30 * 1000,
});

const transactionsApi = {
  getAllTransactions: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('admin/transactions', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  reverseTransaction: (body) => {
    const { id } = body;
    body.id = undefined;
    return new Promise((resolve, reject) => {
      request
        .post(`/admin/transactions/reverse-transaction/${id}`, { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  exportFile: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/file-export', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },
};

export default apiWrapper(transactionsApi, ['transactionsApi']);

import { useMutation, useQuery } from 'react-query';

import kysyncApi from 'services/kysync';

import {
  GET_ALL_KYSYNC_BUSINESSES_KYB_QUERY,
  GET_ALL_KYSYNC_PLANS_QUERY,
  GET_ALL_KYSYNC_USERS_KYC_QUERY,
  GET_KYSYNC_CUSTOM_SETTINGS_QUERY,
} from './keys';

export const useKySyncUsersKyc = (body) => {
  const users = useQuery([GET_ALL_KYSYNC_USERS_KYC_QUERY, body], () => kysyncApi.getUsersKYC(body), {
    select: ({ data }) => data,
  });

  return users;
};

export const useKySyncBusinessesKyb = (body) => {
  const users = useQuery([GET_ALL_KYSYNC_BUSINESSES_KYB_QUERY, body], () => kysyncApi.getBusinessesKYB(body), {
    select: ({ data }) => data,
  });

  return users;
};

export const useAddKYCContact = () => {
  const response = useMutation(kysyncApi.addKYCContact, {
    mutationKey: 'addKYCContact',
    onSuccess: (data) => {
      console.log('KYC Contact Save Success', data);
    },
    onError: (error) => {
      console.log('KYC Contact Save Error', error);
    },
  });

  return response;
};

export const useAddKYBContact = () => {
  const response = useMutation(kysyncApi.addKYBContact, {
    mutationKey: 'addKYBContact',
    onSuccess: (data) => {
      console.log('KYB Contact Save Success', data);
    },
    onError: (error) => {
      console.log('KYB Contact Save Error', error);
    },
  });

  return response;
};

export const useKysyncPlans = (body) => {
  const users = useQuery([GET_ALL_KYSYNC_PLANS_QUERY, body], () => kysyncApi.getKysyncPlans(body), {
    select: ({ data }) => data,
  });

  return users;
};

export const useKySyncFundingSources = (body) => {
  const users = useQuery(['kysyncFundingSources', body], () => kysyncApi.getKySyncFundingSources(body), {
    select: ({ data }) => data,
  });

  return users;
};

export const usePurchaseKySyncPlan = () => {
  const response = useMutation(kysyncApi.purchaseKySyncPlan, {
    mutationKey: 'purhcaseKySyncPlan',
    onSuccess: (data) => {
      console.log('Purchase KySync Plan Success', data);
    },
    onError: (error) => {
      console.log('Purchase KySync Plan Error', error);
    },
  });

  return response;
};

export const useSendReminderKYC = () => {
  const response = useMutation(kysyncApi.sendReminderKYC, {
    mutationKey: 'sendReminderKYC',
    onSuccess: (data) => {
      console.log('Send reminder KYC Success', data);
    },
    onError: (error) => {
      console.log('Send reminder KYC Error', error);
    },
  });

  return response;
};

export const useSendReminderKYB = () => {
  const response = useMutation(kysyncApi.sendReminderKYB, {
    mutationKey: 'sendReminderKYB',
    onSuccess: (data) => {
      console.log('Send reminder KYB Success', data);
    },
    onError: (error) => {
      console.log('Send reminder KYB Error', error);
    },
  });

  return response;
};

export const useUpdateKYCEntity = () => useMutation(kysyncApi.updateKYCEntity, { mutationKey: 'updateKYCEntity' });

export const useUpdateKYBEntity = () => useMutation(kysyncApi.updateKYBEntity, { mutationKey: 'updateKYBEntity' });

export const useDeleteKYCEntity = () => {
  const response = useMutation(kysyncApi.deleteKYCEntity, {
    mutationKey: 'deleteKYCEntity',
    onSuccess: (data) => {
      console.log('Delete KYC Entity Success', data);
    },
    onError: (error) => {
      console.log('Delete KYC Entity Error', error);
    },
  });

  return response;
};

export const useDeleteKYBEntity = () => {
  const response = useMutation(kysyncApi.deleteKYBEntity, {
    mutationKey: 'deleteKYBEntity',
    onSuccess: (data) => {
      console.log('Delete KYB Entity Success', data);
    },
    onError: (error) => {
      console.log('Delete KYB Entity Error', error);
    },
  });

  return response;
};

export const useCustomSettings = (body) => {
  const response = useQuery([GET_KYSYNC_CUSTOM_SETTINGS_QUERY, body], () => kysyncApi.getCustomSettings(body), {
    select: ({ data }) => data,
  });

  return response;
};

export const useSaveCustomSettings = () => {
  const response = useMutation(kysyncApi.saveCustomSettings, {
    mutationKey: 'saveCustomSettings',
    onSuccess: (data) => {
      console.log('Save Custom Settings Success', data);
    },
    onError: (error) => {
      console.log('Save Custom Settings Error', error);
    },
  });

  return response;
};

export const useGetKySyncHits = (body) => {
  const response = useQuery(['getKySyncHits', body], () => kysyncApi.getKySyncHits(body), {
    select: ({ data }) => data,
  });

  return response;
};

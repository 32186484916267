import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Button } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import { queryClient } from 'rc-query';
import { useBlockUserMutation } from 'rc-query/userHooks';
import { getAdmin, getBackoffice } from 'helpers/localStorage';
import { GET_ALL_USERS_QUERY, GET_USER } from 'rc-query/keys';

import './EntityDetail.less';

const AccountSummary = ({ entityId, entityType, summary, entityStatus }) => {
  const adminUser = getAdmin();
  const backOffice = getBackoffice();
  const apiKey = backOffice?.data?.name;
  const adminToken = adminUser?.token;

  const blockUserMutation = useBlockUserMutation();

  const { transactions, messages, issued_accounts, issued_cards } = summary;

  const setEntityStatus = (entityId, status) => {
    const body = {
      api_key: apiKey,
      token: adminToken,
      entityType,
    };

    if (entityType === 'business') {
      body.business_id = entityId;
    } else {
      body.user_id = entityId;
      body.status = status;
    }

    blockUserMutation
      .mutateAsync({
        ...body,
      })
      .then(() => {
        queryClient.refetchQueries([GET_USER]);
        queryClient.refetchQueries([GET_ALL_USERS_QUERY]);
      });
  };

  return (
    <Card>
      <div className="box-header">
        <p className="header-text">ACCOUNT SUMMARY</p>
      </div>
      <div className="user-account-item">
        <div>
          <p className="label">Transactions</p>
          <p className="value">{transactions || 0}</p>
        </div>
        <div className="viewEye">
          <Link to={`/user_transactions/${entityType}/${entityId}`}>
            <EyeOutlined /> View
          </Link>
        </div>
      </div>
      {entityType !== 'individual' ? (
        <div className="user-account-item">
          <div>
            <p className="label">Messages</p>
            <p className="value">{messages || 0}</p>
          </div>
          <div className="viewEye">
            <Link to={`/user_messages/${entityType}/${entityId}`}>
              <EyeOutlined /> View
            </Link>
          </div>
        </div>
      ) : null}
      <div className="user-account-item">
        <div>
          <p className="label">Bank Accounts</p>
          <p className="value">{issued_accounts || 0}</p>
        </div>
        <div className="viewEye">
          <Link to={`/user_bank_accounts/${entityType}/${entityId}`}>
            <EyeOutlined /> View
          </Link>
        </div>
      </div>
      <div className="user-account-item">
        <div>
          <p className="label">Cards</p>
          <p className="value">{issued_cards || 0}</p>
        </div>
        <div className="viewEye">
          <Link to={`/user_cards/${entityType}/${entityId}`}>
            <EyeOutlined /> View
          </Link>
        </div>
      </div>
      {entityStatus === 'blocked' ? (
        <Button className="button-success" onClick={() => setEntityStatus(entityId, 'active')}>
          Activate {entityType === 'business' ? 'Business' : 'User'}
        </Button>
      ) : (
        <Button className="button-danger" onClick={() => setEntityStatus(entityId, 'blocked')}>
          Block {entityType === 'business' ? 'Business' : 'User'}
        </Button>
      )}
    </Card>
  );
};

export default AccountSummary;

import axios from 'axios';

import { API_BASE_URL } from 'constants/config';
import { apiWrapper } from './interceptors';
// const host = window.location.origin;

const request = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30 * 1000,
});

const kysyncApi = {
  getUsersKYC: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/kysync/get-users-kyc', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getBusinessesKYB: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/kysync/get-businesses-kyb', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  addKYCContact: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/kysync/add-user-kyc', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  addKYBContact: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/kysync/add-business-kyb', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getKysyncPlans: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/kysync/get-kysync-plans', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getKySyncFundingSources: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/payouts/get-funding-sources', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  sendReminderKYC: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/kysync/send-kyc-verification-link', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  sendReminderKYB: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/kysync/send-kyb-verification-link', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  updateKYCEntity: (body) => {
    const { id } = body;
    body.id = undefined;
    // body.first_name = undefined;
    // body.last_name = undefined;
    return new Promise((resolve, reject) => {
      request
        .post(`/admin/kysync/update-user-kyc/${id}`, { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  updateKYBEntity: (body) => {
    const { id } = body;
    body.id = undefined;
    // body.first_name = undefined;
    // body.last_name = undefined;
    return new Promise((resolve, reject) => {
      request
        .post(`/admin/kysync/update-business-kyb/${id}`, { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  deleteKYCEntity: (body) => {
    const { entity_id, token, api_key } = body;
    return new Promise((resolve, reject) => {
      request
        .post(`/admin/kysync/delete-user-kyc/${entity_id}`, { token, api_key })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  deleteKYBEntity: (body) => {
    const { entity_id, token, api_key } = body;
    return new Promise((resolve, reject) => {
      request
        .post(`/admin/kysync/delete-business-kyb/${entity_id}`, { token, api_key })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getCustomSettings: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/kysync/get-kysync-verifications', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  saveCustomSettings: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/kysync/update-kysync-verifications', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getKySyncHits: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/kysync/get-kysync-hits', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  purchaseKySyncPlan: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/kysync/purchase-kysync-plan', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },
};

export default apiWrapper(kysyncApi);

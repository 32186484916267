import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Tabs } from 'antd';

import LayoutWarpper from 'components/Layout';
import InformationSetting from './InformationSetting';
import AccountSetting from './AccountSetting';
import CardSetting from './CardSetting';
import TransactionSetting from './TransactionSetting';
import SecuritySetting from './SecuritySetting';
import Teams from './Teams';
import MasterWallet from './MasterWallet';
import Customisations from './Customisations';

import './Settings.less';

const { TabPane } = Tabs;

const Settings = () => {
  const location = useLocation();
  const history = useHistory();

  const [settingTitle, setSettingTitle] = useState('information');

  const titleCase = (s) => s.replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : ' ' + d.toUpperCase()));

  const handleTabClick = (key) => {
    setSettingTitle(key);
    history.push(`/settings#${key}`);
  };

  return (
    <LayoutWarpper>
      <h1 className="page-title">Settings - {titleCase(settingTitle)}</h1>
      <Row style={{ width: '100%', marginBottom: '20px' }} className="settingsPage">
        <Col span={24}>
          <Tabs
            defaultActiveKey="information"
            activeKey={location?.hash?.substring(1) || settingTitle}
            left="true"
            size="large"
            onTabClick={handleTabClick}
          >
            <TabPane tab="Information" key="information">
              <InformationSetting />
            </TabPane>
            <TabPane tab="Account" key="account">
              <AccountSetting />
            </TabPane>
            <TabPane tab="Card" key="card">
              <CardSetting />
            </TabPane>
            <TabPane tab="Transaction" key="transaction">
              <TransactionSetting />
            </TabPane>
            <TabPane tab="Security" key="security">
              <SecuritySetting />
            </TabPane>
            <TabPane tab="Teams" key="teams">
              <Teams />
            </TabPane>
            <TabPane tab="Master Wallet" key="master_wallet">
              <MasterWallet />
            </TabPane>
            <TabPane tab="Customisations" key="customisations">
              <Customisations />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </LayoutWarpper>
  );
};

export default Settings;

import axios from 'axios';

import { API_BASE_URL } from '@Constants/config';
import { apiWrapper } from './interceptors';
// const host = window.location.origin;

const request = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30 * 1000,
});

const statisticsApi = {
  getDashboardStatistics: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/statistics', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getUserStatistics: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/stats/users', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getDepositsStatistics: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/stats/deposits', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getPayoutsStatistics: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/stats/payouts', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },
};

export default apiWrapper(statisticsApi, ['getUserStatistics', 'getDepositsStatistics', 'getPayoutsStatistics']);

import { Row, Col, notification, Spin } from 'antd';
import { Formik } from 'formik';
import {
  Form,
  Input,
  Select,
  FormItem,
  SubmitButton,
  // FormikDebug
} from 'formik-antd';
import * as yup from 'yup';
import { isEmpty } from 'lodash';

import { getAdmin, getBackoffice } from 'helpers/localStorage';
import { queryClient } from 'rc-query';
import { useGetUserNameEmail, useGetUserNamePhone, useGetUserNamePushToken } from 'rc-query/userHooks';
import { GET_ALL_MESSAGES_QUERY } from 'rc-query/keys';
import { useSendMessageMutation, useSaveMessageMutation, useUpdateMessageMutation } from 'rc-query/messageHooks';

import Draft from './Editor';

const { Option } = Select;

import './MessagingForm.less';

const MessagingForm = ({ message, setViewModal, parentFormKey }) => {
  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const sendMessageMutation = useSendMessageMutation();
  const saveMessageMutation = useSaveMessageMutation();
  const updateMessageMutation = useUpdateMessageMutation();

  const { data } = useGetUserNameEmail({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
  });
  const allUserNameEmail = data || [0];

  const phone_data = useGetUserNamePhone({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
  });
  const allUserNamePhone = phone_data['data'] || [0];

  const push_token_data = useGetUserNamePushToken({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
  });
  const allUserNamePushToken = push_token_data['data'] || [0];

  const handleSaveEmail = (values) => {
    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      ...values,
      selected_recipients: values.recipients === 'selected' ? values.selected_recipients : undefined,
    };

    if (message?.id) {
      body.id = `${message.id}`;
      updateMessageMutation.mutateAsync(body).then((data) => {
        if (data?.status === 'error') {
          notification.error({
            message: data.message,
          });
        } else if (data?.status === 'success') {
          notification.success({
            message: data.message,
          });
        }
        queryClient.refetchQueries([GET_ALL_MESSAGES_QUERY]);
        setViewModal(false);
      });
    } else {
      saveMessageMutation
        .mutateAsync(body)
        .then((data) => {
          if (data?.status === 'error') {
            notification.error({
              message: data.message,
            });
          } else if (data?.status === 'success') {
            notification.success({
              message: data.message,
            });
          }
        })
        .finally(() => {
          queryClient.refetchQueries([GET_ALL_MESSAGES_QUERY]);
          saveMessageMutation.isLoading = false;
          setViewModal(false);
        });
    }
  };

  const handleSaveSendEmail = (values) => {
    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      ...values,
      selected_recipients: values.recipients === 'selected' ? values.selected_recipients : undefined,
    };

    if (message?.id) {
      body.id = message.id;
    }

    saveMessageMutation.mutateAsync(body).then((data) => {
      if (data?.status === 'error') {
        notification.error({
          message: data.message,
        });
      } else if (data?.status === 'success') {
        notification.success({
          message: data.message,
        });
        sendMessageMutation
          .mutateAsync({
            api_key: backOffice?.data?.name,
            token: adminUser?.token,
            id: data?.data?.id?.toString(),
          })
          .then((data) => {
            if (data?.status === 'error') {
              notification.error({
                message: data.message,
              });
            } else if (data?.status === 'success') {
              notification.success({
                message: data.message,
              });
            }
            queryClient.refetchQueries([GET_ALL_MESSAGES_QUERY]);
          })
          .finally(() => {
            sendMessageMutation.isLoading = false;
            setViewModal(false);
          });
      }
      // saveMessageMutation.isLoading = false;
    });
  };

  let selected_recipients_list = []
  const handleSelectedRecipientsList = (value) => {
    if (value == 'email') {
      selected_recipients_list = allUserNameEmail
    } else if (value == 'push') {
      selected_recipients_list = allUserNamePushToken
    } else if (value == 'phone') {
      selected_recipients_list = allUserNamePhone
    }
  };

  // const defaultValue = (options, value) => {
  //   console.log('value here', value);
  //   return options ? options.find((option) => option.value === value) : '';
  // };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setSelectedPersonas([{ label: 'Salary increase', value: 'salary_increase', key: 'salary_increase' }]);
  //   }, 5000);
  // }, []);

  return (
    <Spin spinning={saveMessageMutation.isLoading || sendMessageMutation.isLoading}>
      <Formik
        key={`innter-form-${parentFormKey}`}
        initialValues={{
          type: message?.type || 'email',
          recipients: message?.recipients || 'all',
          subject: message?.subject || '',
          message: message?.message || '',
        }}
        validationSchema={yup.object().shape({
          type: yup.string().nullable(true).required('Message Type is required'),
          recipients: yup.string().nullable(true).required('Recipient is required'),
          selected_recipients: yup.array().when('recipients', {
            is: (recipients) => recipients === 'selected',
            then: yup.array().min(1, 'Select at least one recipient').required('Select at least one recipient'),
          }),
          subject: yup.string().nullable(true).required('Subject is required'),
          message: yup.string().nullable(true).required('Message is required'),
        })}
        onSubmit={handleSaveEmail}
      >
        {(formProps) => {
          const { isSubmitting, values, validateForm } = formProps;

          return (
            <Spin spinning={isSubmitting}>
              <Form
                layout="vertical"
                initialValues={{
                  type: message?.type || 'email',
                  recipients: message?.recipients || 'all',
                  subject: message?.subject,
                  message: message?.message,
                }}
              >
                <Row gutter={[20, 16]}>
                  <Col span={24} md={12}>
                    <FormItem label="Message Type" name="type" required>
                      <Select name="type" placeholder="Email" onChange={handleSelectedRecipientsList}>
                        <Option value="email">Email</Option>
                        <Option value="phone">
                          SMS
                        </Option>
                        <Option value="push">
                          Push Notification
                        </Option>
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={24} md={12}>
                    <FormItem label="Select Recipients" name="recipients" required>
                      <Select name="recipients" placeholder="Select Recipients">
                        <Option key="all" value="all">
                          All
                        </Option>
                        <Option key="active" value="active">
                          Active
                        </Option>
                        <Option key="Inactive" value="inactive">
                          Inactive
                        </Option>
                        <Option key="selected" value="selected">
                          Select Recipients
                        </Option>
                      </Select>
                    </FormItem>
                  </Col>
                  {values.recipients === 'selected' ? (
                    <Col span={24}>
                      <FormItem label="Add / Select Recipients" name="selected_recipients" required>
                        <Select name="selected_recipients" placeholder="Add / Select Recipients" mode="multiple">
                          {selected_recipients_list?.map((user) => (
                            <Option key={user.email} value={user.email} label={user.name}>
                              <div className="select-option-label-item">
                                <span role="img" aria-label={user.name} style={{ marginRight: '10px' }}>
                                  {user.name}
                                </span>
                                ({user.email})
                              </div>
                            </Option>
                          ))}
                        </Select>
                      </FormItem>
                    </Col>
                  ) : null}
                  <Col span={24}>
                    <FormItem label="Subject" name="subject" required>
                      <Input name="subject" placeholder="Subject" />
                    </FormItem>
                  </Col>
                  <Col span={24}>
                  {values.type === 'email' ? (
                      <FormItem label="Message" name="message">
                        <Draft alreadySaveTemplate={message ? message.message : ''} />
                      </FormItem>
                  ) : 
                    <FormItem label="Message" name="message" required>
                      <Input name="message" placeholder="Message" />
                    </FormItem>
                  }
                  </Col>
                  {saveMessageMutation.isLoading || sendMessageMutation.isLoading ? (
                    ''
                  ) : (
                    <>
                      <Col>
                        <SubmitButton type="primary" htmlType="submit" loading={saveMessageMutation.isLoading}>
                          Save Message
                        </SubmitButton>
                      </Col>
                      <Col>
                        <SubmitButton
                          type="primary"
                          htmlType="submit"
                          onClick={() =>
                            validateForm().then((values) => {
                              if (isEmpty(values)) {
                                handleSaveSendEmail(formProps.values);
                              }
                            })
                          }
                          loading={saveMessageMutation.isLoading}
                        >
                          Save and Send Message
                        </SubmitButton>
                      </Col>
                    </>
                  )}
                </Row>

                {/* <pre
                  style={{
                    background: '#f6f8fa',
                    fontSize: '.65rem',
                    padding: '.5rem',
                  }}
                >
                  <FormikDebug />
                </pre> */}
              </Form>
            </Spin>
          );
        }}
      </Formik>
    </Spin>
  );
};
export default MessagingForm;

import React, { useState } from 'react';
import { Button, notification } from 'antd';
import { ExportOutlined } from '@ant-design/icons';

import { EXPORT_FILE_SUCCESS_MESSAGE } from 'constants/config';
import { useExportFileMutation } from 'rc-query/transactionHooks';

import { getAdmin, getBackoffice } from 'helpers/localStorage';

import './ExportFile.less';

const ExportFile = ({ data_type, status = 'all' }) => {
  const [exportLoading, setExportLoading] = useState(false);

  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const exportFileMutation = useExportFileMutation();

  const handleExportFile = () => {
    setExportLoading(true);
    exportFileMutation
      .mutateAsync({
        api_key: backOffice?.data?.name,
        token: adminUser?.token,
        data_type,
        status,
      })
      .then((res) => {
        if (res.status === 'error') {
          notification.error({ message: res?.message });
        } else {
          notification.success({ message: res?.message || EXPORT_FILE_SUCCESS_MESSAGE });
        }
      })
      .finally(() => {
        setExportLoading(false);
      });
  };

  return (
    <Button size="large" onClick={handleExportFile} loading={exportLoading}>
      <ExportOutlined /> Export
    </Button>
  );
};

export default ExportFile;

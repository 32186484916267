export const host = window.location.host;

//Set Actions

export const setAdmin = (admin) => {
  localStorage.setItem(`${host}_admin`, JSON.stringify(admin));
};

export const setBackOffice = (backOffice) => {
  localStorage.setItem(`${host}_backOffice`, JSON.stringify(backOffice));
};

export const setToken = (token) => {
  localStorage.setItem(`${host}_token`, token);
};

export const setQRCode = (qrCode) => {
  localStorage.setItem(`${host}_qrcode`, qrCode);
};

export const setEmail = (email) => {
  localStorage.setItem(`${host}_email`, email);
};

export const setVerifyToken = (tokenData) => {
  localStorage.setItem(`${host}_verify_token`, JSON.stringify(tokenData));
};

export const setUsers = (tokenData) => {
  localStorage.setItem(`${host}_users`, JSON.stringify(tokenData));
};

export const setVerifyEmail = (email) => {
  localStorage.setItem(`${host}_verify_email`, JSON.stringify(email));
};

export const setStageToken = (token) => {
  localStorage.setItem(`${host}_stage_token`, JSON.stringify(token));
};

export const setBulkUploadedKysync = (data) => {
  localStorage.setItem(`${host}_bulk_uploaded_kysync`, JSON.stringify(data));
};

export const setFingerprint = (data) => {
  localStorage.setItem(`${host}_fingerprint`, JSON.stringify(data));
};

//Get Actions

export const getAdmin = () => {
  return JSON.parse(localStorage.getItem(`${host}_admin`));
};

export const getBackoffice = () => {
  return JSON.parse(localStorage.getItem(`${host}_backOffice`));
};

export const getToken = () => {
  return localStorage.getItem(`${host}_token`);
};

export const getQRCode = () => {
  return localStorage.getItem(`${host}_qrcode`);
};

export const getEmail = () => {
  return localStorage.getItem(`${host}_email`);
};

export const getVerifyToken = () => {
  return localStorage.getItem(`${host}_verify_token`);
};

export const getVerifyEmail = () => {
  return JSON.parse(localStorage.getItem(`${host}_verify_email`));
};

export const getStageToken = () => {
  return JSON.parse(localStorage.getItem(`${host}_stage_token`));
};

export const getBulkUploadedKysync = () => {
  return JSON.parse(localStorage.getItem(`${host}_bulk_uploaded_kysync`));
};

export const getFingerprint = () => {
  return JSON.parse(localStorage.getItem(`${host}_fingerprint`));
};

// Remove Actions

export const removeAdmin = () => {
  localStorage.removeItem(`${host}_admin`);
};

export const removeBackoffice = () => {
  localStorage.removeItem(`${host}_backOffice`);
};

export const removeToken = () => {
  localStorage.removeItem(`${host}_token`);
};

export const removeQRCode = () => {
  localStorage.removeItem(`${host}_qrcode`);
};

export const removeEmail = () => {
  localStorage.removeItem(`${host}_email`);
};

export const removeVerifyToken = () => {
  localStorage.removeItem(`${host}_verify_token`);
};

export const removeVerifyEmail = () => {
  localStorage.removeItem(`${host}_verify_email`);
};

export const removeStageToken = () => {
  localStorage.removeItem(`${host}_stage_token`);
};

export const removeBulkUploadedKysync = () => {
  localStorage.removeItem(`${host}_bulk_uploaded_kysync`);
};

export const removeFingerprint = () => {
  localStorage.removeItem(`${host}_fingerprint`);
};

export const clearLocalStorage = () => {
  removeAdmin();
  removeBackoffice();
  removeToken();
  removeQRCode();
  removeEmail();
  removeVerifyToken();
  removeVerifyEmail();
  removeStageToken();
  removeBulkUploadedKysync();
}
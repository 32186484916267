import React from 'react';
import { Row, Col } from 'antd';

import BankAccountsTable from 'components/BankAccountsTable';

import LayoutWarpper from 'components/Layout';

const UserBankAccounts = (props) => {
  const userId = props.match.params.userId;
  const entityType = props.match.params.entityType;
  const isIssued = props.match.params.isIssued || true;

  return (
    <LayoutWarpper>
      <h1 className="page-title">User Bank Accounts</h1>
      <Row style={{ width: '100%' }} gutter={[16, 16]}>
        <Col span={24}>
          <BankAccountsTable userId={userId} entityType={entityType} issued_bank_account={isIssued} />
        </Col>
      </Row>
    </LayoutWarpper>
  );
};

export default UserBankAccounts;

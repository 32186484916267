import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Row, Col } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { useVerifyMut } from 'rc-query/authHooks';
import { getEmail, getVerifyToken } from 'helpers/localStorage';

import './Enable2FA.less';

const Login = (props) => {
  const verifyMut = useVerifyMut(props.history);
  let message;
  // let userEmail = currentUserEmail;
  const onFinish = async (values) => {
    const verifyToken = JSON.parse(getVerifyToken());
    verifyMut.mutate({
      code: values.passcode,
      stage_token: verifyToken?.stage_token || null,
      method: verifyToken?.twofa || null,
      email: getEmail(),
    });
  };

  if (verifyMut?.data?.status === 'error') {
    message = verifyMut?.data?.message;
  }

  if (verifyMut?.data?.status === 'success') {
    props.history.push('/dashboard');
  }

  return (
    <>
      <div className="main-container">
        <Row justify="center" align="middle">
          <Col>
            <Row
              type="flex"
              align="middle"
              justify="center"
              className="px-3 bg-white mh-page"
              style={{ minHeight: '100vh' }}
            >
              <div
                style={{
                  maxWidth: '500px',
                  zIndex: 2,
                  minWidth: '300px',
                  backgroundColor: 'white',
                  padding: '2rem',
                  boxShadow: '2px 3px 5px 0px rgba(38,7,4,0.39)',
                }}
              >
                <span>{message && message}</span>
                <Link to="/login">
                  <ArrowLeftOutlined /> Back
                </Link>

                <Form layout="vertical" onFinish={onFinish}>
                  <Form.Item
                    label="Passcode"
                    name="passcode"
                    rules={[
                      {
                        pattern: new RegExp(/^[a-z A-Z 0-9]+$/),
                        message: 'The input is not valid number.',
                      },
                      {
                        required: true,
                        message: 'Please input your passcode.',
                      },
                    ]}
                    extra="Enter the one-time passcode provided to your Email"
                  >
                    <Input type="text" placeholder="PASSCODE" />
                  </Form.Item>

                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    style={{ marginTop: '1rem' }}
                    loading={verifyMut.isLoading}
                  >
                    Verify
                  </Button>
                </Form>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Login;

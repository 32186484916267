import React from 'react';
import { Steps } from 'antd';

import './FundAccounts.less';

const { Step } = Steps;

const WireTransferSteps = ({ stepNumber }) => {
  return (
    <Steps progressDot current={stepNumber} direction="vertical">
      <Step title="Funding method" />
      <Step title="Account & Wire details" />
    </Steps>
  );
};

export default WireTransferSteps;

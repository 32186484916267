import React, { useState } from 'react';
import { Row, Col, Card, Descriptions, notification, Modal, Input, Tooltip, Button } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Switch } from 'formik-antd';
import { CheckCircleFilled, CopyFilled } from '@ant-design/icons';
import OtpInput from 'react-otp-input';

import { getAdmin, getBackoffice } from 'helpers/localStorage';
import {
  useGetSecuritySetting,
  useEnableGoogle2FA,
  useConfirmGoogle2FA,
  useDisableGoogle2FA,
  useConfirmDisableGoogle2FA,
  useEnableSMS2FA,
  useConfirmSMS2FA,
  useDisableSMS2FA,
  useConfirmDisableSMS2FA,
  useEnableEmail2FA,
  useConfirmEmail2FA,
  useDisableEmail2FA,
  useConfirmDisableEmail2FA,
} from 'rc-query/settingHooks';
import { queryClient } from 'rc-query';
import { GET_SECURITY_SETTING_QUERY } from 'rc-query/keys';
import { generateKey } from 'helpers/utils';

import UpdatePasswordSetting from './UpdatePasswordSetting';
import Loading from 'components/Loading';

import './Settings.less';

const SecuritySetting = () => {
  const [showGoogle2FAModal, setShowGoogle2FAModal] = useState(false);
  const [showDisableGoogle2FAModal, setShowDisableGoogle2FAModal] = useState(false);
  const [showSMS2FAModal, setShowSMS2FAModal] = useState(false);
  const [showDisableSMS2FAModal, setShowDisableSMS2FAModal] = useState(false);
  const [showEmail2FAModal, setShowEmail2FAModal] = useState(false);
  const [showDisableEmail2FAModal, setShowDisableEmail2FAModal] = useState(false);

  const [QRCodeString, setQRCodeString] = useState('');
  const [QRImage, setQRImage] = useState('');
  const [OTPCode, setOTPCode] = useState('');
  const [phone, setPhone] = useState('');

  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const { isLoading, data } = useGetSecuritySetting({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
  });

  const { google_authenticator, text_message, email, connected_email } = data?.data?.security_setting || {};

  const enableGoogle2FA = useEnableGoogle2FA();
  const confirmGoogle2FA = useConfirmGoogle2FA();
  const disableGoogle2FA = useDisableGoogle2FA();
  const confirmDisableGoogle2FA = useConfirmDisableGoogle2FA();

  const enableSMS2FA = useEnableSMS2FA();
  const confirmSMS2FA = useConfirmSMS2FA();
  const disableSMS2FA = useDisableSMS2FA();
  const confirmDisableSMS2FA = useConfirmDisableSMS2FA();

  const enableEmail2FA = useEnableEmail2FA();
  const confirmEmail2FA = useConfirmEmail2FA();
  const disableEmail2FA = useDisableEmail2FA();
  const confirmDisableEmail2FA = useConfirmDisableEmail2FA();

  const handleGoogle2FASwitchToggle = (event, formProps) => {
    if (event === true) {
      handleEnableGoogle2FA();
      formProps.setFieldValue('google_authenticator', false);
    } else {
      handleDisableGoogle2FA();
      setShowDisableGoogle2FAModal(true);
      formProps.setFieldValue('google_authenticator', true);
    }
  };

  const handleEnableGoogle2FA = () => {
    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
    };

    enableGoogle2FA.mutateAsync(body).then((response) => {
      if (response?.status === 'error') {
        notification.error({
          message: response?.message,
        });
      } else if (response?.status === 'success') {
        notification.success({
          message: response?.message,
        });
        setQRCodeString(response?.data?.secret);
        setQRImage(response?.data?.image);
        setShowGoogle2FAModal(true);
      }
    });
  };

  const handleGoogle2FAConfirm = () => {
    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      code: OTPCode,
    };

    confirmGoogle2FA
      .mutateAsync(body)
      .then((response) => {
        if (response?.status === 'error') {
          notification.error({
            message: response?.message,
          });
        } else if (response?.status === 'success') {
          notification.success({
            message: response?.message,
          });
        }
      })
      .finally(() => {
        queryClient.refetchQueries([GET_SECURITY_SETTING_QUERY]);
        setShowGoogle2FAModal(false);
      });
  };

  const handleDisableGoogle2FA = () => {
    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
    };

    disableGoogle2FA.mutateAsync(body).then((response) => {
      if (response?.status === 'error') {
        notification.error({
          message: response?.message,
        });
      } else if (response?.status === 'success') {
        notification.success({
          message: response?.message,
        });
        setShowGoogle2FAModal(true);
      }
    });
  };

  const handleDisableGoogle2FAConfirm = () => {
    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      code: OTPCode,
    };

    confirmDisableGoogle2FA
      .mutateAsync(body)
      .then((response) => {
        if (response?.status === 'error') {
          notification.error({
            message: response?.message,
          });
        } else if (response?.status === 'success') {
          notification.success({
            message: response?.message,
          });
          queryClient.refetchQueries([GET_SECURITY_SETTING_QUERY]);
        }
      })
      .finally(() => {
        setShowGoogle2FAModal(false);
      });
  };

  const handleSMS2FASwitchToggle = (event, formProps) => {
    if (event === true) {
      setShowSMS2FAModal(true);
      formProps.setFieldValue('text_message', false);
    } else {
      handleDisableSMS2FA();
      setShowDisableSMS2FAModal(true);
      formProps.setFieldValue('text_message', true);
    }
  };

  const handleEnableSMS2FA = () => {
    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      phone_number: phone,
    };

    enableSMS2FA.mutateAsync(body).then((response) => {
      if (response?.status === 'error') {
        notification.error({
          message: response?.message,
        });
      } else if (response?.status === 'success') {
        notification.success({
          message: response?.message,
        });
        console.log('enableSMS2FA.mutateAsync ~ response', response);
        setShowSMS2FAModal(true);
      }
    });
  };

  const handleSMS2FAConfirm = () => {
    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      code: OTPCode,
    };

    confirmSMS2FA
      .mutateAsync(body)
      .then((response) => {
        if (response?.status === 'error') {
          notification.error({
            message: response?.message,
          });
        } else if (response?.status === 'success') {
          notification.success({
            message: response?.message,
          });
        }
      })
      .finally(() => {
        queryClient.refetchQueries([GET_SECURITY_SETTING_QUERY]);
        setShowSMS2FAModal(false);
      });
  };

  const handleDisableSMS2FA = () => {
    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
    };

    disableSMS2FA.mutateAsync(body).then((response) => {
      if (response?.status === 'error') {
        notification.error({
          message: response?.message,
        });
      } else if (response?.status === 'success') {
        notification.success({
          message: response?.message,
        });
        setShowGoogle2FAModal(true);
      }
    });
  };

  const handleDisableSMS2FAConfirm = () => {
    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      code: OTPCode,
    };

    confirmDisableSMS2FA
      .mutateAsync(body)
      .then((response) => {
        if (response?.status === 'error') {
          notification.error({
            message: response?.message,
          });
        } else if (response?.status === 'success') {
          notification.success({
            message: response?.message,
          });
          queryClient.refetchQueries([GET_SECURITY_SETTING_QUERY]);
        }
      })
      .finally(() => {
        setShowSMS2FAModal(false);
      });
  };

  const handleEmail2FASwitchToggle = (event, formProps) => {
    if (event === true) {
      handleEnableEmail2FA();
      formProps.setFieldValue('email', false);
    } else {
      handleDisableEmail2FA();
      setShowDisableEmail2FAModal(true);
      formProps.setFieldValue('email', true);
    }
  };

  const handleEnableEmail2FA = () => {
    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
    };

    enableEmail2FA.mutateAsync(body).then((response) => {
      if (response?.status === 'error') {
        notification.error({
          message: response?.message,
        });
      } else if (response?.status === 'success') {
        notification.success({
          message: response?.message,
        });
        setShowEmail2FAModal(true);
      }
    });
  };

  const handleEmail2FAConfirm = () => {
    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      code: OTPCode,
    };

    confirmEmail2FA
      .mutateAsync(body)
      .then((response) => {
        if (response?.status === 'error') {
          notification.error({
            message: response?.message,
          });
        } else if (response?.status === 'success') {
          notification.success({
            message: response?.message,
          });
        }
      })
      .finally(() => {
        queryClient.refetchQueries([GET_SECURITY_SETTING_QUERY]);
        setShowEmail2FAModal(false);
      });
  };

  const handleDisableEmail2FA = () => {
    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
    };

    disableEmail2FA.mutateAsync(body).then((response) => {
      if (response?.status === 'error') {
        notification.error({
          message: response?.message,
        });
      } else if (response?.status === 'success') {
        notification.success({
          message: response?.message,
        });
        setShowEmail2FAModal(true);
      }
    });
  };

  const handleDisableEmail2FAConfirm = () => {
    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      code: OTPCode,
    };

    confirmDisableEmail2FA
      .mutateAsync(body)
      .then((response) => {
        if (response?.status === 'error') {
          notification.error({
            message: response?.message,
          });
        } else if (response?.status === 'success') {
          notification.success({
            message: response?.message,
          });
          queryClient.refetchQueries([GET_SECURITY_SETTING_QUERY]);
        }
      })
      .finally(() => {
        setShowEmail2FAModal(false);
      });
  };

  const handleOTPChange = (otp) => {
    setOTPCode(otp);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleCancelBox = () => {
    setShowGoogle2FAModal(false);
    setShowSMS2FAModal(false);
    setShowEmail2FAModal(false);
  };

  return (
    <>
      <Row gutter={[18, 18]} style={{ marginTop: '20px' }}>
        <Col span={15}>
          {isLoading ? (
            <Loading fullScreen loading={isLoading} />
          ) : (
            <Formik
              layout="vertical"
              initialValues={{
                google_authenticator: google_authenticator || false,
                text_message: text_message || false,
                email: email || false,
              }}
              key={`twoFactorAuthForm-${generateKey()}`}
            >
              {(formProps) => {
                // const { formikHelpers } = formProps;
                return (
                  <Form
                    initialValues={{
                      google_authenticator: google_authenticator || false,
                      text_message: text_message || false,
                      email: email || false,
                    }}
                  >
                    <Card title="2-Factor Authentication">
                      <div className="borderedFormItem">
                        <h2>Google Authenticator</h2>
                        <Descriptions layout="horizontal" column={1}>
                          <Descriptions.Item label="Use the Google Authenticator app to generate one time security codes">
                            <FormItem name="google_authenticator">
                              <Switch
                                name="google_authenticator"
                                onChange={(e) => handleGoogle2FASwitchToggle(e, formProps)}
                              />
                            </FormItem>
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                      <div className="borderedFormItem">
                        <h2>Text message (SMS)</h2>
                        <Descriptions layout="horizontal" column={1}>
                          <Descriptions.Item label="Prompt and get security codes to your connected phone number via SMS">
                            <FormItem name="text_message">
                              <Switch name="text_message" onChange={(e) => handleSMS2FASwitchToggle(e, formProps)} />
                            </FormItem>
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                      <div className="borderedFormItem">
                        <h2>Email</h2>
                        <Descriptions layout="horizontal" column={1}>
                          <Descriptions.Item label="Prompt and get security codes to your connected email address">
                            <FormItem name="email">
                              <Switch name="email" onChange={(e) => handleEmail2FASwitchToggle(e, formProps)} />
                            </FormItem>
                          </Descriptions.Item>
                        </Descriptions>
                        <div className="defaultEmailInfo">
                          <CheckCircleFilled style={{ fontSize: '24px', color: '#17CE89' }} />{' '}
                          <span className="defaultEmailType">Default</span>
                          <span className="defaultEmail">{connected_email}</span>
                        </div>
                      </div>
                    </Card>
                    {/* <pre
                    style={{
                      background: '#f6f8fa',
                      fontSize: '.65rem',
                      padding: '.5rem',
                    }}
                  >
                    <strong>props</strong> = {JSON.stringify(formProps, null, 2)}
                  </pre> */}
                  </Form>
                );
              }}
            </Formik>
          )}
        </Col>
        <Col span={9}>
          <UpdatePasswordSetting />
        </Col>
      </Row>
      <Modal
        width={showDisableGoogle2FAModal ? 350 : 580}
        title={showDisableGoogle2FAModal ? 'Disable Google Authenticator' : 'Set Up Google Authenticator'}
        visible={showGoogle2FAModal}
        onCancel={handleCancelBox}
        footer={false}
        style={{ padding: '0px !important' }}
        className="securitySettingModal"
      >
        {!showDisableGoogle2FAModal ? (
          <div style={{ padding: '20px' }}>
            <Row>
              <Col span={24}>
                <p className="descriptionText">
                  Follow the guide below to set up Google Authenticator for additional layer of security.
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                <div className="QR-container">
                  <Row>
                    <img className="QR-image" src={QRImage} alt="QR Code" />
                  </Row>
                  <Row>
                    <p className="QR-Description">
                      Use Google Authenticator app to scan this QR code,{' '}
                      <a href="https://apps.apple.com/us/app/google-authenticator/id388497605">Download</a> &nbsp;the
                      app here if you don’t already have it.
                    </p>
                  </Row>
                </div>
              </Col>
              <Col span={14}>
                <div>
                  <Row>
                    <p className="descriptionText">
                      If you have any problem with scanning the QR code, enter this code manually into the app.
                    </p>
                  </Row>
                  <Row>
                    <Input.Group compact>
                      <Input style={{ width: '80%' }} defaultValue={QRCodeString} />
                      <Tooltip title="Copy QR Code">
                        <Button icon={<CopyFilled />} />
                      </Tooltip>
                    </Input.Group>
                  </Row>
                </div>
                <div style={{ marginTop: '3rem' }}>
                  <Row>
                    <p>Enter security code from app:</p>
                  </Row>
                  <Row>
                    <OtpInput
                      autoFocus={true}
                      value={OTPCode}
                      onChange={handleOTPChange}
                      numInputs={6}
                      placeholder="------"
                      inputStyle="code-digit"
                      containerStyle="code-container"
                      isInputNum
                    />
                  </Row>
                  <Row>
                    <Button
                      type="primary"
                      className="enable-button"
                      size="large"
                      onClick={handleGoogle2FAConfirm}
                      style={{ width: '100%' }}
                    >
                      Enable Google Authenticator
                    </Button>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <div style={{ padding: '20px' }}>
            <Row>
              <Col span={24}>
                <p className="descriptionText">Enter security code to disable Google 2FA Authentication.</p>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div style={{ marginTop: '1rem' }}>
                  <Row>
                    <p>Enter security code from app:</p>
                  </Row>
                  <Row>
                    <OtpInput
                      autoFocus={true}
                      value={OTPCode}
                      onChange={handleOTPChange}
                      numInputs={4}
                      placeholder="----"
                      inputStyle="code-digit"
                      containerStyle="code-container"
                      // isInputNum
                    />
                  </Row>
                  <Row>
                    <Button
                      type="primary"
                      className="enable-button"
                      size="large"
                      onClick={handleDisableGoogle2FAConfirm}
                      style={{ width: '100%' }}
                    >
                      Disable Google Authenticator
                    </Button>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Modal>
      <Modal
        width={400}
        title={showDisableSMS2FAModal ? 'Disable SMS Verifiction' : 'Set up SMS Verification'}
        visible={showSMS2FAModal}
        onCancel={handleCancelBox}
        footer={false}
        style={{ padding: '0px !important' }}
        className="securitySettingModal"
      >
        {!showDisableSMS2FAModal ? (
          <div style={{ padding: '20px' }}>
            <Row>
              <Col span={24}>
                <p className="descriptionText">
                  Follow the guide below to set up SMS 2FA for additional layer of security
                </p>
              </Col>
            </Row>
            <Row style={{ marginTop: '16px' }}>
              <Col span={24}>
                <span className="phone-label">Phone Number</span>
                <Input size="large" placeholder="Enter Phone Number" onChange={handlePhoneChange} />
                <Button type="link" onClick={handleEnableSMS2FA}>
                  Send me SMS code
                </Button>
              </Col>
            </Row>
            <Row style={{ marginTop: '16px' }}>
              <Col span={24}>
                <span className="phone-label">Enter SMS Verification code:</span>
                <OtpInput
                  autoFocus={true}
                  value={OTPCode}
                  onChange={handleOTPChange}
                  numInputs={4}
                  placeholder="-----"
                  inputStyle="code-digit"
                  containerStyle="code-container phone-code-container"
                  // isInputNum
                />
              </Col>
            </Row>
            <Row className="enable-button sms-btn-container">
              <Col span={24}>
                <Button type="primary" size="large" onClick={handleSMS2FAConfirm} style={{ width: '100%' }}>
                  Enable SMS Verification
                </Button>
              </Col>
            </Row>
          </div>
        ) : (
          <div style={{ padding: '20px' }}>
            <Row>
              <Col span={24}>
                <p className="descriptionText">Enter security code to disable SMS 2FA Authentication.</p>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div style={{ marginTop: '1rem' }}>
                  <Row>
                    <p>Enter security code from app:</p>
                  </Row>
                  <Row>
                    <OtpInput
                      autoFocus={true}
                      value={OTPCode}
                      onChange={handleOTPChange}
                      numInputs={4}
                      placeholder="----"
                      inputStyle="code-digit"
                      containerStyle="code-container"
                      // isInputNum
                    />
                  </Row>
                  <Row>
                    <Button
                      type="primary"
                      className="enable-button"
                      size="large"
                      onClick={handleDisableSMS2FAConfirm}
                      style={{ width: '100%' }}
                    >
                      Disable SMS Authenticator
                    </Button>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Modal>
      <Modal
        width={showDisableEmail2FAModal ? 350 : 580}
        title={showDisableEmail2FAModal ? 'Disable Email Authenticator' : 'Set Up Email Authenticator'}
        visible={showEmail2FAModal}
        onCancel={handleCancelBox}
        footer={false}
        style={{ padding: '0px !important' }}
        className="securitySettingModal"
      >
        {!showDisableEmail2FAModal ? (
          <div style={{ padding: '20px' }}>
            <Row>
              <Col span={24}>
                <p className="descriptionText">
                  Follow the guide below to set up Email Authenticator for additional layer of security.
                </p>
              </Col>
            </Row>
            <Row style={{ marginTop: '16px' }}>
              <Col span={24}>
                <span className="phone-label">Enter Email Verification code:</span>
                <OtpInput
                  autoFocus={true}
                  value={OTPCode}
                  onChange={handleOTPChange}
                  numInputs={4}
                  placeholder="-----"
                  inputStyle="code-digit"
                  containerStyle="code-container phone-code-container"
                  // isInputNum
                />
              </Col>
            </Row>
            <Row className="enable-button sms-btn-container">
              <Col span={24}>
                <Button type="primary" size="large" onClick={handleEmail2FAConfirm} style={{ width: '100%' }}>
                  Enable Email Verification
                </Button>
              </Col>
            </Row>
          </div>
        ) : (
          <div style={{ padding: '20px' }}>
            <Row>
              <Col span={24}>
                <p className="descriptionText">Enter security code to disable Email 2FA Authentication.</p>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div style={{ marginTop: '1rem' }}>
                  <Row>
                    <p>Enter security code from app:</p>
                  </Row>
                  <Row>
                    <OtpInput
                      autoFocus={true}
                      value={OTPCode}
                      onChange={handleOTPChange}
                      numInputs={4}
                      placeholder="----"
                      inputStyle="code-digit"
                      containerStyle="code-container"
                      // isInputNum
                    />
                  </Row>
                  <Row>
                    <Button
                      type="primary"
                      className="enable-button"
                      size="large"
                      onClick={handleDisableEmail2FAConfirm}
                      style={{ width: '100%' }}
                    >
                      Disable Email Authenticator
                    </Button>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Modal>
    </>
  );
};

export default SecuritySetting;

import React, { useState } from 'react';
import { Row, Col, Button, Spin, notification } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { Form, FormItem, InputNumber, Radio, Select, SubmitButton } from 'formik-antd';
import { capitalize, filter, isEmpty, replace, upperCase } from 'lodash';
import * as yup from 'yup';
import { PDFDownloadLink } from '@react-pdf/renderer';

import { IoArrowBackCircle } from 'react-icons/io5';
import {
  ArrowRightOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  CopyOutlined,
  PlusCircleTwoTone,
  InfoCircleFilled,
} from '@ant-design/icons';

import localStorageService from 'helpers/localStorageService';
import { host, getAdmin, getBackoffice } from 'helpers/localStorage';
import {
  useBankAccounts,
  useFundAccount,
  useFundAccountViaCard,
  useWalletTokenizedCards,
  useWireTransferDetail,
} from 'rc-query/masterWalletHooks';
import { isFormValidate, moneyFormat } from 'helpers/utils';
import { queryClient } from 'rc-query';
import { GET_MASTER_WALLET_INFO, GET_WALLET_TRANSACTIONS } from 'rc-query/keys';

import BankTransferSteps from './BankTransferSteps';
import WireTransferSteps from './WireTransferSteps';
import LayoutWarpper from 'components/Layout';
import WireTransferPDF from './WireTransferPDF';

import './FundAccounts.less';

const { Option } = Select;

const FundAccounts = () => {
  const history = useHistory();
  const storedFundAccount = localStorageService.get(`${host}_fund_account_referral`) ?? {};
  const initialStep = !isEmpty(storedFundAccount) ? 2 : 0;

  const [stepNumber, setStepNumber] = useState(initialStep);
  const [walletId, setWalletId] = useState(storedFundAccount?.values?.wallet ?? 'funding_account');
  const [showBenAccountNumber, setShowBenAccountNumber] = useState(false);
  const [showBankAccountNumber, setShowBankAccountNumber] = useState(false);

  const walletName = walletId === 'funding_account' ? 'Funding Account' : 'Billing / Reserve Account';

  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const fundAccount = useFundAccount();
  const fundAccountViaCard = useFundAccountViaCard();

  const BankAccounts = useBankAccounts({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
  });

  const TokenizedCards = useWalletTokenizedCards({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
  });

  const { isLoading, data: wireTransferDetail } = useWireTransferDetail({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
  });

  const continueSubmit = (formProps, stepNumber) => {
    let touchedFields = { howToFund: true };

    if (stepNumber === 2) {
      touchedFields.wallet = true;
    }
    if (stepNumber === 3) {
      touchedFields.wallet = true;
      touchedFields.amount = true;
      touchedFields.account_id = true;
    }

    formProps.setTouched(touchedFields);

    formProps.validateForm().then((errors) => {
      if (isFormValidate(errors, touchedFields)) {
        setStepNumber(stepNumber);
      }
    });
  };

  const handleSubmit = (values, formikHelpers) => {
    formikHelpers.validateForm();

    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      ...values,
      account_id: `${values.account_id}`,
      amount: `${values.amount}`,
    };

    if (values.howToFund === 'card') {
      body.account_id = undefined;
      body.card_id = `${values.account_id}`;

      fundAccountViaCard
        .mutateAsync(body)
        .then((data) => {
          if (data.status === 'success') {
            notification.success({
              message: data.message,
            });
            queryClient.refetchQueries([GET_WALLET_TRANSACTIONS]);
            queryClient.refetchQueries([GET_MASTER_WALLET_INFO]);

            // remove fund account referral session
            localStorageService.remove(`${host}_fund_account_referral`);

            history.push('/settings#master_wallet');
          } else {
            notification.error({
              message: data.message,
            });
          }
        })
        .finally(() => {
          formikHelpers.setSubmitting(false);
        });
    } else {
      fundAccount
        .mutateAsync(body)
        .then((data) => {
          if (data.status === 'success') {
            notification.success({
              message: data.message,
            });
            queryClient.refetchQueries([GET_WALLET_TRANSACTIONS]);
            queryClient.refetchQueries([GET_MASTER_WALLET_INFO]);

            // remove fund account referral session
            localStorageService.remove(`${host}_fund_account_referral`);

            history.push('/settings#master_wallet');
          } else {
            notification.error({
              message: data.message,
            });
          }
        })
        .finally(() => {
          formikHelpers.setSubmitting(false);
        });
    }
  };

  const handleAddNewBank = (values) => {
    localStorageService.set(`${host}_fund_account_referral`, {
      step: 1,
      linkFundingType: 'bank_link_funding',
      values: { ...values },
    });
    history.push('/link_funding');
  };

  const handleAddNewCard = (values) => {
    localStorageService.set(`${host}_fund_account_referral`, {
      step: 1,
      linkFundingType: 'card_link_funding',
      values: { ...values },
    });
    history.push('/link_funding');
  };

  // const downloadWireTransferPDF = () => {
  //   // ReactPDF.render(<Wire />, `${__dirname}/example.pdf`);
  // };

  return (
    <LayoutWarpper>
      <h1 className="page-title">Master Wallet</h1>
      <Row style={{ width: '100%' }} gutter={[16, 16]} className="fundAccountPage">
        <Col span={24}>
          <Formik
            initialValues={{
              howToFund: storedFundAccount?.values?.howToFund ?? 'ACH',
              wallet: storedFundAccount?.values?.wallet ?? undefined,
            }}
            validationSchema={yup.object().shape({
              howToFund: yup.string().nullable(true).required('How to Fund is required'),
              wallet: yup.string().nullable(true).required('Please select Wallet'),
              amount: yup.number().nullable(true).required('Amount is required'),
              account_id: yup.number().nullable(true).required('Transfer From is required'),
            })}
            onSubmit={handleSubmit}
          >
            {(formProps) => {
              const { isSubmitting, handleSubmit, values } = formProps;
              return (
                <Spin spinning={isSubmitting}>
                  <Form
                    size="large"
                    layout="vertical"
                    initialValues={{
                      howToFund: storedFundAccount?.values?.howToFund ?? 'ACH',
                      wallet: storedFundAccount?.values?.wallet ?? undefined,
                    }}
                  >
                    <div className="backBtn">
                      <Link to="/settings#master_wallet" style={{ display: 'flex', alignContent: 'center' }}>
                        <IoArrowBackCircle size={22} style={{ marginRight: '13px' }} /> <span>Back</span>
                      </Link>
                    </div>
                    <Row gutter={[16, 8]} style={{ marginTop: '40px' }}>
                      <Col span={6}>
                        {values.howToFund === 'ACH' ? (
                          <BankTransferSteps stepNumber={stepNumber} />
                        ) : (
                          <WireTransferSteps stepNumber={stepNumber} />
                        )}
                      </Col>
                      {stepNumber === 0 ? (
                        <Col span={10}>
                          <Row gutter={[16, 8]}>
                            <Col span={24}>
                              <p>How do you want to fund?</p>
                              <FormItem name="howToFund">
                                <Radio.Group name="howToFund">
                                  <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                      <label className="cursor-pointer">
                                        <div className="fundAccountType">
                                          <div className="icon">
                                            <img src="images/icons/bank_transfer.svg" alt="Bank Transfer" />
                                          </div>
                                          <div className="optionText">
                                            <p className="title">Bank Transfer (ACH)</p>
                                            <p className="desc">Should arrive 2-3 business days</p>
                                          </div>
                                          <div className="option">
                                            <Radio name="howToFund" value="ACH" />
                                          </div>
                                        </div>
                                      </label>
                                    </Col>
                                    <Col span={24}>
                                      <label className="cursor-pointer">
                                        <div className="fundAccountType">
                                          <div className="icon">
                                            <img src="images/icons/card.svg" alt="Card Transfer" />
                                          </div>
                                          <div className="optionText" style={{ marginLeft: '0px' }}>
                                            <p className="title">Card</p>
                                            <p className="desc">Add your debit / credit card information</p>
                                          </div>
                                          <div className="option">
                                            <Radio name="howToFund" value="card" />
                                          </div>
                                        </div>
                                        <div style={{ marginTop: '16px' }}>
                                          <p>
                                            <Link to="/link_funding">
                                              Link a funding source <ArrowRightOutlined />
                                            </Link>
                                          </p>
                                        </div>
                                      </label>
                                    </Col>
                                    <Col span={24}>
                                      <label className="cursor-pointer">
                                        <div className="fundAccountType">
                                          <div className="icon">
                                            <img src="images/icons/wire_transfer.svg" alt="Wire Transfer" />
                                          </div>
                                          <div className="optionText">
                                            <p className="title">Wire Transfer</p>
                                            <p className="desc">Should arrive same business day</p>
                                          </div>
                                          <div className="option">
                                            <Radio name="howToFund" value="wire_transfer" />
                                          </div>
                                        </div>
                                      </label>
                                    </Col>
                                  </Row>
                                </Radio.Group>
                              </FormItem>
                            </Col>
                          </Row>
                          <Row gutter={[16, 8]} style={{ marginTop: '20px' }}>
                            <Col span={24}>
                              <Button type="secondary" size="large">
                                <Link to="/settings#master_wallet">Cancel</Link>
                              </Button>
                              <Button
                                type="primary"
                                size="large"
                                style={{ marginLeft: '16px' }}
                                onClick={() => setStepNumber(1)}
                              >
                                Continue
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      ) : stepNumber === 1 && (values.howToFund === 'ACH' || values.howToFund === 'card') ? (
                        <Col span={10}>
                          <Row gutter={[16, 8]}>
                            <Col span={24}>
                              <p>Which account are you funding?</p>
                              <FormItem name="wallet">
                                <Radio.Group name="wallet">
                                  <Row gutter={[16, 8]} style={{ marginBottom: '16px' }} key={`source-row-${walletId}`}>
                                    <Col span={24}>
                                      <label className="cursor-pointer">
                                        <div className="walletOptions">
                                          <div className="icon">
                                            <img src="/images/banks/deposits.svg" alt={walletName} />
                                            <div className="optionText">
                                              <p className="title">{walletName}</p>
                                            </div>
                                          </div>
                                          <div className="option">
                                            <Radio name="wallet" key={walletId} value={walletId} />
                                          </div>
                                        </div>
                                      </label>
                                    </Col>
                                  </Row>
                                </Radio.Group>
                              </FormItem>
                              {walletId === 'funding_account' ? (
                                <Button type="link" onClick={() => setWalletId('billing_reserve_account')}>
                                  Fund billing / reserve account instead <ArrowRightOutlined />
                                </Button>
                              ) : (
                                <Button type="link" onClick={() => setWalletId('funding_account')}>
                                  Fund funding account <ArrowRightOutlined />
                                </Button>
                              )}
                            </Col>
                          </Row>
                          <Row gutter={[16, 8]} style={{ marginTop: '20px' }}>
                            <Col span={24}>
                              <Button type="secondary" size="large" onClick={() => setStepNumber(0)}>
                                Go Back
                              </Button>
                              <Button
                                type="primary"
                                size="large"
                                style={{ marginLeft: '16px' }}
                                onClick={() => continueSubmit(formProps, 2)}
                              >
                                Continue
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      ) : stepNumber === 1 && values.howToFund === 'wire_transfer' ? (
                        <Col span={10}>
                          <Row gutter={[16, 8]} style={{ marginBottom: '16px' }}>
                            <Col span={24}>
                              <p>Domestic transfer details</p>
                              <div className="walletOptions">
                                <div className="icon">
                                  <img src="/images/banks/deposits.svg" alt="Funding Account" />
                                  <div className="optionText">
                                    <p className="title">Funding Account</p>
                                  </div>
                                </div>
                                <div className="option">
                                  <Radio
                                    name="wallet"
                                    key="wire-transfer-wallet"
                                    value="wire-transfer-wallet"
                                    checked
                                  />
                                </div>
                              </div>
                              <div className="optionDetail">
                                <Row gutter={[16, 8]}>
                                  <Col span={24}>
                                    <Row>
                                      <Col span={24}>
                                        <div className="title">BENEFICIARY DETAIL</div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col span={24}>
                                        <div className="option">
                                          <div className="label">Beneficiary Name</div>
                                          <div className="value">{wireTransferDetail?.account_name}</div>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col span={12}>
                                        <div className="option">
                                          <div className="label">
                                            Account Number{' '}
                                            {showBenAccountNumber ? (
                                              <EyeOutlined onClick={() => setShowBenAccountNumber(false)} />
                                            ) : (
                                              <EyeInvisibleOutlined onClick={() => setShowBenAccountNumber(true)} />
                                            )}{' '}
                                            <CopyOutlined
                                              onClick={() =>
                                                navigator.clipboard
                                                  .writeText(wireTransferDetail?.account_number)
                                                  .then(() => notification.info({ message: 'Account number copied!' }))
                                              }
                                            />
                                          </div>
                                          <div className="value">
                                            {showBenAccountNumber ? wireTransferDetail?.account_number : '********'}
                                          </div>
                                        </div>
                                      </Col>
                                      <Col span={12}>
                                        <div className="option">
                                          <div className="label">Account Type</div>
                                          <div className="value">{wireTransferDetail?.account_type}</div>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col span={24}>
                                        <div className="option">
                                          <div className="label">Beneficiary Address</div>
                                          <div className="value">{wireTransferDetail?.beneficiary_branch}</div>
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row style={{ marginTop: '20px' }}>
                                      <Col span={24}>
                                        <div className="title">Receiving bank details</div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col span={24}>
                                        <div className="option">
                                          <div className="label">
                                            Routing Number{' '}
                                            {showBankAccountNumber ? (
                                              <EyeOutlined onClick={() => setShowBankAccountNumber(false)} />
                                            ) : (
                                              <EyeInvisibleOutlined onClick={() => setShowBankAccountNumber(true)} />
                                            )}{' '}
                                            <CopyOutlined
                                              onClick={() =>
                                                navigator.clipboard
                                                  .writeText(wireTransferDetail?.routing_number)
                                                  .then(() => notification.info({ message: 'Routing number copied!' }))
                                              }
                                            />
                                          </div>
                                          <div className="value">
                                            {showBankAccountNumber ? wireTransferDetail?.routing_number : '********'}
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col span={24}>
                                        <div className="option">
                                          <div className="label">Bank Name</div>
                                          <div className="value">{wireTransferDetail?.bank_name}</div>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col span={24}>
                                        <div className="option">
                                          <div className="label">Bank Address</div>
                                          <div className="value">{wireTransferDetail?.bank_branch}</div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                          <Row gutter={[16, 8]} style={{ marginTop: '20px' }}>
                            <Col span={24}>
                              <Button type="secondary" size="large" onClick={() => setStepNumber(0)}>
                                Go Back
                              </Button>
                              {/* <Button
                                type="primary"
                                size="large"
                                style={{ marginLeft: '16px' }}
                                onClick={downloadWireTransferPDF}
                              >
                                Download as PDF
                              </Button> */}
                              {!isLoading ? (
                                <PDFDownloadLink
                                  className="pdfDownloadBtn"
                                  document={<WireTransferPDF data={wireTransferDetail} />}
                                  fileName="wire_transfer_detail.pdf"
                                >
                                  {({ loading }) => (loading ? 'Loading document...' : 'Download as PDF')}
                                </PDFDownloadLink>
                              ) : null}
                            </Col>
                          </Row>
                        </Col>
                      ) : stepNumber === 2 && values.howToFund === 'ACH' ? (
                        <Col span={10}>
                          <Row gutter={[16, 8]}>
                            <Col span={24}>
                              <p>Input amount and select funding source.</p>
                              <Row gutter={[16, 8]}>
                                <Col span={24}>
                                  <FormItem label="Amount to fund" name="amount">
                                    <InputNumber
                                      name="amount"
                                      placeholder="0.00"
                                      style={{ width: '100%' }}
                                      min={0}
                                      prefix="$"
                                      size="medium"
                                      precision={2}
                                      controls={false}
                                    />
                                  </FormItem>
                                </Col>
                              </Row>

                              {!isEmpty(BankAccounts?.data?.data) ? (
                                <Row gutter={[16, 8]}>
                                  <Col span={24}>
                                    <FormItem label="Transfer From" name="account_id">
                                      <Select name="account_id" placeholder="Select Bank Account">
                                        {BankAccounts?.data?.data.map((account) => {
                                          if (account.status == 'pending') {
                                            return (
                                              <Option key={account.id} value={account.id} disabled>
                                                {`${account.account_holder_name} - ${account.bank_name} unverified`}
                                              </Option>
                                            );
                                          } else {
                                            return (
                                              <Option key={account.id} value={account.id}>
                                                {`${account.account_holder_name} - ${account.bank_name}`}
                                              </Option>
                                            );
                                          }
                                        })}
                                      </Select>
                                    </FormItem>
                                  </Col>
                                </Row>
                              ) : (
                                <>
                                  <Row gutter={[16, 8]} style={{ marginTop: '20px' }}>
                                    <Col span={24}>
                                      <div>
                                        <label>Transfer From</label>
                                      </div>
                                      <div>
                                        <Button type="link" onClick={() => handleAddNewBank(values)}>
                                          <div className="fundAccountType addNewAccount">
                                            <div className="icon">
                                              <PlusCircleTwoTone style={{ fontSize: '32px' }} />
                                            </div>
                                            <div className="optionText">
                                              <p className="title">Add a new bank</p>
                                            </div>
                                          </div>
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={24}>
                                      <div
                                        className="infoBox"
                                        style={{ width: '365px', marginTop: '26px', display: 'flex' }}
                                      >
                                        <InfoCircleFilled
                                          style={{ fontSize: '24px', color: '#3895D3', marginRight: '16px' }}
                                        />
                                        <p>
                                          You will need to verify bank account with micro-deposits if you manually enter
                                          your bank account details.
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </Col>
                          </Row>
                          <Row gutter={[16, 8]} style={{ marginTop: '20px' }}>
                            <Col span={24}>
                              <Button type="secondary" size="large" onClick={() => setStepNumber(1)}>
                                Go Back
                              </Button>
                              <Button
                                type="primary"
                                size="large"
                                style={{ marginLeft: '16px' }}
                                onClick={() => continueSubmit(formProps, 3)}
                              >
                                Continue
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      ) : stepNumber === 2 && values.howToFund === 'card' ? (
                        <Col span={10}>
                          <Row gutter={[16, 8]}>
                            <Col span={24}>
                              <p>Input amount and select funding source.</p>
                              <Row gutter={[16, 8]}>
                                <Col span={24}>
                                  <FormItem label="Amount to fund" name="amount">
                                    <InputNumber
                                      name="amount"
                                      placeholder="0.00"
                                      style={{ width: '100%' }}
                                      min={0}
                                      prefix="$"
                                      size="medium"
                                      precision={2}
                                      controls={false}
                                    />
                                  </FormItem>
                                </Col>
                              </Row>

                              {!isEmpty(TokenizedCards?.data) ? (
                                <Row gutter={[16, 8]}>
                                  <Col span={24}>
                                    <FormItem label="Transfer From" name="account_id">
                                      <Select name="account_id" placeholder="Select Card">
                                        {TokenizedCards?.data.map((card) => {
                                          return (
                                            <Option
                                              key={card.card_id}
                                              value={card.card_id}
                                              disabled={card.card_status === 'pending'}
                                            >
                                              {`${card.card_brand} ****${card.last_four}${
                                                card.card_status === 'pending' ? ' unverified' : ''
                                              }`}
                                            </Option>
                                          );
                                        })}
                                      </Select>
                                    </FormItem>
                                  </Col>
                                </Row>
                              ) : (
                                <>
                                  <Row gutter={[16, 8]} style={{ marginTop: '20px' }}>
                                    <Col span={24}>
                                      <div>
                                        <label>Transfer From</label>
                                      </div>
                                      <div>
                                        <Button type="link" onClick={() => handleAddNewCard(values)}>
                                          <div className="fundAccountType addNewAccount">
                                            <div className="icon">
                                              <PlusCircleTwoTone style={{ fontSize: '32px' }} />
                                            </div>
                                            <div className="optionText">
                                              <p className="title">Add a new card</p>
                                            </div>
                                          </div>
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={24}>
                                      <div
                                        className="infoBox"
                                        style={{ width: '365px', marginTop: '26px', display: 'flex' }}
                                      >
                                        <InfoCircleFilled
                                          style={{ fontSize: '24px', color: '#3895D3', marginRight: '16px' }}
                                        />
                                        <p>
                                          You will need to verify bank account with micro-deposits if you manually enter
                                          your bank account details.
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </Col>
                          </Row>
                          <Row gutter={[16, 8]} style={{ marginTop: '20px' }}>
                            <Col span={24}>
                              <Button type="secondary" size="large" onClick={() => setStepNumber(1)}>
                                Go Back
                              </Button>
                              <Button
                                type="primary"
                                size="large"
                                style={{ marginLeft: '16px' }}
                                onClick={() => continueSubmit(formProps, 3)}
                              >
                                Continue
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      ) : stepNumber === 3 ? (
                        <Col span={10}>
                          <Row gutter={[16, 8]}>
                            <Col span={24}>
                              <p>Preview and confirm funding.</p>

                              <div className="preview">
                                <h2 className="previewTitle">FUNDING OVERVIEW</h2>
                                <Row>
                                  <Col span={24}>
                                    <div className="selectedOptions">
                                      <Row style={{ marginTop: '16px' }}>
                                        <Col span={12}>
                                          <div className="label">Account</div>
                                          <div className="value">{replace(capitalize(values.wallet), '_', ' ')}</div>
                                        </Col>
                                        <Col span={12}>
                                          <div className="label">Amount</div>
                                          <div className="value">{moneyFormat(values?.amount, 2)}</div>
                                        </Col>
                                      </Row>

                                      <Row style={{ marginTop: '16px' }}>
                                        <Col span={12}>
                                          <div className="label">Funding Source</div>
                                          <div className="value">
                                            {values.howToFund === 'ACH'
                                              ? filter(BankAccounts?.data?.data, {
                                                  id: values?.account_id,
                                                })[0]?.bank_name || ''
                                              : ''}
                                            {values.howToFund === 'card'
                                              ? filter(TokenizedCards?.data, {
                                                  id: values?.account_id,
                                                })[0]?.card_brand || ''
                                              : ''}
                                          </div>
                                        </Col>
                                        <Col span={12}>
                                          <div className="label">Transaction type</div>
                                          <div className="value">{replace(upperCase(values.howToFund), '_', ' ')}</div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              <Button type="secondary" size="large" onClick={() => setStepNumber(2)}>
                                Go Back
                              </Button>
                              <SubmitButton
                                loading={isSubmitting}
                                type="primary"
                                htmlType="submit"
                                onClick={handleSubmit}
                                size="large"
                                style={{ marginLeft: '16px' }}
                              >
                                Confirm transfer
                              </SubmitButton>
                            </Col>
                          </Row>
                        </Col>
                      ) : null}
                    </Row>
                    {/* <pre
                      style={{
                        background: '#f6f8fa',
                        fontSize: '.65rem',
                        padding: '.5rem',
                      }}
                    >
                      <FormikDebug />
                    </pre> */}
                  </Form>
                </Spin>
              );
            }}
          </Formik>
        </Col>
      </Row>
    </LayoutWarpper>
  );
};

export default FundAccounts;

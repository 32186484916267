import React from 'react';
import { Row, Col } from 'antd';

import CardsTable from 'components/CardsTable';
import LayoutWarpper from 'components/Layout';

const Cards = () => {
  return (
    <LayoutWarpper>
      <h1 className="page-title">Cards</h1>
      <Row style={{ width: '100%' }} gutter={[16, 16]}>
        <Col span={24}>
          <CardsTable />
        </Col>
      </Row>
    </LayoutWarpper>
  );
};

export default Cards;

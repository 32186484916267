import React from 'react';
import { Link } from 'react-router-dom';
import { Timeline, Spin, Card, Avatar, Row, Col, Radio, Select } from 'antd';
import { IoArrowBackCircle } from 'react-icons/io5';

import './Audiences.less';

const { Option } = Select;

const AudienceHistoryDetail = () => {
  return (
    <Spin spinning={false}>
      <Row>
        <Col span={18}>
          <div className="backBtn">
            <Link to="/audiences" style={{ display: 'flex', alignContent: 'center', marginBottom: '20px' }}>
              <IoArrowBackCircle size={22} style={{ marginRight: '13px' }} /> <span>Back</span>
            </Link>
          </div>
          <div>
            <Card style={{ padding: '24px' }}>
              <div className="timeline-subtitle">
                <span>Audience: Distressed driver car loan offer</span>
                <Radio.Group>
                  <Select defaultValue="all" style={{ width: 200 }}>
                    <Option value="all">All time</Option>
                    <Option value="month">This Month</Option>
                  </Select>
                </Radio.Group>
              </div>
              <Timeline className="audience-timeline">
                <Timeline.Item color="black">
                  <div className="index-title">
                    <span>March 17, 2023</span>
                    <span className="current-version ml-15 p-5">Current version</span>
                  </div>
                  <div className="next-container">
                    <div className="next">
                      <Avatar shape="square" className="bullet" />
                      <span>(12) Entities Added</span>
                    </div>
                    <div className="description">
                      Courtney Henry, Marvin McKinney, Jenny Wilson, Leslie Alexander, Floyd Miles, Kristin Watson,
                      Tyler Hampton, Adedeji Makinwa, Jerome Hunterman, Jessica Smith, Emile Schmidt, Alex Gunn
                    </div>
                  </div>
                  <div className="prev-container">
                    <div className="previous">
                      <Avatar shape="square" className="bullet" />
                      <span>(12) Entities Added</span>
                    </div>
                    <div className="description">Annette Black, Jerome Jenkins, Matty Bloom, Bjorn Ulsson</div>
                  </div>
                </Timeline.Item>
                <Timeline.Item color="black">
                  <span className="index-title">March 10, 2023</span>
                  <div className="next-container">
                    <div className="next">
                      <Avatar shape="square" className="bullet" />
                      <span>(12) Entities Added</span>
                    </div>
                    <div className="description">Jerome Hunterman, Jessica Smith, Emile Schmidt, Alex Gunn</div>
                  </div>
                  <div className="prev-container">
                    <div className="previous">
                      <Avatar shape="square" className="bullet" />
                      <span>(12) Entities Added</span>
                    </div>
                    <div className="description">Matty Bloom, Bjorn Ulsson</div>
                  </div>
                </Timeline.Item>
                <Timeline.Item color="black">
                  <span className="index-title">March 9, 2023</span>
                  <div className="next-container">
                    <div className="next">
                      <Avatar shape="square" className="bullet" />
                      <span>(12) Entities Added</span>
                    </div>
                    <div className="description">Jenny Wilson, Leslie Alexander</div>
                  </div>
                  <div className="prev-container">
                    <div className="previous">
                      <Avatar shape="square" className="bullet" />
                      <span>(12) Entities Added</span>
                    </div>
                    <div className="description">Marthe Gill</div>
                  </div>
                </Timeline.Item>
              </Timeline>
            </Card>
          </div>
        </Col>
      </Row>
    </Spin>
  );
};

export default AudienceHistoryDetail;

import React from 'react';
import { Row, Col, Spin } from 'antd';

import { useGetUser } from 'rc-query/userHooks';
import { getAdmin, getBackoffice } from 'helpers/localStorage';

import TransactionStatistics from './TransactionStatistics';
import UserGeneralInformation from './UserGeneralInformation';
import BusinessInformation from './BusinessInformation';
import AccountSummary from './AccountSummary';
import KysyncDocuments from './KysyncDocuments';

import './EntityDetail.less';

const EntityDetail = ({ entityId, entityType, setUserFullName }) => {
  const adminUser = getAdmin();
  const backOffice = getBackoffice();
  const apiKey = backOffice?.data?.name;
  const adminToken = adminUser?.token;

  const body = {
    api_key: apiKey,
    token: adminToken,
    entity_type: entityType === 'individual' ? 'user' : 'business',
  };

  if (entityType === 'business') {
    body.business_id = entityId;
  } else {
    body.user_id = entityId;
  }

  const { isLoading, data } = useGetUser({
    ...body,
  });

  const entityInfo = entityType === 'individual' ? data?.data?.user : data?.data.business;
  const userSummary = data?.data?.summary || {
    transactions: 0,
    messages: 0,
    bank_accounts: 0,
    cards: 0,
    issued_accounts: 0,
    issued_cards: 0,
  };

  const userTransactions = data?.data?.transactions || { balance: 0, deposits: 0, withdrawals: 0 };
  const kycData = data?.data?.kyc_data || [];

  if (entityInfo?.id && entityType === 'individual') {
    setUserFullName(`- ${entityInfo?.first_name} ${entityInfo?.last_name}`);
  } else {
    setUserFullName(`- ${entityInfo?.name}`);
  }

  return (
    <Spin spinning={isLoading}>
      <div className="user-box">
        <Row>
          <Col className="user-box-1" span={15}>
            <div className="site-statistic-demo-card">
              <TransactionStatistics userTransactions={userTransactions} />
            </div>

            {entityType === 'individual' ? (
              <UserGeneralInformation userInfo={entityInfo} />
            ) : (
              <BusinessInformation businessInfo={entityInfo} />
            )}

            <KysyncDocuments kyc_data={kycData} />
          </Col>
          <Col className="user-account" span={8}>
            <AccountSummary
              entityId={entityId}
              entityType={entityType}
              summary={userSummary}
              entityStatus={entityInfo?.status}
            />
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default EntityDetail;

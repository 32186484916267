import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Row, Divider, Modal, Button, Spin, notification } from 'antd';
import { Formik } from 'formik';
import { Checkbox, Form, FormItem, SubmitButton } from 'formik-antd';

import { EyeFilled } from '@ant-design/icons';

import LayoutWarpper from 'components/Layout';
import { queryClient } from 'rc-query';
import { useCustomSettings, useGetKySyncHits, useSaveCustomSettings } from 'rc-query/kysyncHooks';
import { GET_KYSYNC_CUSTOM_SETTINGS_QUERY } from 'rc-query/keys';
import { getAdmin, getBackoffice } from 'helpers/localStorage';
import { numberFormat } from 'helpers/utils';

import './KySync.less';

const KySync = () => {
  const { Meta } = Card;
  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const customSettingOptions = [
    { value: 'email_verification', label: 'Email verification' },
    { value: 'sms_verification', label: 'SMS verification' },
    { value: 'document_verification', label: 'Document verification' },
    { value: 'liveliness_verification', label: 'Liveness verification' },
    { value: 'address_verification', label: 'Address verification' },
  ];

  const [showCustomSettingBox, setShowCustomSettingBox] = useState(false);

  const { isLoading, data } = useCustomSettings({ api_key: backOffice?.data?.name, token: adminUser?.token });
  const KySyncHits = useGetKySyncHits({ api_key: backOffice?.data?.name, token: adminUser?.token });
  const saveCustomSettings = useSaveCustomSettings();

  const customSettingsInitialValues = {
    email_verification: data?.email_verification === 'true' ? true : false || false,
    sms_verification: data?.sms_verification === 'true' ? true : false || false,
    document_verification: data?.document_verification === 'true' ? true : false || false,
    liveliness_verification: data?.liveliness_verification === 'true' ? true : false || false,
    address_verification: data?.address_verification === 'true' ? true : false || false,
  };

  const handleShowCustomSettingBox = () => {
    setShowCustomSettingBox(true);
  };

  const handleSaveCustomSettings = (values, formikHelpers) => {
    formikHelpers.validateForm();

    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      email_verification: `${values.email_verification || false}`,
      sms_verification: `${values.sms_verification || false}`,
      document_verification: `${values.document_verification || false}`,
      liveliness_verification: `${values.liveliness_verification || false}`,
      address_verification: `${values.address_verification || false}`,
    };

    saveCustomSettings
      .mutateAsync(body)
      .then((data) => {
        if (data.status === 'success') {
          notification.success({
            message: data.message,
          });
          // reload listing and redirect
          queryClient.refetchQueries([GET_KYSYNC_CUSTOM_SETTINGS_QUERY]);
          setShowCustomSettingBox(false);
        } else {
          notification.error({
            message: data.message,
          });
        }
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  const onCancelBox = () => {
    setShowCustomSettingBox(false);
  };

  const onCustomSettingChange = (e, formProps) => {
    formProps.setFieldValue('all_settings', false);
  };

  const onCheckAllCustomSettingChange = (e, formProps) => {
    customSettingOptions.map((option) => {
      formProps.setFieldValue(option.value, e.target.checked);
    });
  };

  return (
    <LayoutWarpper>
      <div className="KySyncPage">
        <h1 className="page-title">KySync</h1>
        <h5 className="description">Welcome, pick a service to get started with or go to custom settings.</h5>
        <h5 className="description">
          Before you begin verifying, please purchase some credits by clicking on the purchase credits button.
        </h5>
        <div style={{ display: 'flex', alignItems: 'baseline', marginTop: '16px' }}>
          <h3 style={{ marginRight: '16px' }}>
            Credit Balance: {numberFormat(KySyncHits?.data || 0)} Hits
          </h3>
          <Link to={`/kysync_purchase_credits`}>
            <Button type="primary">Purchase Credit</Button>
          </Link>
        </div>

        <Row gutter={[24, 24]} style={{ marginTop: '60px' }}>
          <Col span={16}>
            <Row gutter={[24, 24]}>
              <Col span={12}>
                {/* KYC */}
                <Card style={{ width: 400 }}>
                  <Link to={`/kysync_kyc`}>
                    <Meta
                      avatar={<img src="/images/icons/kysync_kyc.svg" alt="KYC Verification" />}
                      title="KYC Verification"
                      description="Know Your Customers: Identity verification for individuals. Upload a bulk list or try adding names to get started."
                    />
                  </Link>
                  <Divider />
                  <div className="card-footer" style={{ width: 'auto' }}>
                    <Link to={`/kysync_kyc_results`}>
                      <EyeFilled /> View verification results
                    </Link>
                  </div>
                </Card>
              </Col>
              <Col span={12}>
                {/* KYB */}
                <Card style={{ width: 400 }}>
                  <Link to={`/kysync_kyb`}>
                    <Meta
                      avatar={<img src="/images/icons/kysync_kyb.svg" alt="KYB Verification" />}
                      title="KYB Verification"
                      description="Know Your Business: Identity verification for businesses. Upload a bulk list or try adding businesses to get started."
                    />
                  </Link>
                  <Divider />
                  <div className="card-footer" style={{ width: 'auto' }}>
                    <Link to={`/kysync_kyb_results`}>
                      <EyeFilled /> View verification results
                    </Link>
                  </div>
                </Card>
              </Col>
            </Row>

            <Row gutter={[24, 24]} style={{ marginTop: '20px' }}>
              <Col span={12}>
                {/* Purchase Credits */}
                <Link to={`/kysync_purchase_credits`}>
                  <Card style={{ width: 400 }}>
                    <Meta
                      className="gap"
                      avatar={<img src="/images/icons/kysync_purchase.svg" alt="purchase credits" />}
                      title="Purchase Credits"
                      description="Instantly buy KYC / KYB credits for your individual or businesses verifications."
                    />

                    <Divider />
                    <div className="card-footer2">
                      <Spin spinning={KySyncHits.isLoading || false}>
                        <div>
                          Credit balance: {numberFormat(KySyncHits?.data || 0)} Hits
                        </div>
                      </Spin>
                    </div>
                  </Card>
                </Link>
              </Col>

              <Col span={12}>
                {/* Custom Settings */}
                <Card style={{ width: 400, cursor: 'pointer' }} onClick={handleShowCustomSettingBox}>
                  <Meta
                    avatar={<img src="/images/icons/kysync_custom.svg" alt="Custom Settings" />}
                    title="Custom Settings"
                    description="Customize apperance and options for your KySync application by adding a custom logo and selecting a brand color."
                  />
                  <Divider />
                  <div className="card-footer2">
                    <div>Active: Liveness, Document</div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Modal width={450} title="Custom Settings" visible={showCustomSettingBox} onCancel={onCancelBox} footer={false}>
        <Spin spinning={isLoading}>
          <Formik initialValues={customSettingsInitialValues} onSubmit={handleSaveCustomSettings}>
            {(formProps) => {
              const { handleSubmit } = formProps;
              return (
                <Spin spinning={saveCustomSettings.isLoading}>
                  <Form size="large" layout="vertical" initialValues={customSettingsInitialValues}>
                    <Row>
                      <Col span={24}>
                        <p className="descriptionText">
                          Select KySync functionalities you will like your entities to carry out.
                        </p>
                      </Col>
                    </Row>
                    <FormItem name="all_settings">
                      <Checkbox name="all_settings" onChange={(e) => onCheckAllCustomSettingChange(e, formProps)}>
                        All
                      </Checkbox>
                    </FormItem>
                    <Row gutter={(24, 24)}>
                      {customSettingOptions.map((setting) => (
                        <Col span={24} key={setting.value}>
                          <FormItem name={setting.value}>
                            <Checkbox name={setting.value} onChange={(e) => onCustomSettingChange(e, formProps)}>
                              {setting.label}
                            </Checkbox>
                          </FormItem>
                        </Col>
                      ))}
                    </Row>

                    <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
                      <Col span={24}>
                        <SubmitButton
                          className="actionBtn"
                          type="primary"
                          size="large"
                          style={{ width: '100%' }}
                          onClick={handleSubmit}
                        >
                          Save
                        </SubmitButton>
                      </Col>
                      <Col span={24}>
                        <Button
                          className="actionBtn"
                          type="default"
                          size="large"
                          style={{ width: '100%' }}
                          onClick={onCancelBox}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                    {/* <pre
                      style={{
                        background: '#f6f8fa',
                        fontSize: '.65rem',
                        padding: '.5rem',
                      }}
                    >
                      <FormikDebug />
                    </pre> */}
                  </Form>
                </Spin>
              );
            }}
          </Formik>
        </Spin>
      </Modal>
    </LayoutWarpper>
  );
};

export default KySync;

import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Row, Col, Alert, notification } from 'antd';
import { ArrowLeftOutlined, EyeTwoTone, KeyOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import { useChangePasswordMutation } from 'rc-query/authHooks';

import {
  getAdmin,
  getBackoffice,
  // getStageToken,
  getVerifyEmail,
  removeVerifyEmail,
  // setAdmin,
} from 'helpers/localStorage';

import './ChangePassword.less';

const UpdatePassword = () => {
  const history = useHistory();
  const changePasswordMutation = useChangePasswordMutation();

  const adminUser = getAdmin();
  const backOffice = getBackoffice();
  const verifyEmail = getVerifyEmail();

  if (adminUser?.status === 'active') {
    history.push('/dashboard');
  }

  const onFinish = async (values) => {
    changePasswordMutation
      .mutateAsync({ ...values, email: verifyEmail, api_key: backOffice?.data?.name })
      .then((data) => {
        removeVerifyEmail();
        if (data.status === 'error') {
          notification.error({
            message: data.message,
          });
        } else if (data.status === 'success') {
          notification.success({
            message: data.message,
          });
          // setAdmin(data.data);
          history.push('/login');
        }
      });
  };

  return (
    <>
      <div className="main-container">
        <Row justify="center" align="middle">
          <Col>
            <Row
              type="flex"
              align="middle"
              justify="center"
              className="px-3 bg-white mh-page"
              style={{ minHeight: '100vh' }}
            >
              <div
                style={{
                  maxWidth: '500px',
                  zIndex: 2,
                  minWidth: '300px',
                  backgroundColor: 'white',
                  padding: '2rem',
                  boxShadow: '2px 3px 5px 0px rgba(38,7,4,0.39)',
                }}
              >
                <div className="mb-5">
                  <Row>
                    <Col>
                      <img
                        className="gnp_logo"
                        src={backOffice?.data?.logo ? backOffice.data.logo : 'https://assets.deposits.inc/img/logo/deposits/png/logo_main.png'}
                        alt="Backoffice"
                      />
                    </Col>
                  </Row>

                  <h2 className="mb-0 mt-3">Change Password</h2>
                </div>
                {changePasswordMutation?.data?.status === 'warning' ? (
                  <Alert type="error" message={changePasswordMutation?.data?.message} showIcon closable />
                ) : null}

                <Form layout="vertical" onFinish={onFinish}>
                  <Form.Item
                    label="Enter Passcode"
                    name="stage_token"
                    rules={[
                      {
                        required: true,
                        message: 'Please input passcode!',
                      },
                    ]}
                  >
                    <Input prefix={<KeyOutlined style={{ fontSize: '16px' }} />} type="text" placeholder="Passcode" />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input new password!',
                      },
                    ]}
                  >
                    <Input.Password
                      prefix={<EyeTwoTone style={{ fontSize: '16px' }} />}
                      type="password"
                      placeholder="Password"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Confirm Password"
                    name="password_confirmation"
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm password!',
                      },
                    ]}
                  >
                    <Input.Password
                      prefix={<EyeTwoTone style={{ fontSize: '16px' }} />}
                      type="password"
                      placeholder="Confirm Password"
                    />
                  </Form.Item>

                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    style={{ marginTop: '1rem' }}
                    loading={changePasswordMutation.isLoading}
                  >
                    Change Password
                  </Button>
                </Form>
                <div className="mt-15">
                  <Link to="/login">
                    <ArrowLeftOutlined /> {'  '}Back
                  </Link>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UpdatePassword;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Pagination, Spin, Tag, Modal, Button, Row, Col, Dropdown, Menu } from 'antd';
import { IoArrowBackCircle } from 'react-icons/io5';
import { EllipsisOutlined } from '@ant-design/icons';
import moment from 'moment';
import { queryClient } from 'rc-query';

import { useUserTransactions } from 'rc-query/userHooks';
import { useReverseTransactionMutation } from 'rc-query/transactionHooks';
import { getAdmin, getBackoffice } from 'helpers/localStorage';
import { moneyFormat, renderTransactionStatus } from 'helpers/utils';
import TableColumnTextFilterConfig from '../tableColumnTextFilter/TableUtils';
import { DEFAULT_PAGE_SIZE } from 'constants/config';
import { GET_ALL_TRANSACTIONS_QY } from 'rc-query/keys';

const DataTable = ({ userId, entityType }) => {
  const [showTransactionDetail, setShowTransactionDetail] = useState(false);
  const [showReverseTransaction, setShowReverseTransaction] = useState(false);
  const [transactionDetail, setTransactionDetail] = useState({
    transaction_id: '',
    beneficiary: '',
    category: '',
    amount: '',
    created_at: '',
    status: '',
  });

  const [filters, setFilters] = useState({
    page: 1,
    per_page: DEFAULT_PAGE_SIZE,
  });

  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const reverseTransactionMutation = useReverseTransactionMutation();

  const { isLoading, data } = useUserTransactions({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
    user_id: userId,
    ...filters,
  });

  const userTransactions = data?.data?.data || [];
  const totalTransactions = data?.data?.total || 0;
  const currentPage = data?.data?.current_page || 0;

  const handlePageChange = (page) => {
    setFilters({ ...filters, page });
  };

  const handleTableChange = (pagination, tableFilters, sorter) => {
    const email = tableFilters?.email?.[0]?.toString() || undefined;
    const transaction_id = tableFilters?.transaction_id?.[0]?.toString() || undefined;

    setFilters({
      email,
      transaction_id,
      page: 1,
      per_page: DEFAULT_PAGE_SIZE,
      sort_by_column: sorter.field,
      sort_by_type: sorter.order === 'ascend' ? 'ASC' : 'DESC',
    });
  };

  const handleViewTransaction = (transaction) => {
    setTransactionDetail(transaction);
    setShowTransactionDetail(true);
  };

  const handleShowReverseTransaction = (transaction) => {
    setTransactionDetail(transaction);
    setShowTransactionDetail(true);
    setShowReverseTransaction(true);
  };

  const handleReverseTransaction = (transaction_id) => {
    reverseTransactionMutation
      .mutateAsync({
        api_key: backOffice?.data?.name,
        token: adminUser?.token,
        transaction_id,
      })
      .then(() => {
        queryClient.refetchQueries([GET_ALL_TRANSACTIONS_QY]);
        setShowTransactionDetail(false);
        setShowReverseTransaction(false);
      });
  };

  const handleCancelBox = () => {
    setShowTransactionDetail(false);
    setShowReverseTransaction(false);
    setTransactionDetail({});
  };

  const columns = [
    {
      title: 'Transaction ID',
      dataIndex: 'transaction_id',
      key: 'transaction_id',
      sorter: true,
      ...TableColumnTextFilterConfig(),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      ...TableColumnTextFilterConfig(),
    },
    {
      title: 'Value',
      dataIndex: 'amount',
      key: 'amount',
      sorter: true,
      render: (text, record) => <span>{moneyFormat(record.amount, 2)}</span>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: true,
      render: (text, record) => <span className="capitalize">{record.type}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text, record) => <span>{renderTransactionStatus(record.status, Tag)}</span>,
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (text, record) => moment(record.created_at).format('MMM DD, YYYY'),
    },
    {
      title: 'Action',
      key: 'id',
      dataIndex: 'id',
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu key={`menu-${record.id}`}>
              <Menu.Item key={`view-${record.id}`} onClick={() => handleViewTransaction(record)}>
                View
              </Menu.Item>
              <Menu.Item key={`reverse-${record.id}`} onClick={() => handleShowReverseTransaction(record)}>
                Reverse
              </Menu.Item>
            </Menu>
          }
        >
          <a className="ant-dropdown-link">
            <EllipsisOutlined />
          </a>
        </Dropdown>
      ),
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <div className="backBtn">
        <Link
          to={`/entity/${entityType}/${userId}`}
          style={{ display: 'flex', alignContent: 'center', marginBottom: '20px' }}
        >
          <IoArrowBackCircle size={22} style={{ marginRight: '13px' }} /> <span>Back</span>
        </Link>
      </div>

      <div>
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={userTransactions}
          onChange={handleTableChange}
          pagination={false}
        />
        {totalTransactions > 0 ? (
          <div className="pagination">
            <Pagination
              showLessItems
              defaultPageSize={DEFAULT_PAGE_SIZE}
              total={totalTransactions}
              showSizeChanger={false}
              current={currentPage}
              onChange={handlePageChange}
            />
          </div>
        ) : null}
      </div>

      <Modal
        width={600}
        title="Transaction Detail"
        visible={showTransactionDetail}
        onCancel={handleCancelBox}
        footer={false}
        className="transactionDetailModal"
      >
        <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
          <Col span={12}>
            <label>Transaction ID</label>
            <p>{transactionDetail?.transaction_id}</p>
          </Col>
          <Col span={12}>
            <label>Beneficiary</label>
            <p>{transactionDetail?.email}</p>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
          <Col span={12}>
            <label>Type</label>
            <p>{transactionDetail?.type}</p>
          </Col>
          <Col span={12}>
            <label>Amount</label>
            <p>{moneyFormat(transactionDetail?.amount, 2)}</p>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
          <Col span={12}>
            <label>Category</label>
            <p>{transactionDetail?.category}</p>
          </Col>
          <Col span={12}>
            <label>Description</label>
            <p>{transactionDetail?.description}</p>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
          <Col span={12}>
            <label>Created Date</label>
            <p>{moment(transactionDetail?.created_at).format('MMM DD, YYYY')}</p>
          </Col>
          <Col span={12}>
            <label>Status</label>
            <p>{transactionDetail?.status}</p>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
          <Col span={24}>
            {showReverseTransaction ? (
              <Button
                className="actionBtn"
                type="primary"
                size="large"
                style={{ width: '100%' }}
                onClick={() => handleReverseTransaction(transactionDetail.transaction_id)}
              >
                Reverse transaction
              </Button>
            ) : (
              <Button
                className="actionBtn blueBorderd"
                type="secondary"
                size="large"
                style={{ width: '100%' }}
                onClick={handleCancelBox}
              >
                Close
              </Button>
            )}
          </Col>
        </Row>
      </Modal>
    </Spin>
  );
};

export default DataTable;

import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'antd';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { useFormikContext } from 'formik';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const WysiwygEditor = ({ alreadySaveTemplate }) => {
  const { setFieldValue } = useFormikContext();

  const [editorState, setEditorState] = useState(undefined);

  useEffect(() => {
    if (alreadySaveTemplate) {
      const blocksFromHtml = htmlToDraft(alreadySaveTemplate);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editorContent = EditorState.createWithContent(contentState);
      setEditorState(editorContent);
    } else {
      setEditorState(EditorState.createEmpty());
    }
    return () => {
      setEditorState(EditorState.createEmpty());
    };
  }, [alreadySaveTemplate]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    setFieldValue('message', markup === '<p></p>\n' ? '' : markup);
  };

  //   const imageUploadCallBack = (file) =>
  //     new Promise(async (resolve, reject) => {
  //       try {
  //         const fileName = `${moment().valueOf()}-${file.name}`;
  //         const response = await pharmacyApi.uploadLogo({ fileName });
  //         const { url, fields } = response.data.data;
  //         const formData = new FormData();
  //         const data = {
  //           ...fields,
  //           'Content-Type': file.type,
  //           file: file,
  //         };
  //         for (const name in data) {
  //           formData.append(name, data[name]);
  //         }
  //         fetch(url, {
  //           method: 'POST',
  //           body: formData,
  //         })
  //           .then((response) => {
  //             resolve({ data: { link: `${url}/${fields.key}` } });
  //           })
  //           .catch((e) => {
  //             throw e;
  //           });
  //       } catch (err) {
  //         reject(err);
  //       } finally {
  //         setLoading(false);
  //       }
  //     });

  return (
    <div className="gutter-example button-demo">
      <Row gutter={16}>
        <Col className="gutter-row" md={24}>
          <div className="gutter-box">
            <Card>
              <Editor
                editorState={editorState}
                toolbarClassName="home-toolbar"
                wrapperClassName="home-wrapper"
                editorClassName="home-editor"
                toolbarStyle={{ border: 'none', borderBottom: '1px solid #F1F1F1' }}
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                  history: { inDropdown: true },
                  inline: { inDropdown: false },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  // image: { uploadCallback: imageUploadCallBack },
                }}
                // onContentStateChange={onEditorChange}
                placeholder="Enter Email Content here"
                spellCheck
                onFocus={() => {
                  // console.log('focus');
                }}
                onBlur={() => {
                  // console.log('blur');
                }}
                onTab={() => {
                  // console.log('tab');
                  return true;
                }}
              />
              <style>{`.home-editor { min-height: 300px; }`}</style>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default WysiwygEditor;

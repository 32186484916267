import axios from 'axios';

import { API_BASE_URL } from '@Constants/config';
import { apiWrapper } from './interceptors';
// const host = window.location.origin;

const request = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30 * 1000,
});

const productsApi = {
  getProducts: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/products', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getProduct: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('admin/products/show', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  saveProduct: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/products/create', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  uploadProductLogo: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/files/upload', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },
};

export default apiWrapper(productsApi);

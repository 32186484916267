import React from 'react';
import { Row, Col, Card, Statistic } from 'antd';

import { moneyFormat} from 'helpers/utils';

import Users from '../../assets/users.png';
import Deposits from '../../assets/deposits.png';
import Payout from '../../assets/payouts.png';

import './EntityDetail.less';

const TransactionStatistics = ({ userTransactions }) => {
  const { balance, deposits, withdrawals } = userTransactions;

  return (
    <Row gutter={16}>
      <Col span={8}>
        <Card>
          <Statistic
            title="Balance"
            value={moneyFormat(balance || 0, 2)}
            precision={0}
            valueStyle={{ color: 'rgba(87, 202, 235, 1)' }}
            prefix={
              <div className="icon-box" style={{ backgroundColor: 'rgba(87, 202, 235, 0.2)' }}>
                <img src={Users} />
              </div>
            }
          />
        </Card>
      </Col>
      <Col span={8}>
        <Card>
          <Statistic
            title="Deposits"
            value={moneyFormat(deposits || 0, 2)}
            precision={0}
            valueStyle={{ color: 'rgba(93, 218, 180, 1)' }}
            prefix={
              <div className="icon-box" style={{ backgroundColor: 'rgba(93, 218, 180, 0.2)' }}>
                <img src={Deposits} />
              </div>
            }
          />
        </Card>
      </Col>
      <Col span={8}>
        <Card>
          <Statistic
            title="Withdrawals"
            value={moneyFormat(withdrawals || 0, 2)}
            precision={0}
            valueStyle={{ color: 'rgba(255, 121, 118, 1)' }}
            prefix={
              <div className="icon-box" style={{ backgroundColor: 'rgba(255, 121, 118, 0.2)' }}>
                <img src={Payout} />
              </div>
            }
          />
        </Card>
      </Col>
    </Row>
  );
};

export default TransactionStatistics;

import React from 'react';
import { Row, Col } from 'antd';

import MessageTable from 'components/MessageTable';
import LayoutWarpper from 'components/Layout';

import './UserMessages.less';

const UserMessages = (props) => {
  const userId = props.match.params.userId;
  const entityType = props.match.params.entityType;

  return (
    <LayoutWarpper>
      <h1 className="page-title">User Messages</h1>
      <Row style={{ width: '100%' }} gutter={[16, 16]}>
        <Col span={24}>
          <MessageTable userId={userId} entityType={entityType} />
        </Col>
      </Row>
    </LayoutWarpper>
  );
};

export default UserMessages;

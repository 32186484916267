import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Dashboard from 'pages/Dashboard';
import BackOfficeVerify from 'pages/BackOfficeVerify';
import Login from 'pages/Login';
import ResetPassword from 'pages/ResetPassword';
import ChangePassword from 'pages/ChangePassword';
import VerifyPasscode from 'pages/VerifyPasscode';
import Enable2FA from 'pages/Enable2FA';
import MessageList from 'pages/MessageList';
import MessageListNew from 'pages/MessageListNew';
import AudiencesList from 'pages/AudiencesList';
import AudienceHistory from 'pages/AudiencesList/AudienceHistory';
import UserTransaction from 'pages/UserTransactions';
import UserBankAccounts from 'pages/UserBankAccounts';
import UserMessages from 'pages/UserMessages';
import UserCards from 'pages/UserCards';
import Transactions from 'pages/Transactions';
import Payouts from 'pages/Payouts';
import SendMoney from 'pages/SendMoney';
import BankAccounts from 'pages/BankAccounts';
import IssuedBankAccounts from 'pages/BankAccounts/IssuedBankAccounts';
import Cards from 'pages/Cards';
import IssuedCards from 'pages/Cards/IssuedCards';
import Settings from 'pages/Settings';
import Entities from 'pages/Entities';
import Entity from 'pages/Entity';
import Products from 'pages/Products';
import SelectProductType from 'pages/Products/SelectProductType';
import ConfigureProduct from 'pages/Products/ConfigureProduct';
import ActivityLog from 'pages/ActivityLog';
import FundAccounts from 'pages/FundAccounts';
import WithdrawFunds from 'pages/WithdrawFunds';
import LinkFunding from 'pages/LinkFunding';
import LinkedAccounts from 'pages/LinkedAccounts';
import KySync from 'pages/KySync';
import KySync_KYC from 'pages/KySync/KySync_KYC';
import KySync_KYB from 'pages/KySync/KySync_KYB';
import KySyncKYCResults from 'pages/KySync/KySyncKYCResults';
import KySyncKYBResults from 'pages/KySync/KySyncKYBResults';
import KySync_PurchaseCredits from 'pages/KySync/KySync_PurchaseCredits';
import AddContactsManuallyKYB from 'pages/KySync/AddContactsManuallyKYB';
import AddContactsManuallyKYC from 'pages/KySync/AddContactsManuallyKYC';
import KySyncBulkUploadPreview from 'pages/KySync/KySyncBulkUploadPreview';

import history from 'helpers/history';
import PrivateRoute from './privateRoute';

const RouterComp = () => (
  <Router history={history}>
    <Switch>
      <Route path="/" component={BackOfficeVerify} exact />
      <Route path="/login" component={Login} exact />
      <Route path="/reset_password" component={ResetPassword} exact />
      <Route path="/verify_passcode" component={VerifyPasscode} exact />
      <Route path="/change_password" component={ChangePassword} exact />
      <Route path="/enable_2fa" component={Enable2FA} exact />
      <PrivateRoute path="/dashboard" component={Dashboard} exact />
      <PrivateRoute path="/user_transactions/:entityType/:userId" component={UserTransaction} exact />
      <PrivateRoute path="/user_messages/:entityType/:userId" component={UserMessages} exact />
      <PrivateRoute path="/user_bank_accounts/:entityType/:userId" component={UserBankAccounts} exact />
      <PrivateRoute path="/user_cards/:entityType/:userId" component={UserCards} exact />
      <PrivateRoute path="/transactions" component={Transactions} exact />
      <PrivateRoute path="/payouts" component={Payouts} exact />
      <PrivateRoute path="/payouts/send_money" component={SendMoney} exact />
      <PrivateRoute path="/bank_accounts" component={BankAccounts} exact />
      <PrivateRoute path="/issued_bank_accounts" component={IssuedBankAccounts} exact />
      <PrivateRoute path="/cards" component={Cards} exact />
      <PrivateRoute path="/issued_cards" component={IssuedCards} exact />
      <PrivateRoute path="/message_list" component={MessageList} exact />
      <PrivateRoute path="/message_list_new" component={MessageListNew} exact />
      <PrivateRoute path="/audiences" component={AudiencesList} exact />
      <PrivateRoute path="/audience_history" component={AudienceHistory} exact />
      <PrivateRoute path="/settings" component={Settings} exact />
      <PrivateRoute path="/entities" component={Entities} exact />
      <PrivateRoute path="/products/:productType" component={Products} exact />
      <PrivateRoute path="/products" component={Products} exact />
      <PrivateRoute path="/select_product_type" component={SelectProductType} exact />
      <PrivateRoute path="/configure_product/:productType" component={ConfigureProduct} exact />
      <PrivateRoute path="/activity_log" component={ActivityLog} exact />
      <PrivateRoute path="/activity_log/:entityType/:userId" component={ActivityLog} exact />
      <PrivateRoute path="/entity/:entityType/:entityId" component={Entity} exact />
      <PrivateRoute path="/fund_accounts" component={FundAccounts} exact />
      <PrivateRoute path="/withdraw_funds" component={WithdrawFunds} exact />
      {/* <PrivateRoute path="/link_funding/:step/:linkFundingType" component={LinkFunding} exact /> */}
      <PrivateRoute path="/link_funding" component={LinkFunding} exact />
      <PrivateRoute path="/linked_accounts" component={LinkedAccounts} exact />
      <PrivateRoute path="/kysync_kyc" component={KySync_KYC} exact />
      <PrivateRoute path="/kysync" component={KySync} exact />
      <PrivateRoute path="/kysync_kyb" component={KySync_KYB} exact />
      <PrivateRoute path="/kysync_kyc_results" component={KySyncKYCResults} exact />
      <PrivateRoute path="/kysync_kyb_results" component={KySyncKYBResults} exact />
      <PrivateRoute path="/kysync_purchase_credits" component={KySync_PurchaseCredits} exact />
      <PrivateRoute path="/add_contacts_manually_kyb" component={AddContactsManuallyKYB} exact />
      <PrivateRoute path="/add_contacts_manually_kyc" component={AddContactsManuallyKYC} exact />
      <PrivateRoute path="/kysync_bulk_upload_preview/:kysyncType" component={KySyncBulkUploadPreview} exact />

      <Route path="/tenant/:backoffice" component={BackOfficeVerify} />
      <Route path="/:backoffice" component={BackOfficeVerify} />
    </Switch>
  </Router>
);

export default RouterComp;

import React, { useState } from 'react';
import { Row, Col, Button, Spin, notification } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { IoArrowBackCircle } from 'react-icons/io5';
import { ArrowRightOutlined } from '@ant-design/icons';
import { ErrorMessage, Formik } from 'formik';
import { Form, InputNumber, Radio, Select, SubmitButton } from 'formik-antd';
import { filter } from 'lodash';
import * as yup from 'yup';

import { getAdmin, getBackoffice } from 'helpers/localStorage';
import { useKySyncFundingSources } from 'rc-query/kysyncHooks';
import { useBankAccounts, useMasterWalletInfo, useWithdrawFund } from 'rc-query/masterWalletHooks';
import { moneyFormat } from 'helpers/utils';
import { queryClient } from 'rc-query';
import { GET_MASTER_WALLET_INFO, GET_WALLET_TRANSACTIONS } from 'rc-query/keys';

import WithdrawFundsSteps from './WithdrawFundsSteps';
import LayoutWarpper from 'components/Layout';

import './WithdrawFunds.less';

const { Option } = Select;

const WithdrawFunds = () => {
  const history = useHistory();
  const [stepNumber, setStepNumber] = useState(0);

  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const MasterWalletInfo = useMasterWalletInfo({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
  });

  const KySyncFundingSources = useKySyncFundingSources({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
  });

  const BankAccounts = useBankAccounts({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
  });

  const withdrawFund = useWithdrawFund();

  const goBack = () => {
    history.push('/settings#master_wallet');
  };

  const continueSubmit = (formProps, stepNumber) => {
    formProps.setTouched({
      wallet: true,
      amount: true,
      account_id: true,
    });

    formProps.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        setStepNumber(stepNumber);
      }
    });
  };

  const handleSubmit = (values, formikHelpers) => {
    formikHelpers.validateForm();

    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      ...values,
      account_id: values.wallet !== 'funding_account' ? `${values.account_id}` : undefined,
      amount: `${values.amount}`,
      withdraw_from: 'earning_account',
    };

    withdrawFund
      .mutateAsync(body)
      .then((data) => {
        if (data.status === 'success') {
          notification.success({
            message: data.message,
          });
          queryClient.refetchQueries([GET_WALLET_TRANSACTIONS]);
          queryClient.refetchQueries([GET_MASTER_WALLET_INFO]);
          history.push('/settings#master_wallet');
        } else {
          notification.error({
            message: data.message,
          });
        }
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  return (
    <LayoutWarpper>
      <h1 className="page-title">Master Wallet</h1>
      <Row style={{ width: '100%' }} gutter={[16, 16]} className="withdrawFundForm">
        <Col span={24}>
          <Formik
            enableReinitialize
            initialValues={{
              amount: 0,
              wallet: 'funding_account',
            }}
            validationSchema={yup.object().shape({
              amount: yup
                .number()
                .moreThan(0, 'Amount must be greater than 0')
                .nullable(true)
                .required('Amount is required'),
            })}
            onSubmit={handleSubmit}
          >
            {(formProps) => {
              const { isSubmitting, handleSubmit, values, setFieldValue } = formProps;
              return (
                <Spin spinning={isSubmitting}>
                  <Form
                    initialValues={{
                      amount: 0,
                      wallet: 'funding_account',
                    }}
                    size="large"
                    layout="vertical"
                  >
                    <div className="backBtn">
                      <Link to="/settings#master_wallet" style={{ display: 'flex', alignContent: 'center' }}>
                        <IoArrowBackCircle size={22} style={{ marginRight: '13px' }} /> <span>Back</span>
                      </Link>
                    </div>
                    <Row gutter={[16, 8]} style={{ marginTop: '40px' }}>
                      <Col span={6}>
                        <WithdrawFundsSteps stepNumber={stepNumber} />
                      </Col>
                      {stepNumber === 0 ? (
                        <Col span={10}>
                          <Row gutter={[16, 8]}>
                            <Col span={24}>
                              <p>Input amount and select funding source.</p>
                              <Row gutter={[16, 8]}>
                                <Col span={24}>
                                  <InputNumber
                                    name="amount"
                                    placeholder="0.00"
                                    style={{ width: '100%' }}
                                    min={0}
                                    prefix="$"
                                    size="medium"
                                    precision={2}
                                    controls={false}
                                  />
                                  <ErrorMessage name="amount" component="div" className="color-danger" />
                                  <Spin spinning={MasterWalletInfo?.isLoading}>
                                    <div
                                      className="withdrawAll"
                                      style={{ marginTop: '5px', display: 'flex', justifyContent: 'space-between' }}
                                    >
                                      <div className="balance">
                                        Available balance(Earning):{' '}
                                        {moneyFormat(MasterWalletInfo?.data?.earning_account, 2)}
                                      </div>
                                      <div>
                                        <Button
                                          className="link"
                                          type="link"
                                          onClick={() =>
                                            setFieldValue('amount', parseInt(MasterWalletInfo?.data?.earning_account))
                                          }
                                        >
                                          Withdraw all
                                        </Button>
                                      </div>
                                    </div>
                                  </Spin>
                                </Col>
                              </Row>
                              <Row gutter={[16, 8]}>
                                <Col span={24}>
                                  <Select
                                    name="account_id"
                                    placeholder="Select Bank Account"
                                    onChange={() => {
                                      setFieldValue('wallet', undefined);
                                    }}
                                    style={{ width: '100%', height: '30px', marginTop: '16px' }}
                                  >
                                    {BankAccounts?.data?.data.map((account) => {
                                      if (account.status == 'pending') {
                                        return (
                                          <Option key={account.id} value={account.id} disabled>
                                            {`${account.account_holder_name} - ${account.bank_name} unverified`}
                                          </Option>
                                        );
                                      } else {
                                        return (
                                          <Option key={account.id} value={account.id}>
                                            {`${account.account_holder_name} - ${account.bank_name}`}
                                          </Option>
                                        );
                                      }
                                    })}
                                  </Select>
                                  <ErrorMessage name="account_id" component="div" className="color-danger" />

                                  {/* <FormItem label="Beneficiary" name="account_id">
                                    <Select name="account_id" placeholder="Select Beneficiary">
                                      {Beneficiaries?.data.user.map((account) => (
                                        <Option key={account.id} value={account.id}>
                                          {account.account_holder_name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </FormItem> */}
                                  {/* <FormItem label="Transfer from" name="transferFrom">
                                    <Select
                                      mode="single"
                                      style={{ width: '100%' }}
                                      defaultValue={'wells_fargo'}
                                      optionLabelProp="label"
                                    >
                                      <Option value="wells_fargo">
                                        <div className="bank_account_option">
                                          <div className="optionImage">
                                            <img src="images/banks/wells_fargo.svg" alt="wells fargo" />
                                          </div>
                                          <div className="optionText">
                                            <p>Wells Fargo</p>
                                            <p>Checking **1243</p>
                                          </div>
                                        </div>
                                      </Option>
                                      <Option value="citi_bank">
                                        <div className="bank_account_option">
                                          <div className="optionImage">
                                            <img src="images/banks/citi_bank.svg" alt="wells fargo" />
                                          </div>
                                          <div className="optionText">
                                            <p>Citi Bank</p>
                                            <p>Checking **1243</p>
                                          </div>
                                        </div>
                                      </Option>
                                      <Option value="bank_of_america">
                                        <div className="bank_account_option">
                                          <div className="optionImage">
                                            <img src="images/banks/bank_of_america.svg" alt="Bank of america" />
                                          </div>
                                          <div className="optionText">
                                            <p>Bank of America</p>
                                            <p>Checking **1243</p>
                                          </div>
                                        </div>
                                      </Option>
                                    </Select>
                                  </FormItem> */}
                                </Col>
                              </Row>
                              <Row gutter={[16, 8]} style={{ marginTop: '10px' }}>
                                <Col span={24}>
                                  <Link to="/link_funding">
                                    Link new funding source <ArrowRightOutlined />
                                  </Link>
                                </Col>
                              </Row>
                              <Row gutter={[16, 8]}>
                                <Col span={24}>
                                  <Radio.Group name="wallet">
                                    <Row
                                      gutter={[16, 8]}
                                      style={{ marginTop: '20px', marginBottom: '16px' }}
                                      key={`source-row-${KySyncFundingSources?.data?.wallets[0].id}`}
                                    >
                                      <Col span={24}>
                                        <label className="cursor-pointer">
                                          <div className="walletOptions">
                                            <div className="icon">
                                              {KySyncFundingSources?.data?.wallets[0].id === 'funding_account' ? (
                                                <img
                                                  src="/images/banks/deposits.svg"
                                                  alt={KySyncFundingSources?.data?.wallets[0].name}
                                                />
                                              ) : (
                                                <img
                                                  src="/images/banks/deposits.svg"
                                                  alt={KySyncFundingSources?.data?.wallets[0].name}
                                                />
                                              )}
                                              <div className="optionText">
                                                <p className="title">{KySyncFundingSources?.data?.wallets[0].name}</p>
                                              </div>
                                            </div>
                                            <div className="option">
                                              <Radio
                                                id="wallet"
                                                name="wallet"
                                                onChange={() => {
                                                  setFieldValue('account_id', undefined);
                                                }}
                                                key={KySyncFundingSources?.data?.wallets[0].id}
                                                value={KySyncFundingSources?.data?.wallets[0].id}
                                              />
                                            </div>
                                          </div>
                                        </label>
                                      </Col>
                                    </Row>
                                  </Radio.Group>
                                  <ErrorMessage name="wallet" component="div" className="color-danger" />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row gutter={[16, 8]} style={{ marginTop: '20px' }}>
                            <Col span={24}>
                              <Button type="secondary" size="large" onClick={() => goBack()}>
                                Go Back
                              </Button>
                              <Button
                                type="primary"
                                size="large"
                                style={{ marginLeft: '16px' }}
                                onClick={() => continueSubmit(formProps, 1)}
                              >
                                Continue
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      ) : stepNumber === 1 ? (
                        <Col span={10}>
                          <Row gutter={[16, 8]}>
                            <Col span={24}>
                              <p>Preview and confirm withdrawal.</p>

                              <div className="preview">
                                <h2 className="previewTitle">WITHDRAWAL OVERVIEW</h2>
                                <Row>
                                  <Col span={24}>
                                    <div className="selectedOptions">
                                      <Row style={{ marginTop: '16px' }}>
                                        <Col span={12}>
                                          <div className="label">Withdrawal Source</div>
                                          <div className="value">
                                            {values.wallet === 'funding_account'
                                              ? 'Funding Account'
                                              : values?.account_id
                                              ? filter(BankAccounts?.data?.data, {
                                                  id: values?.account_id,
                                                })[0]?.bank_name
                                              : 'None'}
                                          </div>
                                        </Col>
                                        <Col span={12}>
                                          <div className="label">Amount</div>
                                          <div className="value">
                                            {values?.amount && moneyFormat(values?.amount, 2)}
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              <Button type="secondary" size="large" onClick={() => setStepNumber(0)}>
                                Go Back
                              </Button>
                              <SubmitButton
                                loading={isSubmitting}
                                type="primary"
                                htmlType="submit"
                                onClick={handleSubmit}
                                size="large"
                                style={{ marginLeft: '16px' }}
                              >
                                Confirm withdrawal
                              </SubmitButton>
                            </Col>
                          </Row>
                        </Col>
                      ) : null}
                    </Row>
                    {/* <pre
                      style={{
                        background: '#f6f8fa',
                        fontSize: '.65rem',
                        padding: '.5rem',
                      }}
                    >
                      <FormikDebug />
                    </pre> */}
                  </Form>
                </Spin>
              );
            }}
          </Formik>
        </Col>
      </Row>
    </LayoutWarpper>
  );
};

export default WithdrawFunds;

import React from 'react';
import { Row, Col, Space, Menu, Dropdown } from 'antd';
import { DownOutlined, SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { capitalize } from 'lodash';

import { getAdmin, getBackoffice, setBackOffice } from 'helpers/localStorage';

import './TopBar.less';

// const { Search } = Input;

const TopBar = (props) => {
  const history = useHistory();

  const adminUser = getAdmin();
  const backOffice = getBackoffice();
  const boName = backOffice?.data?.name;
  document.title = capitalize(boName) +' Backoffice'

  const logout = () => {
    // clear all localStorage
    localStorage.clear();
    setBackOffice(backOffice);
    history.push('/login');
  };

  const settings = () => {
    history.push('/settings');
  };

  const menu = (
    <Menu selectedKeys={[`${props.pharmacy?.id}`]}>
      <Menu.Item key="Settings" onClick={() => settings()}>
        <span>
          <SettingOutlined /> User Settings
        </span>
      </Menu.Item>
      <Menu.Item key="Signout" onClick={() => logout()}>
        <span>
          <LogoutOutlined /> Logout
        </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Row id="TopBar">
        <Col span={8}>
          <Row>
            <Link to="/">
              <img
                style={{ width: '200px' }}
                className="gnp_logo"
                src={backOffice?.data?.logo ? backOffice.data.logo : 'https://assets.deposits.inc/img/logo/deposits/png/logo_main.png'}
                alt="Backoffice"
              />
            </Link>
          </Row>
        </Col>
        {/* <Col span={9}>
          <Row justify="center" align="middle">
            <Col>
              <Search className="searchInput" size="large" placeholder="Search" enterButton />
            </Col>
          </Row>
        </Col> */}
        <Col offset={9} span={7}>
          <Row className="profilePic" justify="end" align="middle">
            <Dropdown overlay={menu} trigger={['hover']}>
              <Space size={15}>
                <Col>
                  <div style={{ marginTop: 20 + 'px' }}>
                    <p>{`${adminUser?.first_name} ${adminUser?.last_name} (${adminUser?.role?.role_access?.name})`}</p>
                    <p>{adminUser?.email}</p>
                  </div>
                </Col>
                <Col>
                  <Row>
                    <span className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                      <DownOutlined className="downArrow" />
                    </span>
                  </Row>
                </Col>
              </Space>
            </Dropdown>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default TopBar;

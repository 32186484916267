import React, { useState } from 'react';
import { Table, Pagination, Spin } from 'antd';
import moment from 'moment';

import { DEFAULT_PAGE_SIZE } from 'constants/config';
import { useWalletTransactions } from 'rc-query/masterWalletHooks';
import { getAdmin, getBackoffice } from 'helpers/localStorage';
import { moneyFormat } from 'helpers/utils';

import './WalletTransactionsTable.less';
import ExportFile from 'components/ExportFile';

const WalletTransactionsTable = () => {
  const [filters, setFilters] = useState({
    page: 1,
    per_page: DEFAULT_PAGE_SIZE,
  });

  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const { isLoading, data } = useWalletTransactions({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
    ...filters,
  });

  const walletTransactions = data?.data?.data || [];
  const totalWalletTranscations = data?.data?.total || 0;
  const currentPage = data?.data?.current_page || 0;

  const renderTenantWallet = (tenant_wallet) => {
    switch (tenant_wallet) {
      case 'funding_account':
        return 'Funding Account'
      case 'billing_reserve_account':
        return 'Billing / Reserve Account'
      case 'earning_account':
        return 'Earning Account'
      default:
        break;
    }
  }
  const columns = [
    {
      title: 'Account',
      dataIndex: 'tenant_wallet',
      key: 'tenant_wallet',
      render: (text, record) => <span>{renderTenantWallet(record.tenant_wallet)}</span>,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      sorter: true,
      render: (text, record) => <span>{moneyFormat(record.amount, 2)}</span>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (text, record) => moment(record.created_at).format('MMM DD, YYYY'),
    },
  ];

  const handlePageChange = (page) => {
    setFilters({ ...filters, page });
  };

  const handleTableChange = (pagination, tableFilters, sorter) => {
    setFilters({
      ...filters,
      page: 1,
      per_page: DEFAULT_PAGE_SIZE,
      sort_by_column: sorter.field,
      sort_by_type: sorter.order === 'ascend' ? 'ASC' : 'DESC',
    });
  };

  return (
    <Spin spinning={isLoading}>
      <div className="walletTransactions">
        <div className="searchBox" style={{ margin: '15px 0px' }}>
          <h4 className="page-title">Wallet Transactions</h4>
          <div>
            <ExportFile data_type="payouts" />
          </div>
        </div>
        <Table
          key="wallet-transactions-table"
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={walletTransactions}
          onChange={handleTableChange}
          pagination={false}
        />
        {totalWalletTranscations > 0 ? (
          <div className="pagination">
            <Pagination
              showLessItems
              defaultPageSize={DEFAULT_PAGE_SIZE}
              total={totalWalletTranscations}
              showSizeChanger={false}
              current={currentPage}
              onChange={handlePageChange}
            />
          </div>
        ) : null}
      </div>
    </Spin>
  );
};

export default WalletTransactionsTable;

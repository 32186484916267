import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Row, Col, Alert, notification } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import { useResetMutation } from 'rc-query/authHooks';

import { getAdmin, getBackoffice, setVerifyEmail } from 'helpers/localStorage';

import './ResetPassword.less';

const ResetPassword = () => {
  const history = useHistory();
  const resetMutation = useResetMutation();

  const adminUser = getAdmin();
  const backOffice = getBackoffice();
  const boName = backOffice?.data?.name 
  document.title = boName +' Backoffice'

  if (adminUser?.status === 'active') {
    history.push('/dashboard');
  }

  const onFinish = async (values) => {
    resetMutation.mutateAsync({ ...values, api_key: backOffice?.data?.name }).then((response) => {
      if (response?.status === 'success') {
        setVerifyEmail(values.email);
        history.push('/change_password');
      }else{
        notification.error({ message: response?.message });
      }
    });
  };

  return (
    <>
      <div className="main-container">
        <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
          <Col>
            <div className="resetPasswordContainer">
              <div style={{ marginBottom: '24px' }}>
                <Row>
                  <Col>
                    <img
                      className="gnp_logo"
                      src={backOffice?.data?.logo ? backOffice.data.logo : 'https://assets.deposits.inc/img/logo/deposits/png/logo_main.png'}
                      alt="Backoffice"
                    />
                  </Col>
                </Row>
              </div>
              <div className="boxContainer">
                <div className="mb-5 heading">
                  <h2 className="mb-0 mt-3">Reset Password</h2>
                  {resetMutation?.data?.status === 'warning' ? (
                    <Alert type="error" message={resetMutation?.data?.message} showIcon closable />
                  ) : null}
                </div>

                <Form layout="vertical" onFinish={onFinish}>
                  <Form.Item
                    label="Email Address"
                    name="email"
                    rules={[
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },
                      {
                        required: true,
                        message: 'Please input your E-mail!',
                      },
                    ]}
                  >
                    <Input size="large" type="email" placeholder="Email" />
                  </Form.Item>

                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    size="large"
                    style={{ marginTop: '1rem', height: '50px' }}
                    loading={resetMutation.isLoading}
                  >
                    Send Passcode
                  </Button>
                </Form>
                <div className="mt-15">
                  <Link to="/login">
                    <ArrowLeftOutlined /> {'  '}Back
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ResetPassword;

import { useQuery } from 'react-query';

import statisticsApi from '@Services/statistics';

import {
  GET_USER_STATISTICS_QUERY,
  GET_DEPOSITS_STATISTICS_QUERY,
  GET_PAYOUTS_STATISTICS_QUERY,
  GET_DASHBOARD_STATISTICS_QUERY,
} from './keys';

export const useGetDashboardStatistics = (body) => {
  const statistics = useQuery([GET_DASHBOARD_STATISTICS_QUERY, body], () => statisticsApi.getDashboardStatistics(body));

  return statistics;
};

export const useUserStatistics = (body) => {
  const userStatistics = useQuery([GET_USER_STATISTICS_QUERY, body], () => statisticsApi.getUserStatistics(body));

  return userStatistics;
};

export const useDepositsStatistics = (body) => {
  const depositsStatistics = useQuery([GET_DEPOSITS_STATISTICS_QUERY, body], () =>
    statisticsApi.getDepositsStatistics(body)
  );

  return depositsStatistics;
};

export const usePayoutsStatistics = (body) => {
  const payoutsStatistics = useQuery([GET_PAYOUTS_STATISTICS_QUERY, body], () =>
    statisticsApi.getPayoutsStatistics(body)
  );

  return payoutsStatistics;
};

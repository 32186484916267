import React from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import {
  TableOutlined,
  UserOutlined,
  MessageOutlined,
  SettingFilled,
  BankOutlined,
  CreditCardOutlined,
  TransactionOutlined,
  LogoutOutlined,
  IdcardOutlined,
} from '@ant-design/icons';

import { FiBox } from 'react-icons/fi';
import { BiDownArrowCircle } from 'react-icons/bi';

import { getBackoffice, getVerifyToken, setBackOffice } from 'helpers/localStorage';

// Styles
import './SideBar.less';

const SideBar = () => {
  const history = useHistory();
  const location = useLocation();
  const urlParams = location?.pathname.split('/');
  const backOffice = getBackoffice();

  let loginObj = getVerifyToken();
  loginObj = JSON.parse(loginObj);
  let modules = loginObj['data']['data']['tenant_features'];
  let showTransactions = '',
    showKySync = '',
    showMessaging = '',
    showMessagingV2 = '',
    showProducts = '',
    showVirtualCards = '',
    showPhysicalCards = '',
    showLinkedCards = '',
    showBankAccounts = '',
    showLinkedAccounts = '',
    showFX = '';
  if (modules) {
    for (let i = 0; i < modules.length; i++) {
      const thisModule = modules[i];
      if (thisModule['name'] == 'FX') {
        if (thisModule['tenant_enabled'] == 'true') {
          // eslint-disable-next-line
          showFX = 'true';
        }
      }
      if (thisModule['name'] == 'KySync') {
        if (thisModule['tenant_enabled'] == 'true') {
          showKySync = (
            <Menu.Item key="kysync">
              <NavLink to="/kysync">
                <IdcardOutlined /> <span>KySync</span>
              </NavLink>
            </Menu.Item>
          );
        }
      }
      if (thisModule['name'] == 'Transactions') {
        if (thisModule['tenant_enabled'] == 'true') {
          showTransactions = (
            <Menu.Item key="transactions">
              <NavLink to="/transactions">
                <TransactionOutlined /> <span>Transactions</span>
              </NavLink>
            </Menu.Item>
          );
        }
      }
      if (thisModule['name'] == 'Messaging') {
        if (thisModule['tenant_enabled'] == 'true') {
          showMessaging = (
            <>
              <Menu.Item key="message_list">
                <NavLink to="/message_list">
                  <MessageOutlined /> <span>Messaging</span>
                </NavLink>
              </Menu.Item>
            </>
          );
        }
      }
      if (thisModule['name'] == 'MessagingV2') {
        if (thisModule['tenant_enabled'] == 'true') {
          showMessagingV2 = (
            <>
              <Menu.Item key="message_list_new">
                <NavLink to="/message_list_new">
                  <MessageOutlined /> <span>New Messaging</span>
                </NavLink>
              </Menu.Item>
            </>
          );
        }
      }
      if (thisModule['name'] == 'Products') {
        if (thisModule['tenant_enabled'] == 'true') {
          showProducts = (
            <Menu.Item key="products">
              <NavLink to="/products">
                <FiBox /> <span>Products</span>
              </NavLink>
            </Menu.Item>
          );
        }
      }
      if (thisModule['name'] == 'Bank Accounts') {
        if (thisModule['tenant_enabled'] == 'true') {
          showLinkedAccounts = (
            <Menu.Item key="bank_accounts">
              <NavLink to="/bank_accounts">
                <BankOutlined /> <span>Linked Accounts</span>
              </NavLink>
            </Menu.Item>
          );

          showBankAccounts = (
            <Menu.Item key="issued_bank_accounts">
              <NavLink to="/issued_bank_accounts">
                <BankOutlined /> <span>Bank Accounts</span>
              </NavLink>
            </Menu.Item>
          );
        }
      }
      if (thisModule['name'] == 'Virtual Cards') {
        if (thisModule['tenant_enabled'] == 'true') {
          // eslint-disable-next-line
          showVirtualCards = (
            <Menu.Item key="issued_cards">
              <NavLink to="/issued_cards">
                <CreditCardOutlined /> <span>Cards</span>
              </NavLink>
            </Menu.Item>
          );

          showLinkedCards = (
            <Menu.Item key="cards">
              <NavLink to="/cards">
                <CreditCardOutlined /> <span>Linked Cards</span>
              </NavLink>
            </Menu.Item>
          );
        }
      }
      if (thisModule['name'] == 'Physical Cards') {
        if (thisModule['tenant_enabled'] == 'true') {
          // eslint-disable-next-line
          showPhysicalCards = 'true';

        }
      }
    }
  }

  const logout = () => {
    // clear all localStorage
    localStorage.clear();
    setBackOffice(backOffice);
    history.push('/login');
  };

  const childSelectedKeys = () => {
    const cases = {
      entity: 'entities',
      user_transactions: 'entities',
      user_bank_accounts: 'entities',
      user_cards: 'entities',
      audiences: 'message_list_new',
      audience_history: 'message_list_new',
      kysync_kyc_results: 'kysync',
      kysync_kyb_results: 'kysync',
      kysync_kyc: 'kysync',
      kysync_kyb: 'kysync',
      kysync_purchase_credits: 'kysync',
      add_contacts_manually_kyc: 'kysync',
      add_contacts_manually_kyb: 'kysync',
      configure_product: 'products',
      fund_accounts: 'settings',
      link_funding: 'settings',
      linked_accounts: 'settings',
      withdraw_funds: 'settings',
    };

    return cases[urlParams[1]] || '';
  };

  return (
    <>
      <Menu
        id="SideMenu"
        style={{ backgroundColor: '#FCFCFE' }}
        mode={'inline'}
        theme={'light'}
        defaultSelectedKeys={['dashboard']}
        selectedKeys={[urlParams[1], childSelectedKeys()]}
        inlineIndent={10}
      >
        <Menu.Item key="dashboard">
          <NavLink to="/dashboard">
            <TableOutlined /> <span>Dashboard</span>
          </NavLink>
        </Menu.Item>
        {showTransactions}
        <Menu.Item key="entities">
          <NavLink to="/entities">
            <UserOutlined /> <span>Entities</span>
          </NavLink>
        </Menu.Item>
        {showVirtualCards}
        {showBankAccounts}
        {showLinkedCards}
        {showLinkedAccounts}
        {showMessaging}
        {showMessagingV2}
        <Menu.Item key="payouts">
          <NavLink to="/payouts">
            <BiDownArrowCircle /> <span>Payouts</span>
          </NavLink>
        </Menu.Item>
        {showKySync}
        {showProducts}
        <Menu.Item key="settings">
          <NavLink to="/settings">
            <SettingFilled /> <span>Settings</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="logout" onClick={() => logout()}>
          <span className="logout">
            <LogoutOutlined /> <span>Logout</span>
          </span>
        </Menu.Item>
      </Menu>
    </>
  );
};

export default SideBar;

import * as yup from 'yup';

export const LinkMasterWalletValidation = yup.object().shape({
  card_number: yup.string().when('linkFundingType', {
    is: (linkFundingType) => linkFundingType === 'card_link_funding',
    then: yup.string().nullable(true).required('Card number is required'),
  }),
  card_name: yup.string().when('linkFundingType', {
    is: (linkFundingType) => linkFundingType === 'card_link_funding',
    then: yup.string().nullable(true).required('Card name is required'),
  }),
  card_expiry: yup.string().when('linkFundingType', {
    is: (linkFundingType) => linkFundingType === 'card_link_funding',
    then: yup.string().nullable(true).required('Card expiry is required'),
  }),
  cvv: yup.number().when('linkFundingType', {
    is: (linkFundingType) => linkFundingType === 'card_link_funding',
    then: yup.number().nullable(true).required('CVV is required'),
  }),
  account_type: yup.string().when('linkFundingType', {
    is: (linkFundingType) => linkFundingType === 'bank_link_funding',
    then: yup.string().nullable(true).required('Account type is required'),
  }),
  first_name: yup.string().when('linkFundingType', {
    is: (linkFundingType) => linkFundingType === 'bank_link_funding',
    then: yup.string().nullable(true).required('First name is required'),
  }),
  last_name: yup.string().when('linkFundingType', {
    is: (linkFundingType) => linkFundingType === 'bank_link_funding',
    then: yup.string().nullable(true).required('Last name is required'),
  }),
  routing_number: yup.string().when('linkFundingType', {
    is: (linkFundingType) => linkFundingType === 'bank_link_funding',
    then: yup.string().nullable(true).required('Routing number is required'),
  }),
  bank_name: yup.string().when('linkFundingType', {
    is: (linkFundingType) => linkFundingType === 'bank_link_funding',
    then: yup.string().nullable(true).required('Bank name is required'),
  }),
  account_number: yup.string().when('linkFundingType', {
    is: (linkFundingType) => linkFundingType === 'bank_link_funding',
    then: yup.string().nullable(true).required('Account number is required'),
  }),
  re_account_number: yup.string().oneOf([yup.ref('account_number'), null], 'Re-Account number must match'),
});

import { useMutation, useQuery } from 'react-query';

import usersApi from 'services/users';

import {
  GET_ALL_USERS_QUERY,
  GET_USER_TRANSACTIONS_QUERY,
  GET_USER,
  BLOCK_USER_MUTATION,
  GET_ALL_TEAM_MEMBERS_QUERY,
  GET_ALL_ACTIVITY_LOG_QUERY,
  DELETE_TEAM_MEMBER_MUTATION,
  GET_ROLES,
  GET_ALL_USERNAME_EMAIL_QUERY,
  GET_ALL_USERNAME_PHONE_QUERY,
  GET_ALL_USERNAME_PUSH_TOKEN_QUERY,
} from './keys';

export const useGetAllUsers = (body) => {
  const users = useQuery([GET_ALL_USERS_QUERY, body], () => usersApi.getUsers(body), {
    select: ({ data }) => data,
  });

  return users;
};

export const useGetUser = (body) => {
  const user = useQuery([GET_USER, body], () => usersApi.getUser(body), {});

  return user;
};

export const useUserTransactions = (body) => {
  const userTransactions = useQuery([GET_USER_TRANSACTIONS_QUERY, body], () => usersApi.getUserTransaction(body), {
    select: ({ data }) => data,
  });

  return userTransactions;
};

export const useBlockUserMutation = () => {
  const response = useMutation(usersApi.blockUser, {
    mutationKey: BLOCK_USER_MUTATION,
    onSuccess: (data) => {
      console.log('User Blocked Success', data);
    },
    onError: (error) => {
      console.log('User Blocked Error', error);
    },
  });

  return response;
};

export const useGetAllTeamMembers = (body) => {
  const users = useQuery([GET_ALL_TEAM_MEMBERS_QUERY, body], () => usersApi.getTeamMembers(body), {
    select: ({ data }) => data,
  });

  return users;
};

export const useGetAllActivityLog = (body) => {
  const users = useQuery([GET_ALL_ACTIVITY_LOG_QUERY, body], () => usersApi.getActivityLog(body));

  return users;
};

export const useDeleteTeamMemberMutation = () => {
  const response = useMutation(usersApi.deleteTeamMember, {
    mutationKey: DELETE_TEAM_MEMBER_MUTATION,
    onSuccess: (data) => {
      console.log('Delete Team Member Success', data);
    },
    onError: (error) => {
      console.log('Delete Team Member Error', error);
    },
  });

  return response;
};

export const useCreateTeamMemberMutation = () =>
  useMutation(usersApi.createTeamMember, { mutationKey: 'createTeamMember' });

export const useUpdateTeamMemberMutation = () =>
  useMutation(usersApi.updateTeamMember, { mutationKey: 'updateTeamMember' });

export const useRoles = (body) => {
  const response = useQuery([GET_ROLES, body], () => usersApi.getRoles(body), {
    select: ({ data }) => data,
  });

  return response;
};

export const useGetUserNameEmail = (body) => {
  const username_email = useQuery([GET_ALL_USERNAME_EMAIL_QUERY, body], () => usersApi.getUserNameEmail(body), {
    select: ({ data }) => data,
  });

  return username_email;
};

export const useGetUserNamePhone = (body) => {
  const username_phone = useQuery([GET_ALL_USERNAME_PHONE_QUERY, body], () => usersApi.getUserNamePhone(body), {
    select: ({ data }) => data,
  });

  return username_phone;
};

export const useGetUserNamePushToken = (body) => {
  const username_push_token = useQuery([GET_ALL_USERNAME_PUSH_TOKEN_QUERY, body], () => usersApi.getUserNamePushToken(body), {
    select: ({ data }) => data,
  });

  return username_push_token;
};

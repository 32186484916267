import React from 'react';
import { Row, Col } from 'antd';

import LayoutWarpper from 'components/Layout';
import CardsTable from 'components/IssuedCardsTable';

const UserCards = (props) => {
  const userId = props.match.params.userId;
  const entityType = props.match.params.entityType;

  return (
    <LayoutWarpper>
      <h1 className="page-title">User Cards</h1>
      <Row style={{ width: '100%' }} gutter={[16, 16]}>
        <Col span={24}>
          <CardsTable userId={userId} entityType={entityType} />
        </Col>
      </Row>
    </LayoutWarpper>
  );
};

export default UserCards;

import React from 'react';
import { Row, Col, Card, Descriptions, notification } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, InputNumber, SubmitButton, Switch } from 'formik-antd';

import { getAdmin, getBackoffice } from 'helpers/localStorage';
import { useGetTransactionSetting, useUpdateTransactionSettingMutation } from 'rc-query/settingHooks';

import Loading from 'components/Loading';

import './Settings.less';

const TransactionSetting = () => {
  const adminUser = getAdmin();
  const backOffice = getBackoffice();
  const updateTransactionSettingMutation = useUpdateTransactionSettingMutation();

  const { isLoading, data } = useGetTransactionSetting({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
  });

  const {
    send_daily_limit,
    send_monthly_limit,
    send_intra_bank,
    send_ach,
    send_wire,
    send_check,
    receive_daily_limit,
    receive_monthly_limit,
    receive_intra_bank,
    receive_ach,
    receive_check,
  } = data?.data?.transactions_setting || {};

  const updateTransactionSetting = (values) => {
    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      ...values,
    };

    updateTransactionSettingMutation.mutateAsync(body).then((data) => {
      if (data.status === 'error') {
        notification.error({
          message: data.message,
        });
      } else if (data.status === 'success') {
        notification.success({
          message: data.message,
        });
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <Loading fullScreen loading={isLoading} />
      ) : (
        <Formik
          onSubmit={updateTransactionSetting}
          layout="vertical"
          initialValues={{
            send_daily_limit: send_daily_limit || 0,
            send_monthly_limit: send_monthly_limit || 0,
            send_intra_bank: send_intra_bank || false,
            send_ach: send_ach || false,
            send_wire: send_wire || false,
            send_check: send_check || false,
            receive_daily_limit: receive_daily_limit || 0,
            receive_monthly_limit: receive_monthly_limit || 0,
            receive_intra_bank: receive_intra_bank || false,
            receive_ach: receive_ach || false,
            receive_check: receive_check || false,
          }}
        >
          {() => (
            <Form
              initialValues={{
                send_daily_limit: send_daily_limit || 0,
                send_monthly_limit: send_monthly_limit || 0,
                send_intra_bank: send_intra_bank || false,
                send_ach: send_ach || false,
                send_wire: send_wire || false,
                send_check: send_check || false,
                receive_daily_limit: receive_daily_limit || 0,
                receive_monthly_limit: receive_monthly_limit || 0,
                receive_intra_bank: receive_intra_bank || false,
                receive_ach: receive_ach || false,
                receive_check: receive_check || false,
              }}
            >
              <Row gutter={[18, 18]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <Card title="Send">
                    <Descriptions layout="vertical" column={2}>
                      <Descriptions.Item label="Daily Limit">
                        <FormItem name="send_daily_limit">
                          <InputNumber
                            size="large"
                            prefix="$"
                            precision={2}
                            name="send_daily_limit"
                            placeholder="0.00"
                            style={{ width: '150px', textAlign: 'center' }}
                            min={0}
                          />
                        </FormItem>
                      </Descriptions.Item>
                      <Descriptions.Item label="Monthly Limit">
                        <FormItem name="send_monthly_limit">
                          <InputNumber
                            size="large"
                            prefix="$"
                            precision={2}
                            name="send_monthly_limit"
                            placeholder="0.00"
                            style={{ width: '150px', textAlign: 'center' }}
                            min={0}
                          />
                        </FormItem>
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions layout="horizontal" column={1}>
                      <Descriptions.Item label="Intrabank">
                        <FormItem name="send_intra_bank">
                          <Switch name="send_intra_bank" />
                        </FormItem>
                      </Descriptions.Item>
                      <Descriptions.Item label="ACH">
                        <FormItem name="send_ach">
                          <Switch name="send_ach" />
                        </FormItem>
                      </Descriptions.Item>
                      <Descriptions.Item label="Wire">
                        <FormItem name="send_wire">
                          <Switch name="send_wire" />
                        </FormItem>
                      </Descriptions.Item>
                      <Descriptions.Item label="Check">
                        <FormItem name="send_check">
                          <Switch name="send_check" />
                        </FormItem>
                      </Descriptions.Item>
                    </Descriptions>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card title="Receive">
                    <Descriptions layout="vertical" column={2}>
                      <Descriptions.Item label="Daily Limit">
                        <FormItem name="receive_daily_limit">
                          <InputNumber
                            size="large"
                            prefix="$"
                            precision={2}
                            name="receive_daily_limit"
                            placeholder="0.00"
                            style={{ width: '150px', textAlign: 'center' }}
                            min={0}
                          />
                        </FormItem>
                      </Descriptions.Item>
                      <Descriptions.Item label="Monthly Limit">
                        <FormItem name="receive_monthly_limit">
                          <InputNumber
                            size="large"
                            prefix="$"
                            precision={2}
                            name="receive_monthly_limit"
                            placeholder="0.00"
                            style={{ width: '150px', textAlign: 'center' }}
                            min={0}
                          />
                        </FormItem>
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions layout="horizontal" column={1}>
                      <Descriptions.Item label="Intrabank">
                        <FormItem name="receive_intra_bank">
                          <Switch name="receive_intra_bank" />
                        </FormItem>
                      </Descriptions.Item>
                      {/* TODO: FIXIT: rename field to receive_ach - back-end API issue. talk to Jere */}
                      <Descriptions.Item label="ACH">
                        <FormItem name="receive_ach">
                          <Switch name="receive_ach" />
                        </FormItem>
                      </Descriptions.Item>
                      <Descriptions.Item label="Check">
                        <FormItem name="receive_check">
                          <Switch name="receive_check" />
                        </FormItem>
                      </Descriptions.Item>
                    </Descriptions>
                  </Card>
                </Col>
              </Row>
              <Row gutter={[18, 18]} style={{ marginTop: '20px' }}>
                <Col>
                  <SubmitButton type="primary" htmlType="submit" loading={false}>
                    Save
                  </SubmitButton>
                </Col>
              </Row>
              {/* <pre
                style={{
                  background: '#f6f8fa',
                  fontSize: '.65rem',
                  padding: '.5rem',
                }}
              >
                <strong>props</strong> = {JSON.stringify(formProps, null, 2)}
              </pre> */}
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default TransactionSetting;

import React from 'react';
import { Row, Col, Card, Descriptions, Spin } from 'antd';
import moment from 'moment';

import { ShowIfEmpty } from 'helpers/utils';
import { getAdmin, getBackoffice } from 'helpers/localStorage';
import { useGetGeneralSetting } from '../../rc-query/settingHooks';

import './Settings.less';

const InformationSetting = () => {
  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const { isLoading, data } = useGetGeneralSetting({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
  });

  const {
    owner_name,
    owner_email,
    currency,
    sponsor_bank,
    description,
    created_date,
    company_name,
    company_domain,
    company_sub_domain,
    company_tin,
    company_description,
    legal_bank,
    legal_program,
    legal_help_center,
    legal_disclosure,
  } = data?.data?.general_setting || {};

  return (
    <Spin spinning={isLoading}>
      <Row gutter={[18, 18]}>
        <Col span={12}>
          <Card title="Program Information">
            <Descriptions layout="vertical" column={2}>
              <Descriptions.Item label="Owner Name">{ShowIfEmpty(owner_name, '-')}</Descriptions.Item>
              <Descriptions.Item label="Owner Email">{ShowIfEmpty(owner_email, '-')}</Descriptions.Item>
              <Descriptions.Item label="Currency">{ShowIfEmpty(currency, '-')}</Descriptions.Item>
              <Descriptions.Item label="Created Date">
                {created_date ? moment(created_date).format('MMM DD, YYYY') : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Sponsor Bank">{ShowIfEmpty(sponsor_bank, '-')}</Descriptions.Item>
              <Descriptions.Item label="Description">{ShowIfEmpty(description, '-')}</Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Company Settings">
            <Descriptions layout="vertical" column={2}>
              <Descriptions.Item label="Name">{ShowIfEmpty(company_name, '-')}</Descriptions.Item>
              <Descriptions.Item label="Tin">{ShowIfEmpty(company_tin, '-')}</Descriptions.Item>
              <Descriptions.Item label="Domain">{ShowIfEmpty(company_domain, '-')}</Descriptions.Item>
              <Descriptions.Item label="Description">{ShowIfEmpty(company_description, '-')}</Descriptions.Item>
              <Descriptions.Item label="Sub-Domain">{ShowIfEmpty(company_sub_domain, '-')}</Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      </Row>
      <Row gutter={[18, 18]} style={{ marginTop: '20px' }}>
        <Col span={12}>
          <Card title="Legal Disclosure">
            <Descriptions layout="vertical" column={2}>
              <Descriptions.Item label="Bank">{ShowIfEmpty(legal_bank, '-')}</Descriptions.Item>
              <Descriptions.Item label="Disclosure">{ShowIfEmpty(legal_disclosure, '-')}</Descriptions.Item>
              <Descriptions.Item label="Program">{ShowIfEmpty(legal_program, '-')}</Descriptions.Item>
              <Descriptions.Item label="Help center">{ShowIfEmpty(legal_help_center, '-')}</Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};

export default InformationSetting;

import React from 'react';
import { Row, Col } from 'antd';

import BankAccountsTable from 'components/BankAccountsTable';

import LayoutWarpper from 'components/Layout';

const BankAccounts = () => {
  return (
    <LayoutWarpper>
      <h1 className="page-title">Linked Accounts</h1>
      <Row style={{ width: '100%' }} gutter={[16, 16]}>
        <Col span={24}>
          <BankAccountsTable />
        </Col>
      </Row>
    </LayoutWarpper>
  );
};

export default BankAccounts;

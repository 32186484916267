import axios from 'axios';

import { API_BASE_URL } from 'constants/config';
import { apiWrapper } from './interceptors';
// const host = window.location.origin;

const request = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30 * 1000,
});

const masterWalletApi = {
  getMasterWalletInfo: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('admin/master-wallet', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  withdrawFund: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/master-wallet/withdraw', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  fundAccount: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/master-wallet/topup-tenant-wallet', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  fundAccountViaCard: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/master-wallet/topup-tenant-wallet-via-saved-card', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  saveBankAccount: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/master-wallet/save-bank-account', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  saveTokenizeCard: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/master-wallet/tokenize-card', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getWalletTransactions: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/master-wallet/get-transactions', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getBankAccounts: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/master-wallet/bank-accounts', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getWalletTokenizedCards: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/master-wallet/tokenized-cards', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getWireTransferDetail: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/master-wallet/get-wire-details', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  deleteWalletBankAccount: (body) => {
    const { id } = body;
    body.id = undefined;
    return new Promise((resolve, reject) => {
      request
        .post(`/admin/master-wallet/delete-bank-account/${id}`, { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  deleteWalletTokenizedCard: (body) => {
    const { id } = body;
    body.id = undefined;
    return new Promise((resolve, reject) => {
      request
        .post(`/admin/master-wallet/delete-tokenized-card/${id}`, { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  verifyWalletBankAccount: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post(`/admin/master-wallet/verify-bank-account/${body.account_id}`, { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },
};

export default apiWrapper(masterWalletApi, []);

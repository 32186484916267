import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Card, Button, Spin } from 'antd';
import { ArrowRightOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

import { getAdmin, getBackoffice, host } from 'helpers/localStorage';
import localStorageService from 'helpers/localStorageService';
import { useMasterWalletInfo } from 'rc-query/masterWalletHooks';
import { moneyFormat } from 'helpers/utils';
import WalletTransactionsTable from 'components/WalletTransactionsTable';

import './Settings.less';

const MasterWallet = () => {
  const history = useHistory();
  const [showFundingAccountAmount, setShowFundingAccountAmount] = useState(false);
  const [showEarningsAmount, setShowEarningsAmount] = useState(false);

  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const MasterWalletInfo = useMasterWalletInfo({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
  });

  const handleLinkFunding = () => {
    localStorageService.remove(`${host}_fund_account_referral`);
    history.push('/link_funding');
  };

  return (
    <>
      <Row style={{ marginTop: '20px' }}>
        <Col span={24}>
          <Card className="masterWallet" style={{ padding: '0px' }}>
            <Spin spinning={MasterWalletInfo?.isLoading}>
              <Row>
                <Col span={16} style={{ padding: '24px' }}>
                  <Row>
                    <Col span={24}>
                      <div className="fundingAccount">
                        <label>
                          <span>Funding Account</span>{' '}
                          {showFundingAccountAmount ? (
                            <EyeOutlined onClick={() => setShowFundingAccountAmount(false)} />
                          ) : (
                            <EyeInvisibleOutlined onClick={() => setShowFundingAccountAmount(true)} />
                          )}
                        </label>
                        <p className="amount">
                          {showFundingAccountAmount
                            ? moneyFormat(MasterWalletInfo?.data?.funding_account, 2)
                            : '********'}
                        </p>
                      </div>
                      <div className="billingAccount">
                        <label>Billing / Reserve account</label>
                        <p className="amount">{moneyFormat(MasterWalletInfo?.data?.billing_reserve_account, 2)}</p>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div>
                        <Button type="primary" size="large" style={{ marginRight: '22px' }}>
                          <Link to="/fund_accounts">Fund account</Link>
                        </Button>
                        <Button type="link" onClick={handleLinkFunding}>
                          Link funding / withdrawal source <ArrowRightOutlined />
                          {/* <Link to="/link_funding">
                          </Link> */}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={8} style={{ background: '#F7FBFF', padding: '24px' }}>
                  <Row>
                    <Col span={24}>
                      <div className="earnings">
                        <label>
                          <span>Earnings</span>
                          {showEarningsAmount ? (
                            <EyeOutlined onClick={() => setShowEarningsAmount(false)} />
                          ) : (
                            <EyeInvisibleOutlined onClick={() => setShowEarningsAmount(true)} />
                          )}
                        </label>
                        <p className="amount">
                          {showEarningsAmount ? moneyFormat(MasterWalletInfo?.data?.earning_account, 2) : '********'}
                        </p>
                        <Button
                          type="secondary bordered"
                          className="bordered"
                          size="large"
                          style={{ marginRight: '22px', color: '#0D7FE9', border: '' }}
                          onClick={() => history.push('withdraw_funds')}
                        >
                          Withdraw funds
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Spin>
          </Card>
        </Col>
      </Row>
      <Row gutter={[18, 18]} style={{ marginTop: '20px' }}>
        <Col span={24}>
          <WalletTransactionsTable />
        </Col>
      </Row>
    </>
  );
};

export default MasterWallet;

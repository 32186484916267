import React from 'react';
import { Row, Col, Button, Spin, Descriptions, notification } from 'antd';
import { Formik } from 'formik';
import {
  Form,
  FormItem,
  Input,
  InputNumber,
  Select,
  SubmitButton,
  Switch,
  //  FormikDebug,
} from 'formik-antd';
import * as yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

import { useSaveProductMutation } from 'rc-query/productHooks';
import { getAdmin, getBackoffice } from 'helpers/localStorage';
import { GET_ALL_PRODUCTS } from 'rc-query/keys';
import { moneyFormat } from 'helpers/utils';
import { queryClient } from 'rc-query';

import './Products.less';

const { Option } = Select;

const WalletProductForm = ({ stepNumber, setStepNumber, entities }) => {
  const saveProduct = useSaveProductMutation();
  const history = useHistory();
  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const handleSubmit = (values, formikHelpers) => {
    formikHelpers.validateForm();
    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      ...values,
      type: 'wallet',
      monthly_spending_limit: `${values.monthly_spending_limit}`,
      monthly_withdrawal_limit: `${values.monthly_withdrawal_limit}`,
      maximum_balance: `${values.maximum_balance}`,
      daily_transaction_limit: `${values.daily_transaction_limit}`,
      monthly_transaction_limit: `${values.monthly_transaction_limit}`,
      account_interest: `${values.account_interest}`,
      account_fees: `${values.account_fees}`,
    };

    saveProduct
      .mutateAsync(body)
      .then((data) => {
        if (data.status === 'success') {
          notification.success({
            message: data.message,
          });
          // reload listing and redirect
          queryClient.refetchQueries([GET_ALL_PRODUCTS]);
          history.push('/products/wallet');
        } else {
          notification.error({
            message: data.message,
          });
        }
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  const continueSubmit = (formProps) => {
    formProps.setTouched({
      name: true,
      daily_transaction_limit: true,
      monthly_transaction_limit: true,
      maximum_balance: true,
      account_interest: true,
      account_fees: true,
      account_interest_frequency: true,
    });
    formProps.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        setStepNumber(2);
      }
    });
  };

  return (
    <>
      <Formik
        initialValues={{
          name: '',
          p2p_open_loop: false,
          p2p_close_loop: false,
        }}
        validationSchema={yup.object().shape({
          name: yup.string().nullable(true).required('Product Name is required'),
          daily_transaction_limit: yup.number().nullable(true).required('Daily transaction limit is required'),
          monthly_transaction_limit: yup.number().nullable(true).required('Monthly transaction limit is required'),
          maximum_balance: yup.number().nullable(true).required('Maximum balance is required'),
          account_interest: yup.number().nullable(true).required('Account interest is required'),
          account_fees: yup.number().nullable(true).required('Account fees is required'),
          account_interest_frequency: yup.string().nullable(true).required('Account interest frequency is required'),
        })}
        onSubmit={handleSubmit}
      >
        {(formProps) => {
          const { isSubmitting, handleSubmit, values } = formProps;
          return (
            <Spin spinning={isSubmitting}>
              <Form size="large" layout="vertical">
                <Row gutter={[56, 56]} style={stepNumber !== 1 && { display: 'none' }}>
                  <Col span={12}>
                    <Row gutter={[16, 8]}>
                      <Col span={24}>
                        <FormItem label="Product Name" name="name">
                          <Input name="name" placeholder="Product Name" />
                        </FormItem>
                      </Col>
                    </Row>
                    <h1 className="formTitle" style={{ marginTop: '25px' }}>
                      ACCOUNT LIMITS
                    </h1>
                    <Row gutter={[16, 8]} style={{ marginTop: '20px' }}>
                      <Col span={12}>
                        <FormItem label="Daily transaction limit" name="daily_transaction_limit">
                          <InputNumber
                            size="medium"
                            prefix="$"
                            precision={2}
                            name="daily_transaction_limit"
                            placeholder="0.00"
                            style={{ width: '100%' }}
                            min={0}
                          />
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem label="Monthly transaction limit" name="monthly_transaction_limit">
                          <InputNumber
                            size="medium"
                            prefix="$"
                            precision={2}
                            name="monthly_transaction_limit"
                            placeholder="0.00"
                            style={{ width: '100%' }}
                            min={0}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row gutter={[16, 8]} style={{ marginTop: '20px' }}>
                      <Col span={24}>
                        <FormItem label="Maximum balance" name="maximum_balance">
                          <InputNumber
                            size="medium"
                            prefix="$"
                            precision={2}
                            name="maximum_balance"
                            placeholder="0.00"
                            style={{ width: '100%' }}
                            min={0}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                    <h1 className="formTitle" style={{ marginTop: '25px' }}>
                      INTEREST & FEES
                    </h1>
                    <Row gutter={[16, 8]} style={{ marginTop: '20px' }}>
                      <Col span={12}>
                        <FormItem label="Account Interest" name="account_interest">
                          <InputNumber
                            size="medium"
                            prefix="%"
                            precision={2}
                            name="account_interest"
                            placeholder="0.00"
                            style={{ width: '100%' }}
                            min={0}
                            max={100}
                          />
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem label="Account fees" name="account_fees">
                          <InputNumber
                            size="medium"
                            prefix="$"
                            precision={2}
                            name="account_fees"
                            placeholder="0.00"
                            style={{ width: '100%' }}
                            min={0}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row gutter={[16, 8]} style={{ marginTop: '20px' }}>
                      <Col span={24}>
                        <FormItem label="Account interest frequency" name="account_interest_frequency">
                          <Select name="account_interest_frequency" placeholder="Choose...">
                            <Option value="manually">Manually</Option>
                            <Option value="daily">Daily</Option>
                            <Option value="weekly">Weekly</Option>
                            <Option value="monthly">Monthly</Option>
                            <Option value="quaterly">Quarterly</Option>
                            <Option value="annually">Annually</Option>
                            <Option value="bi-annually">Bi-Annually</Option>
                          </Select>
                        </FormItem>
                      </Col>
                    </Row>
                    <Row gutter={[16, 8]} style={{ marginTop: '20px' }}>
                      <Col span={24}>
                        <Button type="secondary" size="large">
                          <Link to="/select_product_type">Go Back</Link>
                        </Button>
                        <Button
                          type="primary"
                          size="large"
                          onClick={() => continueSubmit(formProps)}
                          style={{ marginLeft: '16px' }}
                        >
                          Continue
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={10}>
                    <div className="transactionTypes">
                      <div className="title">TRANSACTION TYPES</div>
                      <div className="options">
                        <Descriptions layout="horizontal" column={1}>
                          <Descriptions.Item label="P2P (Open loop)">
                            <FormItem name="p2p_open_loop">
                              <Switch name="p2p_open_loop" />
                            </FormItem>
                          </Descriptions.Item>
                          <Descriptions.Item label="P2P (Close loop)">
                            <FormItem name="p2p_close_loop">
                              <Switch name="p2p_close_loop" />
                            </FormItem>
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row gutter={[56, 56]} style={stepNumber !== 2 && { display: 'none' }}>
                  <Col span={16}>
                    <Row gutter={[16, 8]}>
                      <Col span={24}>
                        <FormItem label="Select Entities" name="entities">
                          <Select name="entities" placeholder="Add / Select Recipients" mode="multiple">
                            <Option key="1" value="all_individual">
                              All Individual Users
                            </Option>
                            {entities?.map((user) => (
                              <Option key={user.email} value={user.email} label={user.name}>
                                <div className="select-option-label-item">
                                  <span role="img" aria-label={user.name} style={{ marginRight: '10px' }}>
                                    {user.name}
                                  </span>
                                  ({user.email})
                                </div>
                              </Option>
                            ))}
                          </Select>
                        </FormItem>
                      </Col>
                    </Row>

                    <Row gutter={[16, 8]} style={{ marginTop: '20px' }}>
                      <Col span={24}>
                        <Button type="secondary" size="large" onClick={() => setStepNumber(1)}>
                          Go Back
                        </Button>
                        <Button
                          type="primary"
                          size="large"
                          onClick={() => setStepNumber(3)}
                          style={{ marginLeft: '16px' }}
                        >
                          Continue
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={[56, 56]} style={stepNumber !== 3 && { display: 'none' }}>
                  <Col span={20}>
                    <h1 className="formTitle">Preview and save your product.</h1>
                    <div className="productPreview">
                      <h2 className="previewTitle">PRODUCT OVERVIEW</h2>
                      <Row>
                        <Col span={24}>
                          <div className="selectedOptions">
                            <Row style={{ marginTop: '16px' }}>
                              <Col span={12}>
                                <div className="label">Product Name</div>
                                <div className="value">{values?.name}</div>
                              </Col>
                              <Col span={12}>
                                <div className="label">Account Interest</div>
                                <div className="value">{values?.account_interest}% /month</div>
                              </Col>
                            </Row>
                            <Row style={{ marginTop: '16px' }}>
                              <Col span={12}>
                                <div className="label">Daily transaction limits</div>
                                <div className="value">{moneyFormat(values?.daily_transaction_limit)}</div>
                              </Col>
                              <Col span={12}>
                                <div className="label">Account fees</div>
                                <div className="value">{moneyFormat(values?.account_fees)} /transaction</div>
                              </Col>
                            </Row>
                            <Row style={{ marginTop: '16px' }}>
                              <Col span={12}>
                                <div className="label">Monthly transaction Limit</div>
                                <div className="value">{moneyFormat(values?.monthly_transaction_limit)}</div>
                              </Col>
                              <Col span={12}>
                                <div className="label">Account holders</div>
                                <div className="value">
                                  {values?.entities?.map((entity) => (
                                    <p>{entity}</p>
                                  ))}
                                </div>
                              </Col>
                            </Row>
                            <Row style={{ marginTop: '16px' }}>
                              <Col span={12}>
                                <div className="label">Maximum balance</div>
                                <div className="value">{moneyFormat(values?.maximum_balance)}</div>
                              </Col>
                              <Col span={12}>
                                <div className="label">Transaction types</div>
                                <div className="value">
                                  {values?.p2p_open_loop ? <p>P2P (Open loop)</p> : null}
                                  {values?.p2p_close_loop ? <p>P2P (Close loop)</p> : null}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <Row gutter={[16, 8]} style={{ marginTop: '20px' }}>
                      <Col span={24}>
                        <Button type="secondary" size="large" onClick={() => setStepNumber(2)}>
                          Go Back
                        </Button>
                        <SubmitButton
                          loading={isSubmitting}
                          type="primary"
                          htmlType="submit"
                          onClick={handleSubmit}
                          size="large"
                          style={{ marginLeft: '16px' }}
                        >
                          Create Product
                        </SubmitButton>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* <pre
                  style={{
                    background: '#f6f8fa',
                    fontSize: '.65rem',
                    padding: '.5rem',
                  }}
                >
                  <FormikDebug />
                </pre> */}
              </Form>
            </Spin>
          );
        }}
      </Formik>
    </>
  );
};

export default WalletProductForm;

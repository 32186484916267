import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  Tabs,
  Pagination,
  Spin,
  Tag,
  Button,
  Modal,
  Row,
  Col,
  Input,
  notification,
  //  Dropdown,
  // Menu,
} from 'antd';
import { Formik } from 'formik';
import moment from 'moment';
// import { EllipsisOutlined } from '@ant-design/icons';
import { IoArrowBackCircle } from 'react-icons/io5';
import {
  Form,
  FormItem,
  InputNumber,
  SubmitButton,
  //  FormikDebug
} from 'formik-antd';

import { DEFAULT_PAGE_SIZE } from 'constants/config';
import { queryClient } from 'rc-query';
import { useAllCards, useBlockCardMutation, useCardLimitMutation } from 'rc-query/cardHooks';
import { getAdmin, getBackoffice } from 'helpers/localStorage';
import ExportFile from 'components/ExportFile';

import './CardTable.less';

const { TabPane } = Tabs;
const { Search } = Input;

const CardsTable = ({ userId, entityType, issued_cards = false }) => {
  const [filters, setFilters] = useState({
    page: 1,
    per_page: DEFAULT_PAGE_SIZE,
  });
  const [selectedCard, setSelectedCard] = useState({});
  const [statusToChange, setStatusToChange] = useState('');
  const [showBlockCardBox, setShowBlockCardBox] = useState(false);
  const [showCardLimitBox, setShowCardLimitBox] = useState(false);

  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const apiKey = backOffice?.data?.name;
  const adminToken = adminUser?.token;

  const blockCardMutation = useBlockCardMutation();
  const cardLimitMutation = useCardLimitMutation();

  const payloadProps = {
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
    ...filters,
  };

  if (userId) {
    payloadProps.user_id = userId;
  }

  if (issued_cards) {
    payloadProps.issued_cards = issued_cards;
  }

  const { isLoading, data } = useAllCards(payloadProps);

  const allCards = data?.data?.cards?.data || [];
  const totalRecords = data?.data?.cards?.total || 0;
  const currentPage = data?.data?.cards?.current_page || 0;

  const stats = data?.data?.stats || {};
  const all = stats?.total || 0;
  const active = stats?.active || 0;
  const blocked = stats?.blocked || 0;
  const freeze = stats?.freeze || 0;

  const renderBankAccountStatus = (status) => {
    switch (status?.toLowerCase()) {
      case 'active':
        return <Tag color="green">Active</Tag>;
      case 'blocked':
        return <Tag color="red">Blocked</Tag>;
      case 'Deactive':
        return <Tag color="red">Deactivated</Tag>;
      case 'freeze':
        return <Tag color="red">Freezed</Tag>;
      case 'blocked_deposit':
        return <Tag color="red">Blocked Deposits</Tag>;
      case 'blocked_transfer':
        return <Tag color="red">Blocked Transfer</Tag>;
      default:
        return null;
    }
  };

  // const handleShowBlockCardBox = (card, statusToChange) => {
  //   setSelectedCard(card);
  //   setStatusToChange(statusToChange);
  //   setShowBlockCardBox(true);
  // };

  const handleCardStatus = (card_id, status) => {
    blockCardMutation
      .mutateAsync({
        api_key: apiKey,
        token: adminToken,
        card_id: `${card_id}`,
        status,
      })
      .then((res) => {
        if (res.status === 'success') {
          notification.success({
            message: res.message,
          });

          queryClient.refetchQueries(['allCards']);
          setShowBlockCardBox(false);
        } else {
          notification.error({
            message: res.message,
          });
        }
      });
  };

  // const handleShowCardLimitBox = (card) => {
  //   setSelectedCard(card);
  //   setShowCardLimitBox(true);
  // };

  const handleCardLimit = (values, formikHelpers) => {
    formikHelpers.validateForm();

    cardLimitMutation
      .mutateAsync({
        api_key: apiKey,
        token: adminToken,
        card_id: `${selectedCard?.id}`,
        monthly_spending_limit: `${values.monthly_spending_limit}`,
        monthly_withdrawal_limit: `${values.monthly_withdrawal_limit}`,
      })
      .then(() => {
        queryClient.refetchQueries(['allCards']);
        setShowCardLimitBox(false);
      });
  };

  const columns = [
    {
      title: 'Card Number',
      dataIndex: 'last_four',
      key: 'last_four',
      render: (text, record) => <span>*****{record.last_four}</span>,
    },
    {
      title: 'Card Expiry',
      dataIndex: 'exp_date',
      key: 'exp_date',
    },
    {
      title: 'Status',
      dataIndex: 'card_status',
      key: 'card_status',
      render: (text, record) => <span>{renderBankAccountStatus(record.card_status)}</span>,
    },
    {
      title: 'Card Type',
      dataIndex: 'card_brand',
      key: 'card_brand',
    },
    {
      title: 'Owner Name',
      dataIndex: 'owner_name',
      key: 'owner_name',
      render: (text, record) => <span>{record.owner_name}</span>,
    },
    {
      title: 'Date Created',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (text, record) => moment(record.created_at).format('MMM DD, YYYY'),
    },
    // {
    //   title: 'Action',
    //   key: 'id',
    //   dataIndex: 'id',
    //   render: (text, record) => (
    //     <Dropdown
    //       overlay={
    //         <Menu key={`menu-${record.id}`}>
    //           <Menu.Item key={`setlimit-${record.id}`} onClick={() => handleShowCardLimitBox(record)}>
    //             Set limits
    //           </Menu.Item>
    //           {record?.status === 'freeze' ? (
    //             <Menu.Item key={`unfreeze-card-${record.id}`} onClick={() => handleCardStatus(record.id, 'active')}>
    //               <span>Unfreeze Card</span>
    //             </Menu.Item>
    //           ) : (
    //             <Menu.Item key={`freeze-card-${record.id}`} onClick={() => handleShowBlockCardBox(record, 'freeze')}>
    //               <span>Freeze Card</span>
    //             </Menu.Item>
    //           )}
    //           {record?.status === 'blocked' ? (
    //             <Menu.Item key={`active-${record.id}`} onClick={() => handleCardStatus(record.id, 'active')}>
    //               <span className="green">Activate</span>
    //             </Menu.Item>
    //           ) : (
    //             <Menu.Item key={`block-${record.id}`} onClick={() => handleShowBlockCardBox(record, 'blocked')}>
    //               <span className="red">Block</span>
    //             </Menu.Item>
    //           )}
    //         </Menu>
    //       }
    //     >
    //       <a className="ant-dropdown-link">
    //         <EllipsisOutlined />
    //       </a>
    //     </Dropdown>
    //   ),
    // },
  ];

  const handlePageChange = (page) => {
    setFilters({ ...filters, page });
  };

  const handleTabs = (key) => {
    setFilters(key !== 'all' ? { ...filters, status: key } : {});
  };

  const handleTableChange = (pagination, tableFilters, sorter) => {
    const email = tableFilters?.email?.[0]?.toString() || undefined;

    setFilters({
      email,
      page: 1,
      sort_by_column: sorter?.field,
      sort_by_type: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
    });
  };

  const onSearch = (value) => {
    const keyword = value?.toString() || undefined;

    setFilters({
      ...filters,
      search: keyword,
      page: 1,
      per_page: DEFAULT_PAGE_SIZE,
    });
  };

  const handleCancelBox = () => {
    setShowBlockCardBox(false);
    setShowCardLimitBox(false);
    setStatusToChange('');
    setSelectedCard({});
  };

  return (
    <Spin spinning={isLoading}>
      {userId ? (
        <div className="backBtn">
          <Link
            to={`/entity/${entityType}/${userId}`}
            style={{ display: 'flex', alignContent: 'center', marginBottom: '20px' }}
          >
            <IoArrowBackCircle size={22} style={{ marginRight: '13px' }} /> <span>Back</span>
          </Link>
        </div>
      ) : null}
      <Tabs defaultActiveKey="0" left="true" size="large" onChange={handleTabs}>
        <TabPane onChange={() => handleTabs('all')} tab={`All (${all})`} key="all" />
        <TabPane onChange={() => handleTabs('active')} tab={`Active (${active})`} key="active" />
        <TabPane onChange={() => handleTabs('blocked')} tab={`Blocked (${blocked})`} key="blocked" />
        <TabPane onChange={() => handleTabs('freeze')} tab={`Freezed (${freeze})`} key="freeze" />
      </Tabs>
      <div className="cards">
        <div className="searchBox">
          <Search placeholder="Search" onSearch={onSearch} allowClear style={{ width: 300 }} />
          <ExportFile data_type="tokenized_cards" />
        </div>
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={allCards}
          onChange={handleTableChange}
          pagination={false}
        />
        {totalRecords > 0 ? (
          <div className="pagination">
            <Pagination
              showLessItems
              defaultPageSize={DEFAULT_PAGE_SIZE}
              total={totalRecords}
              showSizeChanger={false}
              current={currentPage}
              onChange={handlePageChange}
            />
          </div>
        ) : null}
      </div>
      <Modal
        width={450}
        title="Card Limits"
        visible={showCardLimitBox}
        onCancel={handleCancelBox}
        footer={false}
        className="cardModal"
        key={`card-limit-modal-${selectedCard?.id}`}
      >
        <Formik
          enableReinitialize
          key={`card-limit-form-${selectedCard?.id}`}
          onSubmit={handleCardLimit}
          layout="vertical"
          initialValues={{
            monthly_spending_limit: parseInt(selectedCard?.monthly_spending_limit || 0),
            monthly_withdrawal_limit: parseInt(selectedCard?.monthly_withdrawal_limit || 0),
          }}
        >
          {(formProps) => {
            const { isSubmitting, handleSubmit } = formProps;
            return (
              <Form
                initialValues={{
                  monthly_spending_limit: parseInt(selectedCard?.monthly_spending_limit || 0),
                  monthly_withdrawal_limit: parseInt(selectedCard?.monthly_withdrawal_limit || 0),
                }}
                layout="vertical"
              >
                <Row>
                  <Col span={24}>
                    <p className="descriptionText">Set entity spending and withdrawals card limits</p>
                  </Col>
                </Row>
                <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
                  <Col span={12}>
                    <FormItem
                      name="monthly_spending_limit"
                      label="Monthly spending limit"
                      key={`card-monthly_spending_limit-${selectedCard?.id}`}
                    >
                      <InputNumber
                        size="medium"
                        prefix="$"
                        precision={2}
                        placeholder="0.00"
                        name="monthly_spending_limit"
                        min={0}
                        style={{ width: '100%' }}
                      />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      name="monthly_withdrawal_limit"
                      label="Monthly withdrawal limit"
                      key={`card-monthly_withdrawal_limit-${selectedCard?.id}`}
                    >
                      <InputNumber
                        size="medium"
                        prefix="$"
                        precision={2}
                        placeholder="0.00"
                        name="monthly_withdrawal_limit"
                        min={0}
                        style={{ width: '100%' }}
                      />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
                  <Col span={24}>
                    <SubmitButton
                      type="primary"
                      htmlType="submit"
                      loading={isSubmitting}
                      onClick={handleSubmit}
                      style={{ width: '100%' }}
                    >
                      Set Limit
                    </SubmitButton>
                  </Col>
                </Row>
                {/* <pre
                style={{
                  background: '#f6f8fa',
                  fontSize: '.65rem',
                  padding: '.5rem',
                }}
              >
                <FormikDebug />
              </pre> */}
              </Form>
            );
          }}
        </Formik>
      </Modal>
      <Modal
        width={450}
        title={
          statusToChange === 'blocked_deposit'
            ? 'Block Deposits'
            : statusToChange === 'freeze'
            ? 'Freeze Card'
            : statusToChange === 'blocked_transfer'
            ? 'Block Transfers'
            : 'Block Card'
        }
        visible={showBlockCardBox}
        onCancel={handleCancelBox}
        footer={false}
        className="cardModal"
      >
        <Row style={{ marginTop: '20px' }}>
          <Col span={24}>
            <p className="descriptionText">
              You are about to{' '}
              {statusToChange === 'freeze'
                ? 'freeze card'
                : statusToChange === 'blocked_deposit'
                ? 'block deposits'
                : statusToChange === 'blocked_transfer'
                ? 'block transfers'
                : 'block card'}{' '}
              from this account. Are you sure about this?
            </p>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
          <Col span={12}>
            <label>Account Number</label>
            <p>{selectedCard?.card_number}</p>
          </Col>
          <Col span={12}>
            <label>Account Name</label>
            <p>{selectedCard?.card_owner}</p>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
          <Col span={24}>
            <label>Card Status</label>
            <p className="capitalize">{selectedCard?.status}</p>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
          <Col span={24}>
            <Button
              className="actionBtn"
              type="danger"
              size="large"
              style={{ width: '100%' }}
              onClick={() => handleCardStatus(selectedCard?.id, statusToChange)}
            >
              {statusToChange === 'freeze' ? 'Freeze Card' : 'Block Card'}
            </Button>
          </Col>
        </Row>
      </Modal>
    </Spin>
  );
};

export default CardsTable;

import axios from 'axios';

import { API_BASE_URL } from 'constants/config';
import { apiWrapper } from './interceptors';

const request = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30 * 1000,
});

const cardsApi = {
  getAllCards: (body) => {
    let path = '';
    if(body.issued_cards){
      path = 'admin/cards/issued'
      if(body.cardType == 'payout'){
        path = 'admin/cards/single_load'
      }else if(body.cardType == 'business'){
        path = 'admin/business/cards/issued'
      }
    }else{
      path = '/admin/cards/tokenized'
    }
    return new Promise((resolve, reject) => {
      request
        .post(path, { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  blockCard: (body) => {
    // const path = body.issued_cards ? 'admin/cards/issued/update/status' : '/admin/cards/tokenized/update/status';
    return new Promise((resolve, reject) => {
      request
        .post('admin/cards/issued/update/status', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  cardLimit: (body) => {
    // const path = body.issued_cards ? 'admin/cards/issued/update' : '/admin/cards/tokenized/update';
    return new Promise((resolve, reject) => {
      request
        .post('admin/cards/issued/update', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },
};

export default apiWrapper(cardsApi);

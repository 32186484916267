import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  //  Font
} from '@react-pdf/renderer';
import { getBackoffice } from 'helpers/localStorage';

// // Register font
// Font.register({ family: 'Circular Std', src: source });

// Create styles
const common = {
  topRowOption: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    height: 20,
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    paddingTop: 4,
    paddingRight: 0,
    paddingBottom: 4,
    paddingLeft: 10,

    height: 20,

    borderWidth: 0.5,
    borderStyle: 'solid',
    borderColor: '#F1F5F9',
  },
  topRowOptionText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    paddingTop: 4,
    paddingRight: 0,
    paddingBottom: 4,
    paddingLeft: 10,

    height: 20,

    borderWidth: 0.5,
    borderStyle: 'solid',
    borderColor: '#F1F5F9',

    textTransform: 'uppercase',
    fontSize: 8,
    fontWeight: 300,
    color: '#212934',
  },
  rowValue: {
    paddingTop: 4,
    paddingRight: 0,
    paddingBottom: 4,
    paddingLeft: 10,

    height: 22,

    borderWidth: 0.5,
    borderStyle: 'solid',
    borderColor: '#F1F5F9',

    fontSize: 10,
    fontWeight: 300,
    color: '#212934',
  },
};

const styles = StyleSheet.create({
  pageHeaderBlueLine: {
    width: 595,
    height: 2.32,
    left: 0,
    top: 0,
    backgroundColor: '#0CB9E9',
  },
  pageHeaderBar: {
    width: 595,
    height: 36,
    backgroundColor: '#F7FBFF',
  },
  pageFooter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    width: 595,
    height: 38,
    padding: 8,
  },
  pageFooterText: {
    fontSize: 8,
    textAlign: 'center',
    color: '#94A3B8',
  },
  logo: {
    width: 88,
    marginTop: 10,
    marginLeft: 40,
  },
  contentTopRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',

    width: 515,

    marginBottom: 15,
  },
  topRowOptionLabel: {
    textTransform: 'uppercase',

    fontSize: 8,
    fontWeight: 450,
    color: '#5F6B7A',
  },
  verificationStatus: {
    ...common.topRowOption,
    width: 161,
  },
  verificationCont: {
    ...common.optionContainer,
    width: 121,
  },
  verificationStatusText: {
    ...common.topRowOptionText,
    width: 48,
  },
  verificationDate: {
    ...common.topRowOption,
    width: 192,
  },
  verificationDateCont: {
    ...common.optionContainer,
    width: 125,
  },
  verificationDateText: {
    ...common.topRowOptionText,
    width: 67,
  },
  liveness: {
    ...common.topRowOption,
    width: 122,
  },
  livenessCont: {
    ...common.optionContainer,
    width: 74,
  },
  livenessText: {
    ...common.topRowOptionText,
    width: 67,
  },
  icon: {
    width: 12,
    height: 12,
    marginRight: 5,
  },
  pageContent: {
    padding: 30,
  },
  pageTitle: {
    fontSize: 18,
    color: '#8895A7',
    marginBottom: 16,
  },
  card: {
    marginBottom: 0,
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',

    paddingTop: 4,
    paddingBottom: 4,
    paddingRight: 10,
    paddingLeft: 10,

    width: 515,
    height: 19,
    backgroundColor: '#F4F7F9',
  },
  tableHeaderText: {
    fontSize: 10,
    fontWeight: 500,
    color: '#4F627D',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  rowLabel: {
    paddingTop: 4,
    paddingRight: 0,
    paddingBottom: 4,
    paddingLeft: 10,

    width: 122,
    height: 22,

    borderWidth: 0.5,
    borderStyle: 'solid',
    borderColor: '#F1F5F9',

    fontSize: 10,
    fontWeight: 300,
    color: '#5F6B7A',

    fontSize: 10,
    fontWeight: 300,
    color: '#5F6B7A',
  },
  rowValue: {
    ...common.rowValue,
    width: 135,
  },
  rowValueExt: {
    ...common.rowValue,
    width: 393,
  },
  verificationLinkRow: {
    marginTop: 10,
  },
});

// Create Document Component
const KysyncReportPDF = (data) => {
  const backOffice = getBackoffice();
  const { kyc_report } = data?.data;
  return (
    <Document>
      <Page size="A4">
        <View>
          <View style={styles.pageHeaderBlueLine}></View>
          <View style={styles.pageHeaderBar}>
            <Image
              style={styles.logo}
              src={backOffice?.data?.logo || 'https://assets.deposits.inc/img/logo/deposits/png/logo_main.png'}
              alt={backOffice?.data?.name || 'Despsits'}
            />
          </View>
          <View style={styles.pageContent}>
            <View>
              <Text style={styles.pageTitle}>KySync Report</Text>
            </View>

            <View style={styles.contentTopRow}>
              <View style={styles.verificationStatus}>
                <View style={styles.verificationCont}>
                  <Image src="/images/icons/green_tick.png" style={styles.icon} />
                  <Text style={styles.topRowOptionLabel}>Verification status:</Text>
                </View>
                <Text style={styles.verificationStatusText}>{kyc_report?.verification_status}</Text>
              </View>
              <View style={styles.verificationDate}>
                <View style={styles.verificationDateCont}>
                  <Image src="/images/icons/calendar.png" style={styles.icon} />
                  <Text style={styles.topRowOptionLabel}>Date of verification:</Text>
                </View>
                <Text style={styles.verificationDateText}>{kyc_report?.verification_date}</Text>
              </View>
              <View style={styles.liveness}>
                <View style={styles.livenessCont}>
                  <Image src="/images/icons/liveness.png" style={styles.icon} />
                  <Text style={styles.topRowOptionLabel}>Liveness:</Text>
                </View>
                <Text style={styles.livenessText}>{kyc_report?.liveness}</Text>
              </View>
            </View>

            <View style={styles.card}>
              <View style={styles.tableHeader}>
                <Text style={styles.tableHeaderText}>IDENTITY</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>First Name</Text>
                <Text style={styles.rowValue}>{kyc_report?.first_name}</Text>
                <Text style={styles.rowLabel}>Country</Text>
                <Text style={styles.rowValue}>{kyc_report?.country}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>Last Name</Text>
                <Text style={styles.rowValue}>{kyc_report?.last_name}</Text>
                <Text style={styles.rowLabel}>Phone</Text>
                <Text style={styles.rowValue}>{kyc_report?.phone}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>Date of Birth (DOB)</Text>
                <Text style={styles.rowValue}>{kyc_report?.date_of_birth}</Text>
                <Text style={styles.rowLabel}>Email</Text>
                <Text style={styles.rowValue}>{kyc_report?.email}</Text>
              </View>
            </View>
            <View style={styles.card}>
              <View style={styles.tableHeader}>
                <Text style={styles.tableHeaderText}>BUSINESS ASSESSMENT</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>Business Legal Name</Text>
                <Text style={styles.rowValue}>{kyc_report?.business_legal_name}</Text>
                <Text style={styles.rowLabel}>Business Type</Text>
                <Text style={styles.rowValue}>{kyc_report?.business_type}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>DBA</Text>
                <Text style={styles.rowValue}>{kyc_report?.dba}</Text>
                <Text style={styles.rowLabel}>Country</Text>
                <Text style={styles.rowValue}>{kyc_report?.business_country}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>Registered State</Text>
                <Text style={styles.rowValue}>{kyc_report?.registered_state}</Text>
                <Text style={styles.rowLabel}>Beneficial Owner</Text>
                <Text style={styles.rowValue}>{kyc_report?.beneficiary_owner}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>Date of Incorporation</Text>
                <Text style={styles.rowValue}>{kyc_report?.date_of_incoporation}</Text>
                <Text style={styles.rowLabel}>Ownership %</Text>
                <Text style={styles.rowValue}>{kyc_report?.ownerhip}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>EIN</Text>
                <Text style={styles.rowValue}>{kyc_report?.ein}</Text>
                <Text style={styles.rowLabel}></Text>
                <Text style={styles.rowValue}></Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>Business Legal Address</Text>
                <Text style={styles.rowValueExt}>{kyc_report?.business_legal_address}</Text>
              </View>
            </View>
            <View style={styles.card}>
              <View style={styles.tableHeader}>
                <Text style={styles.tableHeaderText}>Risk Assessment</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>Country Risk</Text>
                <Text style={styles.rowValue}>{kyc_report?.country_risk}</Text>
                <Text style={styles.rowLabel}>ML Risk</Text>
                <Text style={styles.rowValue}>{kyc_report?.ml_risk}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>PEP Risk</Text>
                <Text style={styles.rowValue}>{kyc_report?.pep_risk}</Text>
                <Text style={styles.rowLabel}>Credit Risk</Text>
                <Text style={styles.rowValue}>{kyc_report?.credit_risk}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>Sanctions Score</Text>
                <Text style={styles.rowValue}>{kyc_report?.sanctions_score}</Text>
                <Text style={styles.rowLabel}>Address Risk</Text>
                <Text style={styles.rowValue}>{kyc_report?.address_risk}</Text>
              </View>
            </View>
            <View style={styles.card}>
              <View style={styles.tableHeader}>
                <Text style={styles.tableHeaderText}>Submitted Documents</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>Drivers License</Text>
                <Text style={styles.rowValueExt}>{kyc_report?.drivers_license}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>Int'l Passport</Text>
                <Text style={styles.rowValueExt}>{kyc_report?.intl_passport}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>Brand Authorization</Text>
                <Text style={styles.rowValueExt}>{kyc_report?.brand_authorization}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>MNDA</Text>
                <Text style={styles.rowValueExt}>{kyc_report?.mnda}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>ETF</Text>
                <Text style={styles.rowValueExt}>{kyc_report?.etf}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>MSA</Text>
                <Text style={styles.rowValueExt}>{kyc_report?.msa}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>MOU</Text>
                <Text style={styles.rowValueExt}>{kyc_report?.mou}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>Deposits ID</Text>
                <Text style={styles.rowValueExt}>{kyc_report?.depositsID}</Text>
              </View>
            </View>
            <View style={styles.card}>
              <View style={styles.tableHeader}>
                <Text style={styles.tableHeaderText}>Broswer Info</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>Browser</Text>
                <Text style={styles.rowValue}>{kyc_report?.browser}</Text>
                <Text style={styles.rowLabel}>Device Name</Text>
                <Text style={styles.rowValue}>{kyc_report?.device_name}</Text>
              </View>

              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>Platform</Text>
                <Text style={styles.rowValue}>{kyc_report?.platform}</Text>
                <Text style={styles.rowLabel}>Device Type</Text>
                <Text style={styles.rowValue}>{kyc_report?.device_type}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>IP</Text>
                <Text style={styles.rowValue}>{kyc_report?.ip}</Text>
                <Text style={styles.rowLabel}>Geo location</Text>
                <Text style={styles.rowValue}>{kyc_report?.geolocation}</Text>
              </View>
            </View>

            <View style={styles.verificationLinkRow}>
              <View style={styles.tableRow}>
                <Text style={styles.rowLabel}>Verification URL</Text>
                <Text style={styles.rowValueExt}>Coming Soon</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.pageFooter}>
          <Text style={styles.pageFooterText}>
            ©2021 Deposits. All Rights Reserved. 2001 Ross Avenue, Suite 8 F Dallas, TX 75201. Deposits is a
            financial technology company, not a bank. Banking services provided by insured partner Banks.
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default KysyncReportPDF;

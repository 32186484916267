import axios from 'axios';

import { API_BASE_URL } from '@Constants/config';
import { apiWrapper } from './interceptors';
// const host = window.location.origin;

const request = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30 * 1000,
});

const usersApi = {
  getUsers: (body) => {
    // const path = body?.entityType === 'business' ? '/admin/business' : '/admin/individual';
    return new Promise((resolve, reject) => {
      request
        .post('admin/entities', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getUserTransaction: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('admin/transactions/individual', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getUser: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/entities/show', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  blockUser: (body) => {
    let path = '/admin/individual/update';
    if (body?.entityType === 'business') {
      if (body.status === 'active') {
        path = '/admin/business/unblock_business';
      } else {
        path = '/admin/business/block_business';
      }
    } else {
      body.entityType = undefined;
    }

    return new Promise((resolve, reject) => {
      request
        .post(path, { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getTeamMembers: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/teams', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getActivityLog: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/activities', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  deleteTeamMember: (body) => {
    const { team_member_id, token, api_key } = body;
    return new Promise((resolve, reject) => {
      request
        .post(`/admin/teams/delete/${team_member_id}`, { token, api_key })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  createTeamMember: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/teams/create', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  updateTeamMember: (body) => {
    const { id } = body;
    body.id = undefined;
    // body.first_name = undefined;
    // body.last_name = undefined;
    return new Promise((resolve, reject) => {
      request
        .post(`/admin/teams/update/${id}`, { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getRoles: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/roles', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getUserNameEmail: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('admin/get-user-name-email', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getUserNamePhone: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('admin/entities/users-with-sms-enabled', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getUserNamePushToken: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('admin/entities/users-with-push-notification-enabled', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },
};

export default apiWrapper(usersApi);

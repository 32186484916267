import React from 'react';
import { Row, Col } from 'antd';

import LayoutWarpper from 'components/Layout';
import PayoutsTable from 'components/PayoutsTable';

import './Payouts.less';

const Payouts = () => {
  return (
    <LayoutWarpper>
      <h1 className="page-title">Payouts</h1>
      <Row style={{ width: '100%' }} gutter={[16, 16]}>
        <Col span={24}>
          <PayoutsTable />
        </Col>
      </Row>
    </LayoutWarpper>
  );
};

export default Payouts;

import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Card, Col, notification, Radio, Row, Space, Spin } from 'antd';
import { find } from 'lodash';

import { getAdmin, getBackoffice } from 'helpers/localStorage';

import { currencyFormat, numberFormat } from 'helpers/utils';
import { useKySyncFundingSources, useKysyncPlans, usePurchaseKySyncPlan } from 'rc-query/kysyncHooks';
import LayoutWrapper from 'components/Layout/Layout';

import './KySync.less';

const KySync_PurchaseCredits = () => {
  const history = useHistory();

  const [planSelected, setPlanSelected] = useState();
  const [wallet, setWallet] = useState();
  const [checkoutStep, setCheckoutStep] = useState('select_plan');
  const [planError, setPlanError] = useState(false);
  const [walletError, setWalletError] = useState(false);

  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const KySyncPlans = useKysyncPlans({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
  });

  const KySyncFundingSources = useKySyncFundingSources({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
  });

  const purchaseKySyncPlan = usePurchaseKySyncPlan();

  const handlePlanChange = (e) => {
    const selectedPlan = find(KySyncPlans?.data, (plan) => plan.id === e.target.value);
    setPlanSelected(selectedPlan);
  };

  const handleWalletChange = (e) => {
    setWallet(e.target.value);
  };

  const handleCheckoutNextStep = (step) => {
    if (planSelected?.id) {
      setCheckoutStep(step);
    } else {
      setPlanError(true);
      return;
    }
  };

  const handleCheckout = () => {
    if (!wallet) {
      setWalletError(true);
      return;
    }

    setWalletError(false);

    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      wallet: wallet,
      plan: planSelected.id,
    };

    purchaseKySyncPlan.mutateAsync(body).then((data) => {
      if (data.status === 'success') {
        notification.success({
          message: data.message,
        });
        history.push('/kysync');
      } else {
        notification.error({
          message: data.message,
        });
      }
    });
  };

  return (
    <LayoutWrapper>
      <div className="KySyncPage">
        <h1 className="page-title">KySync - Purchase Credits</h1>
        <Spin spinning={KySyncPlans?.isLoading}>
          <h5 className="description">Choose your preferred plan</h5>

          <div className="plans" style={checkoutStep === 'select_plan' ? { display: 'block' } : { display: 'none' }}>
            <Radio.Group name="radiogroup" value={planSelected?.id} onChange={handlePlanChange}>
              {KySyncPlans?.data?.map((plan) => (
                <Card className="card" key={`plan-${plan?.id}`}>
                  <label className="cursor-pointer">
                    <div className="plan">
                      <div>
                        <p className="title">{plan?.name}</p>
                        <p className="hits">
                          {numberFormat(plan?.hits)} <span>Hits</span>
                        </p>
                      </div>
                      {plan?.note != '' && (
                        <div className="note">
                          <span>{plan?.note}</span>
                        </div>
                      )}
                      <div className="price">
                        <span>{currencyFormat(plan?.price, plan?.currency)}</span>
                        <Radio value={plan?.id} />
                      </div>
                    </div>
                  </label>
                </Card>
              ))}
            </Radio.Group>
            {planError && <div className="fieldError">Please choose your preferred plan</div>}

            <div className="kyc-btn">
              <Space>
                <Link to="/kysync">
                  <Button size="large">Cancel</Button>
                </Link>
                <Button type="primary" size="large" onClick={() => handleCheckoutNextStep('select_wallet')}>
                  Checkout
                </Button>
              </Space>
            </div>
          </div>

          <div className="payNow" style={checkoutStep === 'select_wallet' ? { display: 'block' } : { display: 'none' }}>
            <Card className="card" title="ORDER SUMMARY">
              <div className="planDetail">
                <Row>
                  <Col span={12}>
                    <div className="label">Item</div>
                    <div className="value">
                      {planSelected?.name}: {numberFormat(planSelected?.hits || 0)} Hits
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="label">Amount</div>
                    <div className="value">{currencyFormat(planSelected?.price)}</div>
                  </Col>
                </Row>
              </div>
            </Card>

            <div className="heading">Pay from</div>

            <Radio.Group onChange={handleWalletChange} value={wallet}>
              {KySyncFundingSources?.data?.wallets?.map((fundingSource) => (
                <label className="cursor-pointer">
                  <div className="walletOptions">
                    <Row style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                      <Col span={2}>
                        <div className="logo">
                          {fundingSource.id === 'funding_account' ? (
                            <img src="images/banks/deposits.svg" alt={fundingSource.name} />
                          ) : (
                            <img src="images/banks/deposits.svg" alt={fundingSource.name} />
                          )}
                          {/* <img src="images/banks/wells_fargo.svg" alt="wells fargo" /> */}
                        </div>
                      </Col>
                      <Col span={10}>
                        <div className="content">
                          <p className="title">{fundingSource.name}</p>
                          {/* <p className="number">Checking **1243</p> */}
                        </div>
                      </Col>
                      {/* <Col span={10}>
                    <div className="actions">
                      <div className="tags">
                        <Select name="">
                          <Option value="1">1</Option>
                          <Option value="2">2</Option>
                        </Select>
                      </div>
                    </div>
                  </Col> */}
                      <Col span={12}>
                        <div className="actions">
                          <div className="tags">
                            <Radio name="wallet" value={fundingSource.id} />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </label>
              ))}
            </Radio.Group>
            {walletError && <div className="fieldError">Please select Pay From Wallet</div>}
            <div className="kyc-btn">
              <Space>
                <Link to="/kysync">
                  <Button size="large">Cancel</Button>
                </Link>
                <Button type="primary" size="large" onClick={() => handleCheckout()}>
                  Checkout
                </Button>
              </Space>
            </div>
          </div>
        </Spin>
      </div>
    </LayoutWrapper>
  );
};

export default KySync_PurchaseCredits;

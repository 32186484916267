import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  Tabs,
  Pagination,
  Spin,
  Dropdown,
  Menu,
  Modal,
  Row,
  Col,
  Button,
  Radio,
  Select,
  Input,
  notification,
} from 'antd';
import moment from 'moment';
import { EllipsisOutlined } from '@ant-design/icons';
import { queryClient } from 'rc-query';

import ExportFile from 'components/ExportFile';
import { DEFAULT_PAGE_SIZE } from 'constants/config';
import { GET_ALL_PAYOUTS_QY } from 'rc-query/keys';
import { usePayouts, useStatusPayout } from 'rc-query/payoutHooks';
import { getAdmin, getBackoffice } from 'helpers/localStorage';
import { moneyFormat } from 'helpers/utils';

import './PayoutsTable.less';

const { TabPane } = Tabs;
const { Option } = Select;
const { Search } = Input;

const PayoutsTable = () => {
  const [showPayoutDetail, setShowPayoutDetail] = useState(false);
  const [showReversePayout, setShowReversePayout] = useState(false);
  const [showStatusPayout, setShowStatusPayout] = useState({ show: false, status: null });
  const [payoutDetail, setPayoutDetail] = useState({
    id: '',
    transaction_id: '',
    beneficiary: '',
    account_number: '',
    amount: '',
    created_at: '',
  });

  const [filters, setFilters] = useState({
    page: 1,
    per_page: DEFAULT_PAGE_SIZE,
  });

  const [entityType, setEntityType] = useState('individual');

  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const statusPayout = useStatusPayout();

  const { isLoading, data } = usePayouts({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
    entityType,
    ...filters,
  });

  const payouts = data?.payouts?.data || [];
  const totalPayouts = data?.payouts?.total || 0;
  const currentPage = data?.payouts?.current_page || 0;
  const stats = data?.stats || {};
  const all = stats?.total || 0;
  const completed = stats?.completed || 0;
  const pending = stats?.pending || 0;
  const cancelled = stats?.cancelled || 0;
  const over_the_limits = stats?.over_the_limits || 0;

  const columns = [
    {
      title: 'Transaction ID',
      dataIndex: 'transaction_id',
      key: 'transaction_id',
      sorter: true,
    },
    {
      title: 'Beneficiary',
      dataIndex: 'account.account_holder_name || account.account_name',
      key: 'account_holder_name',
      render: (text, record) => <span>{record.account.account_holder_name || record.account.account_name}</span>,
    },
    {
      title: 'Account Number',
      dataIndex: 'account.account_number',
      key: 'account_number',
      render: (text, record) => <span>{record.account.account_number}</span>,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      sorter: true,
      render: (text, record) => <span>{moneyFormat(record.amount, 2)}</span>,
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (text, record) => moment(record.created_at).format('MMM DD, YYYY'),
    },
    {
      title: 'Action',
      key: 'id',
      dataIndex: 'id',
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu key={`menu-${record.id}`}>
              <Menu.Item key={`view-${record.id}`} onClick={() => handleViewPayout(record)}>
                View
              </Menu.Item>
              {record.status === 'pending' ? (
                <>
                  <Menu.Item
                    key={`approve-${record.id}`}
                    onClick={() => handleShowStatusPayout(record, 'approve')}
                    style={{ color: 'green' }}
                  >
                    Approve
                  </Menu.Item>
                  <Menu.Item
                    key={`reject-${record.id}`}
                    onClick={() => handleShowStatusPayout(record, 'reject')}
                    style={{ color: 'red' }}
                  >
                    Reject
                  </Menu.Item>
                </>
              ) : null}
            </Menu>
          }
        >
          <a className="ant-dropdown-link">
            <EllipsisOutlined />
          </a>
        </Dropdown>
      ),
    },
  ];

  const handleViewPayout = (transaction) => {
    setPayoutDetail(transaction);
    setShowPayoutDetail(true);
  };

  const handleShowStatusPayout = (transaction, status) => {
    setPayoutDetail(transaction);
    setShowPayoutDetail(true);
    setShowStatusPayout({ show: true, status });
  };

  const handleStatusPayout = (id, status) => {
    statusPayout
      .mutateAsync({
        api_key: backOffice?.data?.name,
        token: adminUser?.token,
        id,
        status,
      })
      .then((res) => {
        if (res.status === 'success') {
          notification.success({
            message: res.message,
          });
          queryClient.refetchQueries([GET_ALL_PAYOUTS_QY]);
          setShowPayoutDetail(false);
          setShowReversePayout(false);
          setShowStatusPayout({ show: false, status: null });
        } else {
          notification.error({
            message: res.message,
          });
        }
      });
  };

  const handleCancelBox = () => {
    setShowPayoutDetail(false);
    setShowReversePayout(false);
    setPayoutDetail({});
    setShowStatusPayout({ show: false, status: null });
  };

  const handlePageChange = (page) => {
    setFilters({ ...filters, page });
  };

  const handleTabs = (key) => {
    setFilters(key !== 'all' ? { ...filters, status: key } : {});
  };

  const handleTableChange = (pagination, tableFilters, sorter) => {
    setFilters({
      ...filters,
      page: 1,
      per_page: DEFAULT_PAGE_SIZE,
      sort_by_column: sorter.field,
      sort_by_type: sorter.order === 'ascend' ? 'ASC' : 'DESC',
    });
  };

  const onSearch = (value) => {
    const searchKeyword = value?.toString() || undefined;

    setFilters({
      search: searchKeyword,
      page: 1,
      per_page: DEFAULT_PAGE_SIZE,
    });
  };

  const handleFilter = (value) => {
    delete filters.month;
    if (value == 'current_month') {
      const d = new Date();
      let month = d.getMonth();
      setFilters({ ...filters, month: month + 1, date: undefined });
    } else if (value == 'today') {
      setFilters({ ...filters, date: moment().format('YYYY-MM-DD'), month: undefined });
    } else if (value == 'all') {
      setFilters({ ...filters, month: undefined, date: undefined });
    }
  };

  const handleEntityTypeChange = (value) => {
    setEntityType(value);
    // setFilters({
    //   page: 1,
    //   per_page: DEFAULT_PAGE_SIZE,
    //   entityType: value,
    // });
  };

  return (
    <Spin spinning={isLoading}>
      <Row>
        <Col span={16}>
          <Tabs defaultActiveKey="0" left="true" size="large" onChange={handleTabs}>
            <TabPane onChange={() => handleTabs('all')} tab={`All (${all})`} key="all" />
            <TabPane onChange={() => handleTabs('pending')} tab={`Pending (${pending})`} key="pending" />
            <TabPane onChange={() => handleTabs('completed')} tab={`Completed (${completed})`} key="completed" />
            <TabPane onChange={() => handleTabs('cancelled')} tab={`Cancelled (${cancelled})`} key="cancelled" />
            <TabPane
              onChange={() => handleTabs('over_the_limits')}
              tab={`Over-the-limits (${over_the_limits})`}
              key="over_the_limits"
            />
          </Tabs>
        </Col>

        <Col span={8}>
          <div className="d-flex flex-end">
            <div>
            <Radio.Group>
              <Select defaultValue="all" onChange={handleFilter} style={{ width: 200, marginRight: '20px' }}>
                <Option value="all">All Time</Option>
                <Option value="today">Today</Option>
                <Option value="current_month">This Month</Option>
              </Select>
            </Radio.Group>
            <p></p>
            <Radio.Group>
              <Select defaultValue="individual" onChange={handleEntityTypeChange} style={{ width: 200, marginRight: '20px' }}>
                <Option value="individual">Individual</Option>
                <Option value="business">Business</Option>
              </Select>
            </Radio.Group>
            </div>
            <Button type="primary" size="large">
              <Link to={'/payouts/send_money'}>Send Money</Link>
            </Button>
          </div>
        </Col>
      </Row>
      <div className="payouts">
        <div className="searchBox" style={{ margin: '15px 0px' }}>
          <Search
            placeholder="Search by transaction and status"
            onSearch={onSearch}
            allowClear
            style={{ width: 300 }}
          />
          <div>
            <ExportFile data_type="payouts" />
          </div>
        </div>
        <Table
          key="payout-table"
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={payouts}
          onChange={handleTableChange}
          pagination={false}
        />
        {totalPayouts > 0 ? (
          <div className="pagination">
            <Pagination
              showLessItems
              defaultPageSize={DEFAULT_PAGE_SIZE}
              total={totalPayouts}
              showSizeChanger={false}
              current={currentPage}
              onChange={handlePageChange}
            />
          </div>
        ) : null}
      </div>
      <Modal
        width={600}
        title="Payout Detail"
        visible={showPayoutDetail}
        onCancel={handleCancelBox}
        footer={false}
        className="payoutDetailModal"
      >
        {showReversePayout ? (
          <Row style={{ marginTop: '20px' }}>
            <Col span={24}>
              <p className="descriptionText">
                You are about to reverse a transfer from this account. Are you sure about this?
              </p>
            </Col>
          </Row>
        ) : null}

        <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
          <Col span={12}>
            <label>Transaction ID</label>
            <p>{payoutDetail?.transaction_id}</p>
          </Col>
          <Col span={12}>
            <label>Beneficiary</label>
            <p>{payoutDetail?.account?.email}</p>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
          <Col span={12}>
            <label>Type</label>
            <p>
            Currency <span className="upperCase">{payoutDetail?.from_currency}</span>{' '}
            </p>
          </Col>
          <Col span={12}>
            <label>Value</label>
            <p>{moneyFormat(payoutDetail?.amount, 2)}</p>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
          <Col span={12}>
            <label>Created Date</label>
            <p>{moment(payoutDetail?.created_at).format('MMM DD, YYYY')}</p>
          </Col>
          <Col span={12}>
            <label>Status</label>
            <p>
              <span className="capitalize">{payoutDetail?.status}</span>
            </p>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
          <Col span={24}>
            {showStatusPayout.status === 'approve' ? (
              <Button
                className="actionBtn blueBorderd"
                type="primary"
                size="large"
                style={{ width: '100%' }}
                onClick={() => handleStatusPayout(payoutDetail.id, 'approve')}
              >
                Approve
              </Button>
            ) : showStatusPayout.status === 'reject' ? (
              <Button
                className="actionBtn blueBorderd"
                type="primary"
                size="large"
                style={{ width: '100%' }}
                onClick={() => handleStatusPayout(payoutDetail.id, 'reject')}
                danger
              >
                Reject
              </Button>
            ) : (
              <Button
                className="actionBtn blueBorderd"
                type="secondary"
                size="large"
                style={{ width: '100%' }}
                onClick={handleCancelBox}
              >
                Close
              </Button>
            )}
          </Col>
        </Row>
      </Modal>
    </Spin>
  );
};

export default PayoutsTable;

import React, { useState } from 'react';
import { Table, Tabs, Pagination, Spin, Tag, Dropdown, Menu, Modal, Row, Col, Button, Radio, Select, notification } from 'antd';
import moment from 'moment';
import { EllipsisOutlined } from '@ant-design/icons';
import { queryClient } from 'rc-query';

// import { ExportIcon } from 'components/Icons/Icons';
import ExportFile from 'components/ExportFile';
import { DEFAULT_PAGE_SIZE } from 'constants/config';
import { GET_ALL_TRANSACTIONS_QY } from 'rc-query/keys';
import { useAllTransactions, useReverseTransactionMutation } from 'rc-query/transactionHooks';

import TableColumnTextFilterConfig from '../tableColumnTextFilter/TableUtils';
import { getAdmin, getBackoffice } from 'helpers/localStorage';
import { moneyFormat, renderTransactionStatus } from 'helpers/utils';

import './AllTransactionTable.less';

const { TabPane } = Tabs;
const { Option } = Select;

const AllTransactionsTable = () => {
  const [showTransactionDetail, setShowTransactionDetail] = useState(false);
  const [showReverseTransaction, setShowReverseTransaction] = useState(false);
  const [transactionDetail, setTransactionDetail] = useState({
    id: '',
    transaction_id: '',
    beneficiary: '',
    category: '',
    amount: '',
    created_at: '',
    status: '',
  });

  const [filters, setFilters] = useState({
    page: 1,
    per_page: DEFAULT_PAGE_SIZE,
  });

  // const [filter, setFilter] = useState('Monthly');
  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const reverseTransactionMutation = useReverseTransactionMutation();

  const { isLoading, data } = useAllTransactions({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
    ...filters,
  });

  const allTransactions = data?.data?.data || [];
  const totalTransactions = data?.data?.total || 0;
  const currentPage = data?.data?.current_page || 0;
  const stats = data?.stats || {};
  const all = stats?.total || 0;
  const completed = stats?.completed || 0;
  const pending = stats?.pending || 0;
  const cancelled = stats?.cancelled || 0;
  const refunded = stats?.refunded || 0;
  const active = stats?.active || 0;

  const columns = [
    {
      title: 'Transaction ID',
      dataIndex: 'transaction_id',
      key: 'transaction_id',
      sorter: true,
      ...TableColumnTextFilterConfig(),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      ...TableColumnTextFilterConfig(),
    },
    {
      title: 'Value',
      dataIndex: 'amount',
      key: 'amount',
      sorter: true,
      render: (text, record) => <span>{moneyFormat(record.amount, 2)}</span>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: true,
      render: (text, record) => <span className="capitalize">{record.type}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text, record) => <span>{renderTransactionStatus(record.status, Tag)}</span>,
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (text, record) => moment(record.created_at).format('MMM DD, YYYY'),
    },
    {
      title: 'Action',
      key: 'id',
      dataIndex: 'id',
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu key={`menu-${record.id}`}>
              <Menu.Item key={`view-${record.id}`} onClick={() => handleViewTransaction(record)}>
                View
              </Menu.Item>
              <Menu.Item key={`reverse-${record.id}`} onClick={() => handleShowReverseTransaction(record)}>
                Reverse
              </Menu.Item>
            </Menu>
          }
        >
          <a className="ant-dropdown-link">
            <EllipsisOutlined />
          </a>
        </Dropdown>
      ),
    },
  ];

  const handleViewTransaction = (transaction) => {
    setTransactionDetail(transaction);
    setShowTransactionDetail(true);
  };

  const handleShowReverseTransaction = (transaction) => {
    setTransactionDetail(transaction);
    setShowTransactionDetail(true);
    setShowReverseTransaction(true);
  };

  const handleReverseTransaction = (id) => {
    reverseTransactionMutation
      .mutateAsync({
        api_key: backOffice?.data?.name,
        token: adminUser?.token,
        id,
      })
      .then((res) => {
        if (res.status === 'success') {
          notification.success({
            message: res.message,
          });
          queryClient.refetchQueries([GET_ALL_TRANSACTIONS_QY]);
          setShowTransactionDetail(false);
          setShowReverseTransaction(false);
        } else {
          notification.error({
            message: res.message,
          });
        }
      });
  };

  const handleCancelBox = () => {
    setShowTransactionDetail(false);
    setShowReverseTransaction(false);
    setTransactionDetail({});
  };

  const handlePageChange = (page) => {
    setFilters({ ...filters, page });
  };

  const handleTabs = (key) => {
    setFilters(key !== 'all' ? { ...filters, status: key } : {});
  };

  const handleTableChange = (pagination, tableFilters, sorter) => {
    const email = tableFilters?.email?.[0]?.toString() || undefined;
    const transaction_id = tableFilters?.transaction_id?.[0]?.toString() || undefined;

    setFilters({
      ...filters,
      email,
      transaction_id,
      page: 1,
      per_page: DEFAULT_PAGE_SIZE,
      sort_by_column: sorter.field,
      sort_by_type: sorter.order === 'ascend' ? 'ASC' : 'DESC',
    });
  };

  const handleFilter = (value) => {
    delete filters.month;
    if (value == 'current_month') {
      const d = new Date();
      let month = d.getMonth();
      setFilters({ ...filters, month: month + 1, date: undefined });
    } else if (value == 'today') {
      setFilters({ ...filters, date: moment().format('YYYY-MM-DD'), month: undefined });
    } else if (value == 'all') {
      setFilters({ ...filters, month: undefined, date: undefined });
    }
  };

  return (
    <Spin spinning={isLoading}>
      <Row>
        <Col span={20}>
          <Tabs defaultActiveKey="0" left="true" size="large" onChange={handleTabs}>
            <TabPane onChange={() => handleTabs('all')} tab={`All (${all})`} key="all" />
            <TabPane onChange={() => handleTabs('active')} tab={`Active (${active})`} key="active" />
            <TabPane onChange={() => handleTabs('completed')} tab={`Completed (${completed})`} key="completed" />
            <TabPane onChange={() => handleTabs('pending')} tab={`Pending (${pending})`} key="pending" />
            <TabPane onChange={() => handleTabs('cancelled')} tab={`Cancelled (${cancelled})`} key="cancelled" />
            <TabPane onChange={() => handleTabs('refunded')} tab={`Refunded (${refunded})`} key="refunded" />
          </Tabs>
        </Col>

        <Col span={4}>
          <Radio.Group>
            <Select defaultValue="all" onChange={handleFilter} style={{ width: 200 }}>
              <Option value="all">All Time</Option>
              <Option value="today">Today</Option>
              <Option value="current_month">This Month</Option>
            </Select>
          </Radio.Group>
        </Col>
      </Row>
      <div className="transactions">
        <div className="searchBox">
          <ExportFile data_type="transactions" />
        </div>
        <Table
          key={`all-transaction-table`}
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={allTransactions}
          onChange={handleTableChange}
          pagination={false}
        />
        {totalTransactions > 0 ? (
          <div className="pagination">
            <Pagination
              showLessItems
              defaultPageSize={DEFAULT_PAGE_SIZE}
              total={totalTransactions}
              showSizeChanger={false}
              current={currentPage}
              onChange={handlePageChange}
            />
          </div>
        ) : null}
      </div>
      <Modal
        width={600}
        title="Transaction Detail"
        visible={showTransactionDetail}
        onCancel={handleCancelBox}
        footer={false}
        className="transactionDetailModal"
      >
        {showReverseTransaction ? (
          <Row style={{ marginTop: '20px' }}>
            <Col span={24}>
              <p className="descriptionText">
                You are about to reverse a transfer from this account. Are you sure about this?
              </p>
            </Col>
          </Row>
        ) : null}

        <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
          <Col span={12}>
            <label>Transaction ID</label>
            <p>{transactionDetail?.transaction_id}</p>
          </Col>
          <Col span={12}>
            <label>Beneficiary</label>
            <p>{transactionDetail?.email}</p>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
          <Col span={12}>
            <label>Type</label>
            <p>
              <span className="capitalize">{transactionDetail?.type}</span>{' '}
            </p>
          </Col>
          <Col span={12}>
            <label>Value</label>
            <p>{moneyFormat(transactionDetail?.amount, 2)}</p>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
          <Col span={12}>
            <label>Created Date</label>
            <p>{moment(transactionDetail?.created_at).format('MMM DD, YYYY')}</p>
          </Col>
          <Col span={12}>
            <label>Status</label>
            <p>
              <span className="capitalize">{transactionDetail?.status}</span>
            </p>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
          <Col span={24}>
            {showReverseTransaction ? (
              <Button
                className="actionBtn"
                type="primary"
                size="large"
                style={{ width: '100%' }}
                onClick={() => handleReverseTransaction(transactionDetail.id)}
              >
                Reverse transaction
              </Button>
            ) : (
              <Button
                className="actionBtn blueBorderd"
                type="secondary"
                size="large"
                style={{ width: '100%' }}
                onClick={handleCancelBox}
              >
                Close
              </Button>
            )}
          </Col>
        </Row>
      </Modal>
    </Spin>
  );
};

export default AllTransactionsTable;

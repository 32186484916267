import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { IoArrowBackCircle } from 'react-icons/io5';

import EntityDetail from 'components/EntityDetail';
import LayoutWarpper from 'components/Layout';

import './Entity.less';

const Entity = ({ match }) => {
  const { entityId, entityType } = match.params;
  const [fullName, setFullName] = useState('');

  return (
    <LayoutWarpper>
      <div className="backBtn">
        <Link to={`/entities`} style={{ display: 'flex', alignContent: 'center' }}>
          <IoArrowBackCircle size={22} style={{ marginRight: '13px' }} /> <span>Back to entities</span>
        </Link>
      </div>
      <h1 className="page-title">Entity {fullName} </h1>
      <Row style={{ width: '100%' }} gutter={[16, 16]}>
        <Col span={24}>
          <EntityDetail entityId={entityId} entityType={entityType} setUserFullName={setFullName} />
        </Col>
      </Row>
    </LayoutWarpper>
  );
};

export default Entity;

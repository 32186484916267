import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import { setFingerprint } from './localStorage';

export const assignFingerprint = () => {
    let visitorId = ''

    // Initialize an agent at application startup.
    const fpPromise = FingerprintJS.load({
        apiKey: "vZOLWDQeWgM0iZV7t1sV"
    })

    // Get the visitor identifier when you need it.
    fpPromise
        .then(fp => fp.get())
        .then((result) => {
            console.log(result.visitorId, 'result.visitorId')
            setFingerprint(result.visitorId)
        })
    return visitorId;
};
import React, { useState } from 'react';
import { Row, Col, Spin, Table, Pagination, Tag, Button, Modal, Input } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { IoArrowBackCircle } from 'react-icons/io5';

import { useGetAllActivityLog } from 'rc-query/userHooks';
import { getAdmin, getBackoffice } from 'helpers/localStorage';
import { DEFAULT_PAGE_SIZE } from 'constants/config';

import LayoutWarpper from 'components/Layout';
import ExportFile from 'components/ExportFile';

import './ActivityLog.less';

const { Search } = Input;

const ActivityLog = () => {
  const history = useHistory();
  // const userId = props.match.params.userId;
  const [filters, setFilters] = useState({
    page: 1,
    per_page: DEFAULT_PAGE_SIZE,
  });
  const [showActivityLogResponse, setShowActivityLogResponse] = useState(false);
  const [activityDetail, setActivityDetail] = useState({
    request: '',
    response: '',
  });

  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const { isLoading, data } = useGetAllActivityLog({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
    // userId:
    ...filters,
  });

  const activityLog = data?.data?.data || [];
  const totalActivityLog = data?.data?.total || 0;
  const currentPage = data?.data?.current_page || 0;

  const renderStatus = (status) => {
    switch (status) {
      case 'info':
        return <Tag color="blue">Info</Tag>;
      case 'failed':
        return <Tag color="red">Failed</Tag>;
      case 'successful':
        return <Tag color="green">Successful</Tag>;
      default:
        return null;
    }
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (text, record) => record.created_at,
    },
    {
      title: 'Entity',
      dataIndex: 'entity',
      key: 'entity',
      render: (text, record) => <span className="capitalize">{record.entity}</span>,
    },
    {
      title: 'Event',
      dataIndex: 'event',
      key: 'event',
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      sorter: true,
      render: (text, record) => <span>{renderStatus(record.status)}</span>,
    },
    {
      title: 'Event Type',
      dataIndex: 'event_type',
      key: 'event_type',
      sorter: true,
      render: (text, record) => (
        <span>
          <Tag className="grayTag">{record.event_type}</Tag>
        </span>
      ),
    },

    {
      title: 'Action',
      render: (text, record) => (
        <Button type="link" onClick={() => handleShowActivityLogResponse(record)}>
          View
        </Button>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setFilters({ ...filters, page });
  };

  const handleTableChange = (pagination, tableFilters, sorter) => {
    const name = tableFilters?.entity?.[0]?.toString() || undefined;

    setFilters({
      search: name,
      page: 1,
      per_page: DEFAULT_PAGE_SIZE,
      sort_by_column: sorter?.field,
      sort_by_type: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
    });
  };

  const onSearch = (value) => {
    const name = value?.toString() || undefined;

    setFilters({
      search: name,
      page: 1,
      per_page: DEFAULT_PAGE_SIZE,
    });
  };

  const handleShowActivityLogResponse = (record) => {
    setActivityDetail({ request: record.request, response: record.response });
    setShowActivityLogResponse(true);
  };

  const handleCancelBox = () => {
    setShowActivityLogResponse(false);
  };

  return (
    <LayoutWarpper>
      <h1 className="page-title">Activity Log</h1>
      <div className="backBtn">
        <Link
          to={history.location?.state?.from || '/settings#teams'}
          style={{ display: 'flex', alignContent: 'center', marginBottom: '20px' }}
        >
          <IoArrowBackCircle size={22} style={{ marginRight: '13px' }} /> <span>Back</span>
        </Link>
      </div>

      <Row style={{ width: '100%' }} gutter={[16, 16]}>
        <Col span={24}>
          <Spin spinning={isLoading}>
            <div className="searchBox" style={{ margin: '15px 0px' }}>
              <Search
                placeholder="Search by entity, environment, event type and status"
                onSearch={onSearch}
                allowClear
                size="large"
                style={{ width: 300 }}
              />
              <div>
                <ExportFile data_type="activity_logs" />
              </div>
            </div>
            <div className="activityLogs">
              <Table
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={activityLog}
                onChange={handleTableChange}
                pagination={false}
              />
              {totalActivityLog > 0 ? (
                <div className="pagination">
                  <Pagination
                    showLessItems
                    defaultPageSize={DEFAULT_PAGE_SIZE}
                    total={totalActivityLog}
                    showSizeChanger={false}
                    current={currentPage}
                    onChange={handlePageChange}
                  />
                </div>
              ) : null}
            </div>
          </Spin>
          <Modal
            width={550}
            title="Activity log Response"
            visible={showActivityLogResponse}
            onCancel={handleCancelBox}
            footer={false}
            className="blockAccountModal"
          >
            <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
              <Col span={24}>
                <label>Request</label>
              </Col>
              <Col span={24}>
                <div className="logBox">
                  <div className="jsonString">{JSON.stringify(activityDetail.request)}</div>
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
              <Col span={24}>
                <label>Response</label>
              </Col>
              <Col span={24}>
                <div className="logBox">
                  <div className="jsonString">{JSON.stringify(activityDetail.response)}</div>
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
              <Col span={24}>
                <Button
                  className="actionBtn blueBorderd"
                  type="secondary"
                  size="large"
                  style={{ width: '100%' }}
                  onClick={handleCancelBox}
                >
                  Close
                </Button>
              </Col>
            </Row>
          </Modal>
        </Col>
      </Row>
    </LayoutWarpper>
  );
};

export default ActivityLog;

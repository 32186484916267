import { useMutation, useQuery } from 'react-query';

import cardsApi from 'services/cards';

import { BLOCK_CARD_MUTATION, CARD_LIMIT_MUTATION, GET_ALL_CARDS_QY } from './keys';

export const useAllCards = (body) => {
  const data = useQuery([GET_ALL_CARDS_QY, body], () => cardsApi.getAllCards(body));

  return data;
};

export const useBlockCardMutation = () => {
  const response = useMutation(cardsApi.blockCard, {
    mutationKey: BLOCK_CARD_MUTATION,
    onSuccess: (data) => {
      console.log('Card Blocked Success', data);
    },
    onError: (error) => {
      console.log('Card Blocked Error', error);
    },
  });

  return response;
};

export const useCardLimitMutation = () => {
  const response = useMutation(cardsApi.cardLimit, {
    mutationKey: CARD_LIMIT_MUTATION,
    onSuccess: (data) => {
      console.log('Card Limit Set Success', data);
    },
    onError: (error) => {
      console.log('Card Limit Set Error', error);
    },
  });

  return response;
};

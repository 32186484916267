import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Layout, Modal } from 'antd';
import { useIdleTimer } from 'react-idle-timer';
import Countdown from 'react-countdown';

import { IDLE_TIMEOUT, REMAINING_TIMEOUT } from 'constants/config';
import { clearLocalStorage, getBackoffice, setBackOffice } from 'helpers/localStorage';

//Components
import TopBar from './TopBar';
import SideBar from './Sidebar';

import './Layout.less';
import { capitalize } from 'lodash';

const LayoutWrapper = ({ children }) => {
  const history = useHistory();
  const backOffice = getBackoffice();
  const boName = backOffice?.data?.name;

  const [countDownVisible, setCountDownVisible] = useState(false);

  const { Header, Content, Sider } = Layout;

  const onResetTimeout = () => {
    setCountDownVisible(false);
    reset();
  };

  const onLogout = () => {
    setCountDownVisible(false);
    reset();

    // // clear all localStorage
    clearLocalStorage();
    setBackOffice(backOffice);
    history.push('/login');
  };

  const showCountDown = () => {
    setCountDownVisible(true);
  };

  const renderer = ({ seconds, completed }) => {
    if (completed) {
      onLogout();
      return false;
    } else {
      // Render a countdown
      if (seconds > 0) {
        document.title = 'Session expires in ' + seconds + ' seconds!';
      } else {
        document.title = capitalize(boName) + ' Backoffice';
      }

      return <span>{seconds}</span>;
    }
  };

  const { reset } = useIdleTimer({
    timeout: 1000 * 60 * (IDLE_TIMEOUT - 1),
    onIdle: showCountDown,
    stopOnIdle: true,
    debounce: 500,
  });

  return (
    <>
      <Layout id="MainLayout" key="mainLayout">
        <Header className="header">
          <TopBar></TopBar>
        </Header>
        <Content id="contentLayout" key="mainContent">
          <Layout className="site-layout-background" key="contentLayout" style={{ padding: '12px' }}>
            <Sider id="SideBarLayout" className="site-layout-background" width={275}>
              <SideBar />
            </Sider>
            <Content
              style={{
                padding: '0 24px',
                minHeight: '85vh',
              }}
              key="childrenContent"
            >
              {children}
            </Content>
          </Layout>

          <Modal title="Need More Time?" visible={countDownVisible} onCancel={onResetTimeout} footer={false} centered>
            <div className="text-center mt-5 mb-3">
              <p className="mb-5">
                Due to inactivity, your session will expire in{' '}
                <Countdown date={Date.now() + 1000 * REMAINING_TIMEOUT} renderer={renderer} /> seconds
              </p>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '20px' }}>
                <div>
                  <Button onClick={onLogout} type="default" outline>
                    Sign Out
                  </Button>
                </div>
                <div>
                  <Button onClick={onResetTimeout} type="primary" outline>
                    Stay Signed In
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        </Content>
      </Layout>
    </>
  );
};

export default LayoutWrapper;

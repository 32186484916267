import React from 'react';
import { Formik } from 'formik';
import {
  Form,
  FormItem,
  SubmitButton,
  InputNumber,
  Switch,
  Select,
  // FormikDebug
} from 'formik-antd';
import { Row, Col, Card, Descriptions, notification, Spin } from 'antd';

import { getAdmin, getBackoffice } from 'helpers/localStorage';
import { useGetAccountSetting, useUpdateAccountSettingMutation } from '../../rc-query/settingHooks';

import { ShowIfEmpty } from 'helpers/utils';
import Loading from 'components/Loading';

import './Settings.less';

const { Option } = Select;

const AccountSetting = () => {
  const adminUser = getAdmin();
  const backOffice = getBackoffice();
  const updateAccountSettingMutation = useUpdateAccountSettingMutation();

  const { isLoading, data } = useGetAccountSetting({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
  });

  const {
    custodian,
    max_business_account_per_user,
    max_personal_account_per_user,
    account_interest,
    account_fees,
    account_interest_frequency,
    personal_checking,
    business_checking,
    personal_savings,
    business_savings,
    subtype_dda,
    subtype_clearing,
    subtype_interest,
    subtype_reserve,
  } = data?.data?.accounts_setting || {};

  const updateAccountSetting = (values, formikHelpers) => {
    formikHelpers.validateForm();

    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      ...values,
    };

    updateAccountSettingMutation
      .mutateAsync(body)
      .then((data) => {
        if (data.status === 'error') {
          notification.error({
            message: data.message,
          });
        } else if (data.status === 'success') {
          notification.success({
            message: data.message,
          });
        }
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <Loading fullScreen loading={isLoading} />
      ) : (
        <Formik
          onSubmit={updateAccountSetting}
          layout="vertical"
          initialValues={{
            max_personal_account_per_user: max_personal_account_per_user || 0,
            max_business_account_per_user: max_business_account_per_user || 0,
            account_interest: account_interest || 0,
            account_fees: account_fees || 0,
            account_interest_frequency: account_interest_frequency || 0,
            personal_checking: personal_checking || false,
            business_checking: business_checking || false,
            personal_savings: personal_savings || false,
            business_savings: business_savings || false,
            subtype_dda: subtype_dda || false,
            subtype_clearing: subtype_clearing || false,
            subtype_interest: subtype_interest || false,
            subtype_reserve: subtype_reserve || false,
          }}
        >
          {(formProps) => {
            const { isSubmitting, handleSubmit } = formProps;
            return (
              <Spin spinning={isSubmitting}>
                <Form
                  initialValues={{
                    max_personal_account_per_user: max_personal_account_per_user || 0,
                    max_business_account_per_user: max_business_account_per_user || 0,
                    account_interest: account_interest || 0,
                    account_fees: account_fees || 0,
                    account_interest_frequency: account_interest_frequency || 0,
                    personal_checking: personal_checking || false,
                    business_checking: business_checking || false,
                    personal_savings: personal_savings || false,
                    business_savings: business_savings || false,
                    subtype_dda: subtype_dda || false,
                    subtype_clearing: subtype_clearing || false,
                    subtype_interest: subtype_interest || false,
                    subtype_reserve: subtype_reserve || false,
                  }}
                >
                  <Row gutter={[18, 18]}>
                    <Col span={12}>
                      <Card title="General">
                        <Descriptions layout="vertical" column={2}>
                          <Descriptions.Item label="Custodian">{ShowIfEmpty(custodian, '-')}</Descriptions.Item>
                          <Descriptions.Item label="Max personal account per user">
                            <FormItem name="max_personal_account_per_user">
                              <InputNumber
                                name="max_personal_account_per_user"
                                placeholder="0"
                                style={{ width: '150px', textAlign: 'center' }}
                                size="large"
                                min={0}
                              />
                            </FormItem>
                          </Descriptions.Item>
                          <Descriptions.Item label="Max business account per user" span={2}>
                            <FormItem name="max_business_account_per_user">
                              <InputNumber
                                name="max_business_account_per_user"
                                placeholder="0"
                                style={{ width: '150px', textAlign: 'center' }}
                                size="large"
                                min={0}
                              />
                            </FormItem>
                          </Descriptions.Item>
                        </Descriptions>
                      </Card>
                    </Col>
                    <Col span={12}>
                      <Card title="Interest & Fees">
                        <Descriptions layout="vertical" column={2}>
                          <Descriptions.Item label="Account interest">
                            <FormItem name="account_interest">
                              <InputNumber
                                name="account_interest"
                                placeholder="0"
                                style={{ width: '150px', textAlign: 'center' }}
                                size="large"
                                prefix="%"
                                precision={2}
                                min={0}
                                max={100}
                              />
                            </FormItem>
                          </Descriptions.Item>
                          <Descriptions.Item label="Account fees">
                            <FormItem name="account_fees">
                              <InputNumber
                                prefix="$"
                                precision={2}
                                name="account_fees"
                                placeholder="0.00"
                                style={{ width: '150px', textAlign: 'center' }}
                                size="large"
                                min={0}
                              />
                            </FormItem>
                          </Descriptions.Item>
                          <Descriptions.Item label="Account interest frequency" span={2}>
                            <FormItem name="account_interest_frequency">
                              <Select
                                name="account_interest_frequency"
                                placeholder="Choose..."
                                size="large"
                                style={{ width: '150px' }}
                              >
                                <Option value="manually">Manually</Option>
                                <Option value="daily">Daily</Option>
                                <Option value="weekly">Weekly</Option>
                                <Option value="monthly">Monthly</Option>
                                <Option value="quaterly">Quarterly</Option>
                                <Option value="annually">Annually</Option>
                                <Option value="bi-annually">Bi-Annually</Option>
                              </Select>
                            </FormItem>
                          </Descriptions.Item>
                        </Descriptions>
                      </Card>
                    </Col>
                  </Row>
                  <Row gutter={[18, 18]} style={{ marginTop: '20px' }}>
                    <Col span={12}>
                      <Card title="Account Type">
                        <Descriptions layout="horizontal" column={1}>
                          <Descriptions.Item label="Personal Checking">
                            <FormItem name="personal_checking">
                              <Switch name="personal_checking" />
                            </FormItem>
                          </Descriptions.Item>
                          <Descriptions.Item label="Business Checking">
                            <FormItem name="business_checking">
                              <Switch name="business_checking" />
                            </FormItem>
                          </Descriptions.Item>
                          <Descriptions.Item label="Personal Savings">
                            <FormItem name="personal_savings">
                              <Switch name="personal_savings" />
                            </FormItem>
                          </Descriptions.Item>
                          <Descriptions.Item label="Business Savings">
                            <FormItem name="business_savings">
                              <Switch name="business_savings" />
                            </FormItem>
                          </Descriptions.Item>
                        </Descriptions>
                      </Card>
                    </Col>
                    <Col span={12}>
                      <Card title="Account Sub Type">
                        <Descriptions layout="horizontal" column={1}>
                          <Descriptions.Item label="DDA">
                            <FormItem name="subtype_dda">
                              <Switch name="subtype_dda" />
                            </FormItem>
                          </Descriptions.Item>
                          <Descriptions.Item label="Clearing">
                            <FormItem name="subtype_clearing">
                              <Switch name="subtype_clearing" />
                            </FormItem>
                          </Descriptions.Item>
                          <Descriptions.Item label="Interest">
                            <FormItem name="subtype_interest">
                              <Switch name="subtype_interest" />
                            </FormItem>
                          </Descriptions.Item>
                          <Descriptions.Item label="Reserve">
                            <FormItem name="subtype_reserve">
                              <Switch name="subtype_reserve" />
                            </FormItem>
                          </Descriptions.Item>
                        </Descriptions>
                      </Card>
                    </Col>
                  </Row>
                  <Row gutter={[18, 18]} style={{ marginTop: '20px' }}>
                    <Col>
                      <SubmitButton type="primary" htmlType="submit" loading={isSubmitting} onClick={handleSubmit}>
                        Save
                      </SubmitButton>
                    </Col>
                  </Row>
                  {/* <pre
                    style={{
                      background: '#f6f8fa',
                      fontSize: '.65rem',
                      padding: '.5rem',
                    }}
                  >
                    <FormikDebug />
                  </pre> */}
                </Form>
              </Spin>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default AccountSetting;

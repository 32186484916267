import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Card, Col, Divider, Row, Spin, Space, notification } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, SubmitButton } from 'formik-antd';
import PhoneInput from 'react-phone-input-2';
import * as yup from 'yup';

import { IoArrowBackCircle } from 'react-icons/io5';
import { ArrowRightOutlined } from '@ant-design/icons';

import { getAdmin, getBackoffice } from 'helpers/localStorage';
import { queryClient } from 'rc-query';
import { useAddKYBContact, useSendReminderKYB } from 'rc-query/kysyncHooks';
import { GET_ALL_KYSYNC_BUSINESSES_KYB_QUERY } from 'rc-query/keys';
import { PHONE_ONLY_COUNTRIES, PHONE_REGEXP } from 'constants/config';

import LayoutWarpper from 'components/Layout';

import 'react-phone-input-2/lib/style.css';
import './KySync.less';

const AddContactsManuallyKYB = () => {
  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const history = useHistory();
  const addKYBContact = useAddKYBContact();

  const sendReminderKYB = useSendReminderKYB();

  const handleSubmit = (values, formikHelpers) => {
    formikHelpers.validateForm();

    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      ...values,
    };

    addKYBContact
      .mutateAsync(body)
      .then((data) => {
        if (data.status === 'success') {
          notification.success({
            message: data.message,
          });

          const sendReminderBody = {
            api_key: backOffice?.data?.name,
            token: adminUser?.token,
            business_ids: [data?.data?.id],
          };

          sendReminderKYB.mutateAsync(sendReminderBody).then((data) => {
            if (data.status === 'success') {
              notification.success({
                message: data.message,
              });
            } else {
              notification.error({
                message: data.message,
              });
            }
          });

          // reload listing and redirect
          queryClient.refetchQueries([GET_ALL_KYSYNC_BUSINESSES_KYB_QUERY]);
          history.push('/kysync_kyb_results');
        } else {
          notification.error({
            message: data.message,
          });
        }
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  return (
    <LayoutWarpper>
      <div className="KySyncPage">
        <h1 className="page-title">KySync - KYB</h1>
        <div className="backBtn">
          <Link to="/kysync_kyb" style={{ display: 'flex', alignContent: 'center', marginBottom: '20px' }}>
            <IoArrowBackCircle size={22} style={{ marginRight: '13px' }} /> <span>Back</span>
          </Link>
        </div>
        <Row style={{ width: '100%' }} gutter={[16, 16]}>
          <Col span={16}>
            <Formik
              initialValues={{
                business_name: '',
                contact_email: '',
                contact_phone: '',
              }}
              validationSchema={yup.object().shape({
                business_name: yup.string().nullable(true).required('Business Name is required'),
                contact_email: yup.string().nullable(true).required('Contact Email is required'),
                contact_phone: yup
                  .string()
                  .nullable(true)
                  .required('Contact Phone number is required')
                  .matches(PHONE_REGEXP, 'Phone Number is not valid'),
              })}
              onSubmit={handleSubmit}
            >
              {(formProps) => {
                const { isSubmitting, handleSubmit, setFieldValue } = formProps;
                return (
                  <Spin spinning={isSubmitting}>
                    <div>
                      <Card>
                        <p className="kyc-ver-title">KYB identity verification</p>
                        <p className="kyc-ver-description">
                          Know Your Business: Identity verification for businesses. Upload a bulk list or try adding
                          <br />
                          businesses to get started.
                        </p>
                        <Divider />
                        <Form
                          layout="vertical"
                          size="large"
                          initialValues={{
                            business_name: '',
                            contact_email: '',
                            contact_phone: 'us',
                          }}
                        >
                          <Row gutter={[16, 16]}>
                            <Col span={24}>
                              <FormItem label="Business Name" name="business_name">
                                <Input name="business_name" placeholder="Enter business name" />
                              </FormItem>
                            </Col>

                            <Col span={12}>
                              <FormItem label="Contact Email Address" name="contact_email">
                                <Input name="contact_email" placeholder="example@gmail.com" />
                              </FormItem>
                            </Col>
                            <Col span={12}>
                              <div class="ant-col ant-form-item-label">
                                <label for="Phone Number" class="" title="Phone Number">
                                  Contact Phone Number
                                </label>
                              </div>
                              <PhoneInput
                                onlyCountries={PHONE_ONLY_COUNTRIES}
                                inputClass="ant-form-item-control-input w-full"
                                country="us"
                                onChange={(phone) => setFieldValue('contact_phone', `${phone}`)}
                                placeholder="Please enter phone number"
                              />
                              {formProps.errors.contact_phone && (
                                <div className="ant-form-item-explain-error">{formProps.errors.contact_phone}</div>
                              )}
                            </Col>
                          </Row>

                          <div className="kyc-btn">
                            <Space>
                              <Link to={`/kysync_kyb`}>
                                <Button size="large">Go Back</Button>
                              </Link>
                              <SubmitButton
                                loading={isSubmitting}
                                type="primary"
                                htmlType="submit"
                                onClick={handleSubmit}
                                size="large"
                                style={{ marginLeft: '16px' }}
                              >
                                Add
                              </SubmitButton>
                            </Space>
                          </div>
                          {/* <pre
                            style={{
                              background: '#f6f8fa',
                              fontSize: '.65rem',
                              padding: '.5rem',
                            }}
                          >
                            <FormikDebug />
                          </pre> */}
                        </Form>
                      </Card>
                      <div className="link">
                        <Link to={`/kysync_kyc_results`}>
                          Verification results <ArrowRightOutlined />
                        </Link>
                      </div>
                    </div>
                  </Spin>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </div>
    </LayoutWarpper>
  );
};

export default AddContactsManuallyKYB;

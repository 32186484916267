import React from 'react';
import { includes, keys, isEmpty } from 'lodash';
import { Tag } from 'antd';

export const filterSearchString = (recordValue, searchString) => {
  if (!searchString) {
    return '';
  }
  return searchString.toString().toLowerCase().includes(String(recordValue).toLowerCase());
};

export const filterTransactionStatus = (transactions, value) => {
  if (!searchString) {
    return '';
  }
  return transactions.filter((transaction) => transaction.status === value);
};

export const moneyFormat = (number = 0, minimumFractionDigits = 0, maximumFractionDigits = 2, currency = 'USD') => {
  // Create our number formatter.
  const formattedCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits, // (causes 2500.99 to be printed as $2,501)
  });

  return formattedCurrency.format(number); /* $2,500.00 */
};

export const numberFormat = (number) => {
  const formattedNumber = new Intl.NumberFormat('en-US').format(number);
  return formattedNumber;
};

export const replacer = (value, toReplace, replaceWith) => {
  let finalValue = value;
  let toReplaceCount = value.search(toReplace);
  for (let i = 0; i < toReplaceCount; i++) {
    finalValue = finalValue.replace(toReplace, replaceWith);
  }
  return finalValue;
};

export const abbreviateNumber = (number) => {
  var SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

  // what tier? (determines SI symbol)
  var tier = (Math.log10(Math.abs(number)) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier == 0) return number;

  // get suffix and determine scale
  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);

  // scale the number
  var scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(1) + suffix;
};

export const ShowIfEmpty = (data, str) => {
  return !isEmpty(data) ? data : str;
};

export const renderTransactionStatus = (status, Tag) => {
  switch (status) {
    case 'completed':
      return <Tag color="green"> Completed </Tag>;
    case 'pending':
      return <Tag color="orange"> Pending </Tag>;
    case 'cancelled':
      return <Tag color="red"> Cancelled </Tag>;
    case 'active':
      return <Tag color="blue"> Active </Tag>;
    case 'refunded':
      return <Tag color="red"> Refunded </Tag>;
    default:
      return null;
  }
};

export const renderUserStatus = (status, Tag) => {
  switch (status) {
    case 'verified':
      return <Tag color="green"> Verified </Tag>;
    case 'active':
      return <Tag color="green"> Active </Tag>;
    case 'pending':
      return <Tag color="orange"> Pending </Tag>;
    case 'blocked':
      return <Tag color="red"> Blocked </Tag>;
    default:
      return null;
  }
};

// formatter and parser input number
export const currencyFormat = (val, currency = '$') => {
  if (!val) return 0;
  // return `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace(/\.(?=\d{0, 2}$)/g, '.');
  return `${currency} ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const parserNumber = (val) => {
  if (!val) return 0;
  // return Number.parseFloat(val.replace(/\$\s?|(\.*)/g, '').replace(/(\,{1})/g, '.')).toFixed(2);
  return val.replace(/\$\s?|(,*)/g, '');
};

export const generateKey = (pre) => {
  return `${pre}_${new Date().getTime()}`;
};

export const isFormValidate = (errors, touchedFields) => {
  let continueForm = true;
  Object.keys(touchedFields).forEach((e) => {
    const errorKeys = keys(errors);
    if (includes(errorKeys, e)) {
      continueForm = false;
      return;
    }
  });

  return continueForm;
};

export const renderTags = (tags) => {
  return tags.map((tag) => (
    <Tag key={tag} color="default" style={{ margin: '5px' }}>
      {tag}
    </Tag>
  ));
};

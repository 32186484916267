import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Menu, Dropdown, Tag, Pagination, Spin, Modal, Button, notification } from 'antd';
import moment from 'moment';
import { EllipsisOutlined } from '@ant-design/icons';
import { IoArrowBackCircle } from 'react-icons/io5';

import { DEFAULT_PAGE_SIZE } from 'constants/config';
import { getAdmin, getBackoffice } from 'helpers/localStorage';
import { generateKey } from 'helpers/utils';
import {
  useGetAllMessages,
  useSendMessageMutation,
  useResendMessageMutation,
  useArchiveMessageMutation,
  useDeleteMessageMutation,
} from 'rc-query/messageHooks';
import { queryClient } from 'rc-query';

import MessagingForm from '../MessagingFormNew';

import './MessageTable.less';

const MessageTable = ({ userId, entityType }) => {
  const [filters, setFilters] = useState({
    page: 1,
    per_page: DEFAULT_PAGE_SIZE,
  });

  const [viewModal, setViewModal] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(undefined);

  const adminUser = getAdmin();
  const backOffice = getBackoffice();
  const apiKey = backOffice?.data?.name;
  const adminToken = adminUser?.token;

  const sendMessageMutation = useSendMessageMutation();
  const resendMessageMutation = useResendMessageMutation();
  const archiveMessageMutation = useArchiveMessageMutation();
  const deleteMessageMutation = useDeleteMessageMutation();

  const payloadProps = {
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
    ...filters,
  };

  if (userId) {
    payloadProps.user_id = userId;
  }

  const { isLoading, data } = useGetAllMessages(payloadProps);
  const messagesData = data?.data?.data || [];
  const totalMessages = data?.data?.total || 0;
  const currentPage = data?.data?.current_page || 0;

  const sendMessage = (record) => {
    sendMessageMutation
      .mutateAsync({
        api_key: apiKey,
        token: adminToken,
        id: record.id.toString(),
      })
      .then((res) => {
        notification.success({ message: res?.message || 'Message Sent' });
        queryClient.refetchQueries(['allMessages']);
      });
  };

  const resendMessage = (record) => {
    resendMessageMutation
      .mutateAsync({
        api_key: apiKey,
        token: adminToken,
        id: record.id.toString(),
      })
      .then((res) => {
        notification.success({ message: res?.message || 'Message Resent' });
        queryClient.refetchQueries(['allMessages']);
      });
  };

  const archiveMessage = (record) => {
    archiveMessageMutation
      .mutateAsync({
        api_key: apiKey,
        token: adminToken,
        id: record.id.toString(),
      })
      .then((res) => {
        notification.success({ message: res?.message || 'Message Archived' });
        queryClient.refetchQueries(['allMessages']);
      });
  };

  const deleteMessage = (record) => {
    deleteMessageMutation
      .mutateAsync({
        api_key: apiKey,
        token: adminToken,
        id: record.id.toString(),
      })
      .then((res) => {
        notification.success({ message: res?.message || 'Message Deleted' });
        queryClient.refetchQueries(['allMessages']);
      });
  };

  const renderMessageStatus = (status) => {
    switch (status) {
      case 'sent':
      case 'success':
        return <Tag color="green">Sent</Tag>;
      case 'draft':
        return <Tag color="orange">Draft</Tag>;
      case 'archived':
        return <Tag color="blue">Archived</Tag>;
      case 'cancelled':
        return <Tag color="red">Cancelled</Tag>;
      case 'deleted':
        return <Tag color="red">Deleted</Tag>;
      case 'failed':
        return <Tag color="red">Failed</Tag>;
      default:
        return null;
    }
  };

  const columns = [
    {
      title: 'Message ID',
      dataIndex: 'id',
      key: 'id',
      width: 150,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => <span className="capitalize">{record.type}</span>,
      filters: [
        { text: 'Email', value: 'email' },
        { text: 'Phone', value: 'phone' },
        { text: 'Push Notification', value: 'push' },
      ],
      filterMultiple: false,
    },
    {
      title: 'Audience',
      dataIndex: 'audience',
      key: 'audience',
      render: () => <Link to="/audiences">Weekend warrior heloc</Link>,
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      render: (text, record) => <span className="capitalize">{record.subject}</span>,
    },
    {
      title: 'Recipients',
      dataIndex: 'recipients',
      key: 'recipients',
      render: (text, record) => <span className="capitalize">{record.recipients}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <span>{renderMessageStatus(record.status)}</span>,
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text, record) => moment(record.created_at).format('MMM DD, YYYY'),
    },

    {
      title: 'Action',
      key: 'id',
      dataIndex: 'id',
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu key={`menu-${record.id}`}>
              <Menu.Item
                key={`view-${record.id}`}
                onClick={() => {
                  setViewModal(true);
                  setCurrentMessage(record);
                }}
              >
                View
              </Menu.Item>
              {record.status !== 'sent' && record.status !== 'archived' ? (
                <Menu.Item onClick={() => sendMessage(record)} key={`send-${record.id}`}>
                  Send
                </Menu.Item>
              ) : null}
              {record.status !== 'draft' && record.status !== 'archived' ? (
                <Menu.Item onClick={() => resendMessage(record)} key={`re-send-${record.id}`}>
                  Re-send
                </Menu.Item>
              ) : null}
              {record.status !== 'archived' ? (
                <Menu.Item onClick={() => archiveMessage(record)} key={`archive-${record.id}`}>
                  Archive
                </Menu.Item>
              ) : null}
              <Menu.Item onClick={() => deleteMessage(record)} key={`delete-${record.id}`}>
                Delete
              </Menu.Item>
            </Menu>
          }
        >
          <a className="ant-dropdown-link">
            <EllipsisOutlined />
          </a>
        </Dropdown>
      ),
    },
  ];

  const handleTableChange = (pagination, tableFilters, sorter) => {
    const type = tableFilters?.type?.[0]?.toString() || undefined;
    const recipients = tableFilters?.recipients?.[0]?.toString() || undefined;
    const status = tableFilters?.status?.[0]?.toString() || undefined;

    setFilters({
      ...filters,
      type,
      recipients,
      status,
      page: 1,
      per_page: DEFAULT_PAGE_SIZE,
      sort_by_column: sorter.field,
      sort_by_type: sorter.order === 'ascend' ? 'ASC' : 'DESC',
    });
  };

  const handlePageChange = (page) => {
    setFilters({ ...filters, page });
  };

  return (
    <Spin spinning={isLoading}>
      {!userId ? (
        <>
          <div style={{ float: 'right', marginBottom: '15px' }}>
            <Button
              type="primary"
              onClick={() => {
                setViewModal(true);
              }}
            >
              + New Message
            </Button>
          </div>
        </>
      ) : (
        <div className="backBtn">
          <Link
            to={`/entity/${entityType}/${userId}`}
            style={{ display: 'flex', alignContent: 'center', marginBottom: '20px' }}
          >
            <IoArrowBackCircle size={22} style={{ marginRight: '13px' }} /> <span>Back</span>
          </Link>
        </div>
      )}
      <div>
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          pagination={false}
          dataSource={messagesData}
          onChange={handleTableChange}
        />
        {totalMessages > 0 ? (
          <div className="pagination">
            <Pagination
              showLessItems
              defaultPageSize={DEFAULT_PAGE_SIZE}
              total={totalMessages}
              showSizeChanger={false}
              current={currentPage}
              onChange={handlePageChange}
            />
          </div>
        ) : null}
      </div>
      <Modal
        key={`message-form-${currentMessage?.id}`}
        width={900}
        title="Messaging"
        visible={viewModal}
        onCancel={() => {
          setViewModal(false);
          setCurrentMessage(undefined);
        }}
        footer={false}
      >
        <MessagingForm message={currentMessage} setViewModal={setViewModal} key={`message-form-${generateKey()}`} />
      </Modal>
    </Spin>
  );
};

export default MessageTable;

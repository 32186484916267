import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';

export default function TableColumnTextFilterConfig() {
  const searchInputHolder = { current: null };

  const filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={(node) => {
          searchInputHolder.current = node;
        }}
        placeholder="Search"
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => confirm()}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => confirm()}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button
        size="small"
        style={{ width: 90 }}
        onClick={() => {
          clearFilters();
          confirm();
        }}
      >
        Reset
      </Button>
    </div>
  );

  const filterIcon = (filtered) => <SearchOutlined style={{ color: filtered ? '#03ab2c' : undefined }} />;

  return {
    filterDropdown,
    filterIcon,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputHolder.current?.select());
      }
    },
  };
}

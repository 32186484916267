import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Result } from 'antd';
import { isEmpty } from 'lodash';

import { useBackOfficeVerifyMutation } from 'rc-query/authHooks';
import { getAdmin, setBackOffice } from 'helpers/localStorage';
import { assignFingerprint } from 'helpers/fingerprintjs';

import './BackOfficeVerify.less';

const BackOfficeVerify = ({ match }) => {
  const history = useHistory();
  const adminUser = getAdmin();

  let { backoffice: tenantName } = match.params;

  if (!tenantName) {
    tenantName = location.href;
    // history.push('/dashboard');
  }

  const backOfficeVerifyMutation = useBackOfficeVerifyMutation(history);
  const [subTitle, setSubTitle] = useState('Verifying Back-Office...');

  useEffect(() => {
    if (!isEmpty(adminUser)) {
      history.push('/dashboard');
    }

    setSubTitle('Verifying Back-Office...');
    assignFingerprint();

    backOfficeVerifyMutation.mutateAsync({ value: tenantName }).then((data) => {
      if (data?.status === 'success') {
        setSubTitle('Redirecting to login...');
        setBackOffice(data);
        history.push('/login');
      } else if (data?.status === 'error') {
        setSubTitle('The requested back-office was not found, please confirm your back-office URL and try again');
      }
    });
  }, []);

  return <Result status="warning" title="Back-Office Verification" subTitle={subTitle} />;
};

export default BackOfficeVerify;

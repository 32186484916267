import React from 'react';
import ReactDOM from 'react-dom';
import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';
import App from './app/App';
// import reportWebVitals from './reportWebVitals';

// import 'antd/dist/antd.css';
import './styles/base.less';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <FpjsProvider
      loadOptions={{
        apiKey: "vZOLWDQeWgM0iZV7t1sV",
      }}
    >
      <App />
    </FpjsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

import React from 'react';
import { Row, Col, Card, Descriptions, notification } from 'antd';
import { Formik } from 'formik';
import {
  Form,
  FormItem,
  SubmitButton,
  InputNumber,
  Switch,
  // FormikDebug
} from 'formik-antd';

import { getAdmin, getBackoffice } from 'helpers/localStorage';
import { useGetCardSetting, useUpdateCardSettingMutation } from 'rc-query/settingHooks';

import Loading from 'components/Loading';

import './Settings.less';

const CardSetting = () => {
  const adminUser = getAdmin();
  const backOffice = getBackoffice();
  const updateCardSettingMutation = useUpdateCardSettingMutation();

  const { isLoading, data } = useGetCardSetting({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
  });

  const { virtual_card, physical_card, max_virtual_card_per_account, max_physical_card_per_account } =
    data?.data?.cards_setting || {};

  const updateCardSetting = (values) => {
    const productLogo = ''
    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      ...values,
      logo: productLogo,
    };

    updateCardSettingMutation.mutateAsync(body).then((data) => {
      console.log('Card setting updated successfully', data);
      if (data.status === 'error') {
        notification.error({
          message: data.message,
        });
      } else if (data.status === 'success') {
        notification.success({
          message: data.message,
        });
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <Loading fullScreen loading={isLoading} />
      ) : (
        <Formik
          onSubmit={updateCardSetting}
          layout="vertical"
          initialValues={{
            card_type_virtual: virtual_card || false,
            card_type_physical: physical_card || false,
            max_virtual_card_per_account: max_virtual_card_per_account || 0,
            max_physical_card_per_account: max_physical_card_per_account || 0,
          }}
        >
          {() => (
            <Form
              initialValues={{
                card_type_virtual: virtual_card || false,
                card_type_physical: physical_card || false,
                max_virtual_card_per_account: max_virtual_card_per_account || 0,
                max_physical_card_per_account: max_physical_card_per_account || 0,
              }}
            >
              <Row gutter={[18, 18]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <Card title="Card Type">
                    <Descriptions layout="horizontal" column={1}>
                      <Descriptions.Item label="Virtual Card">
                        <FormItem name="card_type_virtual">
                          <Switch name="card_type_virtual" />
                        </FormItem>
                      </Descriptions.Item>
                      <Descriptions.Item label="Physical Card">
                        <FormItem name="card_type_physical">
                          <Switch name="card_type_physical" />
                        </FormItem>
                      </Descriptions.Item>
                    </Descriptions>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card title="Card Count">
                    <Descriptions layout="vertical" column={2}>
                      <Descriptions.Item label="Max Virtual Card per account">
                        <FormItem name="max_virtual_card_per_account">
                          <InputNumber
                            name="max_virtual_card_per_account"
                            placeholder="0"
                            style={{ width: '150px', textAlign: 'center' }}
                            size="large"
                            min={0}
                          />
                        </FormItem>
                      </Descriptions.Item>
                      <Descriptions.Item label="Max Physical Card per account">
                        <FormItem name="max_physical_card_per_account">
                          <InputNumber
                            name="max_physical_card_per_account"
                            placeholder="0"
                            style={{ width: '150px', textAlign: 'center' }}
                            size="large"
                            min={0}
                          />
                        </FormItem>
                      </Descriptions.Item>
                    </Descriptions>
                  </Card>
                </Col>
              </Row>
              <Row gutter={[18, 18]} style={{ marginTop: '20px' }}>
                <Col>
                  <SubmitButton type="primary" htmlType="submit" loading={false}>
                    Save
                  </SubmitButton>
                </Col>
              </Row>
              {/* <pre
                style={{
                  background: '#f6f8fa',
                  fontSize: '.65rem',
                  padding: '.5rem',
                }}
              >
                <FormikDebug />
              </pre> */}
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default CardSetting;

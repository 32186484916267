import { useMutation, useQuery } from 'react-query';

import payoutsApi from 'services/payouts';

import { GET_ALL_PAYOUTS_QY } from './keys';

export const usePayouts = (body) => {
  const payouts = useQuery([GET_ALL_PAYOUTS_QY, body], () => payoutsApi.getPayouts(body), {
    select: ({ data }) => data,
  });

  return payouts;
};

export const useBeneficiaries = (body) => {
  const data = useQuery(['AllBeneficiaries', body], () => payoutsApi.getBeneficiaries(body), {
    select: ({ data }) => data,
  });

  return data;
};

export const useSendMoney = () => {
  const response = useMutation(payoutsApi.sendMoney, {
    mutationKey: 'sendMoney',
    onSuccess: (data) => {
      console.log('Send Money Success', data);
    },
    onError: (error) => {
      console.log('Send Money Error', error);
    },
  });

  return response;
};

export const useReversePayout = () => {
  const response = useMutation(payoutsApi.reversePayout, {
    mutationKey: 'reversePayout',
    onSuccess: (data) => {
      console.log('Reverse Payout Success', data);
    },
    onError: (error) => {
      console.log('Reverse Payout Error', error);
    },
  });

  return response;
};

export const useStatusPayout = () => {
  const response = useMutation(payoutsApi.statusPayout, {
    mutationKey: 'statusPayout',
    onSuccess: (data) => {
      console.log('Payout Status Success', data);
    },
    onError: (error) => {
      console.log('Payout Status Error', error);
    },
  });

  return response;
};

export const useExportFileMutation = () => {
  const response = useMutation(payoutsApi.exportFile, {
    mutationKey: 'exportFile',
    onSuccess: (data) => {
      console.log('Export file Success', data);
    },
    onError: (error) => {
      console.log('Export file Error', error);
    },
  });

  return response;
};

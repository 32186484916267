import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Input, notification, Pagination, Row, Spin, Table, Tag, Dropdown, Menu } from 'antd';
import { IoArrowBackCircle } from 'react-icons/io5';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { EllipsisOutlined } from '@ant-design/icons';

import LayoutWarpper from 'components/Layout';
import { getAdmin, getBackoffice } from 'helpers/localStorage';
import { useKySyncUsersKyc, useSendReminderKYC } from 'rc-query/kysyncHooks';

import KysyncReportPDF from './KysyncReportPDF';

const { Search } = Input;

const KySyncKYCResults = () => {
  const [filters, setFilters] = useState({
    page: 1,
    per_page: 10,
  });

  const sendReminderKYC = useSendReminderKYC();

  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const { isLoading, data } = useKySyncUsersKyc({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
    ...filters,
  });

  const kySyncResults = data?.data || [];
  const totalKySyncResults = data?.total || 0;
  const currentPage = data?.current_page || 0;

  const renderStatus = (status) => {
    switch (status) {
      case 'active':
      case 'Active':
        return <Tag color="yellow">Active</Tag>;
      case 'completed':
      case 'Completed':
        return <Tag color="green">Completed</Tag>;
      case 'pending':
      case 'Pending':
        return <Tag color="gray">Pending</Tag>;
      case 'inprogress':
      case 'Inprogress':
      case 'in_progress':
      case 'In_progress':
        return <Tag color="yellow">In Progress</Tag>;
      case 'failed':
      case 'Failed':
        return <Tag color="red">Failed</Tag>;
      case 'verified':
      case 'Verified':
        return <Tag color="green">Verified</Tag>;
      default:
        return null;
    }
  };

  const columns = [
    {
      title: 'S/No',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    {
      title: 'Unique ID',
      dataIndex: 'unique_id',
      key: 'unique_id',
      sorter: true,
    },
    {
      title: 'Entity',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (text, record) => <span>{record.phone_number || 'NA'}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text, record) => <span>{renderStatus(record.status)}</span>,
    },
    {
      title: 'Action',
      render: (text, record) => {
        return (
          <Dropdown
            overlay={
              <Menu key={`menu-${record.id}`}>
                <Menu.Item key={`send-reminder-${record.id}`} onClick={() => handleSendReminder(record)}>
                  Send Reminder
                </Menu.Item>
                <Menu.Item key={`pdf-report-${record.id}`}>
                  <PDFDownloadLink
                    document={<KysyncReportPDF data={record} />}
                    fileName="kyc_user_report.pdf"
                  >
                    Download PDF Report
                  </PDFDownloadLink>
                </Menu.Item>
              </Menu>
            }
          >
            <a className="ant-dropdown-link">
              <EllipsisOutlined />
            </a>
          </Dropdown>
        );
      },
    },
  ];

  const handleSendReminder = (record) => {
    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      user_ids: [record.id],
    };

    sendReminderKYC.mutateAsync(body).then((data) => {
      if (data.status === 'success') {
        notification.success({
          message: data.message,
        });
      } else {
        notification.error({
          message: data.message,
        });
      }
    });
  };

  const handlePageChange = (page) => {
    setFilters({ ...filters, page });
  };

  const handleTableChange = (pagination, tableFilters, sorter) => {
    // const email = tableFilters?.email?.[0]?.toString() || undefined;

    setFilters({
      ...filters,
      // email,
      page: 1,
      per_page: 10,
      sort_by_column: sorter?.field,
      sort_by_type: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
    });
  };

  const onSearch = (value) => {
    const name = value?.toString() || undefined;

    setFilters({
      search: name,
      page: 1,
      per_page: 10,
    });
  };

  return (
    <LayoutWarpper>
      <div className="KySyncPage">
        <h1 className="page-title">KYC - Results</h1>
        <div className="backBtn">
          <Link to="/kysync" style={{ display: 'flex', alignContent: 'center', marginBottom: '20px' }}>
            <IoArrowBackCircle size={22} style={{ marginRight: '13px' }} /> <span>Back</span>
          </Link>
        </div>
        <div className="searchBox" style={{ margin: '15px 0px' }}>
          <Search
            placeholder="Search by entity, unique ID, email..."
            onSearch={onSearch}
            allowClear
            style={{ width: 300 }}
          />
        </div>
        <Row style={{ width: '100%' }} gutter={[16, 16]}>
          <Col span={24}>
            <Spin spinning={isLoading || sendReminderKYC.isLoading}>
              <div>
                <Table
                  rowKey={(record) => record.id}
                  columns={columns}
                  dataSource={kySyncResults}
                  onChange={handleTableChange}
                  pagination={false}
                />
                {totalKySyncResults > 0 ? (
                  <div className="pagination">
                    <Pagination
                      showLessItems
                      defaultPageSize={10}
                      total={totalKySyncResults}
                      showSizeChanger={false}
                      current={currentPage}
                      onChange={handlePageChange}
                    />
                  </div>
                ) : null}
              </div>
            </Spin>
          </Col>
        </Row>
      </div>
    </LayoutWarpper>
  );
};

export default KySyncKYCResults;

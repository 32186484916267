//Queries
export const GET_ALL_USERS_QUERY = 'allUsers';
export const GET_ALL_USERNAME_EMAIL_QUERY = 'allUserNameEmail';
export const GET_ALL_USERNAME_PHONE_QUERY = 'allUserNamePhone';
export const GET_ALL_USERNAME_PUSH_TOKEN_QUERY = 'allUserNamePushToken';
export const GET_USER_TRANSACTIONS_QUERY = 'userTransaction';
export const GET_ALL_TRANSACTIONS_QY = 'allTransaction';
export const GET_ALL_PAYOUTS_QY = 'allPayouts';
export const GET_ALL_MESSAGES_QUERY = 'allMessages';
export const GET_SINGLE_MESSAGE_QUERY = 'singleMessages';
export const GET_ALL_BANK_ACCOUNTS_QY = 'allBankAccounts';
export const GET_ALL_WALLET_BANK_ACCOUNTS_QY = 'allWalletBankAccounts';
export const GET_ALL_WALLET_BANK_CARDS_QY = 'allWalletBankCards';
export const GET_ALL_WALLET_TOKENIZED_CARDS = 'allWalletTokenizedCards';
export const GET_ALL_CARDS_QY = 'allCards';
export const GET_USER = 'singleUser';
export const GET_ALL_ACTIVITY_LOG_QUERY = 'allActivityLog';
export const GET_ROLES = 'allRoles';
export const GET_ALL_KYSYNC_USERS_KYC_QUERY = 'allKysyncUsersKyc';
export const GET_ALL_KYSYNC_BUSINESSES_KYB_QUERY = 'allKysyncBusinessesKyb';
export const GET_ALL_KYSYNC_PLANS_QUERY = 'allKysyncPlans';
export const GET_KYSYNC_CUSTOM_SETTINGS_QUERY = 'allKySyncCustomSettings';

// Statistics
export const GET_DASHBOARD_STATISTICS_QUERY = 'statistics';
export const GET_USER_STATISTICS_QUERY = 'userStatistics';
export const GET_DEPOSITS_STATISTICS_QUERY = 'depositsStatistics';
export const GET_PAYOUTS_STATISTICS_QUERY = 'payoutsStatistics';

//Login Mutations
export const LOGIN_MUTATION = 'loginMutation';
export const RESET_MUTATION = 'resetMutation';
export const VERIFY_MUTATION = 'verifyMutation';
export const VERIFY_BACKOFFICE_MUTATION = 'verifyBackOfficeMutation';

// Message Mutations
export const MESSAGE_MUTATION = 'messageMutation';
export const SAVE_MESSAGE_MUTATION = 'saveMessageMutation';
export const UPDATE_MESSAGE_MUTATION = 'updateMessageMutation';
export const SEND_MESSAGE_MUTATION = 'sendMessageMutation';
export const RESEND_MESSAGE_MUTATION = 'resendMessageMutation';
export const ARCHIVE_MESSAGE_MUTATION = 'archiveMessageMutation';
export const DELETE_MESSAGE_MUTATION = 'deleteMessageMutation';

// User Mutations
export const BLOCK_USER_MUTATION = 'blockUserMutation';

// Card Mutations
export const BLOCK_CARD_MUTATION = 'blockCardMutation';
export const CARD_LIMIT_MUTATION = 'cardLimitMutation';

// Bank Account Mutations
export const BLOCK_ACCOUNT_MUTATION = 'blockAccountMutation';

// Setting Keys
export const GET_GENERAL_SETTING_QUERY = 'getGeneralSetting';
export const GET_ACCOUNT_SETTING_QUERY = 'getAccountSetting';
export const GET_CARD_SETTING_QUERY = 'getCardSetting';
export const GET_TRANSACTION_SETTING_QUERY = 'getTransactionSetting';
export const GET_SECURITY_SETTING_QUERY = 'getSecuritySetting';
export const UPDATE_ACCOUNT_SETTING_MUTATION = 'updateAccountSettingMutation';
export const UPDATE_CARD_SETTING_MUTATION = 'updateCardSettingMutation';
export const UPDATE_TRANSACTION_SETTING_MUTATION = 'updateTransactionSettingMutation';
export const UPDATE_SECURITY_SETTING_MUTATION = 'updateSecuritySettingMutation';
export const UPDATE_PASSWORD_SETTING_MUTATION = 'updatePasswordSettingMutation';

// Products Keys
export const GET_ALL_PRODUCTS = 'getAllProducts';
export const GET_PRODUCT = 'getProduct';
export const SAVE_PRODUCT_MUTATION = 'saveProductMutation';

// Team Member Keys
export const GET_ALL_TEAM_MEMBERS_QUERY = 'allTeamMembers';
export const CREATE_TEAM_MEMBER_MUTATION = 'createTeamMemberMutation';
export const DELETE_TEAM_MEMBER_MUTATION = 'deleteTeamMemberMutation';

// Wallet Keys
export const GET_WALLET_TRANSACTIONS = 'walletTransactions';
export const GET_MASTER_WALLET_INFO = 'masterWalletInfo';

export const GET_CUSTOMIZATION_SETTING_QUERY = 'getCustomizationSetting';
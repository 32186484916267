// export const API_BASE_URL = 'https://api.swys.io/api/v1';

let prodUrl = 'https://api.ondeposits.com/api/v1';
let stageUrl = 'https://api.deposits.dev/api/v1';
let baseUrlDev = 'https://api.dev.deposits.dev/api/v1';
let baseUrlLocal = 'http://localhost:8000/api/v1';
let baseUrl = baseUrlDev;
// baseUrl = baseUrlLocal;

if (location.host.search('deposits.inc') > -1 || location.host.search('deposits.com') > -1 || location.host.search('deposits.app') > -1 || location.host.search('swys.io') > -1) {
    baseUrl = prodUrl;
} else if (location.host.search('127.0.0.1') > -1) {
    baseUrl = baseUrlLocal;
} else {
    if (location.host.search('dev.') > -1) {
        baseUrl = baseUrlDev;
    } else {
        baseUrl = stageUrl;
    }
}

// export const API_BASE_URL = 'https://client.api.swys.xyz/api/v1';
export const API_BASE_URL = baseUrl;
export const CHART_DATE_ONLY = 'yyyy-MM-DD';
export const DEFAULT_PAGE_SIZE = 25;
export const LOGO_FILE_SIZE = 5120;
export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];
export const EXPORT_FILE_SUCCESS_MESSAGE = 'Data will be sent to your mail shortly';
export const PHONE_REGEXP = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const PHONE_ONLY_COUNTRIES = ['us'];
export const IDLE_TIMEOUT = 5;
export const REMAINING_TIMEOUT = 60;
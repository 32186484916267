import { useMutation, useQuery } from 'react-query';

import masterWalletApi from 'services/masterWallet';
import {
  GET_ALL_WALLET_BANK_ACCOUNTS_QY,
  GET_ALL_WALLET_TOKENIZED_CARDS,
  GET_MASTER_WALLET_INFO,
  GET_WALLET_TRANSACTIONS,
} from './keys';

export const useMasterWalletInfo = (body) => {
  const maseterWalletInfo = useQuery([GET_MASTER_WALLET_INFO, body], () => masterWalletApi.getMasterWalletInfo(body), {
    select: ({ data }) => data,
    staleTime: 5,
  });

  return maseterWalletInfo;
};

export const useFundAccount = () => {
  const response = useMutation(masterWalletApi.fundAccount, {
    mutationKey: 'fundAccount',
    onSuccess: (data) => {
      console.log('Fund Account Success', data);
    },
    onError: (error) => {
      console.log('Fund Account Error', error);
    },
  });

  return response;
};

export const useFundAccountViaCard = () => {
  const response = useMutation(masterWalletApi.fundAccountViaCard, {
    mutationKey: 'fundAccountViaCard',
    onSuccess: (data) => {
      console.log('Fund Account via Card Success', data);
    },
    onError: (error) => {
      console.log('Fund Account via Card Error', error);
    },
  });

  return response;
};

export const useWithdrawFund = () => {
  const response = useMutation(masterWalletApi.withdrawFund, {
    mutationKey: 'withdrawFund',
    onSuccess: (data) => {
      console.log('Withdraw Fund Success', data);
    },
    onError: (error) => {
      console.log('Withdraw Fund Error', error);
    },
  });

  return response;
};

export const useBankAccounts = (body) => {
  const data = useQuery([GET_ALL_WALLET_BANK_ACCOUNTS_QY, body], () => masterWalletApi.getBankAccounts(body), {
    select: ({ data }) => data,
  });

  return data;
};

export const useWalletTokenizedCards = (body) => {
  const data = useQuery([GET_ALL_WALLET_TOKENIZED_CARDS, body], () => masterWalletApi.getWalletTokenizedCards(body), {
    select: ({ data }) => data,
  });

  return data;
};

export const useWireTransferDetail = (body) => {
  const data = useQuery(['wireTransferDetail', body], () => masterWalletApi.getWireTransferDetail(body), {
    select: ({ data }) => data,
  });

  return data;
};

export const useSaveBankAccount = () => {
  const response = useMutation(masterWalletApi.saveBankAccount, {
    mutationKey: 'saveBankAccount',
    onSuccess: (data) => {
      console.log('Save Bank Account Success', data);
    },
    onError: (error) => {
      console.log('Save Bank Account Error', error);
    },
  });

  return response;
};

export const useSaveTokenizeCard = () => {
  const response = useMutation(masterWalletApi.saveTokenizeCard, {
    mutationKey: 'saveTokenizeCard',
    onSuccess: (data) => {
      console.log('Save Tokenize Card Success', data);
    },
    onError: (error) => {
      console.log('Save Tokenize Card Error', error);
    },
  });

  return response;
};

export const useWalletTransactions = (body) => {
  const walletTransactions = useQuery(
    [GET_WALLET_TRANSACTIONS, body],
    () => masterWalletApi.getWalletTransactions(body),
    {
      select: ({ data }) => data,
    }
  );

  return walletTransactions;
};

export const useDeleteWalletBankAccount = () => {
  const response = useMutation(masterWalletApi.deleteWalletBankAccount, {
    mutationKey: 'deleteWalletBankAccount',
    onSuccess: (data) => {
      console.log('Wallet Bank Account Delete Success', data);
    },
    onError: (error) => {
      console.log('Wallet Bank Account Delete Error', error);
    },
  });

  return response;
};

export const useDeleteWalletTokenizedCard = () => {
  const response = useMutation(masterWalletApi.deleteWalletTokenizedCard, {
    mutationKey: 'deleteWalletTokenizedCard',
    onSuccess: (data) => {
      console.log('Wallet Tokenized Card Delete Success', data);
    },
    onError: (error) => {
      console.log('Wallet Tokenized Card Delete Error', error);
    },
  });

  return response;
};

export const useVerifyWalletBankAccount = () => {
  const response = useMutation(masterWalletApi.verifyWalletBankAccount, {
    mutationKey: 'verifyWalletBankAccount',
    onSuccess: (data) => {
      console.log('Wallet Bank Account Verification Success', data);
    },
    onError: (error) => {
      console.log('Wallet Bank Account Verification Error', error);
    },
  });

  return response;
};

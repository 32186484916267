import React from 'react';
import { Row, Col } from 'antd';

import AllTransactionsTable from 'components/AllTransactionsTable';

import LayoutWarpper from 'components/Layout';

const Transactions = () => {
  return (
    <LayoutWarpper>
      <h1 className="page-title">Transactions</h1>
      <Row style={{ width: '100%' }} gutter={[16, 16]}>
        <Col span={24}>
          <AllTransactionsTable />
        </Col>
      </Row>
    </LayoutWarpper>
  );
};

export default Transactions;

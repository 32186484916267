import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Card, Col, Divider, Row, Spin, Space, notification } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, SubmitButton } from 'formik-antd';
import PhoneInput from 'react-phone-input-2';
import * as yup from 'yup';

import { IoArrowBackCircle } from 'react-icons/io5';
import { ArrowRightOutlined } from '@ant-design/icons';

import { getAdmin, getBackoffice } from 'helpers/localStorage';
import { queryClient } from 'rc-query';
import { useAddKYCContact, useSendReminderKYC } from 'rc-query/kysyncHooks';
import { GET_ALL_KYSYNC_USERS_KYC_QUERY } from 'rc-query/keys';
import { PHONE_ONLY_COUNTRIES, PHONE_REGEXP } from 'constants/config';

import LayoutWarpper from 'components/Layout';

import 'react-phone-input-2/lib/style.css';
import './KySync.less';

const AddContactsManuallyKYC = () => {
  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const history = useHistory();
  const addKYCContact = useAddKYCContact();
  const sendReminderKYC = useSendReminderKYC();

  const handleSubmit = (values, formikHelpers) => {
    formikHelpers.validateForm();

    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      client_id: adminUser?.client_id,
      status: 'active',
      ...values,
    };

    addKYCContact
      .mutateAsync(body)
      .then((data) => {
        if (data.status === 'success') {
          notification.success({
            message: data.message,
          });

          const sendReminderBody = {
            api_key: backOffice?.data?.name,
            token: adminUser?.token,
            user_ids: [data?.data?.id],
          };

          sendReminderKYC.mutateAsync(sendReminderBody).then((data) => {
            if (data.status === 'success') {
              notification.success({
                message: data.message,
              });
            } else {
              notification.error({
                message: data.message,
              });
            }
          });

          // reload listing and redirect
          queryClient.refetchQueries([GET_ALL_KYSYNC_USERS_KYC_QUERY]);
          history.push('/kysync_kyc_results');
        } else {
          notification.error({
            message: data.message,
          });
        }
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  return (
    <LayoutWarpper>
      <div className="KySyncPage">
        <h1 className="page-title">KySync - KYC</h1>
        <div className="backBtn">
          <Link to="/kysync_kyc" style={{ display: 'flex', alignContent: 'center', marginBottom: '20px' }}>
            <IoArrowBackCircle size={22} style={{ marginRight: '13px' }} /> <span>Back</span>
          </Link>
        </div>
        <Row gutter={[16, 16]}>
          <Col span={18}>
            <Formik
              initialValues={{
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
              }}
              validationSchema={yup.object().shape({
                first_name: yup.string().nullable(true).required('First Name is required'),
                last_name: yup.string().nullable(true).required('Last Name is required'),
                email: yup.string().nullable(true).required('Email is required'),
                phone_number: yup
                  .string()
                  .nullable(true)
                  .required('Phone number is required')
                  .matches(PHONE_REGEXP, 'Phone Number is not valid'),
              })}
              onSubmit={handleSubmit}
            >
              {(formProps) => {
                const { isSubmitting, handleSubmit, setFieldValue } = formProps;
                return (
                  <Spin spinning={isSubmitting}>
                    <div>
                      <Card>
                        <p className="kyc-ver-title">KYC identity verification</p>
                        <p className="kyc-ver-description">
                          Know Your Customers: Identity verification for individuals. Upload a bulk list or try adding{' '}
                          <br />
                          names to get started.
                        </p>
                        <Divider />
                        <Form
                          layout="vertical"
                          size="large"
                          initialValues={{
                            first_name: '',
                            last_name: '',
                            email: '',
                            phone_number: { short: 'US' },
                          }}
                        >
                          <Row gutter={[16, 16]}>
                            <Col span={12}>
                              <FormItem label="First Name" name="first_name">
                                <Input name="first_name" placeholder="John" />
                              </FormItem>
                            </Col>
                            <Col span={12}>
                              <FormItem label="Last Name" name="last_name">
                                <Input name="last_name" placeholder="Doe" />
                              </FormItem>
                            </Col>

                            <Col span={12}>
                              <FormItem label="Email Address" name="email">
                                <Input name="email" placeholder="example@gmail.com" />
                              </FormItem>
                            </Col>
                            <Col span={12}>
                              <div class="ant-col ant-form-item-label">
                                <label for="Phone Number" class="" title="Phone Number">
                                  Phone Number
                                </label>
                              </div>
                              <PhoneInput
                                onlyCountries={PHONE_ONLY_COUNTRIES}
                                inputClass="ant-form-item-control-input w-full"
                                country="us"
                                onChange={(phone) => setFieldValue('phone_number', `${phone}`)}
                                placeholder="Please enter phone number"
                              />
                              {formProps.errors.phone_number && (
                                <div className="ant-form-item-explain-error">{formProps.errors.phone_number}</div>
                              )}
                            </Col>
                          </Row>

                          <div className="kyc-btn">
                            <Space>
                              <Link to={`/kysync_kyc`}>
                                <Button size="large">Go Back</Button>
                              </Link>
                              <SubmitButton
                                loading={isSubmitting}
                                type="primary"
                                htmlType="submit"
                                onClick={handleSubmit}
                                size="large"
                                style={{ marginLeft: '16px' }}
                              >
                                Add
                              </SubmitButton>
                            </Space>
                          </div>
                          {/* <pre
                              style={{
                                background: '#f6f8fa',
                                fontSize: '.65rem',
                                padding: '.5rem',
                              }}
                            >
                              <FormikDebug />
                            </pre> */}
                        </Form>
                      </Card>
                      <div className="link">
                        <Link to={`/kysync_kyc_results`}>
                          Verification results <ArrowRightOutlined />
                        </Link>
                      </div>
                    </div>
                  </Spin>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </div>
    </LayoutWarpper>
  );
};

export default AddContactsManuallyKYC;

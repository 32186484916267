import { useMutation, useQuery } from 'react-query';

import transactionsApi from 'services/transactions';

import { GET_ALL_TRANSACTIONS_QY } from './keys';

export const useAllTransactions = (body) => {
  const allTransactions = useQuery([GET_ALL_TRANSACTIONS_QY, body], () => transactionsApi.getAllTransactions(body), {
    select: ({ data }) => data,
  });

  return allTransactions;
};

export const useReverseTransactionMutation = () => {
  const response = useMutation(transactionsApi.reverseTransaction, {
    mutationKey: 'reverseTransaction',
    onSuccess: (data) => {
      console.log('Reverse Transaction Success', data);
    },
    onError: (error) => {
      console.log('Reverse Transaction Error', error);
    },
  });

  return response;
};

export const useExportFileMutation = () => {
  const response = useMutation(transactionsApi.exportFile, {
    mutationKey: 'exportFile',
    onSuccess: (data) => {
      console.log('Export file Success', data);
    },
    onError: (error) => {
      console.log('Export file Error', error);
    },
  });

  return response;
};

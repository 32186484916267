import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Tag, Button, notification, Spin, Modal } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, InputNumber, SubmitButton } from 'formik-antd';
import { capitalize } from 'lodash';
import * as yup from 'yup';

import { IoArrowBackCircle } from 'react-icons/io5';
import { DeleteOutlined } from '@ant-design/icons';

import { getAdmin, getBackoffice, host } from 'helpers/localStorage';
import localStorageService from 'helpers/localStorageService';
import {
  useBankAccounts,
  useDeleteWalletBankAccount,
  useDeleteWalletTokenizedCard,
  useVerifyWalletBankAccount,
  useWalletTokenizedCards,
} from 'rc-query/masterWalletHooks';
import { queryClient } from 'rc-query';
import { GET_ALL_WALLET_BANK_ACCOUNTS_QY, GET_ALL_WALLET_TOKENIZED_CARDS } from 'rc-query/keys';

import LayoutWarpper from 'components/Layout';

import './LinkedAccounts.less';

const LinkedAccounts = () => {
  const initialWelletBankAccountValues = {
    id: -1,
    account_number: '0000',
    account_type: '',
    routing_number: -1,
    show: false,
  };
  const initialWelletCardValues = {
    id: -1,
    card_brand: '',
    last_four: '0000',
    show: false,
  };
  const [showVerifyWalletBankAccount, setShowVerifyWalletBankAccount] = useState(false);
  const [showDeleteWalletBankAccount, setShowDeleteWalletBankAccount] = useState(initialWelletBankAccountValues);
  const [selectedBankAccount, setSelectedBankAccount] = useState({});
  const [showDeleteWalletCard, setShowDeleteWalletCard] = useState(initialWelletCardValues);

  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const { data: BankAccounts, isLoading: BankAccountsLoading } = useBankAccounts({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
  });

  const { data: WalletTokenizedCards, isLoading: WalletTokenizedCardsLoading } = useWalletTokenizedCards({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
  });

  const deleteWalletBankAccount = useDeleteWalletBankAccount();
  const verifyWalletBankAccount = useVerifyWalletBankAccount();
  const deleteWalletTokenizedCard = useDeleteWalletTokenizedCard();

  const accountStatusColor = (status) => {
    switch (status) {
      case 'active':
        return 'green';
      case 'pending':
        return 'orange';
      case 'rejected':
        return 'red';
      default:
        return null;
    }
  };

  const handleVerifyBankAccount = (values, formikHelpers) => {
    const fundAccountReferral = localStorageService.get(`${host}_fund_account_referral`);

    formikHelpers.validateForm();

    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      account_id: `${selectedBankAccount.id}`,
      deposit_one: `${values.deposit_one}`,
      deposit_two: `${values.deposit_two}`,
    };

    verifyWalletBankAccount
      .mutateAsync(body)
      .then((data) => {
        if (data.status === 'success') {
          notification.success({
            message: data.message,
          });
          setShowVerifyWalletBankAccount(false);
          queryClient.refetchQueries([GET_ALL_WALLET_BANK_ACCOUNTS_QY]);

          // check send back to Fund Account (Referral) page
          if (fundAccountReferral) {
            history.push('/fund_accounts');
          }
        } else {
          notification.error({
            message: data.message,
          });
        }
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  const deleteBankAccount = (bank_account_id) => {
    deleteWalletBankAccount
      .mutateAsync({
        api_key: backOffice?.data?.name,
        token: adminUser?.token,
        id: bank_account_id,
      })
      .then((res) => {
        notification.success({ message: res.message });
        setShowDeleteWalletBankAccount(initialWelletBankAccountValues);
        queryClient.refetchQueries([GET_ALL_WALLET_BANK_ACCOUNTS_QY]);
      });
  };

  const deleteTokenizedCard = (card_id) => {
    deleteWalletTokenizedCard
      .mutateAsync({
        api_key: backOffice?.data?.name,
        token: adminUser?.token,
        id: card_id,
      })
      .then((res) => {
        notification.success({ message: res.message });
        setShowDeleteWalletCard(initialWelletCardValues);
        queryClient.refetchQueries([GET_ALL_WALLET_TOKENIZED_CARDS]);
      });
  };

  useEffect(() => {
    return () => {
      localStorageService.remove(`${host}_fund_account_referral`);
    };
  }, []);

  return (
    <LayoutWarpper>
      <h1 className="page-title">Linked Accounts</h1>
      <div className="backBtn">
        <Link to="/settings#master_wallet" style={{ display: 'flex', alignContent: 'center' }}>
          <IoArrowBackCircle size={22} style={{ marginRight: '13px' }} /> <span>Back to master wallet</span>
        </Link>
      </div>
      <Row gutter={[16, 16]} style={{ width: '100%', marginTop: '40px' }}>
        <Col span={18}>
          <Card title="LINKED BANKS">
            <Spin spinning={BankAccountsLoading}>
              {BankAccounts?.data?.map((bank) => (
                <Row>
                  <Col span={1}>
                    <div className="logo">{/* <img src="images/banks/wells_fargo.svg" alt="wells fargo" /> */}</div>
                  </Col>
                  <Col span={8}>
                    <div className="content">
                      <p className="title">{bank.account_holder_name}</p>
                      <p className="number">
                        {bank.account_type} {bank.account_number}
                      </p>
                    </div>
                  </Col>
                  <Col span={14}>
                    <div className="actions">
                      <div className="tags">
                        <Tag color={accountStatusColor(bank.status)}>{capitalize(bank.status)}</Tag>
                        {/* <Tag color="lightgray">Primary</Tag> */}
                      </div>
                      <div className="actionBtns">
                        {bank.status !== 'active' && bank.status !== 'completed' ? (
                          <Button
                            type="primary"
                            style={{ marginRight: '16px' }}
                            onClick={() => {
                              setShowVerifyWalletBankAccount(true);
                              setSelectedBankAccount(bank);
                            }}
                          >
                            Verify
                          </Button>
                        ) : null}
                        {/* <Button disabled>Make primary</Button> */}
                      </div>
                      <div className="actionIcons">
                        <Button>
                          <DeleteOutlined
                            style={{ color: 'red', fontSize: '20px' }}
                            onClick={() =>
                              setShowDeleteWalletBankAccount({
                                id: bank?.id,
                                account_type: bank?.account_type,
                                account_number: bank?.account_number,
                                routing_number: bank?.routing_number,
                                show: true,
                              })
                            }
                          />
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              ))}
            </Spin>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ width: '100%', marginTop: '40px' }}>
        <Col span={18}>
          <Card title="LINKED CARDS">
            <Spin spinning={WalletTokenizedCardsLoading}>
              {WalletTokenizedCards?.map((card) => (
                <Row>
                  <Col span={1}>
                    <div className="logo"> {/* <img src="images/banks/wells_fargo.svg" alt="wells fargo" /> */}</div>
                  </Col>
                  <Col span={8}>
                    <div className="content">
                      <p className="title">{card.hostname}</p>
                      <p className="number">
                        {card.card_type} **{card.last_four}
                      </p>
                    </div>
                  </Col>
                  <Col span={14}>
                    <div className="actions">
                      <div className="actionBtns">{/* <Button disabled>Make primary</Button> */}</div>
                      <div className="actionIcons">
                        <Button>
                          <DeleteOutlined
                            style={{ color: 'red', fontSize: '20px' }}
                            onClick={() =>
                              setShowDeleteWalletCard({
                                id: card.card_id,
                                card_brand: card.card_brand,
                                last_four: card.last_four,
                                show: true,
                              })
                            }
                          />
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              ))}
            </Spin>
          </Card>
        </Col>
      </Row>
      <Modal
        width={425}
        title="Verify bank account"
        visible={showVerifyWalletBankAccount}
        onCancel={() => setShowVerifyWalletBankAccount(false)}
        footer={false}
        className="cardModal"
        key={`verify-wallet-bank-account-${selectedBankAccount?.id}`}
      >
        <Formik
          enableReinitialize
          key={`verify-wallet-bank-account-form-${selectedBankAccount?.id}`}
          onSubmit={handleVerifyBankAccount}
          layout="vertical"
          initialValues={{
            deposit_one: +selectedBankAccount?.deposit_one || 0,
            deposit_two: +selectedBankAccount?.deposit_two || 0,
          }}
          validationSchema={yup.object().shape({
            deposit_one: yup.number().nullable(true).required('First Amount is required'),
            deposit_two: yup.number().nullable(true).required('Second Amount is required'),
          })}
        >
          {(formProps) => {
            const { isSubmitting, handleSubmit } = formProps;
            return (
              <Form
                layout="vertical"
                initialValues={{
                  deposit_one: +selectedBankAccount?.deposit_one || 0,
                  deposit_two: +selectedBankAccount?.deposit_two || 0,
                }}
              >
                <Row>
                  <Col span={24}>
                    <p className="descriptionText">
                      We deposited into and withdrew two small amounts from this bank account. They will appear in your
                      account arround [date].
                    </p>
                  </Col>
                </Row>
                <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
                  <Col span={24}>
                    <p className="descriptionText" style={{ marginBottom: '16px' }}>
                      Enter amount below:
                    </p>
                    <FormItem name="deposit_one" label="First Amount">
                      <InputNumber
                        size="large"
                        prefix="$"
                        precision={2}
                        name="deposit_one"
                        placeholder="0.00"
                        min={0}
                        style={{ width: '100%' }}
                      />
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem name="deposit_two" label="Second Amount">
                      <InputNumber
                        size="large"
                        prefix="$"
                        precision={2}
                        name="deposit_two"
                        placeholder="0.00"
                        min={0}
                        style={{ width: '100%' }}
                      />
                    </FormItem>
                  </Col>
                </Row>
                <Row style={{ marginTop: '22px' }}>
                  <Col span={24}>
                    <SubmitButton
                      loading={isSubmitting}
                      type="primary"
                      htmlType="submit"
                      onClick={handleSubmit}
                      size="large"
                      style={{ width: '100%' }}
                    >
                      Verify Bank Account
                    </SubmitButton>
                  </Col>
                </Row>
                {/* <pre
                style={{
                  background: '#f6f8fa',
                  fontSize: '.65rem',
                  padding: '.5rem',
                }}
              >
                <FormikDebug />
              </pre> */}
              </Form>
            );
          }}
        </Formik>
      </Modal>
      <Modal
        width={425}
        title="Delete linked source"
        visible={showDeleteWalletBankAccount.show}
        onCancel={() => setShowDeleteWalletBankAccount(initialWelletBankAccountValues)}
        footer={false}
        className="cardModal"
        key={`delete-wallet-bank-account-${showDeleteWalletBankAccount?.id}`}
      >
        <Row>
          <Col span={24}>
            <p className="descriptionText">
              You are about to delete your linked bank, {showDeleteWalletBankAccount.account_type} ****
              {showDeleteWalletBankAccount?.account_number.slice(5)}. Are you sure about this?.
            </p>
          </Col>
        </Row>
        <Row style={{ marginTop: '22px' }}>
          <Col span={24}>
            <Button
              type="danger"
              htmlType="submit"
              onClick={() => deleteBankAccount(showDeleteWalletBankAccount?.id)}
              size="large"
              style={{ width: '100%' }}
            >
              Delete bank account
            </Button>
          </Col>
          <Col span={24}>
            <Button
              type="secondary"
              onClick={() => setShowDeleteWalletBankAccount(initialWelletBankAccountValues)}
              size="large"
              style={{ marginTop: '16px', width: '100%' }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        width={425}
        title="Delete linked source"
        visible={showDeleteWalletCard.show}
        onCancel={() => setShowDeleteWalletCard(initialWelletCardValues)}
        footer={false}
        className="cardModal"
        key={`delete-wallet-bank-account-${showDeleteWalletCard?.id}`}
      >
        <Row>
          <Col span={24}>
            <p className="descriptionText">
              You are about to delete your linked card, {showDeleteWalletCard.card_brand} ****
              {showDeleteWalletCard?.last_four}. Are you sure about this?
            </p>
          </Col>
        </Row>
        <Row style={{ marginTop: '22px' }}>
          <Col span={24}>
            <Button
              type="danger"
              htmlType="submit"
              onClick={() => deleteTokenizedCard(showDeleteWalletCard?.id)}
              size="large"
              style={{ width: '100%' }}
            >
              Delete card
            </Button>
          </Col>
          <Col span={24}>
            <Button
              type="secondary"
              onClick={() => setShowDeleteWalletCard(initialWelletCardValues)}
              size="large"
              style={{ marginTop: '16px', width: '100%' }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal>
    </LayoutWarpper>
  );
};

export default LinkedAccounts;

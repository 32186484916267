import React, { useState } from 'react';
import { Row, Col, Button, Spin, Steps, notification, Badge } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { IoArrowBackCircle } from 'react-icons/io5';
import { Formik } from 'formik';
import { Form, FormItem, InputNumber, Radio, Select, SubmitButton } from 'formik-antd';
import { capitalize, filter, replace } from 'lodash';
import * as yup from 'yup';

import { queryClient } from 'rc-query';
import { useKySyncFundingSources } from 'rc-query/kysyncHooks';
import { useBeneficiaries, useSendMoney } from 'rc-query/payoutHooks';
import { GET_ALL_PAYOUTS_QY } from 'rc-query/keys';

import LayoutWarpper from 'components/Layout';
import { getAdmin, getBackoffice } from 'helpers/localStorage';
import { isFormValidate, moneyFormat } from 'helpers/utils';

const { Option } = Select;
const { Step } = Steps;

import './SendMoney.less';

const SendMoney = () => {
  const history = useHistory();

  const [stepNumber, setStepNumber] = useState(0);

  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const KySyncFundingSources = useKySyncFundingSources({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
  });

  const Beneficiaries = useBeneficiaries({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
    page: 1,
    page_size: 1000,
  });

  const sendMoney = useSendMoney();

  const continueSubmit = (formProps, stepNumber) => {
    let touchedFields = {};

    if (stepNumber === 1) {
      touchedFields.wallet = true;
    }

    if (stepNumber === 2) {
      touchedFields.wallet = true;
      touchedFields.account_id = true;
    }

    if (stepNumber === 3) {
      touchedFields.wallet = true;
      touchedFields.amount = true;
      touchedFields.account_id = true;
    }

    formProps.setTouched(touchedFields);

    formProps.validateForm().then((errors) => {
      if (isFormValidate(errors, touchedFields)) {
        setStepNumber(stepNumber);
      }
    });
  };

  const handleSubmit = (values, formikHelpers) => {
    formikHelpers.validateForm();

    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      ...values,
      account_id: `${values.account_id}`,
      amount: `${values.amount}`,
    };

    sendMoney
      .mutateAsync(body)
      .then((data) => {
        if (data.status === 'success') {
          notification.success({
            message: data.message,
          });
          queryClient.refetchQueries([GET_ALL_PAYOUTS_QY]);
          history.push('/payouts');
        } else {
          notification.error({
            message: data.message,
          });
        }
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  return (
    <LayoutWarpper>
      <h1 className="page-title">Payouts</h1>
      <Row style={{ width: '100%' }} gutter={[16, 16]}>
        <Col span={24}>
          <Formik
            initialValues={{
              entity_type: 'user_issued',
            }}
            validationSchema={yup.object().shape({
              wallet: yup.string().nullable(true).required('Wallet is required'),
              amount: yup.string().nullable(true).required('Amount is required'),
              account_id: yup.number().nullable(true).required('Beneficiary is required'),
            })}
            onSubmit={handleSubmit}
          >
            {(formProps) => {
              const { isSubmitting, handleSubmit, values } = formProps;
              return (
                <Spin spinning={isSubmitting || KySyncFundingSources.isLoading || Beneficiaries.isLoading}>
                  <Form size="large" layout="vertical">
                    <div className="backBtn">
                      <Link to="/payouts" style={{ display: 'flex', alignContent: 'center' }}>
                        <IoArrowBackCircle size={22} style={{ marginRight: '13px' }} /> <span>Back</span>
                      </Link>
                    </div>
                    <Row gutter={[16, 8]} style={{ marginTop: '40px' }}>
                      <Col span={6}>
                        <Steps progressDot current={stepNumber} direction="vertical">
                          <Step title="Transfer type" />
                          <Step title="Account to pay" />
                          <Step title="Amount & Source" />
                          <Step title="Preview and confirm" />
                        </Steps>
                      </Col>
                      {stepNumber === 0 ? (
                        <Col span={10}>
                          <Row gutter={[16, 8]}>
                            <Col span={24}>
                              <p>How would you like to send money?</p>
                              <FormItem name="wallet">
                                <Radio.Group name="wallet">
                                  {KySyncFundingSources?.data?.wallets?.map((fundingSource) => (
                                    <Row
                                      gutter={[16, 8]}
                                      style={{ marginBottom: '16px' }}
                                      key={`source-row-${fundingSource.id}`}
                                    >
                                      <Col span={24}>
                                        <label className="cursor-pointer">
                                          <div className="walletOptions">
                                            <div className="icon">
                                              {fundingSource.id === 'funding_account' ? (
                                                <img src="/images/banks/deposits.svg" alt={fundingSource.name} />
                                              ) : (
                                                <img src="/images/banks/deposits.svg" alt={fundingSource.name} />
                                              )}
                                              <div className="optionText">
                                                <p className="title">{fundingSource.name}</p>
                                                {/* <p className="desc">Should arrive 2-3 business days</p> */}
                                              </div>
                                            </div>
                                            <div className="option">
                                              <Radio name="wallet" key={fundingSource.id} value={fundingSource.id} />
                                            </div>
                                          </div>
                                        </label>
                                      </Col>
                                    </Row>
                                  ))}

                                  <Row gutter={[16, 8]} style={{ marginBottom: '16px' }} key="source-row-ACH">
                                    <Col span={24}>
                                      <Badge.Ribbon text="Coming Soon">
                                        <label className="cursor-pointer">
                                          <div className="walletOptions">
                                            <div className="icon">
                                              <img src="/images/icons/bank_transfer.svg" alt="Bank Transfer" />
                                              <div className="optionText">
                                                <p className="title">Bank Transfer (ACH)</p>
                                                <p className="desc">Should arrive 2-3 business days</p>
                                              </div>
                                            </div>
                                            <div className="option">
                                              <Radio name="wallet" key="ACH" value="ACH" disabled />
                                            </div>
                                          </div>
                                        </label>
                                      </Badge.Ribbon>
                                    </Col>
                                  </Row>
                                  <Row gutter={[16, 8]} style={{ marginBottom: '16px' }} key="source-row-wire-transfer">
                                    <Col span={24}>
                                      <Badge.Ribbon text="Coming Soon">
                                        <label className="cursor-pointer">
                                          <div className="walletOptions">
                                            <div className="icon">
                                              <img src="/images/icons/wire_transfer.svg" alt="Wire Transfer" />
                                              <div className="optionText">
                                                <p className="title">Wire Transfer</p>
                                                <p className="desc">Should arrive same business day</p>
                                              </div>
                                            </div>
                                            <div className="option">
                                              <Radio name="wallet" key="wire_transfer" value="wire_transfer" disabled />
                                            </div>
                                          </div>
                                        </label>
                                      </Badge.Ribbon>
                                    </Col>
                                  </Row>
                                </Radio.Group>
                              </FormItem>
                            </Col>
                          </Row>
                          <Row gutter={[16, 8]} style={{ marginTop: '20px' }}>
                            <Col span={24}>
                              <Button type="secondary" size="large">
                                <Link to="/payouts">Cancel</Link>
                              </Button>
                              <Button
                                type="primary"
                                size="large"
                                style={{ marginLeft: '16px' }}
                                onClick={() => continueSubmit(formProps, 1)}
                              >
                                Continue
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      ) : stepNumber === 1 ? (
                        <Col span={10}>
                          <Row gutter={[16, 8]}>
                            <Col span={24}>
                              <p>Who are you paying?</p>
                              <FormItem label="Entity Type" name="entity_type" initialValue="business">
                                <Select name="entity_type">
                                  <Option key="user_issued" value="user_issued">
                                    Individual Issued Accounts
                                  </Option>
                                  <Option key="business_issued" value="business_issued">
                                    Business Issued Accounts
                                  </Option>
                                  <Option key="user" value="user">
                                    Individual Linked Accounts
                                  </Option>
                                  <Option key="business" value="business">
                                    Business Linked Accounts
                                  </Option>
                                </Select>
                              </FormItem>
                              <FormItem label="Beneficiary" name="account_id">
                                <Select
                                  name="account_id"
                                  placeholder="Select Beneficiary"
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                  options={Beneficiaries?.data[values?.entity_type].map((account) => ({
                                    value: account.id,
                                    label: `${account.account_holder_name} (${
                                      values?.entity_type === 'user' || values?.entity_type === 'user_issued' ? account.email : account.business_email
                                    })`,
                                  }))}
                                />
                                {/* {Beneficiaries?.data[values?.entity_type].map((account) => (
                                    <Option key={account.id} value={account.id}>
                                      {account.account_holder_name} (
                                      {values?.entity_type === 'user' ? account.email : account.business_email})
                                    </Option>
                                  ))}
                                </Select> */}
                              </FormItem>
                            </Col>
                          </Row>
                          <Row gutter={[16, 8]} style={{ marginTop: '20px' }}>
                            <Col span={24}>
                              <Button type="secondary" size="large" onClick={() => setStepNumber(0)}>
                                Go Back
                              </Button>
                              <Button
                                type="primary"
                                size="large"
                                style={{ marginLeft: '16px' }}
                                onClick={() => continueSubmit(formProps, 2)}
                              >
                                Continue
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      ) : stepNumber === 2 ? (
                        <Col span={10}>
                          <Row gutter={[16, 8]}>
                            <Col span={24}>
                              <p>Input amount and select funding source.</p>
                              <Row gutter={[16, 8]}>
                                <Col span={24}>
                                  <FormItem label="Amount to fund" name="amount">
                                    <InputNumber
                                      name="amount"
                                      placeholder="0.00"
                                      style={{ width: '100%' }}
                                      min={0}
                                      prefix="$"
                                      precision={2}
                                    />
                                  </FormItem>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row gutter={[16, 8]} style={{ marginTop: '20px' }}>
                            <Col span={24}>
                              <Button type="secondary" size="large" onClick={() => setStepNumber(1)}>
                                Go Back
                              </Button>
                              <Button
                                type="primary"
                                size="large"
                                style={{ marginLeft: '16px' }}
                                onClick={() => continueSubmit(formProps, 3)}
                              >
                                Continue
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      ) : stepNumber === 3 ? (
                        <Col span={10}>
                          <Row gutter={[16, 8]}>
                            <Col span={24}>
                              <p>Preview and confirm payout</p>

                              <div className="preview mb-15">
                                <h2 className="previewTitle">PAYOUT OVERVIEW</h2>
                                <Row>
                                  <Col span={24}>
                                    <div className="selectedOptions">
                                      <Row style={{ marginTop: '16px' }}>
                                        <Col span={12}>
                                          <div className="label">Account Number</div>
                                          <div className="value">
                                            {filter(Beneficiaries?.data[values?.entity_type], {
                                              id: values?.account_id,
                                            })[0]?.account_number || ''}
                                          </div>
                                        </Col>
                                        <Col span={12}>
                                          <div className="label">Beneficiary</div>
                                          <div className="value">
                                            {filter(Beneficiaries?.data[values?.entity_type], {
                                              id: values?.account_id,
                                            })[0]?.account_holder_name || ''}
                                          </div>
                                        </Col>
                                      </Row>

                                      <Row style={{ marginTop: '16px' }}>
                                        <Col span={12}>
                                          <div className="label">Amount</div>
                                          <div className="value">{moneyFormat(values?.amount, 2)}</div>
                                        </Col>
                                        <Col span={12}>
                                          <div className="label">Transaction type</div>
                                          <div className="value">{replace(capitalize(values.wallet), '_', ' ')}</div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              <Button type="secondary" size="large" onClick={() => setStepNumber(2)}>
                                Go Back
                              </Button>
                              <SubmitButton
                                loading={isSubmitting}
                                type="primary"
                                htmlType="submit"
                                onClick={handleSubmit}
                                size="large"
                                style={{ marginLeft: '16px' }}
                              >
                                Confirm payouts
                              </SubmitButton>
                            </Col>
                          </Row>
                        </Col>
                      ) : null}
                    </Row>
                    {/* <pre
                      style={{
                        background: '#f6f8fa',
                        fontSize: '.65rem',
                        padding: '.5rem',
                      }}
                    >
                      <FormikDebug />
                    </pre> */}
                  </Form>
                </Spin>
              );
            }}
          </Formik>
        </Col>
      </Row>
    </LayoutWarpper>
  );
};

export default SendMoney;

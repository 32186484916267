import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Col, Modal, notification, Row, Spin, Table } from 'antd';
import { IoArrowBackCircle } from 'react-icons/io5';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Formik } from 'formik';
import { Form, FormItem, Input, SubmitButton } from 'formik-antd';
import PhoneInput from 'react-phone-input-2';
import * as yup from 'yup';
import { findIndex, isEmpty } from 'lodash';

import LayoutWarpper from 'components/Layout';
import { getAdmin, getBackoffice, getBulkUploadedKysync, setBulkUploadedKysync } from 'helpers/localStorage';
import {
  useDeleteKYBEntity,
  useDeleteKYCEntity,
  useSendReminderKYB,
  useSendReminderKYC,
  useUpdateKYBEntity,
  useUpdateKYCEntity,
} from 'rc-query/kysyncHooks';
import { remove } from 'lodash';
import { cloneDeep } from 'lodash';
import { PHONE_ONLY_COUNTRIES, PHONE_REGEXP } from 'constants/config';

const KySyncBulkUploadPreview = (props) => {
  const kysyncType = props.match.params.kysyncType || '';
  const history = useHistory();

  if (isEmpty(getBulkUploadedKysync())) {
    if (kysyncType === 'kyc') {
      history.push('/kysync_kyc');
    } else {
      history.push('/kysync_kyb');
    }
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [entityDetail, setEntityDetail] = useState({});
  const [kysyncEntites, setKysyncEntities] = useState([]);
  const [showDeleteEntityBox, setShowDeleteEntityBox] = useState(false);
  const [showEditEntityBox, setShowEditEntityBox] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});

  useEffect(() => {
    const entites = getBulkUploadedKysync();
    if (!isEmpty(entites) && (showEditEntityBox === false || showDeleteEntityBox === false)) {
      if (kysyncType === 'kyc') {
        setKysyncEntities(entites.saved_users);
      } else if (kysyncType === 'kyb') {
        setKysyncEntities(entites.saved_businesses);
      }
    }
  }, [showEditEntityBox, showDeleteEntityBox]);

  const adminUser = getAdmin();
  const backOffice = getBackoffice();

  const sendReminderKYB = useSendReminderKYB();
  const sendReminderKYC = useSendReminderKYC();
  const updateKYCEntity = useUpdateKYCEntity();
  const updateKYBEntity = useUpdateKYBEntity();
  const deleteKYCEntity = useDeleteKYCEntity();
  const deleteKYBEntity = useDeleteKYBEntity();

  const columns = [];

  if (kysyncType === 'kyc') {
    columns.push(
      {
        title: 'First Name',
        dataIndex: 'first_name',
        key: 'first_name',
        sorter: (a, b) => a.first_name.localeCompare(b.first_name),
        sortOrder: sortedInfo.columnKey === 'first_name' ? sortedInfo.order : null,
      },
      {
        title: 'Last Name',
        dataIndex: 'last_name',
        key: 'last_name',
        sorter: (a, b) => a.last_name.localeCompare(b.last_name),
        sortOrder: sortedInfo.columnKey === 'last_name' ? sortedInfo.order : null,
      }
    );
  } else {
    columns.push({
      title: 'Business Name',
      dataIndex: 'business_name',
      key: 'business_name',
      sorter: (a, b) => a.business_name.localeCompare(b.business_name),
      sortOrder: sortedInfo.columnKey === 'business_name' ? sortedInfo.order : null,
    });
  }

  columns.push(
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortOrder: sortedInfo.columnKey === 'email' ? sortedInfo.order : null,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
    {
      title: 'Action',
      render: (text, record) => (
        <>
          <EditOutlined onClick={() => handleShowEditEntityBox(record)} />
          <DeleteOutlined onClick={() => handleShowDeleteEntityBox(record)} style={{ marginLeft: '16px' }} />
        </>
      ),
    }
  );

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: 'odd',
        text: 'Select Odd Row',
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }

            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: 'even',
        text: 'Select Even Row',
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }

            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  const handleChange = (pagination, filters, sorter) => {
    // console.log('Various parameters', pagination, filters, sorter);
    // setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const handleSendKYCVerificationLink = () => {
    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      user_ids: selectedRowKeys,
    };

    sendReminderKYC.mutateAsync(body).then((data) => {
      if (data.status === 'success') {
        notification.success({
          message: data.message,
        });
        history.push('kysync_kyc_results');
      } else {
        notification.error({
          message: data.message,
        });
      }
    });
  };

  const handleSendKYBVerificationLink = () => {
    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      business_ids: selectedRowKeys,
    };

    sendReminderKYB.mutateAsync(body).then((data) => {
      if (data.status === 'success') {
        notification.success({
          message: data.message,
        });
        history.push('kysync_kyb_results');
      } else {
        notification.error({
          message: data.message,
        });
      }
    });
  };

  const updateKysyncEntity = (id, values) => {
    const entityIndex = findIndex(kysyncEntites, (entity) => entity.id === id);
    if (kysyncType === 'kyc') {
      kysyncEntites[entityIndex].first_name = values.first_name;
      kysyncEntites[entityIndex].last_name = values.last_name;
      kysyncEntites[entityIndex].email = values.email;
      kysyncEntites[entityIndex].phone_number = values.phone_number;
    } else {
      const tempKysyncEntities = cloneDeep(kysyncEntites);
      kysyncEntites[entityIndex].business_name = values.business_name;
      kysyncEntites[entityIndex].contact_email = values.contact_email;
      kysyncEntites[entityIndex].contact_phone = values.contact_phone;

      tempKysyncEntities[entityIndex].business_name = values.business_name;
      tempKysyncEntities[entityIndex].email = values.contact_email;
      tempKysyncEntities[entityIndex].phone_number = values.contact_phone;
    }

    setBulkUploadedKysync(kysyncEntites);
    setKysyncEntities(kysyncEntites);
  };

  const deleteKysyncEntity = (entityId) => {
    remove(kysyncEntites, (entity) => entity.id === entityId);
    setBulkUploadedKysync(kysyncEntites);
    setKysyncEntities(kysyncEntites);
  };

  const handleUpdateEntity = (values, formikHelpers) => {
    formikHelpers.validateForm();

    const body = {
      api_key: backOffice?.data?.name,
      token: adminUser?.token,
      ...values,
    };

    if (entityDetail?.id) {
      body.id = entityDetail?.id;

      if (kysyncType === 'kyc') {
        updateKYCEntity
          .mutateAsync(body)
          .then((data) => {
            if (data.status === 'success') {
              notification.success({
                message: data.message,
              });

              updateKysyncEntity(entityDetail.id, values);
              setShowEditEntityBox(false);
            } else {
              notification.error({
                message: data.message,
              });
            }
          })
          .finally(() => {
            formikHelpers.setSubmitting(false);
          });
      } else {
        updateKYBEntity
          .mutateAsync(body)
          .then((data) => {
            if (data.status === 'success') {
              notification.success({
                message: data.message,
              });

              updateKysyncEntity(entityDetail.id, values);
              setShowEditEntityBox(false);
            } else {
              notification.error({
                message: data.message,
              });
            }
          })
          .finally(() => {
            formikHelpers.setSubmitting(false);
          });
      }
    }
  };

  const handleDeleteEntity = (entityId) => {
    if (kysyncType === 'kyc') {
      deleteKYCEntity
        .mutateAsync({
          api_key: backOffice?.data?.name,
          token: adminUser?.token,
          entity_id: entityId,
        })
        .then(() => {
          notification.success({ message: 'KYC Entity deleted successfully.' });
          deleteKysyncEntity(entityId);
          setShowDeleteEntityBox(false);
        });
    } else {
      deleteKYBEntity
        .mutateAsync({
          api_key: backOffice?.data?.name,
          token: adminUser?.token,
          entity_id: entityId,
        })
        .then(() => {
          notification.success({ message: 'KYB Entity deleted successfully.' });
          deleteKysyncEntity(entityId);
          setShowDeleteEntityBox(false);
        });
    }
  };

  const handleShowDeleteEntityBox = (record) => {
    setEntityDetail(record);
    setShowDeleteEntityBox(true);
  };

  const handleShowEditEntityBox = (record) => {
    setEntityDetail(record);
    setShowEditEntityBox(true);
  };

  const handleCancelBox = () => {
    setEntityDetail({});
    setShowDeleteEntityBox(false);
    setShowEditEntityBox(false);
  };

  return (
    <LayoutWarpper>
      <div className="KySyncPage">
        <h1 className="page-title">KySync - Added Entities</h1>
        <div className="backBtn">
          <Link to="/kysync" style={{ display: 'flex', alignContent: 'center', marginBottom: '20px' }}>
            <IoArrowBackCircle size={22} style={{ marginRight: '13px' }} /> <span>Back</span>
          </Link>
        </div>
        <div className="searchBox" style={{ float: 'right', marginRight: '16px', marginBottom: '16px' }}>
          {kysyncType === 'kyc' ? (
            <Button type="primary" size="large" onClick={handleSendKYCVerificationLink}>
              Send verification link
            </Button>
          ) : (
            <Button type="primary" size="large" onClick={handleSendKYBVerificationLink}>
              Send verification link
            </Button>
          )}
        </div>
        <Row style={{ width: '100%' }} gutter={[16, 16]}>
          <Col span={24}>
            <Spin spinning={sendReminderKYB.isLoading || updateKYCEntity.isLoading || updateKYBEntity.isLoading}>
              <div>
                <Table
                  rowKey={(record) => record.id}
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={kysyncEntites}
                  pagination={true}
                  onChange={handleChange}
                />
              </div>
            </Spin>
          </Col>
        </Row>
      </div>

      <Modal
        width={450}
        title="Delete Entity"
        visible={showDeleteEntityBox}
        onCancel={handleCancelBox}
        footer={false}
        className="blockEntityModal"
        key={`delete-entity-modal-${entityDetail?.id}`}
      >
        <Row style={{ marginTop: '20px' }}>
          <Col span={24}>
            <p className="descriptionText">
              You are about to delete{' '}
              {kysyncType === 'kyc'
                ? `"${entityDetail?.first_name} ${entityDetail?.last_name}"`
                : `"${entityDetail?.business_name}"`}{' '}
              from {kysyncType === 'kyc' ? 'KYC' : 'KYB'} verification list. Are you sure about this?
            </p>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
          <Col span={24}>
            <Button
              className="actionBtn"
              type="danger"
              size="large"
              style={{ width: '100%' }}
              onClick={() => handleDeleteEntity(entityDetail.id)}
            >
              Delete [
              {kysyncType === 'kyc'
                ? `${entityDetail?.first_name} ${entityDetail?.last_name}`
                : `${entityDetail?.business_name}`}
              ]
            </Button>
          </Col>
          <Col span={24}>
            <Button
              className="actionBtn"
              type="default"
              size="large"
              style={{ width: '100%' }}
              onClick={handleCancelBox}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal>

      <Modal
        width={450}
        visible={showEditEntityBox}
        title="Edit entity info"
        onCancel={handleCancelBox}
        footer={false}
        className="cardModal"
        key={`edit-entity-modal-${entityDetail?.id}`}
      >
        {kysyncType === 'kyc' ? (
          <Formik
            enableReinitialize
            initialValues={{
              first_name: entityDetail?.first_name || null,
              last_name: entityDetail?.last_name || null,
              email: entityDetail?.email || null,
              phone_number: entityDetail?.phone_number || null,
            }}
            validationSchema={yup.object().shape({
              first_name: yup.string().nullable(true).required('First Name is required'),
              last_name: yup.string().nullable(true).required('Last Name is required'),
              email: yup.string().email().nullable(true).required('Email is required'),
              phone_number: yup
                .string()
                .nullable(true)
                .required('Phone Number is required')
                .matches(PHONE_REGEXP, 'Phone Number is not valid'),
            })}
            onSubmit={handleUpdateEntity}
          >
            {(formProps) => {
              const { isSubmitting, handleSubmit, setFieldValue, values } = formProps;
              return (
                <Spin spinning={isSubmitting}>
                  <Form
                    layout="vertical"
                    initialValues={{
                      first_name: entityDetail?.first_name || '',
                      last_name: entityDetail?.last_name || '',
                      email: entityDetail?.email || '',
                      phone_number: entityDetail?.phone_number || { short: 'US' },
                    }}
                  >
                    <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
                      <Col span={24}>
                        <Row gutter={[16, 16]}>
                          <Col span={12}>
                            <FormItem name="first_name" label="First Name" required>
                              <Input name="first_name" placeholder="Blackmon" />
                            </FormItem>
                          </Col>
                          <Col span={12}>
                            <FormItem name="last_name" label="Last Name" required>
                              <Input name="last_name" placeholder="London" />
                            </FormItem>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <FormItem name="email" label="Email Address" required>
                          <Input name="email" disabled />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <div class="ant-col ant-form-item-label">
                          <label for="Phone Number" class="ant-form-item-required" title="Phone Number">
                            Phone Number
                          </label>
                        </div>
                        <PhoneInput
                          onlyCountries={PHONE_ONLY_COUNTRIES}
                          value={values.phone_number}
                          inputClass="ant-form-item-control-input w-full"
                          country="us"
                          onChange={(phone) => setFieldValue('phone_number', `${phone}`)}
                          placeholder="Please enter phone number"
                        />
                        {formProps.errors.phone_number && (
                          <div className="ant-form-item-explain-error">{formProps.errors.phone_number}</div>
                        )}
                      </Col>
                      <Col span={24}>
                        <SubmitButton
                          loading={isSubmitting}
                          type="primary"
                          htmlType="submit"
                          onClick={handleSubmit}
                          size="large"
                          style={{ width: '100%' }}
                        >
                          Save
                        </SubmitButton>
                      </Col>
                    </Row>
                    {/* <pre
                    style={{
                      background: '#f6f8fa',
                      fontSize: '.65rem',
                      padding: '.5rem',
                    }}
                  >
                    <FormikDebug />
                  </pre> */}
                  </Form>
                </Spin>
              );
            }}
          </Formik>
        ) : (
          <Formik
            enableReinitialize
            initialValues={{
              business_name: entityDetail?.business_name || null,
              contact_email: entityDetail?.email || null,
              contact_phone: entityDetail?.phone_number || null,
            }}
            validationSchema={yup.object().shape({
              business_name: yup.string().nullable(true).required('Business Name is required'),
              contact_email: yup.string().email().nullable(true).required('Email is required'),
              contact_phone: yup.string().nullable(true).required('Phone Number is required'),
            })}
            onSubmit={handleUpdateEntity}
          >
            {(formProps) => {
              const { isSubmitting, handleSubmit, setFieldValue, values } = formProps;
              return (
                <Spin spinning={isSubmitting}>
                  <Form
                    layout="vertical"
                    initialValues={{
                      business_name: entityDetail?.business_name || '',
                      contact_email: entityDetail?.email || '',
                      contact_phone: entityDetail?.phone_number || { short: 'US' },
                    }}
                  >
                    <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
                      <Col span={24}>
                        <Row gutter={[16, 16]}>
                          <Col span={24}>
                            <FormItem name="business_name" label="Business Name" required>
                              <Input name="business_name" placeholder="Blackmon" />
                            </FormItem>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <FormItem name="contact_email" label="Email Address" required>
                          <Input name="contact_email" disabled />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <div class="ant-col ant-form-item-label">
                          <label for="Phone Number" class="" title="Phone Number">
                            Phone Number
                          </label>
                        </div>
                        <PhoneInput
                          onlyCountries={PHONE_ONLY_COUNTRIES}
                          value={values.contact_phone}
                          inputClass="ant-form-item-control-input w-full"
                          country="us"
                          onChange={(phone) => setFieldValue('contact_phone', `${phone}`)}
                          placeholder="Please enter phone number"
                        />
                        {formProps.errors.contact_phone && (
                          <div className="ant-form-item-explain-error">{formProps.errors.contact_phone}</div>
                        )}
                      </Col>
                      <Col span={24}>
                        <SubmitButton
                          loading={isSubmitting}
                          type="primary"
                          htmlType="submit"
                          onClick={handleSubmit}
                          size="large"
                          style={{ width: '100%' }}
                        >
                          Save
                        </SubmitButton>
                      </Col>
                    </Row>
                    {/* <pre
                    style={{
                      background: '#f6f8fa',
                      fontSize: '.65rem',
                      padding: '.5rem',
                    }}
                  >
                    <FormikDebug />
                  </pre> */}
                  </Form>
                </Spin>
              );
            }}
          </Formik>
        )}
      </Modal>
    </LayoutWarpper>
  );
};

export default KySyncBulkUploadPreview;

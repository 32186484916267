import React from 'react';
import { Row, Col, Card, Statistic, Spin } from 'antd';
import { Link } from 'react-router-dom';

import { moneyFormat, numberFormat } from 'helpers/utils';

import Users from '../../assets/users.png';
import Deposits from '../../assets/deposits.png';
import Payout from '../../assets/payouts.png';
import Payout2 from '../../assets/payouts2.png';

const StatisticsCard = ({ isLoading, data }) => {
  return (
    <Spin spinning={isLoading}>
      <div className="site-statistic-demo-card">
        <Row gutter={16}>
          <Col span={6}>
            <Link to="/entities">
              <Card>
                <Statistic
                  title="Users"
                  value={numberFormat(data?.data?.users || 0)}
                  precision={0}
                  valueStyle={{ color: 'rgba(87, 202, 235, 1)' }}
                  prefix={
                    <div className="icon-box" style={{ backgroundColor: 'rgba(87, 202, 235, 0.2)' }}>
                      <img src={Users} />
                    </div>
                  }
                />
              </Card>
            </Link>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="Balances"
                value={`${moneyFormat(data?.data?.balances || 0, 2)}`}
                precision={0}
                valueStyle={{ color: 'rgba(93, 218, 180, 1)' }}
                prefix={
                  <div className="icon-box" style={{ backgroundColor: 'rgba(93, 218, 180, 0.2)' }}>
                    <img src={Deposits} />
                  </div>
                }
              />
            </Card>
          </Col>
          <Col span={6}>
            <Link to="/payouts">
              <Card>
                <Statistic
                  title="Payouts"
                  value={`${moneyFormat(data?.data?.payouts || 0, 2)}`}
                  precision={0}
                  valueStyle={{ color: 'rgba(255, 121, 118, 1)' }}
                  prefix={
                    <div className="icon-box" style={{ backgroundColor: 'rgba(255, 121, 118, 0.2)' }}>
                      <img src={Payout} />
                    </div>
                  }
                />
              </Card>
            </Link>
          </Col>
          <Col span={6}>
            <Link to="/transactions">
              <Card>
                <Statistic
                  title="Transactions"
                  value={`${moneyFormat(data?.data?.transactions || 0, 2)}`}
                  precision={2}
                  valueStyle={{ color: 'rgba(150, 148, 255, )' }}
                  prefix={
                    <div className="icon-box" style={{ backgroundColor: 'rgba(150, 148, 255, 0.2)' }}>
                      <img src={Payout2} />
                    </div>
                  }
                />
              </Card>
            </Link>
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default StatisticsCard;

import axios from 'axios';

import { API_BASE_URL } from '@Constants/config';
import { apiWrapper } from './interceptors';
// const host = window.location.origin;

const request = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30 * 1000,
});

const authApi = {
  login: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/login', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },
  verify2FA: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/verify-admin-login', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },
  reset: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/forgot-password', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },
  verifyPasscode: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/forgot-password/verify-code', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },
  changePassword: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/forgot-password/reset', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },
};

// const verifyApi = {
//   verify: (body) => {
//     return new Promise((resolve, reject) => {
//       request
//         .post('/user/twofa/verify', body)
//         .then((res) => {
//           resolve(res.data);
//         })
//         .catch((error) => {
//           reject(error?.response?.data?.message);
//         });
//     });
//   },
// };

export default apiWrapper(authApi, ['login']);

import axios from 'axios';

import { API_BASE_URL } from 'constants/config';
import { apiWrapper } from './interceptors';

const request = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30 * 1000,
});

const bankAccountsApi = {
  getAllBankAccounts: (body) => {
    let path = '';
    if(body.issued_bank_account){
      path = 'admin/bank-accounts/issued'
      if(body.accountType == 'business'){
        path = 'admin/business/bank-accounts/issued'
      }
    }else{
      path = '/admin/accounts'
    }
    return new Promise((resolve, reject) => {
      request
        .post(path, { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },
  blockAccount: (body) => {
    const path = body.issued_bank_account
      ? '/admin/bank-accounts/issued/update/status'
      : '/admin/accounts/update/status';
    return new Promise((resolve, reject) => {
      request
        .post(path, { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },
  accountLimit: (body) => {
    const path = body.issued_bank_account ? '/admin/bank-accounts/issued/update' : '/admin/accounts/update';
    return new Promise((resolve, reject) => {
      request
        .post(path, { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },
};

export default apiWrapper(bankAccountsApi, ['bankAccountsApi']);

import axios from 'axios';

import { API_BASE_URL } from '@Constants/config';
import { apiWrapper } from './interceptors';

const request = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30 * 1000,
});

const settingApi = {
  getGeneralSetting: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/settings', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getAccountSetting: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/settings/accounts', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  updateAccountSetting: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/settings/accounts/update', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getCardSetting: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/settings/cards', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  updateCardSetting: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/settings/cards/update', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getTransactionSetting: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/settings/transactions', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  updateTransactionSetting: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/settings/transactions/update', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getSecuritySetting: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/settings/security', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  updateSecuritySetting: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/settings/security/update', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  updatePasswordSetting: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/update-password', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  enableGoogle2FA: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/settings/security/enable-authenticator-two-fa', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  confirmGoogle2FA: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/settings/security/confirm-authenticator-two-fa', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  disableGoogle2FA: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/settings/security/request-disable-authenticator-two-fa', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  confirmDisableGoogle2FA: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/settings/security/disable-authenticator-two-fa', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  enableSMS2FA: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/settings/security/enable-sms-two-fa', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  confirmSMS2FA: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/settings/security/confirm-sms-two-fa', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  disableSMS2FA: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/settings/security/request-disable-sms-two-fa', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  confirmDisableSMS2FA: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/settings/security/disable-sms-two-fa', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  enableEmail2FA: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/settings/security/enable-email-two-fa', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  confirmEmail2FA: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/settings/security/confirm-email-two-fa', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  disableEmail2FA: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/settings/security/request-disable-email-two-fa', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  confirmDisableEmail2FA: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/settings/security/disable-email-two-fa', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  getCustomization: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/settings/customization/get', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },

  updateCustomization: (body) => {
    return new Promise((resolve, reject) => {
      request
        .post('/admin/settings/customization/update', { ...body })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error?.response?.data?.message);
        });
    });
  },
};

export default apiWrapper(settingApi, ['getGeneralSetting']);
